import module from './module';


export default {
    get dark(): boolean {
        return module.dark;
    },
    set dark(value: boolean) {
        module.dark = value;
    }
};