





















import Class from './c-chart-stack-column-view';
export default Class;
