import { Vue, Prop, Component } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';

@Component({
    name: 'd-toneBar',
    components: {
        highcharts: Chart
    }
})
export default class ChartToneBar extends Vue {
    @Prop({
        required: true,
        default: () => {
            return [];
        }
    })
    private datas!: any[]; // массив постов

    private chartOptions = {};
    private month = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

    public created() {
        this.$watch('datas', () => {
            this.updateOptions();
        });
    }

    public mounted() {
        this.updateOptions();
    }

    private updateOptions() {
        const colors = ['#C0504C', '#207CAD', '#56B475'];
        const categories = new Set();
        const mapSeries = new Map();
        let datas = { neg: 0, neut: 0, pos: 0, tot: 0 };
        const series = [];

        this.datas.forEach((item) => {
            if (!item.isGroup) {
                datas = this.mapValue(mapSeries, item.autor);

                switch (item.tone) {
                    case -2:
                        datas.neg++;
                        break;
                    case 1:
                        datas.neut++;
                        break;
                    case 2:
                        datas.pos++;
                        break;
                    default:
                        break;
                }
                datas.tot++;
                mapSeries.set(item.autor, datas);
            }
        });

        const entries = Array.from(mapSeries, ([name, value]) => ({ name, value }));
        const sortMap = entries.sort(this.sortDownByField('value'));

        const negative: any[] = [];
        const neutral: any[] = [];
        const positive: any[] = [];
        let i = 0;
        sortMap.forEach((item) => {
            if (i < 5) {
                categories.add(item.name);
                positive.push(item.value.pos);
                neutral.push(item.value.neut);
                negative.push(item.value.neg);
            }
            i++;
        });

        series.push({ name: 'Негативная', data: negative });
        series.push({ name: 'Нейтральная', data: neutral });
        series.push({ name: 'Позитивная', data: positive });

        this.chartOptions = {
            colors,
            chart: {
                type: 'bar',
                height: 200
            },
            title: {
                text: (this.$route.name === 'Topic' ? 'Топ авторов' : ''),
                style: {
                    color: '#5e5e5e',
                    fontSize: '1rem'
                }
            },
            xAxis: {
                categories: Array.from(categories),
                labels: {
                    style: {
                        fontSize: '1.14rem',
                        color: '#5e5e5e'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            legend: {
                verticalAlign: 'top',
                itemStyle: {
                    color: '#5e5e5e',
                    fontSize: '.9rem',
                    fontWeight: '500'
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                        backgroundColor: '#fff',
                        borderColor: '#f2f2f2',
                        borderWidth: '1px',
                        color: 'black',
                        /* formatter() {
                            return (this.y > 0 ? '<span style="color: ' + this.point.color + '">' + this.y + '</span>' : '');
                        }, */
                        style: {
                            fontSize: '.8rem',
                            fontWeight: 'bold',
                            color: '#fff',
                            textOutline: '0'
                        }
                    }
                },
                series: {
                    stacking: 'normal',
                    pointWidth: 15
                }
            },
            series: series,
            credits: {
                enabled: false
            }
        };
    }

    private sortDownByField(field: string): any {
        return (a: { [x: string]: any }, b: { [x: string]: any }) => (a[field].tot > b[field].tot ? 1 : -1);
    }

    private mapValue(map: Map<any, any>, autor: string) {
        let datas = { 'tot': 0, 'neg': 0, 'neut': 0, 'pos': 0 };
        if (map.size === 0) {
            // map = new Map()
            map.set(autor, datas);
        } else {
            if (map.get(autor) === undefined) {
                map.set(autor, datas);
            } else {
                datas = map.get(autor);
            }
        }
        return datas;
    }
}