







































import Class from './c-content-chart-bars';
export default Class;
