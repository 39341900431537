import { Ax } from '@/utils';

import { accessor } from './module';
import { getPeriodRange } from './periods';
import { EIndicatorPeriodicity, IIndicator, TWidget } from './types';

const loadInitial = () => {
    // Loading indicators
    Ax<IIndicator[]>(
        {
            url: '/api/integration/excel/data/indicators/full'
        },
        (loadedData) => {
            const indicators: IIndicator[] = [];

            // eslint-disable-next-line consistent-return
            loadedData.forEach((indicator) => {
                try {
                    if (indicator.periodicity === undefined || indicator.periodicity === null) {
                        return undefined;
                    }
                    indicator.periodicity = indicator.periodicity.trim().toLowerCase() as EIndicatorPeriodicity;

                    switch (indicator.periodicity) {
                        case EIndicatorPeriodicity.DAY:
                        case EIndicatorPeriodicity.DAYLY:
                        case EIndicatorPeriodicity.INTIME:
                        case EIndicatorPeriodicity.DAY_WITH_ACCUMULATION:
                        case EIndicatorPeriodicity.MONTH:
                        case EIndicatorPeriodicity.MONTH_WITH_ACCUMULATION:
                        case EIndicatorPeriodicity.QUARTER:
                        case EIndicatorPeriodicity.QUARTER_WITH_ACCUMULATION:
                        case EIndicatorPeriodicity.WEEK:
                        case EIndicatorPeriodicity.YEAR:
                        case EIndicatorPeriodicity.ONCE_YEAR:
                            break;
                        default:
                            throw new Error(`Unknown periodicity "${indicator.periodicity}"`);
                    }


                    if (indicator.indicator !== null) { indicator.indicator = indicator.indicator.trim(); }

                    if ((indicator.shortIndicator === undefined) || (indicator.shortIndicator === null) || (indicator.shortIndicator.trim().length === 0)) {
                        indicator.shortIndicator = indicator.indicator;
                    }

                    indicator.key = `${indicator.indicator} || ${indicator.sheet} || ${indicator.file} || ${indicator.periodicity}`;

                    indicator.newKey = `${indicator.indicator} || ${indicator.sheet} || ${indicator.file}`;

                    let withPlan = false;

                    let items = indicator.items;
                    if (items === undefined) {
                        items = indicator.itemsInd;
                    }
                    items.forEach((indicatorItem, i) => {
                        if (typeof indicatorItem.date !== 'number') {
                            throw new Error(`items[${i}].date is not number - ${indicatorItem.date === null ? 'null' : typeof indicatorItem.date}`);
                        }

                        if (indicatorItem.plan !== null) {
                            withPlan = true;
                        }

                        const date = new Date(indicatorItem.date);
                        if (date.getTimezoneOffset() / 60 !== -6) {
                            const def = -6;
                            const diff = Math.abs(def - date.getTimezoneOffset() / 60);
                            date.setHours(date.getHours() + diff);
                        }
                        const periodRange = getPeriodRange(indicator.periodicity, date);
                        const periodStart = periodRange[0];
                        const periodEnd = periodRange[1];

                        indicatorItem.date = date;
                        indicatorItem.periodStart = periodStart;
                        indicatorItem.periodEnd = periodEnd;
                        indicatorItem.fact = typeof indicatorItem.fact === 'number' ? indicatorItem.fact : 0;
                        indicatorItem.plan = typeof indicatorItem.plan === 'number' ? indicatorItem.plan : 0;
                    });

                    indicator.withPlan = withPlan;

                    indicator.items = items.sort((a: any, b: any) => a.date - b.date);
                    indicators.push(indicator);
                } catch (e) {
                    // eslint-disable-next-line no-warning-comments
                    // TODO use toast service
                    console.error(`Error while preparing indicator "${indicator.indicator}"`, e);
                }
            });

            indicators.sort((a, b): number => {
                if (a.key > b.key) {
                    return 1;
                }
                if (a.key < b.key) {
                    return -1;
                }

                return 0;
            });

            accessor.indicators = indicators;
        },
        (error) => {
            // eslint-disable-next-line no-warning-comments
            // TODO use toast service
            console.error('Error while loading indicators', error);
        }
    );

    // Loading hierarchy
    Ax<TWidget[]>(
        {
            url: '/api/datas/hierarchy'
        },
        (data) => {
            accessor.widgets = data;
            accessor.widgetsLoadState = 'ready';
        },
        (error) => {
            accessor.widgetsLoadState = 'error';
            // eslint-disable-next-line no-warning-comments
            // TODO use toast service
            console.error('Error while loading widgets config', error);
        }
    );
    Ax<any>(
        {
            url: '/api/dict/integration_category'
        },
        (data) => {
            accessor.categories = data;
            accessor.widgetsLoadState = 'ready';
        },
        (error) => {
            accessor.widgetsLoadState = 'error';
            // eslint-disable-next-line no-warning-comments
            // TODO use toast service
            console.error('Error while loading widgets config', error);
        }
    );
};

const saveWidgets = () => {
    accessor.widgetsLoadState = 'saving';
    Ax(
        {
            url: '/api/datas/hierarchy',
            data: accessor.widgets,
            method: 'POST'
        },
        () => {
            // no-op - widgets are saved
        },
        (error) => {
            // eslint-disable-next-line no-warning-comments
            // TODO use toast service
            console.error('Error while saving widgets', error);
        },
        () => {
            accessor.widgetsLoadState = 'ready';
        }
    );
};

const nextWidgetId = (callback: (id: number) => void, final: () => void) => {
    Ax<number>(
        {
            url: '/api/datas/next-widget-id'
        },
        (data) => {
            callback(data);
        },
        undefined,
        final
    );
};


export default { nextWidgetId, loadInitial, saveWidgets };