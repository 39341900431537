import { Component, Prop, Vue } from 'vue-property-decorator';


import ChartToneLine from './chart-tone-line';

import store from '@/services/store';

export interface IShownData {
    type: string;
    data: any | null;
}

@Component({
    components: {
        'chart-tone-line': ChartToneLine
    }
})
export default class CContentChartToneLine extends Vue {
    @Prop({
        required: true,
        default: () => {
            return { value: 1 };
        }
    })
    private period!: any; // выбранный период

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly full!: boolean;

    public datas: any = null;


    private mounted() {
        this.loadData();
    }

    public code: any = store.state._instanceCode;

    public get selectedMap(): any {
        let ajxParams;
        if (this.code === '' || this.code === 55) {
            ajxParams = {
                'params': {
                    'type': 1,
                    'period': 7,
                    'selectedPeriod': null,
                    'keywords': 'Павлодар* | Аксу* | (Ақсу* + қала) | (Экибастуз + город) | (Екібастұз + қала) | (Актогайск + район) | (Ақтоғай + аудан) | (Баянаульск + район) | (Баянауыл + аудан) | (Железинск + район) | (Железин + аудан) | (Иртышск + район) | (Ертіс + аудан) | (Качирск + район) | (Качиры + аудан) | (Район + Тереңкөл) | (Район + Теренкол) | (Тереңкөл + аудан) | (Лебяжинск + район) | (Лебяжі + аудан) | (Район + Аққулы) | (Район + Аккулы) | (Аққулы + аудан) | (Майск + район) | (Май + аудан) | (Павлодарск + район) | (Павлодар + аудан) | (Успенск* + район) | (Успен + ауданы) | (Щербактинск* + район) | (Шарбақты + аудан) | Pavlodar |(Скаков* + Абылкаир) | (Сқақов + Әбілқайыр*)',
                    'attrSearch': false,
                    'tone': null
                }
            };
        } else if (this.code === 23) {
            ajxParams = {
                'params': {
                    'type': 1,
                    'period': 7,
                    'selectedPeriod': null,
                    'keywords': 'Актюбинск* | Ақтөбе* | Актобе | (Алгинск* + район) | (Алға + район) | (Айтекебийск* + район) | (Әйтеке би + район) | (Байганинск* + район) | (Байғанин + район)  | (Каргалинск* + район) | (Қарғалы + район) | (Хобдинск + район) | (Қобда + район) | (Мартукск* + район) | (Мәртөк + район) | (Мугалжарск* + район) | (Мұғалжар + район) | (Уилск* + район) | (Ойыл+ район) | (Темирск* + район) | (Хромтауск + район) | (Шалкарск + район) | (Иргизск + район) | (Алға + аудан) | (Әйтеке би + аудан) | (Байғанин + аудан) | (Қарғалы + аудан) | (Қобда + аудан) | (Мәртөк + аудан) | (Мұғалжар + аудан) | (Ойыл + аудан) | (Темір + аудан) | (Хромтау + аудан) | (Шалқар + аудан) | (Ырғыз + аудан) | (Темір + район) | (Хромтау + район) | (Шалқар + район) | (Ырғыз + район*)',
                    'attrSearch': false,
                    'tone': null
                }
            };
        } else if (this.code === 11) {
            ajxParams = {
                'params':
                {
                    'type': 1,
                    'period': 7,
                    'selectedPeriod': null,
                    'keywords': 'Акмола| Aqmola|Кокшетау|Көкшетау| (Ақкөл+ аудан) | (Аккол+ район) | Акколь| Aqkol |Алексеевка| (Аршалы+ аудан) |(Аршалын+район) | Вишнев| (Аршалы+Вишнёвка)|(Астрахан+ аудан)|(Астрахан+район)|Астраханка| (Атбасар+ аудан) | (Атбасар+район) |Атбасар | Atbasar* | (Бұланды+ аудан) |(Буланды+ район)|Макинский Макинск|(Бурабай+ аудан) | Боровое| (Бурабайск+ район) | Щучинск* | (Щучинск+ аудан) | (Егіндікөл+ аудан) | (Егиндыкол+ район) | Краснознамен* |(Біржан+сал+ аудан) | (район+ Биржан* +сал) | Енбекшильдер | Степняк|(Ерейментау+ аудан)|(Ерейментауск+район)|Ерейментау|(Есіл+ аудан) | ( Есильск+район) |  Есил* |(Жақсы+ аудан) | (Жаксынск+район) | Жаксы| (Жарқайың+ аудан) | (Жаркаинск+ район) |Державинский| Державинск|(Зеренді+аудан) |(Зерендинск+ район) | Зеренда|(Қорғалжын+ аудан) |(Коргалжынск+ район) | Коргалжын|(Сандықтау+аудан) | (Сандыктауск+район)| Балкашинск| Балкашино* |(Целиноград+ аудан) | (Целиноградск+район) | Аккол* |(Шортанды+ аудан) | (Шортандинск+район)|Шортанды|(город+Кокшетау)|Кокшетау|Kokshetau* |( город+ Степногорск)| Ильинка| Степногорск',
                    'attrSearch': false,
                    'tone': null
                }
            };
        }
        const settings = {
            method: 'POST',
            body: JSON.stringify(ajxParams),
            headers: {
                'Accept': 'application/json, text/plain, **/**',
                'Content-Type': 'application/json;charset=utf-8'
            }
        };
        const result = {
            postLine: 'https://mm.csi.kz/app-py/report-form',
            settings: settings
        };

        return result;
    }

    private async loadData() {
        try {
            const response = await fetch(this.selectedMap.postLine, this.selectedMap.settings);
            if (!response.ok) {
                const message = `>>>An error has occured>>>: ${response.status}`;
                return message;
            }
            const data = await response.json();
            if (data !== null || data.data.length !== 0) {
                data.data.sort(this.sortByField('bDate'));
            }
            this.datas = { type: 'chart-tone-line', data: data.data };
        } catch (e) {
            return e;
        }
        // console.log('result=', this.datas);
        return this.datas;
    }

    private sortByField(field: string): any {
        return (a: { [x: string]: number }, b: { [x: string]: number }) => (a[field] > b[field] ? 1 : -1);
    }

    public get indicatorKeys(): string[] {
        return [];
    }
}