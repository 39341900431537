import { Component, Model, Prop, Vue } from 'vue-property-decorator';

import { getDateZoom, EDateZoom } from '@/utils/dates';


const events = {
    model: 'change',
    zoom: 'zoom',
    dayClick: 'day-click',
    todayClick: 'today-click',
    clearClick: 'clear-click',
    toolbarClick: 'toolbar-click'
};

const today = new Date().clearTimePart();

@Component
export default class CDatePickerBody extends Vue {
    // #region Lifecycle
    private created(): void {
        this.$watch('zoom', this.zoomChanged);
        this.$watch('minZoom', this.minZoomChanged);
        this.$watch('maxZoom', this.maxZoomChanged);
        this.$watch('value', this.valueChanged);
        this.$watch('min', this.minChanged);
        this.$watch('max', this.maxChanged);

        this.updateLocalZoom();
        this.updateShownValue();
    }
    // #endregion


    // #region Zoom
    @Prop({
        type: Number,
        required: false,
        default: null
    })
    public readonly zoom!: number | null;

    public get actualZoom(): EDateZoom | null {
        if (this.zoom === null) {
            return null;
        }

        return getDateZoom({
            alias: 'zoom',
            value: this.zoom,
            fallback: EDateZoom.MONTH,
            min: EDateZoom.MONTH,
            max: EDateZoom.YEARS_1000000
        });
    }

    private zoomChanged(): void {
        this.updateLocalZoom();
    }
    // #endregion


    // #region Min zoom
    @Prop({
        type: Number,
        required: false,
        default: null
    })
    public readonly minZoom!: number | null;

    public get actualMinZoom(): EDateZoom {
        if (this.minZoom === null) {
            return EDateZoom.MONTH;
        }

        return getDateZoom({
            alias: 'min zoom',
            value: this.minZoom,
            fallback: EDateZoom.MONTH,
            min: EDateZoom.MONTH,
            max: EDateZoom.YEARS_1000000
        });
    }

    private minZoomChanged(): void {
        this.updateLocalZoom();
    }
    // #endregion


    // #region Max zoom
    @Prop({
        type: Number,
        required: false,
        default: null
    })
    public readonly maxZoom!: number | null;

    public get actualMaxZoom(): EDateZoom {
        if (this.maxZoom === null) {
            return EDateZoom.YEARS_1000;
        }

        return getDateZoom({
            alias: 'max zoom',
            value: this.maxZoom,
            fallback: EDateZoom.YEARS_1000,
            min: EDateZoom.MONTH,
            max: EDateZoom.YEARS_1000000
        });
    }

    private maxZoomChanged(): void {
        this.updateLocalZoom();
    }
    // #endregion


    // #region Local zoom
    private localZoom = EDateZoom.MONTH;

    private updateLocalZoom(): void {
        if (this.actualMinZoom > this.actualMaxZoom) {
            console.error(`Min zoom ${this.actualMinZoom} is greater than ${this.actualMaxZoom}`);
            return;
        }

        if (this.actualZoom === null) {
            if (this.localZoom < this.actualMinZoom) {
                this.localZoom = this.actualMinZoom;
            } else if (this.localZoom > this.actualMinZoom) {
                this.localZoom = this.actualMaxZoom;
            }
        } else if (this.actualZoom < this.actualMinZoom) {
            console.error(`Zoom ${this.actualZoom} is less than min zoom ${this.actualMinZoom}`);
        } else if (this.actualZoom > this.actualMaxZoom) {
            console.error(`Zoom ${this.actualZoom} is greater than max zoom ${this.actualMaxZoom}`);
        } else if (this.localZoom !== this.actualZoom) {
            this.localZoom = this.actualZoom;
        }
    }

    public resetLocalZoom(): void {
        this.setZoom(this.actualMinZoom);
    }

    private setZoom(value: EDateZoom): void {
        let newZoom = value;
        if (newZoom < this.actualMinZoom) {
            newZoom = this.actualMinZoom;
        } else if (newZoom > this.actualMaxZoom) {
            newZoom = this.actualMaxZoom;
        }

        if (this.actualZoom === null) {
            if (this.localZoom !== newZoom) {
                this.localZoom = newZoom;
            }
            this.$emit(events.zoom, newZoom);
        } else if (this.actualZoom !== newZoom) {
            this.$emit(events.zoom, newZoom);
        }
    }
    // #endregion


    private onZoomChange(zoom: number): void {
        const newZoom = getDateZoom({
            alias: 'new zoom',
            value: zoom,
            fallback: this.localZoom,
            min: this.actualMinZoom,
            max: this.actualMaxZoom
        });

        this.setZoom(newZoom);
    }


    // #region Value
    @Model(events.model, {
        type: Date,
        required: false,
        default: null
    })
    public readonly value!: Date | null;

    private valueChanged(): void {
        this.updateShownValue();
    }
    // #endregion


    // #region Min
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly min!: Date | null;

    private minChanged(): void {
        this.updateShownValue();
    }
    // #endregion


    // #region Max
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly max!: Date | null;

    private maxChanged(): void {
        this.updateShownValue();
    }
    // #endregion


    // #region Shown value
    private shownValue = new Date();

    private updateShownValue(): void {
        if ((this.min !== null) && (this.max !== null) && (this.min > this.max)) {
            console.error(`Min ${this.min} is less than max ${this.max}`);
            return;
        }

        if (this.value === null) {
            if ((this.min !== null) && (this.shownValue < this.min)) {
                this.shownValue = new Date(this.min);
            } else if ((this.max !== null) && (this.shownValue > this.max)) {
                this.shownValue = new Date(this.max);
            }
        } else if (this.shownValue.notEquals(this.value)) {
            this.shownValue = new Date(this.value);
        }
    }
    // #endregion


    private setValue(value: Date): void {
        const date = new Date(value);
        if (this.value !== null) {
            date.copyTimePart(this.value);
        }

        if ((this.min !== null) && (date < this.min)) {
            date.setTime(this.min.getTime());
        }
        if ((this.max !== null) && (date > this.max)) {
            date.setTime(this.max.getTime());
        }

        if ((this.value === null) || this.value.notEquals(date)) {
            this.$emit(events.model, date);
        }
        if (this.shownValue.notEquals(date)) {
            this.shownValue = new Date(date).clearTimePart();
        }
    }


    private onDayClick(value: Date): void {
        this.setValue(value);
        this.$emit(events.dayClick, value);
    }


    private onAnotherMonthClick(value: Date) {
        this.shownValue = new Date(value);
    }


    // #region Locale
    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly locale!: string | null;
    // #endregion


    // #region First day of week
    @Prop({
        type: Number,
        required: false,
        default: null
    })
    public readonly firstDayOfWeek!: number | null;
    // #endregion


    // #region Tool buttons
    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly today!: string | null;

    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly reset!: string | null;

    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly clear!: string | null;

    public get showTodayButton(): boolean {
        return (this.today !== null);
    }

    public get todayDisabled(): boolean {
        if (this.min !== null) {
            const date = new Date(this.min)
                .clearTimePart();
            if (today < date) {
                return true;
            }
        }

        if (this.max !== null) {
            const date = new Date(this.max)
                .clearTimePart()
                .changeDate(1)
                .changeMilliseconds(-1);
            if (today > date) {
                return true;
            }
        }

        return false;
    }

    public get showResetButton(): boolean {
        return (this.reset !== null);
    }

    public get resetDisabled(): boolean {
        return (
            (
                (this.value === null)
                && (this.shownValue.datePartEquals(new Date()))
            )
            || (
                (this.value !== null)
                && (this.shownValue.datePartEquals(this.value))
            )
        );
    }

    public get showClearButton(): boolean {
        return (this.clear !== null);
    }

    public get showToolbar(): boolean {
        return (
            this.showTodayButton
            || this.showResetButton
            || this.showClearButton
            || (
                Array.isArray(this.$slots.toolbar)
                && (this.$slots.toolbar.isNotEmpty)
            )
        );
    }

    private onTodayClick(): void {
        if (!this.todayDisabled) {
            this.resetLocalZoom();
            this.setValue(today);
            this.$emit(events.todayClick, today);
        }
    }

    private onResetClick(): void {
        if (!this.resetDisabled) {
            this.resetLocalZoom();
            if (this.value === null) {
                this.shownValue = new Date().clearTimePart();
            } else {
                this.shownValue = new Date(this.value).clearTimePart();
            }
            this.updateShownValue();
        }
    }

    private onClearClick(): void {
        if (this.value !== null) {
            this.resetLocalZoom();
            this.$emit(events.model, null);
            this.$emit(events.clearClick);
        }
    }

    private onToolbarClick(ev: MouseEvent) {
        const toolbarEl = this.$refs.toolbar;
        if ((toolbarEl instanceof HTMLElement) && (ev.target !== toolbarEl)) {
            this.resetLocalZoom();
            this.$emit(events.toolbarClick);
        }
    }
    // #endregion
}