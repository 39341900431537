



















































import Class from './c-board-right';

export default Class;
