import { Component, Model, Prop, Vue } from 'vue-property-decorator';

import { Flying } from '@/utils';

import { flyingTriggers, TFlyingTrigger } from '../types';


const events = {
    model: 'change',
    anchorHidden: 'anchor-hidden',
    anchorShown: 'anchor-shown',
    rectsDefined: 'rects-defined',
    bodyPrepare: 'body-prepare',
    rectChoosen: 'rect-choosen',
    bodyPlaced: 'body-placed',
    beforeArrowPlace: 'before-arrow-place',
    afterArrowPlace: 'after-arrow-place'
};

@Component
export default class CDropdown extends Vue {
    // #region Lifecycle
    private mounted(): void {
        this.localEl = this.$el as HTMLButtonElement;
    }

    private beforeDestroy(): void {
        this.localEl = null;
    }
    // #endregion


    // #region Elements
    private localEl: HTMLButtonElement | null = null;
    // #endregion


    // #region Value
    @Model(events.model, {
        type: Boolean,
        required: false,
        default: null
    })
    public readonly value!: boolean | null;

    private get localValue(): boolean | null {
        return this.value;
    }

    private set localValue(value: boolean | null) {
        this.$emit(events.model, value);
    }
    // #endregion


    // #region Arrow
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly arrow!: boolean;
    // #endregion


    // #region Initially visible
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly initiallyVisible!: boolean;
    // #endregion


    // #region Viewport
    @Prop({
        type: [ Element, String ],
        required: false,
        default: null,
        validator(value: any): boolean {
            return (value === null) || (value === 'portal') || (value === 'body') || (value instanceof Element);
        }
    })
    public readonly viewport!: Element | 'portal' | 'body' | null;
    // #endregion


    // #region Boundary
    @Prop({
        type: Element,
        required: false,
        default: null
    })
    public readonly boundary!: Element | null;
    // #endregion


    // #region Allow anchor overlap
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly allowAnchorOverlap!: boolean;
    // #endregion


    // #region Strategy
    @Prop({
        type: String,
        required: false,
        default: 'fixed',
        validator(value: any): boolean {
            return Flying.Types.strategies.includes(value);
        }
    })
    public readonly strategy!: Flying.Types.TStrategy;
    // #endregion


    // #region Placement
    @Prop({
        type: Object,
        required: false,
        default: (): Flying.Types.IPlacement => {
            return {
                placement: 'bottom',
                variant: 'start'
            };
        }
    })
    public readonly placement!: Flying.Types.IPlacement | null;
    // #endregion


    // #region Alt placements
    @Prop({
        type: Array,
        required: false,
        default: (): Flying.Types.IPlacement[] => {
            return [
                {
                    placement: 'auto',
                    variant: 'start'
                }
            ];
        }
    })
    public readonly altPlacements!: Flying.Types.IPlacement[] | null;
    // #endregion


    // #region Show without anchor
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly showWithoutAnchor!: boolean;
    // #endregion


    // #region Trigger
    @Prop({
        type: [ String, Array ],
        required: false,
        default: 'anchor-click,window-click-hide',
        validator(value: any): boolean {
            if (value === null) {
                return true;
            }

            if (typeof value === 'string') {
                const items = value.split(',');

                for (const item of items) {
                    const prepared = item.trim() as TFlyingTrigger;
                    if (flyingTriggers.notIncludes(prepared)) {
                        return false;
                    }
                }

                return true;
            }

            if (Array.isArray(value)) {
                for (const item of value) {
                    if (flyingTriggers.notIncludes(item)) {
                        return false;
                    }
                }
                return true;
            }

            return false;
        }
    })
    public readonly trigger!: TFlyingTrigger | TFlyingTrigger[] | null;
    // #endregion


    // #region Variant
    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly variant!: string | null;
    // #endregion


    // #region Trigger class
    public get triggerClass(): Record<string, boolean> {
        const result: Record<string, boolean> = {};

        if (this.variant !== null) {
            result[`control-${this.variant}`] = true;
        }

        return result;
    }
    // #endregion


    // #region Events-callbacks
    private onAnchorHidden(data: Flying.Types.IAnchorHideCallbackData): void {
        this.$emit(events.anchorHidden, data);
    }

    private onAnchorShown(data: Flying.Types.IAnchorHideCallbackData): void {
        this.$emit(events.anchorShown, data);
    }

    private onRectsDefined(data: Flying.Types.IRectsCallbackData): void {
        this.$emit(events.rectsDefined, data);
    }

    private onBodyPrepare(data: Flying.Types.IBodyPrepareCallbackData): void {
        Flying.Flying.setMaxVisible(data);
        this.$emit(events.bodyPrepare, data);
    }

    private onRectChoosen(data: Flying.Types.IChoosenRectCallbackData): void {
        this.$emit(events.rectChoosen, data);
    }

    private onBodyPlaced(data: Flying.Types.IBodyPlacedCallbackData): void {
        this.$emit(events.bodyPlaced, data);
    }

    private onBeforeArrowPlace(data: Flying.Types.IBeforeArrowPlaceCallbackData): void {
        this.$emit(events.beforeArrowPlace, data);
    }

    private onAfterArrowPlace(data: Flying.Types.IAfterArrowPlaceCallbackData): void {
        this.$emit(events.afterArrowPlace, data);
    }
    // #endregion
}