import { Component, Prop, Vue } from 'vue-property-decorator';

import '../../initialization';
import queue from '../queue';
import { accessor } from '../../store';
import { ISelectedIndicatorItems, IWidgetTextBars } from '../../store/types';
import { assignGrow } from '../common';

interface IShownItem {
    percent: number;
    src: string;
    title: string;
    value: number;
    unit: string | null;
    growProc: any;
}

@Component
export default class CContentTextBars extends Vue {
    private created() {
        this.$watch('getShownData', this.selectedMapChanged);
    }

    private mounted() {
        this.selectedMapChanged();
    }

    private beforeDestroy() {
        queue.clear(this);
    }


    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;

    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: IWidgetTextBars;


    public get indicatorConfigs() {
        return this.data.indicatorConfigs;
    }


    public get selectedMap(): Map<string, ISelectedIndicatorItems> {
        const result = new Map<string, ISelectedIndicatorItems>();

        this.indicatorConfigs.forEach((key) => {
            const selectedIndicator = accessor.selectedMap.get(key.indicator);
            if (selectedIndicator !== undefined) {
                result.set(key.indicator, selectedIndicator);
            }
        });

        return result;
    }

    private previousDate() {
        this.year += 1;
    }
    private nextDate() {
        this.year -= 1;
    }

    private year = 0;

    private selectedMapChanged() {
        setTimeout(() => {
            queue.schedule(this, () => {
                this.shownData = this.getShownData;
            });
        }, 1);
    }

    private indic: any = [];

    private itemsLen = 0;

    private shownData: IShownItem[] = [];

    public get getShownData(): IShownItem[] {
        const result: IShownItem[] = [];
        const len: any = [];
        let indicLen = 0;

        this.indicatorConfigs.forEach((indicatorKey) => {
            let data: any = null;
            let percent: number | 0 = 0;
            let src = '';
            let unitLowerCased: string | null = null;
            let measurement = 1;
            if (indicatorKey.measurement !== undefined) {
                measurement = indicatorKey.measurement;
            }
            const growDatas = assignGrow(indicatorKey.indicator);
            if (growDatas !== undefined) {
                data = growDatas;
                percent = ((growDatas !== undefined) ? (growDatas.value * 100) / growDatas.max : 0);

                if (growDatas.value > growDatas.max) {
                    data.max = growDatas.value;
                }

                if (growDatas.unit === 'лет') {
                    src = '/img/component/time.png';
                }
                if (growDatas.unit === '%') {
                    src = '/img/component/percent.png';
                }
                const confUnit: any = indicatorKey.unit;
                let unit: any;
                if ((Object.keys(confUnit).length === 0)) {
                    unit = data.unit;
                } else if ((confUnit.name_ru === '')) {
                    unit = data.unit;
                } else {
                    if (confUnit.nationalSymbol === undefined || confUnit.nationalSymbol === null) {
                        unit = confUnit.name_ru;
                    } else {
                        unit = confUnit.nationalSymbol;
                    }
                }
                if (unit !== null || unit !== undefined) {
                    unitLowerCased = unit.toLowerCase();
                    if (unitLowerCased === 'процент') {
                        unitLowerCased = '%';
                    }
                }
                const selectedIndicator = accessor.selectedMap.get(indicatorKey.indicator);
                if (selectedIndicator !== undefined) {
                    if (selectedIndicator.items.length > len.length) {
                        const indicator = [];
                        for (let i = selectedIndicator.items.length - 1; i > -1; i--) {
                            const current = selectedIndicator.items[i].fact;
                            const date = selectedIndicator.items[i].date.getFullYear();
                            let growProc = null;
                            const lcUnit = (Boolean((unit === 'процент') || (unit === '%')));
                            if (i !== 0) {
                                const j = i - 1;
                                const previous = selectedIndicator.items[j].fact;
                                growProc = (lcUnit
                                    ? Math.round((current - previous) * 100) / 100
                                    : Math.round(((current - previous) / previous) * 100 * 100) / 100);
                            }
                            const item = {
                                percent: (percent > 100 ? 100 : percent),
                                src: src,
                                title: indicatorKey.title,
                                value: current * measurement,
                                unit: unitLowerCased,
                                growProc: growProc,
                                date
                            };
                            indicator.push(item);
                            if (i === 0) {
                                len.push(indicator);
                            }
                            if (indicLen < selectedIndicator.items.length) {
                                indicLen = selectedIndicator.items.length;
                            }
                        }
                    }
                }
            }
            result.push({
                percent: (percent > 100 ? 100 : percent),
                src: src,
                title: data.title,
                value: data.value * measurement,
                unit: unitLowerCased,
                growProc: data.growProc
            });
        });
        this.indic = len;
        this.itemsLen = indicLen;
        return result;
    }

    private selectedYear: any[] = [];
    private get years() {
        if (this.indic[0] !== undefined) {
            const items = this.indic[0];
            const years: any[] = [];
            items.forEach((item: any) => {
                years.push(item.date);
            });
            this.selectedYear = items;
            const date = new Date();
            const currYear = date.getFullYear();
            const firstIndex = this.selectedYear.findIndex(item => item.date === currYear);
            this.year = firstIndex;
            return years.reverse();
        }
        return null;
    }
}