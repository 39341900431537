import { Component, Prop, Vue } from 'vue-property-decorator';

import '../../initialization';
import { accessor } from '../../store';
import { ISelectedIndicatorItems, IWidgetChartStackColumn } from '../../store/types';

import queue from '../queue';

import CChartStackColumnView from './c-chart-stack-column-view';
import { IItemGraph, IChartItem, ILastResult, IItemTitle } from './types';

@Component({
    components: {
        'c-chart-stack-column-view': CChartStackColumnView
    }
})
export default class CContentChartStackColumn extends Vue {
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;

    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: IWidgetChartStackColumn;

    @Prop()
    public readonly redrawTrigger!: any;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;



    private created() {
        this.$watch('getResult', this.selectedMapChanged);
        this.$watch('year', this.showNav);
    }

    private mounted() {
        this.selectedMapChanged();
    }

    private beforeDestroy() {
        queue.clear(this);
    }

    private showNav() {
        return (this.year > -(this.indic !== null ? this.indic : 0) + 1 && (this.indic !== null ? this.indic : 0) > 7);
    }

    public get indicatorConfigs() {
        return this.data.indicatorConfigs;
    }


    public get selectedMap(): Map<string, ISelectedIndicatorItems> {
        const result = new Map<string, ISelectedIndicatorItems>();

        // eslint-disable-next-line consistent-return
        this.indicatorConfigs.forEach((key) => {
            const newKey: any = this.getIndicator(key.indicator);
            let selectedIndicator = accessor.selectedMap.get(key.indicator);
            if (selectedIndicator === undefined) {
                selectedIndicator = accessor.selectedMap.get(newKey.key);
                if (selectedIndicator !== undefined) {
                    result.set(newKey.key, selectedIndicator);
                    return result;
                }
            }
            if (selectedIndicator !== undefined) {
                result.set(key.indicator, selectedIndicator);
            }
        });

        return result;
    }

    private selectedMapChanged() {
        setTimeout(() => {
            queue.schedule(this, () => {
                this.sumFact = this.getSumFact();
                this.titleData = this.getTitleData();
                this.result = this.getResult;
            });
        }, 1);
    }


    private sumFact: number | null = null;
    private titleData: IItemTitle | null = null;
    private result: ILastResult[] | null = null;


    public getSumFact(): number | null {
        let check: number | null;
        check = null;
        // eslint-disable-next-line consistent-return
        this.indicatorConfigs.forEach((key) => {
            let indicator = accessor.getIndicator(key.indicator);
            if (indicator === undefined) {
                indicator = this.getIndicator(key.indicator);
            }

            if (indicator === undefined) {
                return null;
            }
            const selectedIndicator = this.selectedMap.get(indicator.key);
            if (selectedIndicator !== undefined) {
                const indicatorItem = selectedIndicator.item;
                if (indicatorItem !== undefined) {
                    if (check !== null) {
                        check = check + indicatorItem.fact;
                    } else {
                        check = indicatorItem.fact;
                    }
                }
            }
        });
        return check;
    }

    private getIndicator(key: string) {
        const list = accessor.indicators;
        const indicator = list.find(x => x.newKey === key);
        return indicator;
    }

    public getTitleData(): IItemTitle | null {
        let growIndicProc: number | null = null;
        let growIndic: number | null = null;

        let previousFact = 0;

        // актуальная сумма по всем показателям
        let sumFact: number | null = null;
        //  наименование единиц измерения
        let unitLowerCased: string | null = null;

        // eslint-disable-next-line consistent-return
        this.indicatorConfigs.forEach((key) => {
            let indicator = accessor.getIndicator(key.indicator);
            if (indicator === undefined) {
                indicator = this.getIndicator(key.indicator);
            }
            if (indicator === undefined) {
                return null;
            }
            const selectedIndicator = this.selectedMap.get(indicator.key);
            if (selectedIndicator !== undefined) {
                const indicatorItem = selectedIndicator.item;

                const previous = selectedIndicator.previousItem;
                const typeIndic = selectedIndicator.character;
                if (indicatorItem !== undefined) {
                    let measurement = 1;
                    if (key.measurement !== undefined) {
                        measurement = key.measurement;
                    }
                    if (sumFact !== null) {
                        sumFact = sumFact + indicatorItem.fact * measurement;
                    } else {
                        sumFact = indicatorItem.fact * measurement;
                        const confUnit: any = key.unit;
                        let unit: any;
                        if ((Object.keys(confUnit).length === 0)) {
                            unit = selectedIndicator.unit;
                        } else if ((confUnit.name_ru === '')) {
                            unit = selectedIndicator.unit;
                        } else {
                            if (confUnit.nationalSymbol === undefined || confUnit.nationalSymbol === null) {
                                unit = confUnit.name_ru;
                            } else {
                                unit = confUnit.nationalSymbol;
                            }
                        }
                        if (unit !== null || unit !== undefined) {
                            unitLowerCased = unit.toLowerCase();
                            if (unitLowerCased === 'процент') {
                                unitLowerCased = '%';
                            }
                        }
                    }

                    if (previous !== undefined) {
                        // вычисляем предыдущее актуальное значение
                        if (previousFact !== null) {
                            previousFact = previousFact + previous.fact * measurement;
                        } else {
                            previousFact = previous.fact * measurement;
                        }
                        // характер показателя
                        if (typeIndic === 0) {
                            growIndic = typeIndic;
                        }

                        growIndicProc = Math.round(((sumFact / previousFact) * 100 - 100) * 100) / 100;
                        if (unitLowerCased === '%') {
                            growIndicProc = Math.round((sumFact - previousFact) * 100) / 100;
                        }
                        if (typeIndic === 0) {
                            growIndic = typeIndic;
                        } else {
                            if (growIndicProc === 0) {
                                growIndic = typeIndic;
                            } else {
                                growIndic = typeIndic * growIndicProc;
                            }
                        }
                    }
                }
            }
        });
        return {
            sumFact,
            unit: unitLowerCased,
            growIndic,
            growIndicProc
        };
    }

    private getItems(indicator: any) {
        indicator.items.sort((a: any, b: any) => a.date - b.date);
        let items = indicator.items.slice(-7 + this.year, indicator.items.length + this.year);

        if (indicator.periodicity === 'квартал' || indicator.periodicity === 'квартал с накоплением') {
            items = indicator.items.slice(-8 + this.year, indicator.items.length + this.year);
        }

        if (indicator.periodicity === 'месяц' || indicator.periodicity === 'месяц с накоплением') {
            items = indicator.items.slice(-12 + this.year, indicator.items.length + this.year);
        }
        return items;
    }

    private previousDate() {
        this.year -= 1;
    }
    private nextDate() {
        this.year += 1;
    }

    private year = 0;

    private indic = 0;

    public get getResult(): ILastResult[] | null {
        const lastresult: ILastResult[] = [];
        if (this.indicatorConfigs.length === 0) {
            return null;
        }
        let len = 0;
        this.indicatorConfigs.forEach((indicatorKey) => {
            const key = indicatorKey;
            let indicator = accessor.getIndicator(key.indicator);
            if (indicator === undefined) {
                indicator = this.getIndicator(key.indicator);
            }

            if (indicator === undefined) {
                return null;
            }
            if (indicator.items.length > len) {
                len = indicator.items.length;
            }
            let data: null | IItemGraph = null;
            const items: IChartItem[] = [];
            const datas = this.getItems(indicator);
            const selectedItems = this.selectedMap.get(indicator.key);
            if (selectedItems !== undefined) {
                const periodicity = selectedItems.periodicity;
                const confUnit: any = indicatorKey.unit;
                let unit: any;
                if ((Object.keys(confUnit).length === 0)) {
                    unit = selectedItems.unit;
                } else if ((confUnit.name_ru === '')) {
                    unit = selectedItems.unit;
                } else {
                    unit = confUnit.name_ru;
                }
                let unitLowerCased: string | null = null;
                if (unit !== null || unit !== undefined) {
                    unitLowerCased = unit.toLowerCase();
                    if (unitLowerCased === 'процент') {
                        unitLowerCased = '%';
                    }
                }
                let measurement = 1;
                if (indicatorKey.measurement !== undefined) {
                    measurement = indicatorKey.measurement;
                }
                datas.forEach((selectedItem: any) => {
                    const item: IChartItem = {
                        date: selectedItem.date,
                        value: selectedItem.fact * measurement
                    };
                    items.push(item);
                });
                data = {
                    name: indicatorKey.title,
                    items,
                    unit: unitLowerCased,
                    periodicity
                };
            }
            lastresult.push({ title: indicatorKey.title, data, indicatorKey: key.indicator });
            return lastresult;
        });
        this.indic = len;
        return lastresult;
    }
}