import Rect from './rect';
import {
    IAfterArrowPlaceCallbackData, IAnchorHideCallbackData, IBeforeArrowPlaceCallbackData, IBodyPlacedCallbackData, IBodyPrepareCallbackData,
    IChoosenRectCallbackData, IPlacement, IPlacementRect, IRectsCallbackData,
    TAfterArrowPlaceCallback, TAnchorHideCallback, TBeforeArrowPlaceCallback, TBodyPlacedCallback, TBodyPrepareCallback, TChoosenRectCallback,
    TRectsCallback, TStrategy, TStrictPlacement
} from './types';


const attributeNames = {
    placement: 'data-flying-placement',
    variant: 'data-flying-variant',
    overlap: 'data-flying-overlap',
    hasArrow: 'data-flying-has-arrow',
    arrow: 'data-flying-arrow'
};

const setAttribute = (element: Element, attrName: string, value: string): void => {
    let attribute = element.attributes.getNamedItem(attrName);
    if (attribute === null) {
        attribute = document.createAttribute(attrName);
        element.attributes.setNamedItem(attribute);
    }
    attribute.value = value;
};
const removeAttribute = (element: Element, attrName: string): void => {
    if (element.attributes.getNamedItem(attrName) !== null) {
        element.attributes.removeNamedItem(attrName);
    }
};


export default class Flying {
    private static getParentElements(el: Element): Element[] {
        const result: Element[] = [];

        let parentElement = el.parentElement;
        while (parentElement !== null) {
            result.push(parentElement);
            parentElement = parentElement.parentElement;
        }

        return result;
    }


    public static setMaxVisible(data: IBodyPrepareCallbackData): void {
        data.body.style.width = '';
        data.body.style.height = '';

        let boundary: Rect | undefined;
        let space: number;
        if (data.root.allowAnchorOverlap) {
            boundary = data.boundary;
            space = 30;
        } else {
            const offsetWidth = data.body.offsetWidth;
            const offsetHeight = data.body.offsetHeight;

            let minOverlapPlacement: IPlacementRect | null = null;
            let minOverlapSquare: number | null = null;

            for (const rect of data.rects) {
                const overlapWidth = Math.max(0, offsetWidth - rect.rect.width);
                const overlapHeight = Math.max(0, offsetHeight - rect.rect.height);

                if ((offsetWidth <= rect.rect.width) && (offsetHeight <= rect.rect.height)) {
                    boundary = rect.rect;
                    break;
                }

                const overlapSquare = (overlapWidth * overlapHeight)
                    + (overlapWidth * offsetHeight)
                    + (overlapHeight * offsetWidth);
                if ((minOverlapSquare === null) || (minOverlapSquare > overlapSquare)) {
                    minOverlapSquare = overlapSquare;
                    minOverlapPlacement = rect;
                }
            }

            if ((boundary === undefined) && (minOverlapPlacement !== null)) {
                boundary = minOverlapPlacement.rect;
            }

            if (boundary === undefined) {
                boundary = data.max;
            }

            space = 0;
        }

        let width = data.body.offsetWidth;
        width = Math.min(width, boundary.width - space);
        width = Math.max(0, width);

        let height = data.body.offsetHeight;
        height = Math.min(height, boundary.height - space);
        height = Math.max(0, height);

        if (data.body.offsetWidth > width) {
            data.body.style.width = `${width}px`;
        }
        if (data.body.offsetHeight > height) {
            data.body.style.height = `${height}px`;
        }
    }

    public static isClipping(element: Element): boolean {
        const computedStyle = getComputedStyle(element);
        return ((computedStyle.overflowX !== 'visible') || (computedStyle.overflowY !== 'visible'));
    }


    public constructor() {
        this.scrollListener = (ev) => {
            this.onScroll(ev);
        };

        this.resizeListener = () => {
            this.onResize();
        };
        window.addEventListener('resize', this.resizeListener);

        this.transitionListener = (ev: TransitionEvent) => {
            this.onTransitionRun(ev);
        };
    }


    public clearBodyAttributes = true;

    private removeBodyAttributes(): void {
        if (this.body !== null) {
            removeAttribute(this.body, attributeNames.placement);
            removeAttribute(this.body, attributeNames.variant);
            removeAttribute(this.body, attributeNames.overlap);
            removeAttribute(this.body, attributeNames.hasArrow);

            this.body.style.left = '';
            this.body.style.top = '';
            this.body.style.transform = '';
            this.body.style.willChange = '';
        }
    }


    // #region Listeners
    private scrollListener: (ev: Event) => void;

    private resizeListener: () => void;

    private transitionListener: (ev: TransitionEvent) => void;
    // #endregion


    // #region Anchor
    private localAnchor: Element | null = null;
    private anchorParents: HTMLElement[] = [];
    private anchorWasHidden: boolean | null = null;

    public get anchor(): Element | null {
        return this.localAnchor;
    }

    public set anchor(value: Element | null) {
        if (this.localAnchor !== value) {
            this.anchorWasHidden = null;
            this.localAnchor = value;

            if ((this.localAnchor === null) && this.clearBodyAttributes) {
                this.removeBodyAttributes();
            }

            this.updateAnchorParents();
            this.update();
        }
    }

    private updateAnchorParents(): void {
        this.anchorParents.forEach((anchorParent) => {
            anchorParent.removeEventListener('scroll', this.scrollListener);
        });

        this.anchorParents = [];
        if (this.anchor !== null) {
            let parentElement = this.anchor.parentElement;
            while (parentElement !== null) {
                this.anchorParents.push(parentElement);
                parentElement.addEventListener('scroll', this.scrollListener);
                parentElement = parentElement.parentElement;
            }
        }
    }
    // #endregion


    // #region Body
    private localBody: HTMLElement | null = null;
    private localBodyParent: Element | null = null;

    public get body(): HTMLElement | null {
        return this.localBody;
    }

    public set body(value: HTMLElement | null) {
        if (this.localBody !== value) {
            if (this.localBody !== null) {
                if (this.clearBodyAttributes) {
                    this.removeBodyAttributes();
                }

                this.localBody.removeEventListener('transitionend', this.transitionListener);
                this.localBody.removeEventListener('transitionrun', this.transitionListener);

                if (this.localBodyParent !== null) {
                    this.localBodyParent.appendChild(this.localBody);
                }
            }

            this.localBody = value;

            if (this.localBody === null) {
                this.localBodyParent = null;
            } else {
                this.localBodyParent = this.localBody.parentElement;

                this.localBody.addEventListener('transitionend', this.transitionListener);
                this.localBody.addEventListener('transitionrun', this.transitionListener);
            }

            this.update();
        }
    }
    // #endregion


    // #region Arrow
    private localArrow: HTMLElement | null = null;

    public get arrow(): HTMLElement | null {
        return this.localArrow;
    }

    public set arrow(value: HTMLElement | null) {
        if (this.localArrow !== value) {
            this.localArrow = value;
            this.redraw();
        }
    }

    public get usedArrow(): HTMLElement | null {
        if (this.body === null) {
            return null;
        }

        if (this.arrow !== null) {
            if (this.arrow.parentElement === this.body) {
                return this.arrow;
            }
            console.error(new Error('Arrow is not direct child of body'));
        }

        if (this.body !== null) {
            const bodyChildren = this.body.children;
            if (bodyChildren.length > 0) {
                for (let i = 0; i < bodyChildren.length; i++) {
                    const bodyChild = bodyChildren.item(i);
                    if ((bodyChild instanceof HTMLElement) && (bodyChild.attributes.getNamedItem(attributeNames.arrow) !== null)) {
                        return bodyChild;
                    }
                }
            }
        }

        return null;
    }
    // #endregion


    // #region Viewport
    private localViewport: Element | null = null;

    public get viewport(): Element | null {
        return this.localViewport;
    }

    public set viewport(value: Element | null) {
        if (this.localViewport !== value) {
            this.localViewport = value;
            this.update();
        }
    }

    public get usedViewport(): Element {
        if (this.viewport === null) {
            return document.body;
        }
        return this.viewport;
    }
    // #endregion


    // #region Boundary
    private localBoundary: Element | null = null;

    public get boundary(): Element | null {
        return this.localBoundary;
    }

    public set boundary(value: Element | null) {
        if (this.localBoundary !== value) {
            this.localBoundary = value;
            this.redraw();
        }
    }

    public get usedBoundary(): Element {
        if (this.boundary !== null) {
            return this.boundary;
        }

        if (this.body !== null) {
            let parentElement = this.body.parentElement;
            while (parentElement !== null) {
                if (Flying.isClipping(parentElement)) {
                    return parentElement;
                }
                parentElement = parentElement.parentElement;
            }
        }

        return document.body;
    }
    // #endregion


    // #region Arrow offset
    private localArrowOffset = 0;

    public get arrowOffset(): number {
        return this.localArrowOffset;
    }

    public set arrowOffset(value: number) {
        if (this.localArrowOffset !== value) {
            this.localArrowOffset = value;
            this.updateArrow();
        }
    }
    // #endregion


    // #region Allow anchor overlap
    private localAllowAnchorOverlap = false;

    public get allowAnchorOverlap(): boolean {
        return this.localAllowAnchorOverlap;
    }

    public set allowAnchorOverlap(value: boolean) {
        if (this.localAllowAnchorOverlap !== value) {
            this.localAllowAnchorOverlap = value;
            this.redraw();
        }
    }
    // #endregion


    // #region Strategy
    private localStrategy: TStrategy = 'fixed';

    public get strategy(): TStrategy {
        return this.localStrategy;
    }

    public set strategy(value: TStrategy) {
        if (this.localStrategy !== value) {
            this.localStrategy = value;
            this.redraw();
        }
    }
    // #endregion


    // #region Placement
    private localPlacement: IPlacement = { placement: 'auto' };

    public get placement(): IPlacement {
        return this.localPlacement;
    }

    public set placement(value: IPlacement) {
        if (this.localPlacement !== value) {
            this.localPlacement = value;
            this.redraw();
        }
    }
    // #endregion


    // #region Alt placements
    private localAltPlacements: IPlacement[] = [];

    public get altPlacements(): IPlacement[] {
        return this.localAltPlacements;
    }

    public set altPlacements(value: IPlacement[]) {
        this.localAltPlacements = value;
        this.redraw();
    }
    // #endregion


    // #region callbacks
    /**
     * Step 1
     */
    public anchorHideCallback: TAnchorHideCallback | null = null;

    /**
     * Step 2
     */
    public rectsCallback: TRectsCallback | null = null;

    /**
     * Step 3
     */
    public bodyPrepareCallback: TBodyPrepareCallback | null = null;

    /**
     * Step 4
     */
    public choosenRectCallback: TChoosenRectCallback | null = null;

    /**
     * Step 5
     */
    public bodyPlacedCallback: TBodyPlacedCallback | null = null;

    /**
     * Step 6
     */
    public beforeArrowPlaceCallback: TBeforeArrowPlaceCallback | null = null;

    /**
     * Step 7
     */
    public afterArrowPlaceCallback: TAfterArrowPlaceCallback | null = null;
    // #endregion


    // #region Redraw
    private redrawHandle: number | null = null;
    private lastChoosenRect: IPlacementRect | null = null;

    public redraw(): void {
        if (this.redrawHandle === null) {
            if (this.body !== null) {
                this.body.style.willChange = 'transform';
            }

            // eslint-disable-next-line complexity
            this.redrawHandle = requestAnimationFrame(() => {
                this.redrawHandle = null;

                this.lastChoosenRect = null;

                if ((this.anchor !== null) && (this.body !== null)) {
                    this.body.style.position = this.strategy;
                    this.body.style.left = '0';
                    this.body.style.top = '0';

                    setAttribute(this.body, attributeNames.hasArrow, String(this.usedArrow !== null));


                    const callbackData = {};


                    // #region Check anchor hidden
                    const anchorRect = new Rect(this.anchor.getBoundingClientRect());

                    let anchorParent = this.anchor.parentElement;
                    while (anchorParent !== null) {
                        if (Flying.isClipping(anchorParent)) {
                            const parentRect = anchorParent.getBoundingClientRect();
                            anchorRect.reduce(parentRect);
                        }

                        const position = getComputedStyle(anchorParent).position;
                        if (position === 'fixed') {
                            break;
                        }

                        anchorParent = anchorParent.parentElement;
                    }

                    // fill callback data
                    (() => {
                        const localCallbackData = callbackData as IAnchorHideCallbackData;
                        localCallbackData.root = this;
                        localCallbackData.anchor = this.anchor;
                        localCallbackData.anchorRect = anchorRect;
                        localCallbackData.body = this.body;
                        localCallbackData.anchorHidden = anchorRect.empty;
                    })();

                    if (this.anchorWasHidden !== anchorRect.empty) {
                        this.anchorWasHidden = anchorRect.empty;

                        if (this.anchorHideCallback !== null) {
                            this.anchorHideCallback(callbackData as IAnchorHideCallbackData);
                        }
                    }
                    // #endregion


                    // #region Define rects
                    const boundaryRect: Rect = new Rect(document.body.getBoundingClientRect());
                    (() => {
                        let parentElement: Element | null = this.usedBoundary;
                        while (parentElement !== null) {
                            if (Flying.isClipping(parentElement)) {
                                boundaryRect.reduce(parentElement.getBoundingClientRect());
                            }
                            parentElement = parentElement.parentElement;
                        }
                    })();

                    const leftRect = new Rect(boundaryRect);
                    leftRect.right = anchorRect.left;

                    const rightRect = new Rect(boundaryRect);
                    rightRect.left = anchorRect.right;

                    const topRect = new Rect(boundaryRect);
                    topRect.bottom = anchorRect.top;

                    const bottomRect = new Rect(boundaryRect);
                    bottomRect.top = anchorRect.bottom;

                    const sideRects: [ Rect, Rect, Rect, Rect ] = [ topRect, rightRect, bottomRect, leftRect ];

                    let maxRect = leftRect;
                    let maxPlacement: TStrictPlacement = 'left';
                    if (maxRect.square < rightRect.square) {
                        maxRect = rightRect;
                        maxPlacement = 'right';
                    }
                    if (maxRect.square < topRect.square) {
                        maxRect = topRect;
                        maxPlacement = 'top';
                    }
                    if (maxRect.square < bottomRect.square) {
                        maxRect = bottomRect;
                        maxPlacement = 'bottom';
                    }

                    // fill callback data
                    (() => {
                        const localCallbackData = callbackData as IRectsCallbackData;
                        localCallbackData.boundary = boundaryRect;
                        localCallbackData.left = leftRect;
                        localCallbackData.right = rightRect;
                        localCallbackData.top = topRect;
                        localCallbackData.bottom = bottomRect;
                        localCallbackData.sides = sideRects;
                        localCallbackData.max = maxRect;
                        localCallbackData.maxPlacement = maxPlacement;
                    })();

                    if (this.rectsCallback !== null) {
                        this.rectsCallback(callbackData as IRectsCallbackData);
                    }
                    // #endregion


                    // #region Prepare body
                    const rects: IPlacementRect[] = [];
                    const autoStrictPlacements: [ TStrictPlacement, TStrictPlacement, TStrictPlacement, TStrictPlacement ] = [ 'top', 'right', 'bottom', 'left' ];
                    const addRect = (placement: IPlacement): void => {
                        let rect: Rect | null;
                        let strict: TStrictPlacement | null;

                        switch (placement.placement) {
                            case 'auto':
                                rect = null;
                                strict = null;

                                autoStrictPlacements.forEach((strictPlacement, i) => {
                                    rects.push({
                                        placement,
                                        strict: {
                                            placement: strictPlacement,
                                            variant: placement.variant
                                        },
                                        rect: sideRects[i]
                                    });
                                });
                                break;
                            case 'bottom':
                                rect = bottomRect;
                                strict = 'bottom';
                                break;
                            case 'left':
                                rect = leftRect;
                                strict = 'left';
                                break;
                            case 'right':
                                rect = rightRect;
                                strict = 'right';
                                break;
                            case 'top':
                                rect = topRect;
                                strict = 'top';
                                break;
                            default:
                                console.error(new Error(`Unknown placement "${placement.placement}"`));
                                rect = null;
                                strict = null;
                                break;
                        }

                        if ((rect !== null) && (strict !== null)) {
                            rects.push({
                                placement,
                                strict: {
                                    placement: strict,
                                    variant: placement.variant
                                },
                                rect
                            });
                        }
                    };

                    addRect(this.placement);
                    this.altPlacements.forEach((altPlacement) => {
                        addRect(altPlacement);
                    });

                    // fill callback data
                    (() => {
                        const localCallbackData = callbackData as IBodyPrepareCallbackData;
                        localCallbackData.rects = rects;
                    })();

                    if (this.bodyPrepareCallback !== null) {
                        this.bodyPrepareCallback(callbackData as IBodyPrepareCallbackData);
                    }
                    // #endregion


                    // #region Choosing rect
                    const choosenRect = ((): IPlacementRect => {
                        let result: IPlacementRect | null = null;

                        const width = this.body.offsetWidth;
                        const height = this.body.offsetHeight;

                        for (const rect of rects) {
                            if ((rect.rect.width >= width) && (rect.rect.height >= height)) {
                                result = rect;
                                break;
                            }
                        }

                        if (result === null) {
                            let overflowInfo: { square: number; rect: IPlacementRect } | null = null;
                            const checkRectOverflow = (rect: IPlacementRect): void => {
                                const overflowWidth = Math.max(0, width - rect.rect.width);
                                const overflowHeight = Math.max(0, height - rect.rect.height);

                                let square = overflowWidth * Math.min(height, rect.rect.height);
                                square += overflowHeight * Math.min(width, rect.rect.width);
                                square += overflowWidth * overflowHeight;

                                if ((overflowInfo === null) || (overflowInfo.square > square)) {
                                    overflowInfo = { square, rect };
                                }
                            };

                            rects.forEach((rect) => {
                                if (rect.placement.placement === 'auto') {
                                    // adding left rect
                                    checkRectOverflow({
                                        placement: rect.placement,
                                        strict: {
                                            placement: 'left',
                                            variant: rect.placement.variant
                                        },
                                        rect: leftRect
                                    });

                                    // adding top rect
                                    checkRectOverflow({
                                        placement: rect.placement,
                                        strict: {
                                            placement: 'top',
                                            variant: rect.placement.variant
                                        },
                                        rect: topRect
                                    });

                                    // adding right rect
                                    checkRectOverflow({
                                        placement: rect.placement,
                                        strict: {
                                            placement: 'right',
                                            variant: rect.placement.variant
                                        },
                                        rect: rightRect
                                    });

                                    // adding bottom rect
                                    checkRectOverflow({
                                        placement: rect.placement,
                                        strict: {
                                            placement: 'bottom',
                                            variant: rect.placement.variant
                                        },
                                        rect: bottomRect
                                    });
                                } else {
                                    checkRectOverflow(rect);
                                }
                            });

                            if (overflowInfo !== null) {
                                return (overflowInfo as { rect: IPlacementRect }).rect;
                            }
                        }

                        if (result === null) {
                            result = rects[0];
                        }
                        return result;
                    })();
                    this.lastChoosenRect = choosenRect;

                    // fill callback data
                    (() => {
                        const localCallbackData = callbackData as IChoosenRectCallbackData;
                        localCallbackData.choosenRect = choosenRect;
                    })();

                    if (this.choosenRectCallback !== null) {
                        this.choosenRectCallback(callbackData as IChoosenRectCallbackData);
                    }
                    // #endregion


                    // #region Place body
                    let vDelta = 0;
                    let hDelta = 0;
                    if (this.strategy === 'fixed') {
                        vDelta = window.pageYOffset;
                        hDelta = window.pageXOffset;
                    } else if (this.body.parentElement !== null) {
                        const parentRect = this.body.parentElement.getBoundingClientRect();
                        vDelta = this.body.parentElement.scrollTop - parentRect.top;
                        hDelta = this.body.parentElement.scrollLeft - parentRect.left;
                    }

                    const bodyWidth = this.body.offsetWidth;
                    const bodyHeight = this.body.offsetHeight;

                    let translateX = 0;
                    let translateY = 0;
                    const simplePosition = (vertically: boolean): void => {
                        if (vertically) {
                            if (bodyHeight >= choosenRect.rect.height) {
                                translateY = choosenRect.rect.top + ((choosenRect.rect.height / 2) - (bodyHeight / 2));
                            } else {
                                switch (choosenRect.strict.variant) {
                                    case 'start':
                                        translateY = anchorRect.top;
                                        break;
                                    case 'end':
                                        translateY = anchorRect.bottom - bodyHeight;
                                        break;
                                    default:
                                        translateY = anchorRect.top + (anchorRect.height / 2 - bodyHeight / 2);
                                        break;
                                }

                                if (translateY < choosenRect.rect.top) {
                                    translateY = choosenRect.rect.top;
                                } else if (translateY > choosenRect.rect.bottom - bodyHeight) {
                                    translateY = choosenRect.rect.bottom - bodyHeight;
                                }
                            }
                        } else {
                            // eslint-disable-next-line no-lonely-if
                            if (bodyWidth >= choosenRect.rect.width) {
                                translateX = choosenRect.rect.left + ((choosenRect.rect.width / 2) - (bodyWidth / 2));
                            } else {
                                switch (choosenRect.strict.variant) {
                                    case 'start':
                                        translateX = anchorRect.left;
                                        break;
                                    case 'end':
                                        translateX = anchorRect.right - bodyWidth;
                                        break;
                                    default:
                                        translateX = anchorRect.left + (anchorRect.width / 2 - bodyWidth / 2);
                                        break;
                                }

                                if (translateX < choosenRect.rect.left) {
                                    translateX = choosenRect.rect.left;
                                } else if (translateX > choosenRect.rect.right - bodyWidth) {
                                    translateX = choosenRect.rect.right - bodyWidth;
                                }
                            }
                        }
                    };
                    let overlapped = false;
                    switch (choosenRect.strict.placement) {
                        case 'left':
                            simplePosition(true);
                            translateX = anchorRect.left - bodyWidth;

                            if ((translateX < choosenRect.rect.left) && this.allowAnchorOverlap) {
                                translateX = choosenRect.rect.left;
                                overlapped = true;
                            }
                            break;
                        case 'top':
                            simplePosition(false);
                            translateY = anchorRect.top - bodyHeight;

                            if ((translateY < choosenRect.rect.top) && this.allowAnchorOverlap) {
                                translateY = choosenRect.rect.top;
                                overlapped = true;
                            }
                            break;
                        case 'right':
                            simplePosition(true);
                            translateX = anchorRect.right;

                            if ((translateX > choosenRect.rect.right - bodyWidth) && this.allowAnchorOverlap) {
                                translateX = choosenRect.rect.right - bodyWidth;
                                overlapped = true;
                            }
                            break;
                        case 'bottom':
                            simplePosition(false);
                            translateY = anchorRect.bottom;

                            if ((translateY > choosenRect.rect.bottom - bodyHeight) && this.allowAnchorOverlap) {
                                translateY = choosenRect.rect.bottom - bodyHeight;
                                overlapped = true;
                            }
                            break;
                        default:
                            console.error(new Error(`Unknown placement "${choosenRect.strict.placement}"`));
                            break;
                    }

                    const finalTranslateX = translateX + hDelta;
                    const finalTranslateY = translateY + vDelta;

                    this.body.style.transform = `translate(${finalTranslateX}px, ${finalTranslateY}px)`;

                    // filling callback data
                    (() => {
                        const localCallbackData = callbackData as IBodyPlacedCallbackData;
                        localCallbackData.overlapping = overlapped;
                    })();

                    if (this.bodyPlacedCallback !== null) {
                        this.bodyPlacedCallback(callbackData as IBodyPlacedCallbackData);
                    }
                    // #endregion


                    // #region adding attributes
                    setAttribute(this.body, attributeNames.placement, choosenRect.strict.placement);
                    setAttribute(this.body, attributeNames.variant, (choosenRect.strict.variant === undefined ? '' : choosenRect.strict.variant));
                    setAttribute(this.body, attributeNames.overlap, String(overlapped));
                    // #endregion

                    this.body.style.willChange = '';

                    this.updateArrow();
                }
            });
        }
    }


    private updateArrowHandle: number | null = null;

    private updateArrow(): void {
        if (this.updateArrowHandle === null) {
            if (this.usedArrow !== null) {
                this.usedArrow.style.willChange = 'left, top';
            }

            this.updateArrowHandle = requestAnimationFrame(() => {
                this.updateArrowHandle = null;

                const arrow = this.usedArrow;
                const anchor = this.anchor;
                const body = this.body;
                const choosenRect = this.lastChoosenRect;
                if ((arrow !== null) && (anchor !== null) && (body !== null) && (choosenRect !== null)) {
                    const callbackData = {};
                    const anchorRect = new Rect(anchor.getBoundingClientRect());

                    arrow.style.position = 'absolute';

                    // filling callback data - before place
                    (() => {
                        const localCallbackData = callbackData as IBeforeArrowPlaceCallbackData;
                        localCallbackData.root = this;
                        localCallbackData.arrow = arrow;
                        localCallbackData.anchor = anchor;
                        localCallbackData.anchorRect = anchorRect;
                        localCallbackData.body = body;
                        localCallbackData.choosenRect = choosenRect;
                    })();
                    if (this.beforeArrowPlaceCallback !== null) {
                        this.beforeArrowPlaceCallback(callbackData as IBeforeArrowPlaceCallbackData);
                    }


                    // place arrow
                    (() => {
                        let offsetField: 'top' | 'left';
                        let emptyOffsetField: 'top' | 'left';
                        let offsetSizeField: 'offsetHeight' | 'offsetWidth';
                        let sizeField: 'height' | 'width';
                        if ((choosenRect.strict.placement === 'left') || (choosenRect.strict.placement === 'right')) {
                            offsetField = 'top';
                            emptyOffsetField = 'left';
                            offsetSizeField = 'offsetHeight';
                            sizeField = 'height';
                        } else {
                            offsetField = 'left';
                            emptyOffsetField = 'top';
                            offsetSizeField = 'offsetWidth';
                            sizeField = 'width';
                        }
                        arrow.style[offsetField] = '0';
                        arrow.style[emptyOffsetField] = '';

                        const bodySize = body[offsetSizeField];
                        const arrowSize = arrow[offsetSizeField];
                        const availableSize = Math.max(0, bodySize - this.arrowOffset - arrowSize) / 2;

                        let arrowCenter: number;
                        if (availableSize === 0) {
                            arrowCenter = bodySize / 2;
                        } else {
                            const minCenter = (bodySize / 2 - availableSize);
                            const maxCenter = (bodySize / 2 + availableSize);
                            const bodyOffset = body.getBoundingClientRect()[offsetField];
                            const absMinCenter = minCenter + bodyOffset;
                            const absMaxCenter = maxCenter + bodyOffset;

                            const anchorOffset = anchorRect[offsetField];
                            const anchorSize = anchorRect[sizeField];
                            const anchorCenter = anchorOffset + (anchorSize / 2);

                            if (anchorCenter <= absMinCenter) {
                                arrowCenter = minCenter;
                            } else if (anchorCenter >= absMaxCenter) {
                                arrowCenter = maxCenter;
                            } else {
                                const centerOffset = anchorCenter - absMinCenter;
                                arrowCenter = minCenter + centerOffset;
                            }
                        }

                        const arrowOffset = arrowCenter - (arrowSize / 2);
                        arrow.style[offsetField] = `${arrowOffset}px`;
                    })();

                    // after place
                    if (this.afterArrowPlaceCallback !== null) {
                        this.afterArrowPlaceCallback(callbackData as IAfterArrowPlaceCallbackData);
                    }

                    arrow.style.willChange = '';
                }
            });
        }
    }
    // #endregion


    public update(): void {
        if ((this.anchor !== null) && (this.body !== null)) {
            if ((this.viewport !== null) && (this.body.parentElement !== this.viewport)) {
                this.body.remove();
                this.viewport.appendChild(this.body);
            }

            this.redraw();
        }
    }

    public destroy(): void {
        this.body = null;
        this.anchorHideCallback = null;

        window.removeEventListener('resize', this.resizeListener);

        this.anchorParents.forEach((anchorParent) => {
            anchorParent.removeEventListener('scroll', this.scrollListener);
        });
    }


    private onScroll(ev: Event): void {
        if (this.anchorParents.includes(ev.target as HTMLElement)) {
            this.redraw();
        }
    }

    private onResize(): void {
        this.redraw();
    }

    private onTransitionRun(ev: TransitionEvent): void {
        if (ev.target === this.body) {
            this.updateArrow();
        }
    }
}