import { Component, Model, Prop, Vue } from 'vue-property-decorator';

import { Flying, Events } from '@/utils';

import { flyingTriggers, TFlyingClickLocation, TFlyingTrigger } from '../types';


interface IData {
    body: HTMLElement;
    anchor: Element;
    arrow: HTMLElement | null;
    viewport: Element | null;
    boundary: Element | null;
    allowAnchorOverlap: boolean;
    arrowOffset: number;
    strategy: Flying.Types.TStrategy;
    placement: Flying.Types.IPlacement;
    altPlacements: Flying.Types.IPlacement[];
}


const events = {
    model: 'change',
    anchorHidden: 'anchor-hidden',
    anchorShown: 'anchor-shown',
    rectsDefined: 'rects-defined',
    bodyPrepare: 'body-prepare',
    rectChoosen: 'rect-choosen',
    bodyPlaced: 'body-placed',
    beforeArrowPlace: 'before-arrow-place',
    afterArrowPlace: 'after-arrow-place'
};


const flyingPortal = ((): HTMLElement => {
    const result = document.createElement('div');
    result.classList.add('c-flying-portal');

    document.body.append(result);

    return result;
})();


@Component
export default class CFlying extends Vue {
    // #region Lifecycle
    private created(): void {
        this.initFlying();

        this.$watch('value', this.valueChanged);
        this.$watch('actualAnchor', this.actualAnchorChanged);
        this.$watch('flyingData', this.flyingDataChanged);
        this.$watch('visible', this.visibleChanged);

        if (this.value === null) {
            if (this.localValue !== this.initiallyVisible) {
                this.localValue = this.initiallyVisible;
            }
        } else {
            if (this.localValue !== this.value) {
                this.localValue = this.value;
            }
        }

        window.addEventListener('click', this.onWindowClick);
        this.setAnchorListeners();
    }

    private mounted(): void {
        this.localBody = this.$el as HTMLElement;
        this.redraw();
    }

    private updated(): void {
        if (this.actualAnchor !== null) {
            const rect = new Flying.Rect(this.actualAnchor.getBoundingClientRect());
            let parentElement = this.actualAnchor.parentElement;
            while (parentElement !== null) {
                rect.reduce(parentElement.getBoundingClientRect());
                parentElement = parentElement.parentElement;
            }

            const anchorVisible = !rect.empty;
            if (this.anchorVisible !== anchorVisible) {
                this.anchorVisible = anchorVisible;
            }
        }
        if (this.visible) {
            this.redraw();
        }
    }

    private beforeDestroy(): void {
        window.removeEventListener('click', this.onWindowClick);
        this.removeAnchorListeners(this.actualAnchor);

        this.destroyFlying();

        if (this.body !== null) {
            this.body.remove();
        }
    }
    // #endregion


    // #region Initially visible
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly initiallyVisible!: boolean;
    // #endregion


    // #region Value
    @Model(events.model, {
        type: Boolean,
        required: false,
        default: null
    })
    public readonly value!: boolean | null;

    private valueChanged(): void {
        if ((this.value !== null) && (this.localValue !== this.value)) {
            this.localValue = this.value;
        }
    }
    // #endregion


    // #region Local value
    private localValue = false;

    private setLocalValue(value: boolean): void {
        if ((this.value === null) && (this.localValue !== value)) {
            this.localValue = value;
        }
        if (this.value !== value) {
            this.$emit(events.model, value);
        }
    }

    private toggleLocalValue(): void {
        this.setLocalValue(!this.localValue);
    }
    // #endregion


    // #region Body
    private localBody: HTMLElement | null = null;

    public get body(): HTMLElement | null {
        return this.localBody;
    }
    // #endregion


    // #region Anchor
    @Prop({
        type: [ Element, Vue ],
        required: false,
        default: null
    })
    public readonly anchor!: Element | Vue | null;

    public get actualAnchor(): Element | null {
        if (this.anchor === null) {
            return null;
        }

        if (this.anchor instanceof Element) {
            return this.anchor;
        }

        if ((this.anchor instanceof Vue) && (this.anchor.$el instanceof Element)) {
            return this.anchor.$el;
        }

        return null;
    }

    private setAnchorListeners(o?: Element | null): void {
        if (o !== this.actualAnchor) {
            this.removeAnchorListeners(o);

            if (this.actualAnchor instanceof HTMLElement) {
                this.actualAnchor.addEventListener('focus', this.onAnchorFocus);
                this.actualAnchor.addEventListener('blur', this.onAnchorBlur);
                this.actualAnchor.addEventListener('mouseenter', this.onAnchorMouseEnter);
                this.actualAnchor.addEventListener('mouseleave', this.onAnchorMouseLeave);
            }
        }
    }

    private removeAnchorListeners(o?: Element | null): void {
        if (o instanceof HTMLElement) {
            o.removeEventListener('focus', this.onAnchorFocus);
            o.removeEventListener('blur', this.onAnchorBlur);
            o.removeEventListener('mouseenter', this.onAnchorMouseEnter);
            o.removeEventListener('mouseleave', this.onAnchorMouseLeave);
        }
    }

    private actualAnchorChanged(n: Element | null, o: Element | null): void {
        if (this.anchorVisible) {
            this.anchorVisible = false;
        }

        this.setAnchorListeners(o);
    }
    // #endregion


    // #region Arrow
    @Prop({
        type: [ HTMLElement, Vue ],
        required: false,
        default: null
    })
    public readonly arrow!: HTMLElement | Vue | null;

    public get actualArrow(): HTMLElement | null {
        if (this.arrow === null) {
            return null;
        }

        if (this.arrow instanceof HTMLElement) {
            return this.arrow;
        }

        if ((this.arrow instanceof Vue) && (this.arrow.$el instanceof HTMLElement)) {
            return this.arrow.$el;
        }

        return null;
    }
    // #endregion


    // #region Viewport
    @Prop({
        type: [ Element, String ],
        required: false,
        default: null,
        validator(value: any): boolean {
            return (value === null) || (value === 'portal') || (value === 'body') || (value instanceof Element);
        }
    })
    public readonly viewport!: Element | 'portal' | 'body' | null;

    public get actualViewport(): Element {
        if (this.viewport === null) {
            return flyingPortal;
        }

        if (this.viewport === 'portal') {
            return flyingPortal;
        }

        if (this.viewport === 'body') {
            return document.body;
        }

        return this.viewport;
    }
    // #endregion


    // #region Boundary
    @Prop({
        type: Element,
        required: false,
        default: null
    })
    public readonly boundary!: Element | null;
    // #endregion


    // #region Allow anchor overlap
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly allowAnchorOverlap!: boolean;
    // #endregion


    // #region Arrow offset
    @Prop({
        type: Number,
        required: false,
        default: 0
    })
    public readonly arrowOffset!: number;
    // #endregion


    // #region Strategy
    @Prop({
        type: String,
        required: false,
        default: 'fixed',
        validator(value: any): boolean {
            return Flying.Types.strategies.includes(value);
        }
    })
    public readonly strategy!: Flying.Types.TStrategy;

    public get actualStrategy(): Flying.Types.TStrategy {
        if (Flying.Types.strategies.includes(this.strategy)) {
            return this.strategy;
        }
        return 'fixed';
    }
    // #endregion


    // #region Placement
    @Prop({
        type: Object,
        required: false,
        default: null
    })
    public readonly placement!: Flying.Types.IPlacement | null;

    public get actualPlacement(): Flying.Types.IPlacement {
        if (this.placement === null) {
            return { placement: 'auto' };
        }
        return this.placement;
    }
    // #endregion


    // #region Alt placements
    @Prop({
        type: Array,
        required: false,
        default: null
    })
    public readonly altPlacements!: Flying.Types.IPlacement[] | null;

    public get actualAltPlacements(): Flying.Types.IPlacement[] {
        let result: Flying.Types.IPlacement[];
        if (this.altPlacements === null) {
            result = [];
        } else {
            result = Array.from(this.altPlacements);
        }
        result.push({
            placement: 'auto'
        });
        return result;
    }
    // #endregion


    // #region Show without anchor
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly showWithoutAnchor!: boolean;
    // #endregion


    // #region Trigger
    @Prop({
        type: [ String, Array ],
        required: false,
        default: null,
        validator(value: any): boolean {
            if (value === null) {
                return true;
            }

            if (typeof value === 'string') {
                const items = value.split(',');

                for (const item of items) {
                    const prepared = item.trim() as TFlyingTrigger;
                    if (flyingTriggers.notIncludes(prepared)) {
                        return false;
                    }
                }

                return true;
            }

            if (Array.isArray(value)) {
                for (const item of value) {
                    if (flyingTriggers.notIncludes(item)) {
                        return false;
                    }
                }
                return true;
            }

            return false;
        }
    })
    public readonly trigger!: TFlyingTrigger | TFlyingTrigger[] | null;

    public get actualTriggers(): TFlyingTrigger[] {
        const result: TFlyingTrigger[] = [];

        if (Array.isArray(this.trigger)) {
            this.trigger.forEach((item) => {
                if (flyingTriggers.includes(item)) {
                    result.push(item);
                }
            });
        } else if (typeof this.trigger === 'string') {
            const items = this.trigger.split(',');
            items.forEach((item) => {
                const prepared = item.trim() as TFlyingTrigger;
                if (flyingTriggers.includes(prepared)) {
                    result.push(prepared);
                }
            });
        }

        return result;
    }
    // #endregion


    // #region Flying
    private flying = new Flying.Flying();

    private initFlying(): void {
        this.flying.anchorHideCallback = this.onAnchorHide;
        this.flying.rectsCallback = this.onRectsDefined;
        this.flying.bodyPrepareCallback = this.onBodyPrepare;
        this.flying.choosenRectCallback = this.onRectChoosen;
        this.flying.bodyPlacedCallback = this.onBodyPlaced;
        this.flying.beforeArrowPlaceCallback = this.onBeforeArrowPlace;
        this.flying.afterArrowPlaceCallback = this.onAfterArrowPlace;
    }

    private destroyFlying(): void {
        this.flying.destroy();
    }
    // #endregion


    // #region Flying - callbacks
    private onAnchorHide(data: Flying.Types.IAnchorHideCallbackData): void {
        if (this.anchorVisible === data.anchorHidden) {
            this.anchorVisible = !data.anchorHidden;
        }

        if (data.anchorHidden) {
            this.$emit(events.anchorHidden, data);
        } else {
            this.$emit(events.anchorShown, data);
        }
    }

    private onRectsDefined(data: Flying.Types.IRectsCallbackData): void {
        this.$emit(events.rectsDefined, data);
    }

    private onBodyPrepare(data: Flying.Types.IBodyPrepareCallbackData): void {
        const display = data.body.style.display;
        try {
            data.body.style.display = '';
            this.$emit(events.bodyPrepare, data);
        } finally {
            data.body.style.display = display;
        }
    }

    private onRectChoosen(data: Flying.Types.IChoosenRectCallbackData): void {
        this.$emit(events.rectChoosen, data);
    }

    private onBodyPlaced(data: Flying.Types.IBodyPlacedCallbackData): void {
        this.$emit(events.bodyPlaced, data);
    }

    private onBeforeArrowPlace(data: Flying.Types.IBeforeArrowPlaceCallbackData): void {
        this.$emit(events.beforeArrowPlace, data);
    }

    private onAfterArrowPlace(data: Flying.Types.IAfterArrowPlaceCallbackData): void {
        this.$emit(events.afterArrowPlace, data);
    }
    // #endregion


    // #region Flying - data
    public get flyingData(): IData | null {
        if (this.actualAnchor === null) {
            return null;
        }

        if (this.body === null) {
            return null;
        }

        return {
            body: this.body,
            anchor: this.actualAnchor,
            arrow: this.actualArrow,
            viewport: this.actualViewport,
            boundary: this.boundary,
            allowAnchorOverlap: this.allowAnchorOverlap,
            arrowOffset: this.arrowOffset,
            strategy: this.strategy,
            placement: this.actualPlacement,
            altPlacements: this.actualAltPlacements
        };
    }

    private flyingDataChanged(): void {
        if (this.flyingData !== null) {
            this.flying.body = this.flyingData.body;
            this.flying.anchor = this.flyingData.anchor;
            this.flying.arrow = this.flyingData.arrow;
            this.flying.viewport = this.flyingData.viewport;
            this.flying.boundary = this.flyingData.boundary;
            this.flying.allowAnchorOverlap = this.flyingData.allowAnchorOverlap;
            this.flying.arrowOffset = this.flyingData.arrowOffset;
            this.flying.strategy = this.flyingData.strategy;
            this.flying.placement = this.flyingData.placement;
            this.flying.altPlacements = this.flyingData.altPlacements;
        }
    }
    // #endregion


    // #region Listeners
    private onWindowClick(ev: MouseEvent): void {
        const elementPath = Events.getElementPath(ev);

        let clickLocation: TFlyingClickLocation = 'unknown';
        if ((this.actualAnchor instanceof HTMLElement) && (elementPath.includes(this.actualAnchor))) {
            clickLocation = 'anchor';
        }
        if ((this.body !== null) && (elementPath.includes(this.body))) {
            clickLocation = 'body';
        }

        const value = this.localValue;

        switch (clickLocation) {
            case 'anchor':
                if (this.actualTriggers.includes('anchor-click-show') || this.actualTriggers.includes('anchor-click-hide')) {
                    if (this.actualTriggers.includes('anchor-click-show') && (!value)) {
                        this.setLocalValue(true);
                    }
                    if (this.actualTriggers.includes('anchor-click-hide') && value) {
                        this.setLocalValue(false);
                    }
                } else if (this.actualTriggers.includes('anchor-click')) {
                    this.toggleLocalValue();
                }
                break;
            case 'body':
                if (this.actualTriggers.includes('body-click-hide')) {
                    this.setLocalValue(false);
                }
                break;
            default:
                if (this.actualTriggers.includes('window-click-hide')) {
                    this.setLocalValue(false);
                }
                break;
        }
    }

    private onAnchorFocus(): void {
        if (this.actualTriggers.includes('anchor-focus') || this.actualTriggers.includes('anchor-focus-show')) {
            this.setLocalValue(true);
        }
    }

    private onAnchorBlur(): void {
        if (this.actualTriggers.includes('anchor-focus') || this.actualTriggers.includes('anchor-focus-hide')) {
            this.setLocalValue(false);
        }
    }

    private onAnchorMouseEnter(): void {
        if (this.actualTriggers.includes('anchor-hover') || this.actualTriggers.includes('anchor-hover-show')) {
            this.setLocalValue(true);
        }
    }

    private onAnchorMouseLeave(): void {
        if (this.actualTriggers.includes('anchor-hover') || this.actualTriggers.includes('anchor-hover-hide')) {
            this.setLocalValue(false);
        }
    }
    // #endregion


    // #region Misc
    private anchorVisible = false;

    public get visible(): boolean {
        return this.localValue && (this.flyingData !== null) && (this.anchorVisible || this.showWithoutAnchor);
    }

    private visibleChanged(): void {
        this.redraw();
    }

    public update(): void {
        this.flying.update();
    }

    public redraw(): void {
        this.flying.redraw();
    }
    // #endregion
}