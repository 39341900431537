import service from './index';

/**
 * Шаблон форматирования даты
 *
 * Содержит ключ формата (`key`) и значение (`value`)
 *
 * Метод `toString` возвращает текущее представление даты в выбранном формате
 * (используется компонент локализации приложения)
 */
export default class I18nDateTemplate {
    /**
     * Ключ формата
     */
    public key: string;

    /**
     * Форматируемое значение
     */
    public value: Date;

    /**
     * Распознование ответа или части ответа сервера как шаблона форматирования даты
     * @param source источник - должен быть объектом
     * @throws если `source` не может быть распознан как шаблон форматирования даты
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static serverData(source: any): I18nDateTemplate {
        // source должен быть объектов, иначе - выбрасывается ошибка
        if (source instanceof Object) {
            // ключ формата должен быть строкой
            const key = source.key;
            if (typeof key !== 'string') {
                throw new Error(`source.key is not string: ${typeof source.key}`);
            }

            // значение должно быть датой, строкой или числом
            const value = source.value;
            if (!((value instanceof Date) || (typeof value === 'string') || (typeof value === 'number'))) {
                throw new Error(`source.value is not Date, string nor number: ${typeof value}`);
            }

            return new I18nDateTemplate(new Date(value), key);
        }

        throw new Error('source is not object: ' + (source === null ? 'null' : typeof source));
    }

    /**
     * @param value значение
     * @param key ключ формата
     */
    public constructor(value: Date, key: string) {
        this.key = key;
        this.value = value;

        Object.freeze(this);
    }

    /**
     * Получение текущего результата форматирования
     * (используется компонент локализации приложения)
     */
    public toString(): string {
        return service.getDateTranslate(this.value, this.key);
    }
}