export default class Rect {
    public constructor(source: DOMRect | ClientRect | Rect) {
        this.left = source.left;
        this.top = source.top;
        this.right = source.right;
        this.bottom = source.bottom;
    }

    public left: number;
    public top: number;
    public right: number;
    public bottom: number;

    public get width(): number {
        if (this.right < this.left) {
            return 0;
        }
        return this.right - this.left;
    }

    public get height(): number {
        if (this.bottom < this.top) {
            return 0;
        }
        return this.bottom - this.top;
    }

    public get widthEmpty(): boolean {
        return (this.width === 0);
    }

    public get heightEmpty(): boolean {
        return (this.height === 0);
    }

    public get empty(): boolean {
        return this.widthEmpty || this.heightEmpty;
    }

    public get square(): number {
        return this.width * this.height;
    }

    public get viewportLeft(): number {
        return this.left + window.pageXOffset;
    }

    public get viewportRight(): number {
        return this.right + window.pageXOffset;
    }

    public get viewportTop(): number {
        return this.top + window.pageYOffset;
    }

    public get viewportBottom(): number {
        return this.bottom + window.pageYOffset;
    }

    public reduce(boundingRect: DOMRect | ClientRect | Rect): void {
        if (this.left < boundingRect.left) {
            this.left = boundingRect.left;
        }
        if (this.right > boundingRect.right) {
            this.right = boundingRect.right;
        }
        if (this.top < boundingRect.top) {
            this.top = boundingRect.top;
        }
        if (this.bottom > boundingRect.bottom) {
            this.bottom = boundingRect.bottom;
        }
    }
}