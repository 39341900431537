/* eslint-disable no-extend-native */
// расширение интерфейса "массив" согласно файлу описания типов "src/array.d.ts"

(() => {
    const isEmpty: PropertyDescriptor & ThisType<unknown[]> = {
        get(): boolean {
            return this.length === 0;
        }
    };
    Object.defineProperty(Array.prototype, 'isEmpty', isEmpty);

    const isNotEmpty: PropertyDescriptor & ThisType<unknown[]> = {
        get(): boolean {
            return !this.isEmpty;
        }
    };
    Object.defineProperty(Array.prototype, 'isNotEmpty', isNotEmpty);

    Array.prototype.notIncludes = function notIncludes(searchElement: any, fromIndex?: number | undefined): boolean {
        return !this.includes(searchElement, fromIndex);
    };
})();