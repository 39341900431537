import { accessor } from '../store';
import { IIndicatorItem } from '../store/types';
import { I18nDateTemplate, I18nTemplate } from '@/services/i18n';


export interface IGrowIndex {
    key: string;
    title: string;
    periodicity: string;
    unit: string;
    digit: null | string;
    warning: I18nTemplate;
    character: number;

    previous: IIndicatorItem | undefined;
    current: IIndicatorItem | undefined;
    growProc: number | null; // индикатор роста в процентах
    growMark: number | null; // 0- равное значение, 1- положительный рост, -1- падение роста

    value: number;
    max: number;
}

export const assignGrow = (key: string): IGrowIndex | undefined => {
    let growValues: IGrowIndex | undefined;
    let previous: IIndicatorItem | undefined;
    let current: IIndicatorItem | undefined;

    const indicator = accessor.getIndicator(key);
    const title = (indicator !== undefined ? indicator.shortIndicator : 'N/A');
    const periodicity = (indicator !== undefined ? indicator.periodicity : '');

    const indValues = accessor.selectedMap.get(key);
    if (indValues !== undefined) {
        if (indValues.unit === undefined) {
            indValues.unit = '';
        }
        let unit = ((indValues.unit !== null) ? indValues.unit.charAt(0).toLowerCase() + indValues.unit.slice(1) : '');
        const lcUnit = (Boolean((unit === 'процент') || (unit === '%')));
        unit = (lcUnit ? '%' : unit);
        const digit = unit;
        const growType = indValues.character;

        if (indValues.withPlan) {
            const items = indValues.items;
            for (let i = items.length - 1; i > -1; i--) {
                current = items[i];
                if ((current !== undefined) && (current.fact !== 0) && (current.plan !== 0)) {
                    const j = i - 1;
                    previous = items[j];
                    break;
                }
            }
        } else {
            current = indValues.item;
            if (current === undefined) {
                return undefined;
            }
            previous = indValues.previousItem;
        }

        if (current !== undefined) {
            const warning = new I18nTemplate('modules.widgetYard.widget.textBars.notActualWarning',
                new I18nDateTemplate(current.periodStart, 'short'),
                new I18nDateTemplate(current.periodEnd, 'short'));

            const value = (current.fact !== 0 ? current.fact : 0);
            const max = (current.plan !== 0 ? current.plan : value);

            let growProc = null;
            if (previous !== undefined) {
                growProc = (lcUnit
                    ? Math.round((current.fact - previous.fact) * 100) / 100
                    : Math.round(((current.fact - previous.fact) / previous.fact) * 100 * 100) / 100);
            }

            const growMark = (growType === 0 ? growType : growProc === 0 ? growType : growType * (growProc !== null ? growProc : 0));

            growValues = { key, previous, current, growProc, growMark, title, periodicity, unit, digit, warning, character: growType, value, max };
        }
    }

    return growValues;
};