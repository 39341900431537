import { render, staticRenderFns } from "./c-content-chart-radius-pie-for-budget@.vue?vue&type=template&id=08a99098&scoped=true&"
import script from "./c-content-chart-radius-pie-for-budget@.vue?vue&type=script&lang=ts&"
export * from "./c-content-chart-radius-pie-for-budget@.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a99098",
  null
  
)

export default component.exports