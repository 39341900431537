












































import { Vue, Component, Prop } from 'vue-property-decorator';
import { accessor as moduleAccessor } from './module';
import { IMenu } from './types';


@Component
export default class CBoardLeftMenu extends Vue {
    @Prop({ required: true })
    public item!: IMenu;

    private get expanded(): boolean {
        return moduleAccessor.expanded.includes(this.item);
    }

    private collapsedByClick = false;

    private created() {
        this.$watch('expanded', () => {
            this.onExpandedChanged();
        });
    }

    private onTrigggerClick() {
        const newExpanded: IMenu[] = [];

        const expanded = moduleAccessor.expanded;
        const index = expanded.indexOf(this.item);
        if (index < 0) {
            newExpanded.push(this.item);

            let parentComponent: Vue | undefined = this.$parent;
            while (parentComponent !== undefined) {
                parentComponent = parentComponent.$parent;
                if (parentComponent instanceof CBoardLeftMenu) {
                    newExpanded.unshift(parentComponent.item);
                }
            }

            moduleAccessor.expanded = newExpanded;
        } else {
            this.collapsedByClick = true;

            for (let i = 0; i < index; i++) {
                newExpanded.push(expanded[i]);
            }
        }

        moduleAccessor.expanded = newExpanded;
    }

    private onExpandedChanged() {
        // Раньше сворачивались еще и дочерние меню; теперь список раскрытых - в vuex
        /*
        if (this.collapsed2) {
            const proxies = this.$refs.proxies;
            if (Array.isArray(proxies)) {
                proxies.forEach((proxy) => {
                    if (proxy instanceof Vue) {
                        const menu = (proxy as CBoardLeftProxy).getMenu();
                        if ((menu !== null) && (!menu.collapsed2)) {
                            menu.collapsed2 = true;
                        }
                    }
                });
            }
        }
        */
    }

    private onContentShow() {
        setTimeout(() => {
            const triggerEndScrollTarget = this.$refs.triggerEndScrollTarget;
            if (triggerEndScrollTarget instanceof HTMLElement) {
                triggerEndScrollTarget.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        });
    }

    private onContentShown() {
        setTimeout(() => {
            const contentScrollTarget = this.$refs.contentScrollTarget;
            if (contentScrollTarget instanceof HTMLElement) {
                contentScrollTarget.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        });
    }

    private onContentHide() {
        if (this.collapsedByClick) {
            this.collapsedByClick = false;
            setTimeout(() => {
                const triggerScrollTarget = this.$refs.triggerScrollTarget;
                if (triggerScrollTarget instanceof HTMLElement) {
                    triggerScrollTarget.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                }
            });
        }
    }
}
