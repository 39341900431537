import { Component, Prop, Vue } from 'vue-property-decorator';

import {} from '@/utils';

import '../../initialization';
import queue from '../queue';
import { accessor } from '../../store';
import { IWidgetChartMultipleAxes } from '../../store/types';
import CChartModelData from './c-view';
import { IItemGraph, IChartMultipleItem, ILastResult } from './types';

@Component({
    components: {
        'c-chart-multiple-view': CChartModelData
    }
})
export default class CContentChartModelData extends Vue {
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: IWidgetChartMultipleAxes;

    @Prop()
    public readonly redrawTrigger!: any;

    private typeChart: Map<string, string> = new Map();

    private year = 0;

    private indic = 0;



    private created() {
        this.$watch('result', this.resultChanged);
        this.$watch('year', this.showNav);
    }

    private mounted() {
        this.resultChanged();
    }

    private beforeDestroy() {
        queue.clear(this);
    }

    private showNav() {
        return (this.year > -this.indic + 1 && this.indic > 7);
    }

    public get indicatorConfigs() {
        return this.data.indicatorConfigs;
    }

    private getItems(indicator: any) {
        indicator.items.sort((a: any, b: any) => a.date - b.date);
        let items = indicator.items.slice(-7 + this.year, indicator.items.length + this.year);

        if (indicator.periodicity === 'квартал' || indicator.periodicity === 'квартал с накоплением') {
            items = indicator.items.slice(-8 + this.year, indicator.items.length + this.year);
        }

        if (indicator.periodicity === 'месяц' || indicator.periodicity === 'месяц с накоплением') {
            items = indicator.items.slice(-12 + this.year, indicator.items.length + this.year);
        }
        return items;
    }

    private previousDate() {
        this.year -= 1;
    }
    private nextDate() {
        this.year += 1;
    }

    private getIndicator(key: string) {
        const list = accessor.indicators;
        const indicator = list.find(x => x.newKey === key);
        return indicator;
    }

    public get result(): ILastResult[] | null {
        const lastresult: ILastResult[] = [];
        if (this.indicatorConfigs.length === 0) {
            return null;
        }
        let len = 0;
        this.indicatorConfigs.forEach((config) => {
            const indicatorKey = config.indicator;
            let indicator = accessor.getIndicator(indicatorKey);
            if (indicator === undefined) {
                indicator = this.getIndicator(indicatorKey);
            }

            if (indicator === undefined) {
                return null;
            }
            if (indicator.items.length > len) {
                len = indicator.items.length;
            }
            let data: null | IItemGraph = null;
            const items: IChartMultipleItem[] = [];
            let growIndicProc = null;
            let growIndic = null;
            let fact = 0;
            const periodicity = indicator.periodicity;
            const confUnit: any = config.unit;
            let unit: any;
            if ((Object.keys(confUnit).length === 0)) {
                unit = indicator.unit;
            } else if ((confUnit.name_ru === '')) {
                unit = indicator.unit;
            } else {
                if (confUnit.nationalSymbol === undefined || confUnit.nationalSymbol === null) {
                    unit = confUnit.name_ru;
                } else {
                    unit = confUnit.nationalSymbol;
                }
            }
            let unitLowerCased: string | null = null;
            if (unit !== null || unit !== undefined) {
                unitLowerCased = unit.toLowerCase();
                if (unitLowerCased === 'процент') {
                    unitLowerCased = '%';
                }
            }
            const type = config.type;
            const datas = this.getItems(indicator);
            const selectedItems = accessor.selectedMap.get(indicator.key);
            let measurement = 1;
            if (config.measurement !== undefined) {
                measurement = config.measurement;
            }
            if (selectedItems !== undefined) {
                datas.forEach((selectedItem: any) => {
                    const item: IChartMultipleItem = {
                        date: selectedItem.date,
                        value: selectedItem.fact * measurement
                    };
                    items.push(item);
                });
                const current = selectedItems.item;
                if (current !== undefined) {
                    fact = current.fact;
                    const typeIndic = selectedItems.character;
                    const previous = selectedItems.previousItem;
                    if (previous !== undefined) {
                        growIndicProc = Math.round(((current.fact / previous.fact) * 100 - 100) * 100) / 100;
                        if (unitLowerCased === '%') {
                            growIndicProc = Math.round((current.fact - previous.fact) * 100) / 100;
                        }
                        if (typeIndic === 0) {
                            growIndic = typeIndic;
                        } else {
                            if (growIndicProc === 0) {
                                growIndic = typeIndic;
                            } else {
                                growIndic = typeIndic * growIndicProc;
                            }
                        }
                    }
                    data = {
                        name: indicator.shortIndicator,
                        items,
                        unit: unitLowerCased,
                        growIndic,
                        growIndicProc,
                        fact,
                        type,
                        periodicity,
                        diffAxis: config.diffAxis
                    };
                }
            }
            lastresult.push({ title: config.title, data, indicatorKey });
            return lastresult;
        });
        this.indic = len;
        return lastresult;
    }

    private resultChanged(): void {
        setTimeout(() => {
            queue.schedule(this, () => {
                this.result2 = this.result;
            });
        }, 2);
    }

    private result2: ILastResult[] | null = null;
}