import { Component, Model, Prop, Vue } from 'vue-property-decorator';

import { Dates } from '@/utils';
import { EMonth } from '@/utils/dates';


interface IItem {
    value: number;
    text: string;
    disabled: boolean;
    marked: boolean;
}


const events = {
    model: 'change',
    click: 'click'
};

@Component
export default class CMonthList extends Vue {
    // #region Value
    @Model(events.model, {
        type: Date,
        required: false,
        default: null
    })
    public readonly value!: Date | null;
    // #endregion


    // #region Min
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly min!: Date | null;

    public get minDate(): Date {
        if (this.min === null) {
            return Dates.minDate;
        }
        return new Date(this.min).clearTimePart();
    }

    public get minYear(): number {
        return this.minDate.getFullYear();
    }

    public get minMonth(): number {
        return this.minDate.getMonth();
    }
    // #endregion


    // #region Max
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly max!: Date | null;

    public get maxDate(): Date {
        if (this.max === null) {
            return Dates.maxDate;
        }
        return new Date(this.max).clearTimePart();
    }

    public get maxYear(): number {
        return this.maxDate.getFullYear();
    }

    public get maxMonth(): number {
        return this.maxDate.getMonth();
    }
    // #endregion


    // #region Marked
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly marked!: Date | null;

    public get markedYear(): number | null {
        return (this.marked === null ? null : this.marked.getFullYear());
    }

    public get markedMonth(): number | null {
        return (this.marked === null ? null : this.marked.getMonth());
    }
    // #endregion


    // #region Locale
    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly locale!: string | null;

    public get actualLocale(): string {
        if (this.locale === null) {
            return 'en';
        }
        return this.locale;
    }

    public get monthFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.actualLocale, { month: 'long' });
    }
    // #endregion


    // #region Items
    public get items(): IItem[] {
        const date = new Date()
            .setDateChained(1);

        const result: IItem[] = [];
        const addItem = (month: number): void => {
            const year = (
                this.value === null
                    ? null
                    : this.value.getFullYear()
            );

            const lessThanMin = (
                (year !== null)
                && (
                    (year < this.minYear)
                    || (
                        (year === this.minYear)
                        && (month < this.minMonth)
                    )
                )
            );

            const greaterThanMax = (
                (year !== null)
                && (
                    (year > this.maxYear)
                    || (
                        (year === this.maxYear)
                        && (month > this.maxMonth)
                    )
                )
            );

            date.setMonth(month);

            const text = this.monthFormat.format(date).capitalized();
            const disabled = (lessThanMin || greaterThanMax);
            const marked = (this.markedYear === year) && (this.markedMonth === month);

            const item: IItem = { value: month, text, disabled, marked };
            result.push(item);
        };

        for (let i = EMonth.JANUARY; i <= EMonth.DECEMBER; i++) {
            addItem(i);
        }

        return result;
    }
    // #endregion


    private onClick(month: number): void {
        if ((this.min !== null) && (this.max !== null) && (this.minDate > this.maxDate)) {
            console.error(`Min date ${this.minDate.toDateString()} is greater than ${this.maxDate.toDateString()}`);
            return;
        }

        const date = new Date().clearTimePart();
        if (this.value !== null) {
            date.setTime(this.value.getTime());
        }

        date.setMonth(month);
        if (date.getMonth() !== month) {
            date.setDate(0);
        }

        if ((this.min !== null) && (date < this.min)) {
            date.setTime(this.min.getTime());
        }
        if ((this.max !== null) && (date > this.max)) {
            date.setTime(this.max.getTime());
        }

        if ((this.value === null) || this.value.notEquals(date)) {
            this.$emit(events.model, date);
        }
        this.$emit(events.click);
    }
}