















































import Class from './c-content-meters';
export default Class;
