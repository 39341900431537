/* eslint-disable no-extend-native */
(() => {
    // #region Values
    Date.prototype.getYearMonth = function getYearMonth(): number {
        return (this.getFullYear() * 100) + this.getMonth();
    };
    // #endregion

    // #region Tests
    Date.prototype.equals = function equals(another): boolean {
        return this.getTime() === another.getTime();
    };

    Date.prototype.notEquals = function notEquals(another): boolean {
        return !this.equals(another);
    };

    Date.prototype.fullYearEquals = function fullYearEquals(another): boolean {
        return this.getFullYear() === another.getFullYear();
    };

    Date.prototype.fullYearNotEquals = function fullYearNotEquals(another): boolean {
        return !this.fullYearEquals(another);
    };

    Date.prototype.monthEquals = function monthEquals(another): boolean {
        return this.getMonth() === another.getMonth();
    };

    Date.prototype.monthNotEquals = function monthNotEquals(another): boolean {
        return !this.monthEquals(another);
    };

    Date.prototype.dateEquals = function dateEquals(another): boolean {
        return this.getDate() === another.getDate();
    };

    Date.prototype.dateNotEquals = function dateNotEquals(another): boolean {
        return !this.dateEquals(another);
    };

    Date.prototype.datePartEquals = function datePartEquals(another): boolean {
        return this.fullYearEquals(another) && this.monthEquals(another) && this.dateEquals(another);
    };

    Date.prototype.datePartNotEquals = function datePartNotEquals(another): boolean {
        return !this.datePartEquals(another);
    };

    Date.prototype.hoursEqual = function hoursEqual(another): boolean {
        return this.getHours() === another.getHours();
    };

    Date.prototype.hoursNotEqual = function hoursNotEqual(another): boolean {
        return !this.hoursEqual(another);
    };

    Date.prototype.minutesEqual = function minutesEqual(another): boolean {
        return this.getMinutes() === another.getMinutes();
    };

    Date.prototype.minutesNotEqual = function minutesNotEqual(another): boolean {
        return !this.minutesEqual(another);
    };

    Date.prototype.secondsEqual = function secondsEqual(another): boolean {
        return this.getSeconds() === another.getSeconds();
    };

    Date.prototype.secondsNotEqual = function secondsNotEqual(another): boolean {
        return !this.secondsEqual(another);
    };

    Date.prototype.millisecondsEqual = function millisecondsEqual(another): boolean {
        return this.getMilliseconds() === another.getMilliseconds();
    };

    Date.prototype.millisecondsNotEqual = function millisecondsNotEqual(another): boolean {
        return !this.millisecondsEqual(another);
    };
    // #endregion

    // #region Changes
    Date.prototype.changeTime = function changeTime(delta): Date {
        if ((delta > 0) || (delta < 0)) {
            this.setTime(this.getTime() + delta);
        }

        return this;
    };

    Date.prototype.changeFullYear = function changeFullYear(delta): Date {
        if ((delta > 0) || (delta < 0)) {
            this.setFullYear(this.getFullYear() + delta);
        }
        return this;
    };

    Date.prototype.changeMonth = function changeMonth(delta): Date {
        if ((delta > 0) || (delta < 1)) {
            this.setMonth(this.getMonth() + delta);
        }
        return this;
    };

    Date.prototype.changeDate = function changeDate(delta): Date {
        if ((delta > 0) || (delta < 0)) {
            this.setDate(this.getDate() + delta);
        }
        return this;
    };

    Date.prototype.changeHours = function changeHours(delta): Date {
        if ((delta > 0) || (delta < 0)) {
            this.setHours(this.getHours() + delta);
        }
        return this;
    };

    Date.prototype.changeMinutes = function changeMinutes(delta): Date {
        if ((delta > 0) || (delta < 1)) {
            this.setMinutes(this.getMinutes() + delta);
        }
        return this;
    };

    Date.prototype.changeSeconds = function changeSeconds(delta): Date {
        if ((delta > 0) || (delta < 0)) {
            this.setSeconds(this.getSeconds() + delta);
        }
        return this;
    };

    Date.prototype.changeMilliseconds = function changeMilliseconds(delta): Date {
        if ((delta > 0) || (delta < 0)) {
            this.setMilliseconds(this.getMilliseconds() + delta);
        }
        return this;
    };
    // #endregion

    // #region "Chained" versions of setters
    Date.prototype.setTimeChained = function setTimeChained(time): Date {
        this.setTime(time);
        return this;
    };

    Date.prototype.setMillisecondsChained = function setMillisecondsChained(ms): Date {
        this.setMilliseconds(ms);
        return this;
    };

    Date.prototype.setSecondsChained = function setSecondsChained(sec, ms): Date {
        if (typeof ms === 'number') {
            this.setSeconds(sec, ms);
        } else {
            this.setSeconds(sec);
        }
        return this;
    };

    Date.prototype.setMinutesChained = function setMinutesChained(min, sec, ms): Date {
        if (typeof sec === 'number') {
            if (typeof ms === 'number') {
                this.setMinutes(min, sec, ms);
            } else {
                this.setMinutes(min, sec);
            }
        } else {
            this.setMinutes(min);
        }
        return this;
    };

    Date.prototype.setHoursChained = function setHoursChained(hours, min, sec, ms): Date {
        if (typeof min === 'number') {
            if (typeof sec === 'number') {
                if (typeof ms === 'number') {
                    this.setHours(hours, min, sec, ms);
                } else {
                    this.setHours(hours, min, sec);
                }
            } else {
                this.setHours(hours, min);
            }
        } else {
            this.setHours(hours);
        }
        return this;
    };

    Date.prototype.setDateChained = function setDateChained(date): Date {
        this.setDate(date);
        return this;
    };

    Date.prototype.setMonthChained = function setMonthChained(month, date): Date {
        if (typeof date === 'number') {
            this.setMonth(month, date);
        } else {
            this.setMonth(month);
        }
        return this;
    };

    Date.prototype.setFullYearChained = function setFullYearChained(year, month, date): Date {
        if (typeof month === 'number') {
            if (typeof date === 'number') {
                this.setFullYear(year, month, date);
            } else {
                this.setFullYear(year, month);
            }
        } else {
            this.setFullYear(year);
        }
        return this;
    };
    // #endregion

    // #region Copying
    Date.prototype.copyDatePart = function copyDatePart(source): Date {
        const hours = this.getHours();
        const minutes = this.getMinutes();
        const seconds = this.getSeconds();
        const milliseconds = this.getMilliseconds();

        this
            .setTimeChained(source.getTime())
            .setMillisecondsChained(milliseconds)
            .setSecondsChained(seconds)
            .setMinutesChained(minutes)
            .setHoursChained(hours)
        ;

        return this;
    };

    Date.prototype.copyTimePart = function copyTimePart(source): Date {
        return this
            .setMillisecondsChained(source.getMilliseconds())
            .setSecondsChained(source.getSeconds())
            .setMinutesChained(source.getMinutes())
            .setHoursChained(source.getHours())
        ;
    };
    // #endregion

    Date.prototype.clearTimePart = function clearTimePart(): Date {
        return this
            .setMillisecondsChained(0)
            .setSecondsChained(0)
            .setMinutesChained(0)
            .setHoursChained(0)
        ;
    };
})();