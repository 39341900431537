<template>
    <div class="chart">
        <div class="top-info-block">
            <p class="title">Бюджетные программы на 2022</p>
        </div>
        <div class="content-block full-height">
            <div class="double-general-indicators">
                <div class="indicators-block">
                    <div class="indicator">
                        <p class="text">{{data.data[1].data1.name}}</p>
                        <p class="number">{{data.data[1].data1.count}}</p>
                        <p class="unit">ед.</p>
                    </div>
                    <div class="indicator">
                        <p class="number green">{{data.data[1].data1.budget}}</p>
                        <p class="unit">млрд. тг.</p>
                        <p class="percent green">100%</p>
                    </div>
                </div>
                <div class="indicators-block">
                    <div class="indicator">
                        <p class="text">{{data.data[1].data2.name}}</p>
                        <p class="number">{{data.data[1].data2.count}}</p>
                        <p class="unit">ед.</p>
                    </div>
                    <div class="indicator">
                        <p class="number green">{{data.data[1].data3.budget}}</p>
                        <p class="unit">млрд. тг.</p>
                        <p class="percent green">{{ Math.round(((data.data[1].data3.budget / data.data[1].data1.budget) * 100) * 100) / 100 }}%</p>
                    </div>
                </div>
                <div class="indicators-block">
                    <div class="indicator">
                        <p class="text">{{data.data[1].data3.name}}</p>
                        <p class="number">{{data.data[1].data3.count}}</p>
                        <p class="unit">ед.</p>
                    </div>
                    <div class="indicator">
                        <p class="number green">{{data.data[1].data2.budget}}</p>
                        <p class="unit">млрд. тг.</p>
                        <p class="percent green">{{ Math.round(((data.data[1].data2.budget / data.data[1].data1.budget) * 100) * 100) / 100 }}%</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Chart-investing',
    props: ['subHeader', 'data']
};
</script>