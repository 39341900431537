import { Component, Prop, Vue } from 'vue-property-decorator';


@Component
export default class CTab extends Vue {
    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly title!: string;

    public getEl(): HTMLDivElement | undefined {
        return this.$el as HTMLDivElement;
    }
}