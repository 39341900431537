import { Vue, Component, Prop } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts);


@Component
export default class ChartStackColumnView extends Vue {
    private options: Highcharts.Options = {};

    @Prop({
        type: Array,
        required: true
    })
    private data!: any[];
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly full!: boolean;
    @Prop()
    public readonly redrawTrigger!: any;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    private redrawTriggerChanged() {
        if (this.chart !== undefined) {
            this.chart.reflow();
        }
    }

    private formatDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric'
        });

        return format.format(date);
    }

    private quarterDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric',
            month: 'long'
        });

        return format.format(date);
    }

    private setOptions() {
        // this.data.sort(function (a, b) {
        //     if (a.name < b.name) { return -1; }
        //     if (a.name > b.name) { return 1; }
        //     return 0;
        // });
        const colors = ['#14C485', '#C0504C', '#56B475', '#5a80ad', '#195275', '#632523', '#984807', '#2c4058'];
        const test: any[] = [];
        const label: any[] = [];
        const windowHeight = window.innerHeight;
        let hChart: any = 400;
        let fontSizeLegend: any = '13px';
        let fontSizeXLine: any = '14px';
        let labelFontSize: any = '12px';
        if (this.full) {
            hChart = windowHeight - 300 + (this.data.length * 20);
            fontSizeLegend = '20px';
            fontSizeXLine = '20px';
            labelFontSize = '20px';
        }
        const labels: object = {
            enabled: false
        };
        const title: object = {
            enabled: false
        };
        const stackLabels: object = {
            enabled: false
        };
        const maxPadding = 0.1;
        const visible = true;
        label.push({ stackLabels, labels, title, visible, maxPadding });
        const categories: any[] = [];
        const valid: any[] = [];
        const notValid: any[] = [];
        this.data.forEach((item) => {
            categories.push(item.name);
            valid.push(item.value1);
            notValid.push(item.value2);
        });
        test.push({ name: 'соответствует', data: valid });
        test.push({ name: 'не соответствует', data: notValid });
        const xLine: any = {
            type: 'category',
            categories: categories,
            labels: {
                autoRotation: [0],
                style: {
                    color: '#3F4D67',
                    fontSize: fontSizeXLine,
                    fontWeight: 400
                }
            }
        };
        this.options = {
            colors,
            chart: {
                type: 'column',
                inverted: true,
                backgroundColor: 'transparent',
                spacing: [0, 0, 0, 0],
                height: hChart,
                style: {
                    fontFamily: 'Manrope, sans-serif'
                }
            },
            title: {
                text: ' '
            },
            exporting: {
                enabled: false
            },
            xAxis: xLine,
            yAxis: label,
            legend: {
                align: 'left',
                alignColumns: false,
                verticalAlign: 'top',
                itemStyle: {
                    fontWeight: '400',
                    fontSize: fontSizeLegend,
                    textOverflow: 'null'
                }
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Всего: {point.stackTotal}'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                        // allowOverlap: true,
                        useHTML: true,
                        style: {
                            fontSize: labelFontSize
                        }
                    },
                    animation: false
                }
            },
            series: test
        };
    }

    private chart: Highcharts.Chart | undefined;

    private dataChanged() {
        this.setOptions();
        if (this.chart === undefined) {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
            this.$el.attributes[3].value = 'overflow: visible';
        } else {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
            this.$el.attributes[3].value = 'overflow: visible';
        }
    }

    private created() {
        this.$watch('data', this.dataChanged);
        this.$watch('redrawTrigger', this.redrawTriggerChanged);
    }


    private mounted() {
        this.dataChanged();
    }

    private beforeDestroy() {
        if (this.chart !== undefined) {
            this.chart.destroy();
        }
    }
}