<template>
    <div class="chart">
        <div class="top-info-block" v-if="subHeader">
            <p class="title">{{ data.title }}</p>
        </div>
        <div class="content-block">
            <div class="general-indicators">
                <div class="indicator green">
                    <div class="indicator-content">
                        <p class="text">Всего заявок</p>
                        <p class="number">{{ data.data.total }}</p>
                        <i class="icon icon-document-pencil"></i>
                    </div>
                </div>
                <div class="indicator blue">
                    <span style="cursor: pointer;" @click="$router.push('/budget-form')">
                        <div class="indicator-content">
                            <p class="text">Новые инициативы</p>
                            <p class="number">{{ data.data.new }}</p>
                            <i class="icon icon-document-pencil"></i>
                        </div>
                    </span>
                </div>
                <div class="indicator orange">
                    <div class="indicator-content">
                        <p class="text">Переходящие проекты</p>
                        <p class="number">{{ data.data.old }}</p>
                        <i class="icon icon-document-pencil"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Chart-investing',
    props: ['subHeader', 'data']
};
</script>