import { TModalHideReason } from '../types';


export default class ModalHideEvent extends Event {
    public readonly reason: TModalHideReason;

    public constructor(reason: TModalHideReason) {
        super('hide', {
            cancelable: true
        });

        this.reason = reason;
    }
}