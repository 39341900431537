import VueRouter, { RawLocation } from 'vue-router';
import { TMenuItem, IMenuNavRoute } from './types';


export const forbiddenQueryKeys = ['__ob__'];

export const getFixedQuery = (query: Record<string, any>): Record<string, any> => {
    const keys = Object.getOwnPropertyNames(query);
    keys.sort();

    forbiddenQueryKeys.forEach((key) => {
        const index = keys.indexOf(key);
        if (index >= 0) {
            keys.splice(index, 1);
        }
    });

    const result: Record<string, any> = {};
    keys.forEach((key) => {
        result[key] = query[key];
    });
    return result;
};

export const getFixedRawLocation = (nav: IMenuNavRoute): RawLocation => {
    let path: string;
    if (nav.path.startsWith('/')) {
        path = nav.path;
    } else {
        path = `/${nav.path}`;
    }

    const result: RawLocation = { path };
    if (nav.query) {
        result.query = getFixedQuery(nav.query);
    }

    return result;
};

export const getActive = (router: VueRouter, items: TMenuItem[]): TMenuItem[] => {
    const result: TMenuItem[] = [];

    const route = router.currentRoute;

    const scan = (currentRouter: VueRouter, currentItems: TMenuItem[]): boolean => {
        for (const item of currentItems) {
            if (item.type === 'item') {
                const nav = item.nav;
                if (nav.type === 'route') {
                    if (nav.isActive) {
                        const checkResult = nav.isActive(currentRouter);
                        if (typeof checkResult === 'boolean') {
                            if (checkResult) {
                                result.unshift(item);
                                return true;
                            }
                        }
                    }

                    const rawLocation = getFixedRawLocation(nav);
                    const resolvedLocation = currentRouter.resolve(rawLocation);
                    if (resolvedLocation.route.fullPath === route.fullPath) {
                        result.unshift(item);
                        return true;
                    }
                }
            } else {
                if (item.children.length > 0) {
                    const hasActive = scan(currentRouter, item.children);
                    if (hasActive) {
                        result.unshift(item);
                        return true;
                    }
                }
            }
        }

        return false;
    };
    scan(router, items);

    return result;
};