/* eslint-disable no-extend-native */
(() => {
    String.prototype.capitalized = function capitalized(): string {
        switch (this.length) {
            case 0:
                return this as string;
            case 1:
                return this.toUpperCase();
            default:
                return this.substring(0, 1).toUpperCase() + this.substring(1).toLowerCase();
        }
    };
})();