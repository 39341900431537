import { Component, Prop, Vue } from 'vue-property-decorator';

import '../../initialization';
import { accessor } from '../../store';
import { ISelectedIndicatorItems, IWidgetChart3dPie } from '../../store/types';

import queue from '../queue';

import CView from './c-view';
import { IItem, IItemTitle } from './types';


@Component({
    components: {
        'c-view': CView
    }
})
export default class CContentChartRadiusPie extends Vue {
    private created() {
        this.$watch('getViewData', this.selectedMapChanged);
    }

    private mounted() {
        this.selectedMapChanged();
    }

    private beforeDestroy() {
        queue.clear(this);
    }


    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: IWidgetChart3dPie;

    @Prop()
    public readonly redrawTrigger!: any;

    public get indicatorConfigs() {
        return this.data.indicatorConfigs;
    }

    public get selectedMap(): Map<string, ISelectedIndicatorItems> {
        const result = new Map<string, ISelectedIndicatorItems>();

        this.indicatorConfigs.forEach((key) => {
            const items = accessor.selectedMap.get(key.indicator);
            if (items !== undefined) {
                result.set(key.indicator, items);
            }
        });

        return result;
    }

    private selectedMapChanged() {
        setTimeout(() => {
            queue.schedule(this, () => {
                this.sumFact = this.getSumFact();
                this.unit = this.getUnit();
                this.viewData = this.getViewData;
                this.titleData = this.getTitleData();
            });
        }, 1);
    }

    private getItems(indicator: any) {
        const items = indicator.items.slice(-7 + this.year, indicator.items.length + this.year);
        return items;
    }

    private previousDate() {
        this.year += 1;
        this.selectedMapChanged();
    }
    private nextDate() {
        this.year -= 1;
        this.selectedMapChanged();
    }

    private year = 0;
    private itemsLen = 0;


    private sumFact: number | null = null;
    private unit: string | null = null;
    private viewData: IItem[] | null = null;
    private titleData: IItemTitle | null = null;


    // Считаем сумму по всем показателям
    public getSumFact(): number | null {
        let check: number | null;
        check = null;
        this.indicatorConfigs.forEach((key) => {
            const selectedIndicator = this.selectedMap.get(key.indicator);
            if (selectedIndicator !== undefined) {
                const indicatorItem = selectedIndicator.item;
                if (indicatorItem !== undefined) {
                    if (check !== null) {
                        check = check + indicatorItem.fact;
                    } else {
                        check = indicatorItem.fact;
                    }
                }
            }
        });
        return check;
    }

    // наименование единиц измерения
    public getUnit(): string | null {
        let check: string | null;
        check = null;
        this.indicatorConfigs.forEach((key) => {
            const selectedIndicator = this.selectedMap.get(key.indicator);
            if (selectedIndicator !== undefined) {
                const indicatorItem = selectedIndicator.item;
                if (indicatorItem !== undefined) {
                    check = selectedIndicator.unit;
                }
            }
        });
        return check;
    }

    public get getViewData(): IItem[] | null {
        if (this.indicatorConfigs.length === 0) {
            return null;
        }
        const items: IItem[] = [];
        this.indicatorConfigs.forEach((key) => {
            const selectedIndicator = this.selectedMap.get(key.indicator);
            const indicItems = [];
            if (selectedIndicator !== undefined) {
                const indicatorItems = selectedIndicator.items;
                for (let i = indicatorItems.length - 1; i > -1; i--) {
                    indicItems.push(indicatorItems[i]);
                }
                const indicatorItem = indicItems[this.year];
                let measurement = 1;
                if (key.measurement !== undefined) {
                    measurement = key.measurement;
                }
                const confUnit: any = key.unit;
                let unit: any;
                if ((Object.keys(confUnit).length === 0)) {
                    unit = selectedIndicator.unit;
                } else if ((confUnit.name_ru === '')) {
                    unit = selectedIndicator.unit;
                } else {
                    if (confUnit.nationalSymbol === undefined || confUnit.nationalSymbol === null) {
                        unit = confUnit.name_ru;
                    } else {
                        unit = confUnit.nationalSymbol;
                    }
                }
                let unitLowerCased: string | null = null;
                if (unit !== null || unit !== undefined) {
                    unitLowerCased = unit.toLowerCase();
                    if (unitLowerCased === 'процент') {
                        unitLowerCased = '%';
                    }
                }
                if (indicatorItem !== undefined) {
                    if (indicatorItem.fact !== 0) {
                        const item: IItem = {
                            date: indicatorItem.date,
                            value: indicatorItem.fact * measurement,
                            shortName: key.title,
                            unit: unitLowerCased
                        };
                        items.push(item);
                    }
                }
            }
        });
        return items;
    }

    // Данные для "роста/спада"
    public getTitleData(): IItemTitle | null {
        let indicLen = 0;
        let data: IItemTitle | null = null;
        let growIndicProc: number | null = null;
        let growIndic: number | null = null;

        let previousFact = 0;

        // актуальная сумма по всем показателям
        let sumFact: number | null = null;
        //  наименование единиц измерения
        let unitLowerCased: string | null = null;

        this.indicatorConfigs.forEach((key, index) => {
            const selectedIndicator = this.selectedMap.get(key.indicator);
            if (selectedIndicator !== undefined) {
                if (indicLen < selectedIndicator.items.length) {
                    indicLen = selectedIndicator.items.length;
                }
                const indicItems = [];
                const indicatorItems = selectedIndicator.items;
                for (let i = indicatorItems.length - 1; i > -1; i--) {
                    indicItems.push(indicatorItems[i]);
                }
                if (index === 0) {
                    this.selected_year(indicItems);
                }
                const indicatorItem = indicItems[this.year];
                const current = indicatorItem;
                const typeIndic = selectedIndicator.character;
                if (this.year < indicItems.length) {
                    const previous = indicItems[this.year + 1];
                    if ((indicatorItem !== undefined) && (current.fact !== 0)) {
                        let measurement = 1;
                        if (key.measurement !== undefined) {
                            measurement = key.measurement;
                        }
                        if (sumFact !== null) {
                            sumFact = sumFact + current.fact * measurement;
                        } else {
                            sumFact = current.fact * measurement;
                            const confUnit: any = key.unit;
                            let unit: any;
                            if ((Object.keys(confUnit).length === 0)) {
                                unit = selectedIndicator.unit;
                            } else if ((confUnit.name_ru === '')) {
                                unit = selectedIndicator.unit;
                            } else {
                                unit = confUnit.name_ru;
                            }
                            if (unit !== null || unit !== undefined) {
                                unitLowerCased = unit.toLowerCase();
                                if (unitLowerCased === 'процент') {
                                    unitLowerCased = '%';
                                }
                            }
                        }
                        if (previous !== undefined) {
                            // вычисляем предыдущее актуальное значение
                            if (previousFact !== null) {
                                previousFact = previousFact + previous.fact * measurement;
                            } else {
                                previousFact = previous.fact * measurement;
                            }
                            // характер показателя
                            if (typeIndic === 0) {
                                growIndic = typeIndic;
                            }
                            growIndicProc = Math.round(((sumFact / previousFact) * 100 - 100) * 100) / 100;
                            if (unitLowerCased === '%') {
                                growIndicProc = Math.round((sumFact - previousFact) * 100) / 100;
                            }
                            if (typeIndic === 0) {
                                growIndic = typeIndic;
                            } else {
                                if (growIndicProc === 0) {
                                    growIndic = typeIndic;
                                } else {
                                    growIndic = typeIndic * growIndicProc;
                                }
                            }
                        }
                        data = {
                            sumFact,
                            unit: unitLowerCased,
                            growIndic,
                            growIndicProc
                        };
                    }
                }
            }
        });
        this.itemsLen = indicLen;
        return data;
    }

    private selected_year(items: any) {
        const years = [];
        for (let i = items.length - 1; i > -1; i--) {
            years.push(items[i].date.getFullYear());
        }
        let new_years: any;
        if (this.year < 4) {
            new_years = years.slice(-7, items.length);
        } else {
            new_years = years.slice(-7 - this.year + 3, items.length - this.year + 3);
        }
        this.selected_year_arr = new_years;
        this.current_year = items[this.year].date.getFullYear();
    }

    private selected_year_arr: any = [];

    private current_year: any;
}