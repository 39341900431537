



















































































import Class from './c-content-text-bars';
import { } from '@/components';
export default Class;
