export default class Queue<K> {
    private map = new Map<K, () => void>();
    private runHandle: number | undefined;
    private warningTimeout: number;
    private readonly useAnimation: boolean;

    public constructor(useAnimation: boolean, warningTimeout?: number) {
        this.useAnimation = useAnimation;
        if (warningTimeout === undefined) {
            this.warningTimeout = Number.MAX_SAFE_INTEGER;
        } else {
            this.warningTimeout = warningTimeout;
        }
    }

    private run() {
        if (this.map.size > 0) {
            let entry: [K, () => void] | undefined;
            for (const currentEntry of this.map) {
                entry = currentEntry;
                break;
            }

            if (entry !== undefined) {
                this.map.delete(entry[0]);
                try {
                    let time = Date.now();

                    entry[1]();

                    time = Date.now() - time;
                    if (time > this.warningTimeout) {
                        console.warn(new Error(`code to slow - elapsed ${time} ms`));
                    }
                } catch (error) {
                    console.error(error);
                }

                if (this.map.size > 0) {
                    this.scheduleRun();
                }
            }
        }
    }

    private scheduleRun() {
        if (this.runHandle === undefined) {
            const block = () => {
                this.runHandle = undefined;
                this.run();
            };

            if (this.useAnimation) {
                this.runHandle = requestAnimationFrame(block);
            } else {
                this.runHandle = setTimeout(block);
            }
        }
    }


    public schedule(key: K, block: () => void) {
        this.map.set(key, block);
        this.scheduleRun();
    }

    public clear(key: K) {
        this.map.delete(key);
    }
}