import { FunctionalComponentOptions, VNode } from 'vue';


interface IProps {
    variant: string | null;
}

const cssClass = 'c-badge';

const options: FunctionalComponentOptions<IProps> = {
    functional: true,

    props: {
        variant: {
            type: String,
            required: false,
            default: null
        }
    },

    render: (h, context): VNode => {
        if ((context.data.staticClass === undefined) || (context.data.staticClass.length === 0)) {
            context.data.staticClass = cssClass;
        } else {
            context.data.staticClass = `${context.data.staticClass} ${cssClass}`;
        }

        if (context.props.variant !== null) {
            context.data.staticClass = `${context.data.staticClass} c-badge--${context.props.variant}`;
        }

        return h('span', context.data, context.children);
    }
};
export default options;