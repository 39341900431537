

































import Class from './c-content-chart-model-linear';
export default Class;
