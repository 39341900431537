












import Class from './c-flying';
export default Class;
