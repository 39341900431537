import { Component, Vue, Prop } from 'vue-property-decorator';
import VueResizable from 'vue-resizable';
import { Ax } from '@/utils';

import store from '@/services/store';
import '../../initialization';
import { accessor } from '../../store';
import { assignGrow } from '../common';
import {
    EWidgetType,
    ISelectedIndicatorItems,
    IWidgetTextLines,
    IWidgetTextBars,
    IWidgetMeters,
    IWidgetChartLinearMarkers,
    IWidgetChart3dPie,
    IWidgetChartFlatPie,
    IWidgetChartRadiusPie,
    IWidgetChartStackColumn,
    IWidgetChartTreeMap,
    IWidgetChartActual,
    IWidgetChartAmCharts,
    IWidgetChartBars,
    IWidgetChartBudget,
    IWidgetChartMultipleAxes,
    IWidgetChartCovid,
    IWidgetChartSoc
} from '../../store/types';
import CContent from '../c-content';

type IWidgets =
    IWidgetTextLines
    | IWidgetTextBars
    | IWidgetMeters
    | IWidgetChartLinearMarkers
    | IWidgetChart3dPie
    | IWidgetChartFlatPie
    | IWidgetChartRadiusPie
    | IWidgetChartStackColumn
    | IWidgetChartTreeMap
    | IWidgetChartActual
    | IWidgetChartAmCharts
    | IWidgetChartBars
    | IWidgetChartBudget
    | IWidgetChartMultipleAxes
    | IWidgetChartCovid
    | IWidgetChartSoc;


@Component({
    components: {
        'c-content': CContent,
        'vue-resizable': VueResizable
    }
})
export default class VWidget extends Vue {
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;

    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: IWidgets;

    @Prop({
        type: Object,
        required: false,
        default: {}
    })
    public readonly section!: any;

    @Prop()
    public readonly redrawTrigger!: any;

    private redrawAddition = 0;

    public get actualRedrawTrigger(): any {
        return {
            redrawTrigger: this.redrawTrigger,
            redrawAddition: this.redrawAddition
        };
    }

    private widgetDownload = false;

    private chartAction = '';

    private commentModalShow = false;

    private maxWidth = 1300;

    private maxHeight = 700;

    private fullSize = false;

    private keyBoard = false;

    public mounted() {
        setTimeout(() => {
            this.redrawAddition = Date.now();
        });
    }

    private fullSizible() {
        this.fullSize = !this.fullSize;
        this.$root.$emit('fullSizeActive');
        const cards = document.getElementsByClassName('.col:not(.full-size)');

            if (this.fullSize === true) {
                for (let i = 0; i < cards.length; i++) {
                    cards[i].setAttribute('style', 'display: none');
                }
            }
            if (this.fullSize === false) {
                    for (let i = 0; i < cards.length; i++) {
                        cards[i].setAttribute('style', 'display: block');
                    }
            }
    }

    eHandler(data: any) {
        this.data.size[0] = data.width;
        this.data.size[1] = data.height;
    }

    eHandlerStop() {
        this.key = this.data.size[0] + this.data.size[1];
        accessor.saveWidgets();
    }

    private key = 0;

    public get isAuthorized(): boolean {
        return store.state.user.authorized;
    }

    public get type(): EWidgetType {
        return this.data.type;
    }

    public get id(): number {
        return this.data.id;
    }

    public get singleSelectedItem(): ISelectedIndicatorItems | null {
        if (this.data.indicatorKeys.length === 1) {
            const result = accessor.selectedMap.get(this.data.indicatorKeys[0]);
            if (result !== undefined) {
                return result;
            }
        }

        return null;
    }

    public get title(): string {
        if ((this.data.title.trim().length === 0) && (this.singleSelectedItem !== null)) {
            return this.singleSelectedItem.shortIndicator;
        }

        return this.data.title;
    }

    public get indicatorKeys(): string[] {
        if (this.data.type === EWidgetType.CHART_MULTIPLE_AXES) {
            const data = this.data as IWidgetChartMultipleAxes;
            const resArr: string[] = [];
            for (const indicatorConfig of data.indicatorConfigs) {
                resArr.push(indicatorConfig.indicator);
            }
            return resArr;
        }
        if (this.data.type === EWidgetType.CHART_BARS) {
            const data = this.data as IWidgetChartBars;
            const resArr: string[] = [];
            for (const indicatorConfig of data.indicatorConfigs) {
                resArr.push(indicatorConfig.indicator);
            }
            return resArr;
        }
        if (this.data.type === EWidgetType.CHART_BUDGET) {
            const data = this.data as IWidgetChartBudget;
            const resArr: string[] = [];
            for (const indicatorConfig of data.indicatorConfigs) {
                resArr.push(indicatorConfig.indicator);
            }
            return resArr;
        }
        return this.data.indicatorKeys;
    }

    public get commentText(): string[] {
        const commentArr: string[] = [];

        this.indicatorKeys.forEach((indicatorKey) => {
            const selectedItems = accessor.selectedMap.get(indicatorKey);
            const growDatas = assignGrow(indicatorKey);

            if ((selectedItems !== undefined) && (growDatas !== undefined) && (growDatas.current !== undefined)) {
                let text = 'Показатель "' + selectedItems.indicator + '"';
                if (growDatas.current.periodStart !== undefined) {
                    text += this.getPeriodText(selectedItems.periodicity, growDatas.current.periodStart);
                }
                text += ' составляет ' + growDatas.current.fact + ' ' + growDatas.unit + '.';
                if (growDatas.growProc === 0) {
                    text += ' Изменение по сравнению с предыдущим периодом не наблюдается.';
                } else if (growDatas.growProc !== null) {
                    text += ' Наблюдается ';
                    if (growDatas.growProc > 0) {
                        text += ' рост на ' + growDatas.growProc + '% по сравнению с предыдущим аналогичным периодом.';
                    } else if (growDatas.growProc < 0) {
                        text += ' спад на ' + Math.abs(growDatas.growProc) + '% по сравнению с предыдущим аналогичным периодом.';
                    }
                }
                commentArr.push(text);
            }
            return commentArr;
        });
        return commentArr;
    }

    private getPeriodText(periodicity: string, periodDate: Date): string {
        // eslint-disable-next-line no-param-reassign
        periodicity = periodicity.toLowerCase();
        let text = '';
        switch (periodicity) {
            case 'год': {
                text += ' за ' + periodDate.getFullYear() + ' год';
                break;
            }
            case 'полугодие': {
                if (periodDate.getMonth() === 0) {
                    text += ' за 1 полугодие';
                } else {
                    text += ' за 2 полугодие';
                }
                break;
            }
            case 'квартал':
            case 'квартал с накоплением': {
                switch (periodDate.getMonth()) {
                    case 0: {
                        text += ' за 1 квартал';
                        break;
                    }
                    case 3: {
                        text += ' за 2 квартал';
                        break;
                    }
                    case 6: {
                        text += ' за 3 квартал';
                        break;
                    }
                    case 9: {
                        text += ' за 4 квартал';
                        break;
                    }
                    default: {
                        text += ' за не понятно какой квартал';
                    }
                }
                if (periodicity === 'квартал с накоплением') {
                    text += ' с накоплением';
                }
                text += ' ' + periodDate.getFullYear() + ' года';
                break;
            }
            case 'месяц': {
                text += ' за ' + Intl.DateTimeFormat(this.$i18n.locale, {month: 'long'}).format(periodDate);
                text += ' ' + periodDate.getFullYear() + ' года';
                break;
            }
            case 'месяц с накоплением': {
                text += ' за ' + Intl.DateTimeFormat(this.$i18n.locale, {month: 'long'}).format(periodDate) + ' с накоплением';
                text += ' ' + periodDate.getFullYear() + ' года';
                break;
            }
            default: {
                text += ' на ' + Intl.DateTimeFormat(this.$i18n.locale, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }).format(periodDate);
            }
        }
        return text;
    }

    public toSettings(id: number) {
        this.$router.push({path: '/widget-yard', query: {widgetId: id.toString()}});
    }

    created() {
        this.$root.$on('openBoard', () => {
            const self = this;
            setTimeout(function () {
                self.keyBoard = !self.keyBoard;
            }, 200);
        });
    }

    private getIndicator(key: string) {
        const list = accessor.indicators;
        const indicator = list.find(x => x.newKey === key);
        return indicator;
    }

    // eslint-disable-next-line consistent-return
    private widgetExport(widget: any) {
        const widgets = [];
        const indicatorConfigs = widget.indicatorConfigs;
        for (const config of indicatorConfigs) {
            let indicator = accessor.getIndicator(config.indicator);
            if (indicator === undefined) {
                indicator = this.getIndicator(config.indicator);
            }

            if (indicator === undefined) {
                return null;
            }
            const selectedItems = accessor.selectedMap.get(indicator.key);
            const items: any[] = [];
            indicator.items.sort((a: any, b: any) => a.date - b.date);
            for (const selectedItem of indicator.items) {
                const item: any = {
                    x: selectedItem.date,
                    y: Math.round((selectedItem.fact * config.measurement) * 10) / 10
                };
                items.push(item);
            }
            let unit = selectedItems?.unit;
            if (Object.keys(config.unit).length > 0) {
                unit = config.unit.nationalSymbol;
            }
            let type = null;
            if (widget.type === 'chart-multiple-axes') {
                type = config.type;
            }
            const data = {
                title: config.title,
                periodicity: selectedItems?.periodicity,
                items,
                unit,
                type
            };
            widgets.push(data);
        }
        const formData: any = {
            'dev': ' ',
            'title': widget.title,
            'type': widget.type,
            'widgets': widgets
        };

        Ax(
            {
                url: '/api-py/widget-export',
                method: 'POST',
                data: formData
            },
            (data: any) => {
                this.donwloadXLSX(data);
            },
            (error) => {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        );
    }
    private donwloadXLSX(url: any) {
        if (url === 'Something went wrong') {
            return;
        }
        window.open('/api-py/widget-export/' + url, '_blank');
    }

}