import { Component, Prop, Vue } from 'vue-property-decorator';

import CChartStackColumnView from './c-view/';

@Component({
    components: {
        'c-chart-stack-column-view': CChartStackColumnView
    }
})
export default class CContentChartStackColumn extends Vue {
    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: any;
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly full!: boolean;
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly subHeader!: boolean;

    private get result() {
        const res: any[] = [];
        this.data.data.sort(function (a: any, b: any) {
            if (a.dict.id < b.dict.id) { return -1; }
            if (a.dict.id > b.dict.id) { return 1; }
            return 0;
        });
        this.data.data.forEach((item: any) => {
            const indicator = {
                name: item.dict.name_ru,
                value1: item.correspond,
                value2: item.dependent
            };
            res.push(indicator);
        });
        return res;
    }

    private changePath() {
        this.$router.push(this.data.href);
    }
}