





























































import Class from './c-content-chart-tree-map';
export default Class;
