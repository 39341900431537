import { Component, Prop, Vue } from 'vue-property-decorator';

import '../../initialization';
import { accessor } from '../../store';
import { ISelectedIndicatorItems, IWidgetChartLinearMarkers } from '../../store/types';

import queue from '../queue';

import CView from './c-view';
import { IItemPack, IItem } from './types';
import { assignGrow } from '../common';

@Component({
    components: {
        'c-view': CView
    }
})
export default class CContentLinearMarkers extends Vue {
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: IWidgetChartLinearMarkers;

    @Prop()
    public readonly redrawTrigger!: any;

    private year = 0;

    private viewData: IItemPack | null = null;

    private created() {
        this.$watch('getViewData', this.selectedItemsChanged);
        this.$watch('year', this.showNav);
    }

    private mounted() {
        this.selectedItemsChanged();
    }

    private beforeDestroy() {
        queue.clear(this);
    }

    private showNav() {
        return (this.year > -(this.indic !== null ? this.indic : 0) + 1 && (this.indic !== null ? this.indic : 0) > 7);
    }

    public get indicatorConfigs() {
        return this.data.indicatorConfigs;
    }

    public get selectedItems(): ISelectedIndicatorItems | null {
        if (this.indicatorConfigs.length === 0) {
            return null;
        }

        const result = accessor.selectedMap.get(this.indicatorConfigs[0].indicator);
        if (result === undefined) {
            return null;
        }

        return result;
    }

    private selectedItemsChanged(): void {
        setTimeout(() => {
            queue.schedule(this, () => {
                this.viewData = this.getViewData;
            });
        }, 2);
    }

    private get indic() {
        const key = this.indicatorConfigs[0].indicator;
        const indicator = accessor.getIndicator(key);
        if (indicator === undefined) {
            return null;
        }
        return indicator.items.length;
    }

    private getItems(indicator: any) {
        indicator.items.sort((a: any, b: any) => a.date - b.date);
        let items = indicator.items.slice(-7 + this.year, indicator.items.length + this.year);
        if (indicator.periodicity === 'квартал' || indicator.periodicity === 'квартал с накоплением') {
            items = indicator.items.slice(-8 + this.year, indicator.items.length + this.year);
        }

        if (indicator.periodicity === 'месяц' || indicator.periodicity === 'месяц с накоплением') {
            items = indicator.items.slice(-12 + this.year, indicator.items.length + this.year);
        }
        return items;
    }

    private previousDate() {
        this.year -= 1;
        this.selectedItemsChanged();
    }
    private nextDate() {
        this.year += 1;
        this.selectedItemsChanged();
    }

    private getPrevDate(date: any, indicator: any) {
        const d = new Date(date);
        const lastYear = d.setFullYear(d.getFullYear() - 1);
        const items = indicator.items.slice(-20 + this.year, indicator.items.length + this.year);
        items.sort((a: any, b: any) => {
            return Math.abs(lastYear - a.date.getTime()) - Math.abs(lastYear - b.date.getTime());
        });
        return items;
    }

    private getIndicator(key: string) {
        const list = accessor.indicators;
        const indicator = list.find(x => x.newKey === key);
        return indicator;
    }

    public get getViewData(): IItemPack | null {
        const d = new Date().getTime();
        if (this.indicatorConfigs.length === 0) {
            return null;
        } // return null
        const key = this.indicatorConfigs[0].indicator;
        let indicator = accessor.getIndicator(key);
        if (indicator === undefined) {
            indicator = this.getIndicator(key);
        }

        if (indicator === undefined) {
            return null;
        } // return null

        const datas = this.getItems(indicator);
        const getCurr: any = this.getItems(indicator);
        getCurr.sort((a: any, b: any) => {
            return Math.abs(d - a.date.getTime()) - Math.abs(d - b.date.getTime());
        });
        const items: IItem[] = [];
        const growDatas = assignGrow(indicator.key);
        const confUnit: any = this.indicatorConfigs[0].unit;
        let unit: any;
        if ((Object.keys(confUnit).length === 0)) {
            unit = indicator.unit;
        } else if ((confUnit.name_ru === '')) {
            unit = indicator.unit;
        } else {
            if (confUnit.nationalSymbol === undefined || confUnit.nationalSymbol === null) {
                unit = confUnit.name_ru;
            } else {
                unit = confUnit.nationalSymbol;
            }
        }
        let unitLowerCased: string | null = null;
        if (unit !== null || unit !== undefined) {
            unitLowerCased = unit.toLowerCase();
            if (unitLowerCased === 'процент') {
                unitLowerCased = '%';
            }
        }
        if (growDatas === undefined) {
            return null;
        }
        const lcUnit = (Boolean((unit === 'процент') || (unit === '%')));
        growDatas.current = getCurr[0];
        const prevDate = this.getPrevDate(getCurr[0].date, indicator);
        // eslint-disable-next-line no-unused-expressions
        growDatas.previous = prevDate[0];
        let growProc = null;
        if (growDatas.previous !== undefined) {
            growProc = (lcUnit
                ? Math.round((getCurr[0].fact - prevDate[0].fact) * 100) / 100
                : Math.round(((getCurr[0].fact - prevDate[0].fact) / prevDate[0].fact) * 100 * 100) / 100);
        }
        if (growProc !== null && growDatas.character !== null) {
            growDatas.growProc = growProc;
        }
        growDatas.unit = unit.toLowerCase();
        let measurement = 1;
        if (this.indicatorConfigs[0].measurement !== undefined) {
            measurement = this.indicatorConfigs[0].measurement;
        }
        if (growDatas.current === undefined) {
            return null;
        }
        growDatas.value = growDatas.current.fact * measurement;
        datas.forEach((selectedItem: any) => {
            const item: IItem = {
                date: selectedItem.date,
                value: Math.round((selectedItem.fact * measurement) * 10) / 10
            };
            items.push(item);
        });
        let current = null;
        if (growDatas.current !== undefined) {
            current = Math.round((growDatas.current.fact * measurement) * 10) / 10;
        }
        let previous = null;
        if (growDatas.previous !== undefined) {
            previous = Math.round((growDatas.previous.fact * measurement) * 10) / 10;
        }
        return {
            items,
            growDatas: (growDatas !== undefined ? growDatas : null),
            unit: unitLowerCased,
            previous,
            current
        };
    }
}