import { Component, Model, Prop, Vue } from 'vue-property-decorator';

import { Flying } from '@/utils';

import CDatePickerBody from '../c-date-picker-body';
import { flyingTriggers, TFlyingTrigger } from '../types';


const events = {
    model: 'change',
    zoom: 'zoom',
    visibility: 'visibility'
};


@Component
export default class CDatePopover extends Vue {
    // #region Lifecycle
    private created(): void {
        this.$watch('visible', this.visibleChanged);

        this.visibleChanged();
    }
    // #endregion


    // #region Elements
    private getPickerBody(): CDatePickerBody | undefined {
        const result = this.$refs.pickerBody;
        if (result instanceof CDatePickerBody) {
            return result;
        }
        return undefined;
    }

    public hide(): void {
        this.setLocalVisible(false);
    }
    // #endregion


    // #region Arrow
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly arrow!: boolean;
    // #endregion


    // #region Initially visible
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly initiallyVisible!: boolean;
    // #endregion


    // #region Visible
    @Prop({
        type: Boolean,
        required: false,
        default: null
    })
    public readonly visible!: boolean | null;

    private visibleChanged(): void {
        if ((this.visible !== null) && (this.localVisible !== this.visible)) {
            this.localVisible = this.visible;
        }
    }

    private localVisible = false;

    private setLocalVisible(value: boolean): void {
        if (this.value === null) {
            if (this.localVisible !== value) {
                this.localVisible = value;
            }
        }
        if (this.visible !== value) {
            this.$emit(events.visibility, value);
        }

        if (!value) {
            const body = this.getPickerBody();
            if (body !== undefined) {
                body.resetLocalZoom();
            }
        }
    }
    // #endregion


    // #region Anchor
    @Prop({
        type: [ Element, Vue ],
        required: false,
        default: null
    })
    public readonly anchor!: Element | Vue | null;
    // #endregion


    // #region Viewport
    @Prop({
        type: [ Element, String ],
        required: false,
        default: null,
        validator(value: any): boolean {
            return (value === null) || (value === 'portal') || (value === 'body') || (value instanceof Element);
        }
    })
    public readonly viewport!: Element | 'portal' | 'body' | null;
    // #endregion


    // #region Boundary
    @Prop({
        type: Element,
        required: false,
        default: null
    })
    public readonly boundary!: Element | null;
    // #endregion


    // #region No anchor overlap
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly noAnchorOverlap!: boolean;
    // #endregion


    // #region Strategy
    @Prop({
        type: String,
        required: false,
        default: 'fixed',
        validator(value: any): boolean {
            return Flying.Types.strategies.includes(value);
        }
    })
    public readonly strategy!: Flying.Types.TStrategy;
    // #endregion


    // #region Placement
    @Prop({
        type: Object,
        required: false,
        default: (): Flying.Types.IPlacement => {
            return {
                placement: 'bottom',
                variant: 'start'
            };
        }
    })
    public readonly placement!: Flying.Types.IPlacement | null;
    // #endregion


    // #region Alt placements
    @Prop({
        type: Array,
        required: false,
        default: (): Flying.Types.IPlacement[] => {
            return [
                {
                    placement: 'auto',
                    variant: 'start'
                }
            ];
        }
    })
    public readonly altPlacements!: Flying.Types.IPlacement[] | null;
    // #endregion


    // #region Show without anchor
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly showWithoutAnchor!: boolean;
    // #endregion


    // #region Trigger
    @Prop({
        type: [ String, Array ],
        required: false,
        default: (): TFlyingTrigger[] => {
            return [
                'anchor-click',
                'window-click-hide'
            ];
        },
        validator(value: any): boolean {
            if (value === null) {
                return true;
            }

            if (typeof value === 'string') {
                const items = value.split(',');

                for (const item of items) {
                    const prepared = item.trim() as TFlyingTrigger;
                    if (flyingTriggers.notIncludes(prepared)) {
                        return false;
                    }
                }

                return true;
            }

            if (Array.isArray(value)) {
                for (const item of value) {
                    if (flyingTriggers.notIncludes(item)) {
                        return false;
                    }
                }
                return true;
            }

            return false;
        }
    })
    public readonly trigger!: TFlyingTrigger | TFlyingTrigger[] | null;
    // #endregion


    // #region Zoom
    @Prop({
        type: Number,
        required: false,
        default: null
    })
    public readonly zoom!: number | null;
    // #endregion


    // #region Min zoom
    @Prop({
        type: Number,
        required: false,
        default: null
    })
    public readonly minZoom!: number | null;
    // #endregion


    // #region Max zoom
    @Prop({
        type: Number,
        required: false,
        default: null
    })
    public readonly maxZoom!: number | null;
    // #endregion


    private onZoomChange(zoom: number): void {
        if (this.zoom !== zoom) {
            this.$emit(events.zoom, zoom);
        }
    }


    // #region Value
    @Model(events.model, {
        type: Date,
        required: false,
        default: null
    })
    public readonly value!: Date | null;
    // #endregion


    // #region Min
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly min!: Date | null;
    // #endregion


    // #region Max
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly max!: Date | null;
    // #endregion


    private onChange(value: Date): void {
        if ((this.value === null) || this.value.datePartNotEquals(value)) {
            this.$emit(events.model, value);
        }
    }


    // #region Locale
    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly locale!: string | null;
    // #endregion


    // #region First day of week
    @Prop({
        type: Number,
        required: false,
        default: null
    })
    public readonly firstDayOfWeek!: number | null;
    // #endregion


    // #region Tool buttons
    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly today!: string | null;

    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly reset!: string | null;

    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly clear!: string | null;
    // #endregion


    private onDayClick(): void {
        this.hide();
    }
}