import { Ax } from '@/utils';

import module from './module';
import { IRequest, IStatRequests } from './types';


// #region Initialization - loading data
(() => {
    const coorToString = (coor: number, latitude: boolean): string => {
        const degree = Math.trunc(coor);
        const minutes = (coor - degree) * 60;
        const minutesInt = Math.trunc(minutes);
        const seconds = (minutes - minutesInt) * 60;

        let suffix: string;
        if (latitude) {
            suffix = 'N';
        } else {
            suffix = 'E';
        }

        return `${degree}°${minutesInt}'${seconds.toFixed(3)}"${suffix}`;
    };

    module.loading = true;

    Ax<IRequest[]>(
        {
            url: '/api/integration/appeal'
        },
        (loadedData) => {
            const activeProblems = new Map<string, IStatRequests>();

            loadedData.forEach((request) => {
                request.attributes.dateTime = new Date(request.attributes.time);

                if (request.attributes.fact !== null) {
                    request.attributes.fact.forEach((f) => {
                        f.dateTime = new Date(f.incidentReactionTime);

                        request.attributes.responder = f.participant;
                    });
                }

                if (request.geometry !== null) {
                    const latStr = coorToString(request.geometry.lat, true);
                    const lngStr = coorToString(request.geometry.lng, false);
                    request.geometry.linkGoogle = `https://www.google.com/maps/place/${latStr} ${lngStr}`;
                }

                const key = request.attributes.requestType.trim();

                let _problems = activeProblems.get(key);
                if (_problems === undefined) {
                    _problems = { items: [], inprocess: [], completed: [] };
                }
                _problems.items.push(request);
                if (request.attributes.status === 1) {
                    _problems.inprocess.push(request);
                }
                if (request.attributes.status === 2) {
                    _problems.completed.push(request);
                }
                activeProblems.set(key, _problems);
            });

            module.activeProblems = activeProblems;
        },
        undefined,
        () => {
            module.loading = false;
        }
    );
})();
// #endregion


export default {
    get loading(): boolean {
        return module.loading;
    },

    get wipCount(): number {
        return module.wipCount;
    },

    get activeProblems(): Map<string, IStatRequests> {
        return module.activeProblems;
    }
};