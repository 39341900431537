











































































































import Class from './c-tabs';
export default Class;
