import { EIndicatorPeriodicity } from './types';


export const getPeriodRange = (periodicity: EIndicatorPeriodicity, source: Date): [Date, Date] => {
    const date = new Date(source);
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(0);

    let periodStart = date;
    let periodEnd = date;

    let quarter: number;
    let month: number;

    switch (periodicity) {
        case EIndicatorPeriodicity.DAY:
            periodStart = new Date(date);

            date.setDate(date.getDate() + 1);
            date.setMilliseconds(-1);
            periodEnd = new Date(date);

            break;

        case EIndicatorPeriodicity.MONTH:
            date.setDate(1);
            periodStart = new Date(date);

            date.setMonth(date.getMonth() + 1);
            date.setMilliseconds(-1);
            periodEnd = new Date(date);

            break;

        case EIndicatorPeriodicity.QUARTER:
            date.setDate(1);

            month = date.getMonth();
            quarter = (month - month % 3) / 3;

            date.setMonth(quarter * 3);
            periodStart = new Date(date);

            date.setMonth(date.getMonth() + 3);
            date.setMilliseconds(-1);
            periodEnd = new Date(date);

            break;

        case EIndicatorPeriodicity.QUARTER_WITH_ACCUMULATION:
            periodStart = new Date(date);
            periodStart.setMonth(0);
            periodStart.setDate(1);

            month = date.getMonth();
            quarter = (month - month % 3) / 3;

            date.setDate(1);
            date.setMonth((quarter + 1) * 3);
            date.setMilliseconds(-1);
            periodEnd = new Date(date);

            break;

        case EIndicatorPeriodicity.WEEK:
            date.setDate(date.getDate() - date.getDay() + 1);
            periodStart = new Date(date);

            date.setDate(date.getDate() + 7);
            date.setMilliseconds(-1);
            periodEnd = new Date(date);

            break;

        case EIndicatorPeriodicity.YEAR:
            date.setDate(1);
            date.setMonth(0);
            periodStart = new Date(date);

            date.setFullYear(date.getFullYear() + 1);
            date.setMilliseconds(-1);
            periodEnd = new Date(date);

            break;

        case EIndicatorPeriodicity.ONCE_YEAR:
            date.setDate(1);
            date.setMonth(0);
            periodStart = new Date(date);

            date.setFullYear(date.getFullYear() + 1);
            date.setMilliseconds(-1);
            periodEnd = new Date(date);

            break;

        default:
            // throw new Error(`Unknown periodicity "${periodicity}"`);
    }

    return [periodStart, periodEnd];
};