import { Vue, Component, Prop } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';

@Component({
    name: 'd-sphere-tones-bar',
    components: {
        highcharts: Chart
    }
})
export default class ChartSphereTonesBar extends Vue {
    @Prop({
        required: true,
        default: () => {
            return { value: 1 };
        }
    })
    private period!: any; // выбранный период

    @Prop({
        required: true,
        default: () => {
            return [];
        }
    })
    private datas!: any[]; // массив постов

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly full!: boolean;

    private chartOptions = {};
    private month = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

    public created() {
        this.$watch('datas', () => {
            this.updateOptions();
        });
    }

    public mounted() {
        this.updateOptions();
    }

    private updateOptions() {
        const colors = ['#1f4e79', '#C0504C', '#207CAD', '#56B475'];
        const series = [];
        let categories: any[] = [];

        let total = new Map();
        let negative = new Map();
        let neutral = new Map();
        let positive = new Map();

        let dt = '';
        let date: any = null;

        const valPeriod = this.period.value;
        /* if ((this.period.value === null) && (this.period.selectPeriod)) {
            valPeriod = Math.round((this.selectPeriod[1] - this.selectPeriod[0]) / 86400000);
        } */

        const url: any = this.$router;
        const windowHeight = window.innerHeight;
        let hChart: any = 240;
        let fontSize = '12px';
        let fontSizeYear = '14px';
        let legendFontSize = '14px';
        if (url.history.current.path === '/') {
            hChart = 260;
        }
        if (this.full) {
            hChart = windowHeight - 270;
            fontSize = '20px';
            fontSizeYear = '20px';
            legendFontSize = '20px';
        }

        this.datas.forEach((item) => {
            // if (this.unrelevants.indexOf(item.id) === -1) {
            if (valPeriod === 1) {
                if ((date !== new Date(item.bDate).toLocaleDateString('en-GB'))
                    || (dt === (new Date(item.bDate).toLocaleDateString('en-GB') + ' ' + new Date(item.bDate).getHours() + ':00'))) {
                    dt = new Date(item.bDate).toLocaleDateString('en-GB') + ' ' + new Date(item.bDate).getHours() + ':00';
                } else {
                    dt = new Date(item.bDate).getHours() + ':00';
                }
            }
            if ((valPeriod > 1) && (valPeriod < 31)) {
                dt = new Date(item.bDate).toLocaleDateString('en-GB');
            }
            if ((valPeriod > 30) || (valPeriod === null)) {
                dt = this.month[new Date(item.bDate).getMonth()];
            }
            date = new Date(item.bDate).toLocaleDateString('en-GB');
            dt = new Date(item.bDate).toLocaleDateString('en-GB') + ' ' + new Date(item.bDate).getHours() + ':00';
            categories.push(dt);

            switch (item.tone) {
                case -2:
                    negative = this.mapValue(negative, dt);
                    break;
                case 1:
                    neutral = this.mapValue(neutral, dt);
                    break;
                case 2:
                    positive = this.mapValue(positive, dt);
                    break;
                default:
                    break;
            }
            total = this.mapValue(total, dt);
            // }
        });

        const totalDatas = [];
        const negativeDatas = [];
        const neutralDatas = [];
        const positiveDatas = [];
        for (const c of categories) {
            totalDatas.push(total.get(c) === undefined ? 0 : total.get(c));
            negativeDatas.push(negative.get(c) === undefined ? 0 : negative.get(c));
            neutralDatas.push(neutral.get(c) === undefined ? 0 : neutral.get(c));
            positiveDatas.push(positive.get(c) === undefined ? 0 : positive.get(c));
        }
        series.push({ name: 'Всего', data: totalDatas });
        series.push({ name: 'Негативная', data: negativeDatas });
        series.push({ name: 'Нейтральная', data: neutralDatas });
        series.push({ name: 'Позитивная', data: positiveDatas });

        series.forEach((ser: any) => {
            ser.data = ser.data.splice(ser.data.length - 8, 7);
        });
        // eslint-disable-next-line no-shadow
        categories = categories.filter(function (item, pos, self) {
            return self.indexOf(item) === pos;
        });

        categories = categories.splice(categories.length - 8, 7);

        this.chartOptions = {
            colors,
            chart: {
                type: 'line',
                height: hChart,
                style: {
                    fontFamily: 'Manrope, sans-serif'
                }
            },
            title: {
                text: (this.$route.name === 'Topic' ? 'Хронология публикаций' : ''),
                style: {
                    color: '#5e5e5e',
                    fontSize: fontSizeYear
                }
            },
            xAxis: {
                type: 'category',
                labels: {
                    autoRotation: [0],
                    useHTML: true,
                    style: {
                        color: '#3F4D67',
                        fontSize: fontSize,
                        fontWeight: 400
                    },
                    formatter(): any {
                        const self: any = this;
                        if (self.value.name){
                            const label = self.value.name.split(' ');
                            return '<p class="ton-line-date">' + label[0].split('/20').join('/') + '</p> <p class="ton-line-day">' + label[1] + '</p>';
                        }
                    }
                },
                categories: Array.from(categories)
            },
            yAxis: {
                visible: true,
                gridLineWidth: 1,
                gridLineColor: '#EEF1F8',
                labels: {
                    style: {
                        fontSize: 0
                    }
                },
                title: {
                    style: {
                        fontSize: 0
                    }
                }
            },
            legend: {
                align: 'left',
                verticalAlign: 'top',
                itemStyle: {
                    fontWeight: '500',
                    fontSize: legendFontSize,
                    textOverflow: 'null'
                }
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    showInLegend: true
                },
                series: {
                    dataLabels: {
                        formatter(): any {
                            const self: any = this;
                            return '<span style="color: ' + self.point.color + '">' + self.y + '</span>';
                        },
                        style: {
                            fontSize: fontSize,
                            fontWeight: '500',
                            textOutline: '0px'
                        }
                    }
                }
            },
            series: series,
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            }
        };
    }

    private mapValue(map: Map<any, any>, date: string) {
        if (map.size === 0) {
            // map = new Map();
            map.set(date, 1);
        } else {
            if (map.get(date) === undefined) {
                map.set(date, 1);
            } else {
                let val = map.get(date);
                val++;
                map.set(date, val);
            }
        }
        return map;
    }
}