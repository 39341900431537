import { Vue, Component, Prop } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import Treemap from 'highcharts/modules/treemap';
import { ILastResult } from '../types';

HighchartsMore(Highcharts);
Treemap(Highcharts);

Highcharts.setOptions({
    lang: {
        loading: 'Загрузка...',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
        thousandsSep: ' ',
        decimalPoint: ','
    }
});


interface ITreeMap {
    name: string;
    value: number;
    color: string;
}

@Component
export default class ChartTreeMapView extends Vue {
    private options: Highcharts.Options = {};

    @Prop({
        type: Array,
        required: true
    })
    private data!: ILastResult[];

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    @Prop()
    public readonly redrawTrigger!: any;

    private redrawTriggerChanged() {
        if (this.chart !== undefined) {
            this.chart.reflow();
        }
    }

    private formatDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric'
        });

        return format.format(date);
    }

    private setOptions() {
        const colors = ['#416084', '#257bb0', '#953735', '#e46c0a', '#5a80ad', '#449fd8', '#c0504d', '#f79646', '#2c4058', '#195275', '#632523', '#984807'];
        const result: ITreeMap[] = [];


        this.data.forEach((datas, i) => {
            const items = datas.data;
            if (items === null) {
                return null;
            }
            const unit = items.unit;
            let title = items.name + ', ' + unit;
            if (unit === null) {
                title = items.name;
            }
            const item = items.item;
            const fact: ITreeMap = {
                name: title,
                value: item.value,
                color: colors[i]
            };
            result.push(fact);
            return result;
        });

        const serie = {
            type: 'treemap',
            layoutAlgorithm: 'stripes',
            alternateStartingDirection: true,
            levels: [{
                level: 1,
                layoutAlgorithm: 'sliceAndDice',
                dataLabels: {
                    enabled: true,
                    // format: '<span style="color:{point.color}">{point.value}</span>',
                    formatter() {
                        const anyThis = this as any;
                        return anyThis.point.value;
                    }
                }
            }],
            data: result,
            legendType: 'point',
            showInLegend: true
        } as unknown as Highcharts.SeriesOptionsType;

        this.options = {
            colors,
            chart: {
                type: 'treemap',
                backgroundColor: 'transparent',
                spacing: [0, 0, 0, 0],
                height: 220
            },
            title: {
                text: ' '
            },
            exporting: {
                enabled: false,
                menuItemDefinitions: {
                    // Custom definition
                    printChart: {
                        text: 'Печать'
                    },
                    downloadPNG: {
                        text: 'Скачать PNG'
                    },
                    downloadJPEG: {
                        text: 'Скачать JPEG'
                    },
                    downloadPDF: {
                        text: 'Скачать PDF'
                    },
                    downloadSVG: {
                        text: 'Скачать SVG'
                    }
                }
            },
            plotOptions: {
                treemap: {
                    borderWidth: 0
                },
                series: {
                    dataLabels: {
                        color: 'white',
                        style: {
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            textOutline: '0px'
                        }
                    },
                    animation: false
                }
            },
            legend: {
                x: 20,
                align: 'left',
                verticalAlign: 'top',
                itemStyle: {
                    fontWeight: '100',
                    fontSize: '12px',
                    textOverflow: 'null'
                }
            },
            credits: {
                enabled: false
            },
            series: [serie]
        };

        // treemap
    }

    private chart: Highcharts.Chart | undefined;

    private dataChanged() {
        this.setOptions();
        if (this.chart === undefined) {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
        } else {
            this.chart.update(this.options, true, true);
        }
    }

    private downloadAction() {
        if (this.chart !== undefined) {
            if (this.download === 'print') {
                this.chart.print();
            }
            if (this.download === 'pdf') {
                this.chart.exportChart({
                    type: 'application/pdf'
                }, {});
            }
            if (this.download === 'jpeg') {
                this.chart.exportChart({
                    type: 'image/jpeg'
                }, {});
            }
            if (this.download === 'png') {
                this.chart.exportChart({
                    type: 'image/png'
                }, {});
            }
            if (this.download === 'svg') {
                this.chart.exportChart({
                    type: 'image/svg+xml'
                }, {});
            }
        }
    }

    private created() {
        this.$watch('data', this.dataChanged);
        this.$watch('redrawTrigger', this.redrawTriggerChanged);
    }

    private mounted() {
        this.dataChanged();
        this.$watch('download', this.downloadAction);
    }

    private beforeDestroy() {
        if (this.chart !== undefined) {
            this.chart.destroy();
        }
    }
}