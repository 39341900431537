import { Component, Prop, Vue } from 'vue-property-decorator';

import '../../initialization';
import { accessor } from '../../store';
import { ISelectedIndicatorItems, IWidgetChartBars } from '../../store/types';

import queue from '../queue';

import CChartBarsView from './c-chart-bars-view';
import { IItemGraph, IChartBarItem } from './types';
import { assignGrow } from '../common';

@Component({
    components: {
        'c-chart-bars-view': CChartBarsView
    }
})
export default class CContentChartBars extends Vue {
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: IWidgetChartBars;

    @Prop()
    public readonly redrawTrigger!: any;



    private created() {
        this.$watch('getResult', this.selectedItemsChanged);
        this.$watch('year', this.showNav);
    }

    private mounted() {
        this.selectedItemsChanged();
    }

    private beforeDestroy() {
        queue.clear(this);
    }

    private showNav() {
        return (this.year > -(this.indic !== null ? this.indic : 0) + 1 && (this.indic !== null ? this.indic : 0) > 7);
    }

    public get indicatorConfigs() {
        return this.data.indicatorConfigs;
    }

    public get selectedItems(): ISelectedIndicatorItems | null {
        if (this.indicatorConfigs.length === 0) {
            return null;
        }

        const result = accessor.selectedMap.get(this.indicatorConfigs[0].indicator);
        if (result === undefined) {
            return null;
        }

        return result;
    }

    private selectedItemsChanged() {
        queue.schedule(this, () => {
            this.result = this.getResult;
        });
    }


    private result: IItemGraph | null = null;

    private get indic() {
        const key = this.indicatorConfigs[0].indicator;
        const indicator = accessor.getIndicator(key);
        if (indicator === undefined) {
            return null;
        }
        return indicator.items.length;
    }

    private getItems(indicator: any) {
        indicator.items.sort((a: any, b: any) => a.date - b.date);
        let items = indicator.items.slice(-7 + this.year, indicator.items.length + this.year);

        if (indicator.periodicity === 'квартал' || indicator.periodicity === 'квартал с накоплением') {
            items = indicator.items.slice(-8 + this.year, indicator.items.length + this.year);
        }

        if (indicator.periodicity === 'месяц' || indicator.periodicity === 'месяц с накоплением') {
            items = indicator.items.slice(-12 + this.year, indicator.items.length + this.year);
        }
        return items;
    }

    private previousDate() {
        this.year -= 1;
        this.selectedItemsChanged();
    }
    private nextDate() {
        this.year += 1;
        this.selectedItemsChanged();
    }

    private year = 0;

    private getIndicator(key: string) {
        const list = accessor.indicators;
        const indicator = list.find(x => x.newKey === key);
        if (indicator === undefined) {
            return undefined;
        }
        return indicator;
    }


    public get getResult(): IItemGraph | null {
        if (this.indicatorConfigs.length === 0) {
            return null;
        }
        const key = this.indicatorConfigs[0].indicator;
        let indicator = accessor.getIndicator(key);
        if (indicator === undefined) {
            indicator = this.getIndicator(key);
        }

        if (indicator === undefined) {
            return null;
        }
        const datas = this.getItems(indicator);
        const items: IChartBarItem[] = [];
        const type = this.indicatorConfigs[0].type;
        const plan = this.indicatorConfigs[0].plan;
        const growDatas = assignGrow(indicator.key);
        const confUnit: any = this.indicatorConfigs[0].unit;
        let unit: any;
        if ((Object.keys(confUnit).length === 0)) {
            unit = indicator.unit;
        } else if ((confUnit.name_ru === '')) {
            unit = indicator.unit;
        } else {
            if (confUnit.nationalSymbol === undefined || confUnit.nationalSymbol === null) {
                unit = confUnit.name_ru;
            } else {
                unit = confUnit.nationalSymbol;
            }
        }
        let unitLowerCased: string | null = null;
        if (unit !== null || unit !== undefined) {
            unitLowerCased = unit.toLowerCase();
            if (unitLowerCased === 'процент') {
                unitLowerCased = '%';
            }
        }
        if (growDatas === undefined) {
            return null;
        }
        growDatas.unit = unit.toLowerCase();
        let measurement = 1;
        if (this.indicatorConfigs[0].measurement !== undefined) {
            measurement = this.indicatorConfigs[0].measurement;
        }
        growDatas.value = growDatas.value * measurement;
        datas.forEach((selectedItem: any) => {
            const item: IChartBarItem = {
                date: selectedItem.date,
                value: selectedItem.fact * measurement,
                plan: selectedItem.plan * measurement
            };
            items.push(item);
        });
        return {
            type,
            plan,
            growDatas: (growDatas !== undefined ? growDatas : null),
            items
        };
    }
}