









import Class from './c-content-chart-tone-line';
export default Class;
