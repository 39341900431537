// настройки локализации проекта

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import fallback from './fallback';

Vue.use(VueI18n);

/**
 * Компонент локализации, используемый в приложении
 */
const vue = new VueI18n({
    // текущая локаль
    locale: 'en',

    // локаль по умолчанию
    // для поиска переводов, которые не найдены для текущей локали
    fallbackLocale: '_',

    // использовать ли локаль по умолчанию для поиска ненайденных переводов
    fallbackRoot: true,

    // не показывать в консоли браузера предупреждения об использования перевода для локали по умолчанию
    // значение меняется после загрузки переводов после старта приложения
    silentFallbackWarn: false,

    // не показывать в консоли браузера предупреждения о ненайденном переводе
    // значение меняется после загрузки переводов после старта приложения
    silentTranslationWarn: false,

    // форматы для чисел
    numberFormats: {
        '_': fallback.numberFormats,
        'en': fallback.numberFormats
    },

    // форматы для даты и времени
    dateTimeFormats: {
        '_': fallback.dateTimeFormats,
        'en': fallback.dateTimeFormats
    },

    // переводы
    messages: {
        '_': fallback.messages,
        'en': fallback.messages
    }
});
export default vue;