import { Vue, Component, Prop } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { IItemGraph } from '../types';

HighchartsMore(Highcharts);

Highcharts.setOptions({
    lang: {
        loading: 'Загрузка...',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
        thousandsSep: ' ',
        decimalPoint: ','
    }
});

@Component
export default class ChartBarView extends Vue {
    private options: Highcharts.Options = {};

    @Prop({
        type: Object,
        required: true
    })
    private data!: IItemGraph;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    @Prop()
    public readonly redrawTrigger!: any;

    private redrawTriggerChanged() {
        if (this.chart !== undefined) {
            this.chart.reflow();
        }
    }

    private formatDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric'
        });

        return format.format(date);
    }

    private quarterDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric',
            month: 'long'
        });

        return format.format(date);
    }

    private setOptions() {
        const colors = ['#459ED8', '#953735', '#e46c0a', '#416084', '#5a80ad', '#449fd8',
            '#c0504d', '#f79646', '#2c4058', '#195275', '#632523', '#984807'];
        const test: Highcharts.SeriesOptionsType[] = [];
        const planEnabled = this.data.plan;
        const type = 'column';
        let xAxis = {};
        const nameFact = 'Факт';
        const namePlan = 'План';
        let types = false;
        if (this.data.type === 'bar') {
            types = true;
        }
        if (this.data.growDatas !== null) {
            if (this.data.growDatas.periodicity === 'год') {
                for (let i = 0; i < 2; i++) {
                    if (i === 0) {
                        const data: any = [];
                        this.data.items.forEach((item) => {
                            if (item.value !== 0) {
                                const itemDate = item.date;
                                const itemDateText = this.formatDate(itemDate);
                                const count = Number(itemDateText);
                                data.push([count, item.value]);
                            }
                        });
                        test.push({ data, type, name: nameFact });
                    }
                    if ((i === 1) && (planEnabled)) {
                        const data: any = [];
                        this.data.items.forEach((item) => {
                            if (item.plan !== 0) {
                                const itemDate = item.date;
                                const itemDateText = this.formatDate(itemDate);
                                const count = Number(itemDateText);
                                data.push([count, item.plan]);
                            }
                        });
                        test.push({ data, type, name: namePlan });
                    }
                }
                xAxis = {
                    type: 'category',
                    gridLineColor: '#E3EEF6',
                    gridLineDashStyle: 'dash',
                    gridLineWidth: 1,
                    tickmarkPlacement: 'between',
                    tickWidth: 0,
                    tickInterval: 1,
                    labels: {
                        style: {
                            color: '#223345',
                            fontSize: '0.9rem'
                        }
                    }
                };
            }
            if (this.data.growDatas.periodicity === 'квартал') {
                const categories: any[] = [];
                for (let i = 0; i < 2; i++) {
                    if (i === 0) {
                        const data: any = [];
                        this.data.items.forEach((item, index) => {
                            if (item.value !== 0) {
                                const itemDate = item.date;
                                const year = itemDate.getFullYear();
                                let month = itemDate.getMonth() + 1;
                                if (month <= 3) {
                                    month = 'I кв';
                                } else if ((month > 3) && (month <= 6)) {
                                    month = 'II кв';
                                } else if ((month > 6) && (month <= 9)) {
                                    month = 'III кв';
                                } else if ((month > 9)) {
                                    month = 'IV кв';
                                }
                                if (index < 1) {
                                    const categor = {
                                        name: year,
                                        categories: [month]
                                    };
                                    categories.push(categor);
                                } else {
                                    const ind = categories.length - 1;
                                    if (categories[ind].name === year) {
                                        categories[ind].categories.push(month);
                                    } else {
                                        const categor = {
                                            name: year,
                                            categories: [month]
                                        };
                                        categories.push(categor);
                                    }
                                }
                                data.push(item.value);
                            }
                        });
                        xAxis = {
                            type: 'category',
                            gridLineColor: '#E3EEF6',
                            gridLineDashStyle: 'dash',
                            gridLineWidth: 1,
                            tickmarkPlacement: 'between',
                            tickWidth: 0,
                            labels: {
                                style: {
                                    color: '#223345',
                                    fontSize: '0.9rem'
                                }
                            },
                            categories: categories
                        };
                        test.push({ data, type, name: nameFact });
                    }
                    if ((i === 1) && (planEnabled)) {
                        const data: any = [];
                        this.data.items.forEach((item) => {
                            if (item.plan !== 0) {
                                data.push(item.plan);
                            }
                        });
                    }
                }
            }
            if (this.data.growDatas.periodicity === 'месяц' || this.data.growDatas.periodicity === 'месяц с накоплением') {
                const categories: any = [];
                for (let i = 0; i < 2; i++) {
                    if (i === 0) {
                        const data: any = [];
                        this.data.items.forEach((item, index) => {
                            if (item.value !== 0) {
                                const itemDate = item.date;
                                const year = itemDate.getFullYear();
                                let month = itemDate.getMonth() + 1;
                                if (month < 10) {
                                    month = '0' + month;
                                }
                                if (index < 1) {
                                    const categor = {
                                        name: year,
                                        categories: [month]
                                    };
                                    categories.push(categor);
                                } else {
                                    const ind = categories.length - 1;
                                    if (categories[ind].name === year) {
                                        categories[ind].categories.push(month);
                                    } else {
                                        const categor = {
                                            name: year,
                                            categories: [month]
                                        };
                                        categories.push(categor);
                                    }
                                }
                                data.push(item.value);
                            }
                        });
                        xAxis = {
                            type: 'category',
                            gridLineColor: '#E3EEF6',
                            gridLineDashStyle: 'dash',
                            gridLineWidth: 1,
                            tickmarkPlacement: 'between',
                            tickWidth: 0,
                            labels: {
                                style: {
                                    color: '#223345',
                                    fontSize: '0.9rem'
                                }
                            },
                            categories: categories
                        };
                        test.push({ data, type, name: nameFact });
                    }
                    if ((i === 1) && (planEnabled)) {
                        const data: any = [];
                        this.data.items.forEach((item) => {
                            if (item.plan !== 0) {
                                data.push(item.plan);
                            }
                        });
                        test.push({ data, type, name: nameFact });
                    }
                }
            }
        }
        this.options = {
            colors,
            chart: {
                inverted: types,
                backgroundColor: 'transparent',
                spacing: [0, 0, 0, 0],
                height: 223
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false,
                menuItemDefinitions: {
                    // Custom definition
                    printChart: {
                        text: 'Печать'
                    },
                    downloadPNG: {
                        text: 'Скачать PNG'
                    },
                    downloadJPEG: {
                        text: 'Скачать JPEG'
                    },
                    downloadPDF: {
                        text: 'Скачать PDF'
                    },
                    downloadSVG: {
                        text: 'Скачать SVG'
                    }
                }
            },
            xAxis: xAxis,
            yAxis: {
                visible: false,
                lineWidth: 1,
                labels: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                maxPadding: 0.08
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        crop: false,
                        overflow: 'none',
                        padding: 0
                    },
                    enableMouseTracking: true,
                    showInLegend: true
                },
                series: {
                    dataLabels: {
                        formatter() {
                            const anyThis = this as any;
                            if (anyThis.point.colorIndex === 0) {
                                anyThis.point.shapeArgs.y = anyThis.point.shapeArgs.y - 6;
                                anyThis.point.shapeArgs.x = anyThis.point.shapeArgs.x - 2;
                            }
                            if (anyThis.point.colorIndex === 1) {
                                anyThis.point.shapeArgs.y = anyThis.point.shapeArgs.y + 5;
                                anyThis.point.shapeArgs.x = anyThis.point.shapeArgs.x - 2;
                            }
                            if (this.y !== null) {
                                return '<span style="color: #223345">' + Highcharts.numberFormat(this.y, 0) + '</span>';
                            }
                        },
                        style: {
                            color: '#223345',
                            fontSize: '1rem',
                            fontWeight: '100',
                            textOutline: '0px'
                        }
                    },
                    animation: false
                }
            },
            tooltip: {
                shared: true
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            series: test
        };
        return this.options;
    }

    private chart: Highcharts.Chart | undefined;

    private dataChanged() {
        this.setOptions();
        if (this.chart === undefined) {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
            this.$el.attributes[2].value = 'overflow: visible';
        } else {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
            this.$el.attributes[2].value = 'overflow: visible';
        }
    }

    private downloadAction() {
        if (this.chart !== undefined) {
            if (this.download === 'print') {
                this.chart.print();
            }
            if (this.download === 'pdf') {
                this.chart.exportChart({
                    type: 'application/pdf'
                }, {});
            }
            if (this.download === 'jpeg') {
                this.chart.exportChart({
                    type: 'image/jpeg'
                }, {});
            }
            if (this.download === 'png') {
                this.chart.exportChart({
                    type: 'image/png'
                }, {});
            }
            if (this.download === 'svg') {
                this.chart.exportChart({
                    type: 'image/svg+xml'
                }, {});
            }
        }
    }

    private created() {
        this.$watch('data', this.dataChanged);
        this.$watch('redrawTrigger', this.redrawTriggerChanged);
    }

    private mounted() {
        this.dataChanged();
        this.$watch('download', this.downloadAction);
    }

    private beforeDestroy() {
        if (this.chart !== undefined) {
            this.chart.destroy();
        }
    }
}