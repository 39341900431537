




















import Class from './c-month-list';
export default Class;
