



























import Class from './c-loading';
export default Class;
