





































import Class from './c-content-chart-multiple-axes';
export default Class;
