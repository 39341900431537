import { Component, Vue } from 'vue-property-decorator';
import store from '@/services/store';
import theme from '@/services/theme';


@Component
export default class CCard extends Vue {
    private mounted() {
        this.scanSlots();
    }

    private updated() {
        this.scanSlots();
    }

    // переключение темы
    private get themeDark(): boolean {
        return theme.dark;
    }

    public get isAuthorized(): boolean {
        return store.state.user.authorized;
    }

    private hasHeader = false;
    private hasFooter = false;

    private scanSlots() {
        const hasHeader = (this.$slots.header !== undefined) && this.$slots.header.isNotEmpty;
        if (this.hasHeader !== hasHeader) {
            this.hasHeader = hasHeader;
        }

        const hasFooter = (this.$slots.footer !== undefined) && this.$slots.footer.isNotEmpty;
        if (this.hasFooter !== hasFooter) {
            this.hasFooter = hasFooter;
        }
    }
}