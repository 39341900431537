
















import Class from './c-content-chart-stack-column';
export default Class;
