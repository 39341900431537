import { Component, Prop, Vue } from 'vue-property-decorator';

import '../../initialization';
import { accessor } from '../../store';
import { ISelectedIndicatorItems, IWidgetChartTreeMap } from '../../store/types';

import queue from '../queue';

import CChartTreeMapView from './c-chart-tree-map-view';
import { IItemGraph, IChartItem, ILastResult, IItem, IItemTitle } from './types';

@Component({
    components: {
        'c-chart-tree-map-view': CChartTreeMapView
    }
})
export default class CContentChartTreeMap extends Vue {
    private created() {
        this.$watch('getResult', this.selectedMapChanged);
    }

    private mounted() {
        this.selectedMapChanged();
    }

    private beforeDestroy() {
        queue.clear(this);
    }


    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: IWidgetChartTreeMap;

    @Prop()
    public readonly redrawTrigger!: any;


    public get indicatorConfigs() {
        return this.data.indicatorConfigs;
    }


    public get selectedMap(): Map<string, ISelectedIndicatorItems> {
        const result = new Map<string, ISelectedIndicatorItems>();

        this.indicatorConfigs.forEach((key) => {
            const selectedIndicator = accessor.selectedMap.get(key.indicator);
            if (selectedIndicator !== undefined) {
                result.set(key.indicator, selectedIndicator);
            }
        });

        return result;
    }

    private selectedMapChanged() {
        setTimeout(() => {
            queue.schedule(this, () => {
                this.sumFact = this.getSumFact();
                this.titleData = this.getTitleData();
                this.result = this.getResult;
            });
        }, 1);
    }


    private sumFact: number | null = null;
    private titleData: IItemTitle | null = null;
    private result: ILastResult[] | null = null;


    public getSumFact(): number | null {
        let check: number | null;
        check = null;
        this.indicatorConfigs.forEach((key) => {
            const selectedIndicator = this.selectedMap.get(key.indicator);
            if (selectedIndicator !== undefined) {
                const indicatorItem = selectedIndicator.item;
                if (indicatorItem !== undefined) {
                    if (check !== null) {
                        check = check + indicatorItem.fact;
                    } else {
                        check = indicatorItem.fact;
                    }
                }
            }
        });
        return check;
    }

    public getTitleData(): IItemTitle | null {
        let growIndicProc: number | null = null;
        let growIndic: number | null = null;

        let previousFact = 0;

        // актуальная сумма по всем показателям
        let sumFact: number | null = null;
        //  наименование единиц измерения
        let unitLowerCased: string | null = null;

        this.indicatorConfigs.forEach((key) => {
            const selectedIndicator = this.selectedMap.get(key.indicator);
            if (selectedIndicator !== undefined) {
                const indicatorItem = selectedIndicator.item;

                const previous = selectedIndicator.previousItem;
                const typeIndic = selectedIndicator.character;
                if (indicatorItem !== undefined) {
                    let measurement = 1;
                    if (key.measurement !== undefined) {
                        measurement = key.measurement;
                    }
                    if (sumFact !== null) {
                        sumFact = sumFact + indicatorItem.fact * measurement;
                    } else {
                        sumFact = indicatorItem.fact * measurement;
                        const confUnit: any = key.unit;
                        let unit: any;
                        if ((Object.keys(confUnit).length === 0)) {
                            unit = key.unit;
                        } else if ((confUnit.name_ru === '')) {
                            unit = key.unit;
                        } else {
                            if (confUnit.nationalSymbol === undefined || confUnit.nationalSymbol === null) {
                                unit = confUnit.name_ru;
                            } else {
                                unit = confUnit.nationalSymbol;
                            }
                        }
                        if (unit !== null || unit !== undefined) {
                            unitLowerCased = unit.toLowerCase();
                            if (unitLowerCased === 'процент') {
                                unitLowerCased = '%';
                            }
                        }
                    }

                    if (previous !== undefined) {
                        // вычисляем предыдущее актуальное значение
                        if (previousFact !== null) {
                            previousFact = previousFact + previous.fact * measurement;
                        } else {
                            previousFact = previous.fact * measurement;
                        }
                        // характер показателя
                        if (typeIndic === 0) {
                            growIndic = typeIndic;
                        }

                        growIndicProc = Math.round(((sumFact / previousFact) * 100 - 100) * 100) / 100;
                        if (unitLowerCased === '%') {
                            growIndicProc = Math.round((sumFact - previousFact) * 100) / 100;
                        }
                        if (typeIndic === 0) {
                            growIndic = typeIndic;
                        } else {
                            if (growIndicProc === 0) {
                                growIndic = typeIndic;
                            } else {
                                growIndic = typeIndic * growIndicProc;
                            }
                        }
                    }
                }
            }
        });
        return {
            sumFact,
            unit: unitLowerCased,
            growIndic,
            growIndicProc
        };
    }

    public get getResult(): ILastResult[] | null {
        const lastresult: ILastResult[] = [];
        if (this.indicatorConfigs.length === 0) {
            return null;
        }
        this.indicatorConfigs.forEach((indicatorKey) => {
            const key = indicatorKey;
            const indicator = accessor.getIndicator(key.indicator);
            if (indicator === undefined) {
                return null;
            }
            let data: null | IItemGraph = null;
            const items: IChartItem[] = [];
            const selectedItems = this.selectedMap.get(indicatorKey.indicator);
            if (selectedItems !== undefined) {
                const confUnit: any = indicatorKey.unit;
                let unit: any;
                if ((Object.keys(confUnit).length === 0)) {
                    unit = selectedItems.unit;
                } else if ((confUnit.name_ru === '')) {
                    unit = selectedItems.unit;
                } else {
                    unit = confUnit.name_ru;
                }
                let unitLowerCased: string | null = null;
                if (unit !== null || unit !== undefined) {
                    unitLowerCased = unit.toLowerCase();
                    if (unitLowerCased === 'процент') {
                        unitLowerCased = '%';
                    }
                }
                selectedItems.items.forEach((selectedItem) => {
                    const item: IChartItem = {
                        date: selectedItem.date,
                        value: selectedItem.fact
                    };
                    items.push(item);
                });
                const current = selectedItems.item;
                if (current !== undefined) {
                    let measurement = 1;
                    if (indicatorKey.measurement !== undefined) {
                        measurement = indicatorKey.measurement;
                    }
                    const itemes: IItem = {
                        date: current.date,
                        value: current.fact * measurement,
                        shortName: selectedItems.shortIndicator
                    };
                    const item = itemes;
                    data = {
                        name: indicatorKey.title,
                        items,
                        unit: unitLowerCased,
                        item
                    };
                }
            }
            lastresult.push({ title: indicatorKey.title, data, indicatorKey: indicatorKey.indicator });
            return lastresult;
        });
        return lastresult;
    }
}