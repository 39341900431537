

















































































import Class from './c-card';
export default Class;
