import { Vue, Component, Prop } from 'vue-property-decorator';

import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import Highcharts3D from 'highcharts/highcharts-3d';

import { IItem, ITotalAmountText } from '../types';

HighchartsMore(Highcharts);
Highcharts3D(Highcharts);

Highcharts.setOptions({
    lang: {
        loading: 'Загрузка...',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
        thousandsSep: ' ',
        decimalPoint: ','
    }
});


@Component
export default class CView extends Vue {
    private lineOptions: Highcharts.Options = {};

    @Prop({
        type: Array,
        required: true
    })
    private data!: IItem[];

    @Prop({
        type: Object,
        required: true
    })
    private totalAmount!: ITotalAmountText;
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly full!: boolean;

    @Prop()
    public readonly redrawTrigger!: any;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    private numberWithCommas = (num: number | null, dimension = ''): string => {
        if (num !== null) {
            const os = Math.floor((num * 10) % 10);
            num = Math.floor(num) + os / 10;
            // num = Math.round(num*100) / 10;
            const parts = num.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

            if (parts.join('.') === '0') {
                return parts.join('.') + dimension + '  \t';
            }
            return parts.join('.') + dimension + '\t';
        }
        return '';
    };

    private testFunc(currentPrice: number, sumFact: number | null) {
        if (sumFact !== null) {
            return this.numberWithCommas(((currentPrice / sumFact) * 100), '%');
        }
    }

    private currectNameRu = (nameRu: string | null): string => {
        if (nameRu !== null) {
            if (nameRu.length >= 10) {
                return nameRu.substring(0, 10) + '\t';
            }
            return nameRu + '\t\t';
        }
        return '\t\t\t';
    };

    private redrawTriggerChanged() {
        if (this.chart !== undefined) {
            this.chart.reflow();
        }
    }

    private formatDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: '2-digit',
            month: 'short',
            day: 'numeric'
        });
        return format.format(date);
    }

    private updateOptions() {
        const colors = ['#459ED8', '#953735', '#e46c0a', '#416084', '#449fd8', '#c0504d', '#f79646', '#5a80ad', '#195275', '#632523', '#984807', '#2c4058'];
        const data: Highcharts.SeriesVariablepieDataOptions[] = [];
        const windowHeight = window.innerHeight;
        let hChart: any = 230;
        let leftCircle = 55;
        let titlePieFontSize = '25px';
        let titlePieFontSizeUnit = '14px';
        let testUnit = 'млрд. тг.';
        const summ: any = this.totalAmount.sumFact;
        let summFact = Math.round(summ / 1000000);
        if (this.full) {
            hChart = windowHeight - 295;
            leftCircle = 200;
            titlePieFontSize = '35px';
            titlePieFontSizeUnit = '24px';
            testUnit = 'тыс. тг.';
            summFact = summ;
        }
        this.data.forEach((item) => {
            const plan = item.plan;
            let title: string | null = null; // 20
            title = this.currectNameRu(item.nameRu) + ` <span style="border-left: solid 1px;"> ${this.testFunc(item.plan, item.value)}</span><span style="border-left: solid 1px;"> ${this.numberWithCommas(plan)}</span>`;
            const tooltipTitle: string = item.nameRu + ' | ' + this.testFunc(item.plan, item.value) + ' | ' + this.numberWithCommas(plan);
            if (plan === null && item.value !== null) {
                title = item.value.toString();
            }
            data.push({
                name: title,
                description: tooltipTitle,
                y: plan / 1000
            });
            return data;
        });

        this.lineOptions = {
            colors,
            chart: {
                type: 'variablepie',
                backgroundColor: 'transparent',
                spacing: [0, 0, 0, 0],
                margin: [0, 0, 0, 0],
                height: hChart,
                style: {
                    fontFamily: 'Manrope, sans-serif'
                }
            },
            title: {
                style: {
                    fontSize: titlePieFontSize,
                    color: '#3F4D67'
                },
                align: 'left',
                useHTML: true,
                y: 20,
                verticalAlign: 'middle',
                floating: true,
                text: `<span ${testUnit === 'млрд. тг.' ? 'style="margin-left: 55px; font-weight: 700' : 'style="margin-left: 120px; font-weight: 700'}">${this.numberWithCommas(summFact)}</span>`
            },
            subtitle: {
                style: {
                    fontSize: titlePieFontSizeUnit,
                    color: '#3F4D67'
                },
                align: 'left',
                useHTML: true,
                y: 28,
                verticalAlign: 'middle',
                floating: true,
                text: `<span ${testUnit === 'млрд. тг.' ? 'style="margin-left: 48px; font-weight: 500' : 'style="margin-left: 180px; top: 10px; font-weight: 500'}">${testUnit}</span>`
            },
            exporting: {
                enabled: false,
                menuItemDefinitions: {
                    // Custom definition
                    printChart: {
                        text: 'Печать'
                    },
                    downloadPNG: {
                        text: 'Скачать PNG'
                    },
                    downloadJPEG: {
                        text: 'Скачать JPEG'
                    },
                    downloadPDF: {
                        text: 'Скачать PDF'
                    },
                    downloadSVG: {
                        text: 'Скачать SVG'
                    }
                }
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.description}</b><br/>'
                    + '<b>{point.y:,.2f}</b><br/>'
            },
            plotOptions: {
                variablepie: {
                    center: [leftCircle, '50%'],
                    allowPointSelect: true,
                    borderWidth: 0,
                    cursor: 'pointer',
                    dataLabels: {
                        alignTo: 'connectors',
                        connectorShape: 'fixedOffset',
                        crookDistance: '100%',
                        crop: false,
                        enabled: false,
                        format: '<span style="color:{point.color}">{point.percentage:.1f}%</span>',
                        padding: 0,
                        style: {
                            fontSize: '1.2rem',
                            fontWeight: '300',
                            color: 'contrast',
                            textOutline: '0 contrast'
                        },
                        overflow: 'allow'
                    },
                    showInLegend: false
                },
                series: {
                    animation: false
                }
            },
            legend: {
                enabled: false,
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'top',
                x: 165,
                y: 35,
                labelFormat: '<span>{name}</span>',
                floating: true,
                itemStyle: {
                    fontWeight: '100',
                    fontSize: '13px',
                    textOverflow: 'null',
                    whiteSpace: 'pre'
                },
                useHTML: true,
                navigation: {
                    enabled: false
                },
                symbolRadius: 100
            },
            series: [{
                type: 'variablepie',
                name: '',
                size: '60%',
                minPointSize: 10,
                innerSize: '75%',
                data
            }],
            credits: {
                enabled: false
            }
        };
    }

    private chart: Highcharts.Chart | undefined;

    private dataChanged() {
        this.updateOptions();
        if (this.chart === undefined) {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.lineOptions);
        } else {
            this.chart.update(this.lineOptions);
        }
    }

    private downloadAction() {
        if (this.chart !== undefined) {
            if (this.download === 'print') {
                this.chart.print();
            }
            if (this.download === 'pdf') {
                this.chart.exportChart({
                    type: 'application/pdf'
                }, {});
            }
            if (this.download === 'jpeg') {
                this.chart.exportChart({
                    type: 'image/jpeg'
                }, {});
            }
            if (this.download === 'png') {
                this.chart.exportChart({
                    type: 'image/png'
                }, {});
            }
            if (this.download === 'svg') {
                this.chart.exportChart({
                    type: 'image/svg+xml'
                }, {});
            }
        }
    }

    private created() {
        this.$watch('data', this.dataChanged);
        this.$watch('redrawTrigger', this.redrawTriggerChanged);
    }

    private mounted() {
        this.dataChanged();
        this.$watch('download', this.downloadAction);
    }

    private beforeDestroy() {
        if (this.chart !== undefined) {
            this.chart.destroy();
        }
    }
}