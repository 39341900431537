import { Component, Prop, Vue } from 'vue-property-decorator';


type TAppearanceType = 'width' | 'height' | 'size' | 'opacity';
const appearanceTypes: TAppearanceType[] = [ 'width', 'height', 'size', 'opacity' ];


@Component
export default class CLoading extends Vue {
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly overlay!: boolean;

    // #region Appearance
    @Prop({
        type: String,
        required: false,
        default: null,
        validator(value: any): boolean {
            return (value === null) || appearanceTypes.includes(value);
        }
    })
    public readonly appearance!: TAppearanceType | null;

    public get actualAppearance(): TAppearanceType | null {
        if (this.appearance === null) {
            return null;
        }

        if (typeof this.appearance !== 'string') {
            console.warn(`Unexpected type of appearance - ${typeof this.appearance}`);
            return null;
        }

        switch (this.appearance) {
            case 'width':
            case 'height':
            case 'size':
            case 'opacity':
                return this.appearance;
            default:
                console.warn(`Unknown appearance type "${this.appearance}"`);
                return null;
        }
    }
    // #endregion

    public get cssClass(): Record<string, boolean> {
        const result: Record<string, boolean> = {};

        if (this.actualAppearance !== null) {
            result[`appearance-${this.actualAppearance}`] = true;
        }

        return result;
    }
}