





































import Class from './c-content-chart-radius-pie-for-budget';

export default Class;
