













import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Компонент для отображения текста
 *
 * Отображает внутри себя значение свойства `data`, преобразованное в строку
 */
@Component({
    name: 'c-text'
})
export default class CText extends Vue {
    @Prop({
        required: false,
        default: ''
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public data!: any;
}
