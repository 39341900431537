









































import Class from './c-date-picker';
export default Class;
