


















































































import Class from './v-tabs';
export default Class;
