import addToMap, { TRenderFunc } from '../add-to-map';


export default (map: Map<string, TRenderFunc>): void => {
    // #region check
    addToMap(map, 'check', 'M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z');
    // #endregion

    // #region check-circle-outline
    // eslint-disable-next-line max-len
    addToMap(map, 'check-circle-outline', 'M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z');
    // #endregion
};