import { Component, Prop, Vue } from 'vue-property-decorator';

import '@/modules/widget-yard/initialization';
import { accessor } from '@/modules/widget-yard/store';
import { ISelectedIndicatorItems } from '@/modules/widget-yard/store/types';

import queue from '../queue';

import CView from '@/modules/widget-yard/c-widget/c-content-chart-radius-pie-for-budget/c-view';
import { IItem, IItemTitle, ITotalAmountText } from './types';

@Component({
    components: {
        'c-view': CView
    }
})
export default class CContentChartRadiusPieForBudget extends Vue {
    private numberWithCommas = (num: number | null, dimension = ''): string => {
        if (num !== null) {
            const os = Math.floor((num * 10) % 10);
            num = Math.floor(num) + os / 10;
            // num = Math.round(num*100) / 10;
            const parts = num.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

            if (parts.join('.') === '0') {
                return parts.join('.') + dimension + '  \t';
            }
            return parts.join('.') + dimension + '\t';
        }
        return '';
    };

    private created() {
        this.$watch('getViewData', this.selectedMapChanged);
        this.years = this.getYears();
    }

    private mounted() {
        this.unit = this.getUnit();
        this.viewData = this.getViewData;
        this.selectedMapChanged();
    }

    private beforeDestroy() {
        queue.clear(this);
    }


    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly full!: boolean;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: any;

    @Prop()
    public readonly redrawTrigger!: any;

    public get items() {
        return [...this.data.items.map((el: any) => ({ ...el, plan: el.plan }))];
    }

    public get selectedMap(): Map<string, ISelectedIndicatorItems> {
        const result = new Map<string, ISelectedIndicatorItems>();

        this.items.forEach((key) => {
            const items = accessor.selectedMap.get(key.region);
            if (items !== undefined) {
                result.set(key.region, items);
            }
        });

        return result;
    }

    private selectedMapChanged() {
        this.checkCurrentYear();
        if (this.notFoundYear) {
            this.viewData = this.getViewData;
            this.sumFact = this.getSumFact();
            this.titleData = this.getTitleData();
            this.totalAmount = this.getTotalAmount();
        } else {
            this.viewData = null;
        }
    }


    // private getItems(indicator: any) {
    //     const items = indicator.items.slice(-7 + this.year, indicator.items.length + this.year);
    //     return items;
    // }

    private previousDate() {
        if (Math.min(...this.getYears()) < this.year) {
            const value = this.year - 1;
            this.setYear(value);
        } else {
            this.notFoundYear = false;
        }
    }
    private nextDate() {
        if (Math.max(...this.getYears()) > this.year) {
            const value = this.year + 1;
            this.setYear(value);
        } else {
            this.notFoundYear = false;
        }
    }

    private setYear(newYear: number) {
        if (this.years.includes(newYear)) {
            this.year = newYear;
            this.selectedMapChanged();
        }
    }

    private year = 2022;
    private notFoundYear = false;
    private sumFact: number | null = null;
    private unit: string | null = null;
    private viewData: IItem[] | null = [];
    private titleData: IItemTitle | null = null;
    private totalAmount: ITotalAmountText | null = null;

    private years: number[] = [];
    private dimension: string = 'млрд.тг';
    // private dimensions: string[] = ['тыс.тг', 'млн.тг', 'млрд.тг'];
    // public forecastVRP = {
    //     2021: 3108996,
    //     2022: 3244597,
    //     2023: 3374398,
    //     2024: 3491178
    // };

    // public setForecastVRP(vrp2021: number, vrp2022: number, vrp2023: number, vrp2024: number){
    //     this.forecastVRP["2021"] = vrp2021;
    //     this.forecastVRP["2022"] = vrp2022;
    //     this.forecastVRP["2023"] = vrp2023;
    //     this.forecastVRP["2024"] = vrp2024;
    // }
    public getCurrentForecastVRP() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        return this.forecastVRP[this.year];
    }

    private accessYears() {
        const years: number[] = [];
        this.items.forEach(item => years.push(item.year));
        return [...new Set(years)];
    }

    private colors = ['#459ED8', '#953735', '#e46c0a', '#416084', '#449fd8', '#c0504d', '#f79646', '#5a80ad', '#195275', '#632523', '#984807', '#2c4058', '#459ED8', '#953735', '#e46c0a', '#416084', '#449fd8', '#c0504d', '#f79646', '#5a80ad', '#195275', '#632523', '#984807', '#2c4058'];


    private checkCurrentYear() {
        if (this.years.includes(this.year)) {
            this.notFoundYear = true;
        }
    }

    private getYears(): number[] {
        // const years: number[] = [];
        // this.items.forEach(item => years.push(item.year));
        // return [...new Set(years)];
        return [2021, 2022, 2023, 2024];
    }

    // Считаем сумму по всем показателям
    public getSumFact(): number {
        let check = 0;
        // const arr: any = [];
        this.items.forEach((key) => {
            if (this.year === key.year) {
                if (key.name_ru !== null) {
                    check = check + key.plan;
                }
            }
        });
        return check;
    }

    // наименование единиц измерения
    public getUnit(): string {
        // return this.data.unit;
        return this.dimension
    }

    public get getViewData(): IItem[] | null {
        if (this.items.length === 0) {
            return null;
        }

        const items: IItem[] = [];
        // eslint-disable-next-line consistent-return
        this.items.forEach((key) => {
            if (this.year === key.year) {
                if (key.name_ru !== null) {
                    const item: IItem = {
                        nameRu: key.name_ru,
                        value: this.sumFact,
                        plan: key.plan
                    };
                    items.push(item);
                }
            } else {
                return null;
            }
        });

        items.sort(function (a, b) {
            return b.plan - a.plan;
        });
        return items;
    }

    // Данные для "роста/спада"
    public getTitleData(): IItemTitle | null {
        let data: IItemTitle | null = null;

        data = {
            titleText: 'Затраты на ' + this.year,
            growIndicProc: this.getUnit() // unit "млрд тг"
        };
        return data;
    }
    public getTotalAmount(): ITotalAmountText {
        return {
            sumFact: this.sumFact,
            unit: this.unit
        };
    }
}