











































































































































import Class from './c-content-text-lines';
export default Class;
