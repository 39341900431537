/* eslint-disable no-extend-native */
(() => {
    const properties: PropertyDescriptorMap & ThisType<any> = {
        isNaN: {
            get(): boolean {
                return Number.isNaN(this);
            }
        },
        isNotNaN: {
            get(): boolean {
                return !this.isNaN;
            }
        },
        isFinite: {
            get(): boolean {
                return Number.isFinite(this);
            }
        },
        isNotFinite: {
            get(): boolean {
                return !this.isFinite;
            }
        },
        isPositiveInfinity: {
            get(): boolean {
                return this === Number.POSITIVE_INFINITY;
            }
        },
        isNotPositiveInfinity: {
            get(): boolean {
                return !this.isPositiveInfinity;
            }
        },
        isNegativeInfinity: {
            get(): boolean {
                return this === Number.NEGATIVE_INFINITY;
            }
        },
        isNotNegativeInfinity: {
            get(): boolean {
                return !this.isNegativeInfinity;
            }
        },
        isInfinity: {
            get(): boolean {
                return this.isPositiveInfinity || this.isNegativeInfinity;
            }
        },
        isNotInfinity: {
            get(): boolean {
                return !this.isInfinity;
            }
        },
        isInteger: {
            get(): boolean {
                return Number.isInteger(this);
            }
        },
        isNotInteger: {
            get(): boolean {
                return !this.isInteger;
            }
        },
        isSafeInteger: {
            get(): boolean {
                return Number.isInteger(this);
            }
        },
        isNotSafeInteger: {
            get(): boolean {
                return !this.isSafeInteger;
            }
        },
        isFloat: {
            get(): boolean {
                return this.isFinite && this.isNotInteger;
            }
        },
        isNotFloat: {
            get(): boolean {
                return !this.isFloat;
            }
        }
    };
    Object.defineProperties(Number.prototype, properties);
})();