const cssClass = 'dark';

const getClassList = (): DOMTokenList => {
    return document.body.classList;
};

export default {
    get dark(): boolean {
        return getClassList().contains(cssClass);
    },

    set dark(value: boolean) {
        if (value) {
            if (!getClassList().contains(cssClass)) {
                getClassList().add(cssClass);
            }
        } else {
            if (getClassList().contains(cssClass)) {
                getClassList().remove(cssClass);
            }
        }
    }
};