











































import Class from './c-flying-frame';
export default Class;
