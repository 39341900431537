<template>
    <div class="chart">
        <div class="sub-header" v-if="data.href">
            <span style="cursor: pointer" @click="changePath">{{
                data.title
            }}</span>
        </div>
        <div class="content-block">
            <div class="general-indicators-table">
                <table>
                    <thead>
                        <tr>
                            <th>Взаимоувязки</th>
                            <th>ЦИ</th>
                            <th>БП</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="blue">
                            <td>
                                <i class="icon icon-analytics"></i>
                                Взаимоувязанные
                            </td>
                            <td>{{ data.data[0].withConnection }}</td>
                            <td>{{ data.data[1].withConnection }}</td>
                        </tr>
                        <tr class="orange">
                            <td>
                                <i class="icon icon-analytics"></i>
                                Невзаимоувязанные
                            </td>
                            <td>{{ data.data[0].withoutConnection }}</td>
                            <td>{{ data.data[1].withoutConnection }}</td>
                        </tr>
                        <tr class="grey">
                            <td><i class="icon icon-analytics"></i> Всего</td>
                            <td>{{ data.data[0].total }}</td>
                            <td>{{ data.data[1].total }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Chart-ci-bp',
    props: ['subHeader', 'data'],
    methods: {
        changePath() {
            this.$router.push(this.data.href);
        }
    }
};
</script>