/**
 * Логгер
 *
 * заглушка
 */
export default {
    debug(message: unknown, ...messages: unknown[]) {
        // eslint-disable-next-line no-console
        console.debug(message, ...messages);
    },
    info(message: unknown, ...messages: unknown[]) {
        // eslint-disable-next-line no-console
        console.info(message, ...messages);
    },
    warn(message: unknown, ...messages: unknown[]) {
        // eslint-disable-next-line no-console
        console.warn(message, ...messages);
    },
    error(message: unknown, ...messages: unknown[]) {
        // eslint-disable-next-line no-console
        console.error(message, ...messages);
    }
};