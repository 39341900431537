import { Component, Prop, Vue } from 'vue-property-decorator';

import '../../initialization';

import queue from '../queue';

import CChartGanttView from './c-view';

@Component({
    components: {
        'c-content-chart-gantt-view': CChartGanttView
    }
})
export default class CContentChartGantt extends Vue {
    private created() {
        this.$watch('result', this.resultChanged);
    }

    private mounted() {
        this.resultChanged();
    }

    private beforeDestroy() {
        queue.clear(this);
    }


    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;

    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: any;

    @Prop()
    public readonly redrawTrigger!: any;

    private dat = this.data;

    private resultChanged(): void {
        setTimeout(() => {
            queue.schedule(this, () => {
                this.dat = this.data;
            });
        }, 2);
    }
    private ru_months = ['январь',
        'февраль',
        'март.',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь'];

    private getQuarter(date: any) {
        const d = date.split(' ');
        const dat = d[0] + ' ' + d[1];
        let from: any;
        let to: any;
        if (dat.toLowerCase() === '1 квартал') {
            from = Date.UTC(2020, 0, 1);
            to = Date.UTC(2020, 3, 0);
        }
        if (dat.toLowerCase() === '2 квартал') {
            from = Date.UTC(2020, 3, 1);
            to = Date.UTC(2020, 6, 0);
        }
        if (dat.toLowerCase() === '3 квартал') {
            from = Date.UTC(2020, 6, 1);
            to = Date.UTC(2020, 9, 0);
        }
        if (dat.toLowerCase() === '4 квартал') {
            from = Date.UTC(2020, 9, 1);
            to = Date.UTC(2021, 0, 0);
        }
        const quarter = {
            from: from,
            to: to
        };
        return quarter;
    }

    private getHalfYear(date: any) {
        let from: any;
        let to: any;
        const d = date.toLowerCase().split(' ');
        if (d[0] === 1) {
            from = Date.UTC(2020, 0, 1);
            to = Date.UTC(2021, 7, 0);
        } else {
            from = Date.UTC(2020, 7, 1);
            to = Date.UTC(2021, 0, 0);
        }
        const halfYear = {
            from: from,
            to: to
        };
        return halfYear;
    }

    private get result() {
        const dat: any[] = [];
        this.data.list.forEach((data: any) => {
            let title = '';
            const deal: any[] = [];
            data.name.forEach((names: string) => {
                title += String(names) + '';
            });
            if (data.list.length > 0) {
                const deals: any[] = [];
                data.list.forEach((item: any) => {
                    let naming = '';
                    let completionForm = '';
                    let description = '';
                    let completion = null;
                    let responsible = '';
                    let sourceOfFinancing = '';
                    let supportMeasures = '';
                    item.name.forEach((names: string) => {
                        naming += names + ' ';
                    });
                    item.completionForm.forEach((compl: string) => {
                        completionForm += compl + ' ';
                    });
                    item.description.forEach((descr: string) => {
                        description += descr + ' ';
                    });
                    item.responsible.forEach((resp: string) => {
                        responsible += resp + ' ';
                    });
                    item.sourceOfFinancing.forEach((source: string) => {
                        sourceOfFinancing += source + ' ';
                    });
                    item.supportMeasures.forEach((support: string) => {
                        supportMeasures += support + ' ';
                    });
                    if (item.completion === null) {
                        completion = null;
                    } else if (item.completion.includes('%')) {
                        completion = item.completion.replace('%', '') / 100;
                    } else {
                        completion = parseFloat(item.completion);
                    }
                    const date = item.implementationPeriod;
                    let from: any = null;
                    let to: any = null;
                    date.forEach((dates: any) => {
                        this.ru_months.forEach((m: any, index: number) => {
                            if (m === dates.toLowerCase().split(' ')[0]) {
                                from = Date.UTC(2020, index, 1);
                                to = Date.UTC(2020, index + 1, 0);
                            }
                            if (dates.toLowerCase().includes('-')) {
                                const twoMonths = dates.toLowerCase().split(' ')[0].split('-');
                                if (m === twoMonths[0]) {
                                    from = Date.UTC(2020, index, 1);
                                }
                                if (m === twoMonths[1].split(' ')[0]) {
                                    to = Date.UTC(2020, index + 1, 0);
                                }
                            }
                        });
                        if (dates.toLowerCase().includes('квартал')) {
                            const d = this.getQuarter(dates);
                            from = d.from;
                            to = d.to;
                        }
                        if (dates.toLowerCase().includes('полугодие')) {
                            const half = this.getHalfYear(dates);
                            from = half.from;
                            to = half.to;
                        }
                        if (parseInt(dates) === 2020) {
                            from = Date.UTC(2020, 0, 1);
                            to = Date.UTC(2021, 0, 0);
                        }
                    });
                    deals.push({ naming, completionForm, description, completion, responsible, sourceOfFinancing, supportMeasures, from, to });
                });
                dat.push({ model: title, deals, current: 0 });
            } else {
                let naming = '';
                let completionForm = '';
                let description = '';
                let completion = null;
                let responsible = '';
                let sourceOfFinancing = '';
                let supportMeasures = '';
                data.name.forEach((names: string) => {
                    naming += names + ' ';
                });
                data.completionForm.forEach((compl: string) => {
                    completionForm += compl + ' ';
                });
                data.description.forEach((descr: string) => {
                    description += descr + ' ';
                });
                data.responsible.forEach((resp: string) => {
                    responsible += resp + ' ';
                });
                data.sourceOfFinancing.forEach((source: string) => {
                    sourceOfFinancing += source + ' ';
                });
                data.supportMeasures.forEach((support: string) => {
                    supportMeasures += support + ' ';
                });
                if (data.completion === null) {
                    completion = null;
                } else if (data.completion.includes('%')) {
                    completion = data.completion.replace('%', '') / 100;
                } else {
                    completion = parseFloat(data.completion);
                }
                const date = data.implementationPeriod;
                let from: any = null;
                let to: any = null;
                date.forEach((dates: any) => {
                    this.ru_months.forEach((m: any, index: number) => {
                        if (m === dates.toLowerCase().split(' ')[0]) {
                            from = Date.UTC(2020, index, 1);
                            to = Date.UTC(2020, index + 1, 0);
                        }
                        if (dates.toLowerCase().includes('-')) {
                            const twoMonths = dates.toLowerCase().split(' ')[0].split('-');
                            if (m === twoMonths[0]) {
                                from = Date.UTC(2020, index, 1);
                            }
                            if (m === twoMonths[1]) {
                                to = Date.UTC(2020, index + 1, 0);
                            }
                        }
                    });
                    if (dates.toLowerCase().includes('квартал')) {
                        const d = this.getQuarter(dates);
                        from = d.from;
                        to = d.to;
                    }
                    if (dates.toLowerCase().includes('полугодие')) {
                        const half = this.getHalfYear(dates);
                        from = half.from;
                        to = half.to;
                    }
                    if (parseInt(dates) === 2020) {
                        from = Date.UTC(2020, 0, 1);
                        to = Date.UTC(2021, 0, 0);
                    }
                });
                deal.push({ naming, completionForm, description, completion, responsible, sourceOfFinancing, supportMeasures, from, to });
                dat.push({ model: title, deals: deal, current: 0 });
            }
        });
        return dat;
    }
}