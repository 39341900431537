import { Component, Model, Vue } from 'vue-property-decorator';

import CTab from '../c-tab';


interface ITabInfo {
    tab: CTab;
    title: string;
}


const modelEvent = 'change';

@Component
export default class CTabs extends Vue {
    private windowClickListener: undefined | ((this: Window, ev: MouseEvent) => void);

    // признак мобильности
    private isMobile = false;
    // открытие/закрытие верхнего меню с опциями
    private opened = false;

    private created() {
        this.windowResizeEventHandler();

        this.windowClickListener = () => {
            this.opened = false;
        };
        window.addEventListener('click', this.windowClickListener);

        if (this.value !== null) {
            this.localValue = this.value;
        }

        this.$watch('value', this.valueChanged);
        this.$watch('localValue', this.localValueChanged);
    }

    private mounted() {
        this.scanTabs();
    }

    private updated() {
        this.scanTabs();
    }


    // #region Value
    @Model(modelEvent, {
        type: Number,
        required: false,
        default: null
    })
    private value!: number | null;

    private valueChanged() {
        if (this.value !== null) {
            if (this.localValue !== this.value) {
                this.localValue = this.value;
            }
        }
    }
    // #endregion


    // #region Local value
    private localValue = -1;

    private localValueChanged() {
        if (this.value !== this.localValue) {
            this.$emit(modelEvent, this.localValue);
        }
    }
    // #endregion


    private tabInfos: ITabInfo[] = [];

    private scanTabs() {
        const tabInfos: ITabInfo[] = [];

        let changed = false;
        let index = 0;
        this.$children.forEach((child) => {
            if (child instanceof CTab) {
                const info: ITabInfo = {
                    tab: child,
                    title: child.title
                };
                tabInfos.push(info);

                if (!changed) {
                    if (this.tabInfos.length <= index) {
                        changed = true;
                    } else {
                        const oldInfo = this.tabInfos[index];
                        if ((oldInfo.tab !== info.tab) || (oldInfo.title !== info.title)) {
                            changed = true;
                        }
                    }
                }

                const el = info.tab.getEl();
                if (el !== undefined) {
                    if (index === this.localValue) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (el.style as any).display = null;
                    } else {
                        el.style.display = 'none';
                    }
                }

                index++;
            }
        });

        if (changed) {
            this.tabInfos = tabInfos;
        }
    }

    private windowResizeEventHandler() {
        if (window.innerWidth <= 500) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }
    }

    private columnClicked() {
        setTimeout(() => {
            this.opened = false;
        });
    }
}