import addToMap, { TRenderFunc } from '../add-to-map';


export default (map: Map<string, TRenderFunc>): void => {
    // #region checkbox-blank-outline
    addToMap(map, 'checkbox-blank-outline', 'M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z');
    // #endregion

    // #region checkbox-intermediate
    addToMap(map, 'checkbox-intermediate', 'M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M19,19H5V5H19V19M17,17H7V7H17V17Z');
    // #endregion

    // #region checkbox-marked
    // eslint-disable-next-line max-len
    addToMap(map, 'checkbox-marked', 'M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z');
    // #endregion

    // #region check-box-outline
    // eslint-disable-next-line max-len
    addToMap(map, 'check-box-outline', 'M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M19,5V19H5V5H19M10,17L6,13L7.41,11.58L10,14.17L16.59,7.58L18,9');
    // #endregion
};