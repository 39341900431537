<template>
    <div class="chart">
        <div class="top-info-block">
            <p class="title">заявки на финансирование проектов на 2022</p>
        </div>
        <div class="content-block full-height scrollable">
            <div class="general-indicators-row">
                <div class="indicator" v-for="(item, index) in data.data" :key="index" :class="{'first': (index === 0)}">
                    <div class="text">
                        <i class="icon icon-document"></i>
                        <span>{{item.nameRu}}</span>
                    </div>
                    <div class="number">
                        <span>{{item.count}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Chart-investing',
    props: ['subHeader', 'data']
};
</script>