











































import Class from './c-content-chart-3d-pie';
export default Class;
