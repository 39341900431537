var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.shown),expression:"shown"}],staticClass:"c-modal"},[_c('div',{ref:"overlay",staticClass:"c-modal--overlay",class:{
            'dark': _vm.themeDark
        },style:({
            'opacity': _vm.overlayOpacity
        }),on:{"click":_vm.onOverlayClick}}),_c('div',{ref:"windowContainer",staticClass:"c-modal--window-container",style:({
            'transform': "translateY(-100%)"
        }),on:{"transitionstart":_vm.onTransitionStart,"transitionend":_vm.onTransitionEnd,"click":_vm.onWindowContainerClick}},[_c('div',{ref:"window",staticClass:"c-modal--window",class:{
                'dark': _vm.themeDark
            }},[_c('focus-lock',{staticClass:"c-modal--focus-lock"},[_c('div',{staticClass:"c-modal--content",style:({
                        'max-height': ((Math.round(_vm.windowHeight * 0.8) + 100) + "px")
                    })},[(!_vm.noHeader)?_c('div',{staticClass:"c-modal--header"},[_vm._t("header",function(){return [_c('span',{staticClass:"c-modal--title"},[_vm._t("title")],2),_vm._t("close-button",function(){return [_c('button',{staticClass:"c-modal--close-button",on:{"click":_vm.onCloseButtonClick}},[_vm._t("close-button-text",function(){return [_vm._v("×")]})],2)]})]})],2):_vm._e(),(_vm.hasToolbar)?_c('div',{staticClass:"c-modal--toolbar"},[_vm._t("toolbar")],2):_vm._e(),_c('div',{staticClass:"c-modal--body"},[_vm._t("default")],2),(!_vm.noFooter)?_c('div',{staticClass:"c-modal--footer"},[_vm._t("footer",function(){return [_c('div',{staticClass:"c-modal--buttons"},[_c('button',{on:{"click":_vm.onOkButtonClick}},[_vm._t("ok-button",function(){return [_vm._v("OK")]})],2),_c('button',{on:{"click":_vm.onCancelButtonClick}},[_vm._t("cancel-button",function(){return [_vm._v("Cancel")]})],2)])]})],2):_vm._e()])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }