























































import Class from './c-pagination';
export default Class;
