import VueRouter from 'vue-router';
import { Module } from 'vuex';
import storeService, { IState } from '@/services/store';
import { getActive } from './utils';
import { initialItems, initialize, applyDataForBudgetMenu } from './data';
import { IModuleState, IModuleGetters, TMenuItem, IMenu, IBudgetMenuData } from './types';


// #region Создание модуля
/**
 * Имя модуля
 */
const moduleName = 'cBoardLeft';

/**
 * Модуль vuex
 */
const module: Module<IModuleState, IState> = {
    namespaced: true,
    state: {
        items: initialItems,
        active: [],
        expanded: []
    },
    getters: {
        itemMap: (state): Map<string, TMenuItem> => {
            const result: Map<string, TMenuItem> = new Map();

            const scan = (items: TMenuItem[]) => {
                for (const item of items) {
                    if (item.id) {
                        result.set(item.id, item);
                    }
                    if (item.type === 'menu') {
                        scan(item.children);
                    }
                }
            };
            scan(state.items);

            return result;
        }
    },
    mutations: {
        setActive: (state, active: TMenuItem[]) => {
            state.active = active;
        },

        setItems: (state, items: TMenuItem[]) => {
            state.items = items;
        },

        setExpanded: (state, expanded: IMenu[]) => {
            state.expanded = expanded;
        }
    }
};
export default module;
// #endregion


// #region Вспомогательные переменные и методы
/**
 * Получени данных модуля
 * @returns текущие данные модуля
 */
const getState = (): IModuleState => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-extra-parens
    return (storeService.state as any)[moduleName] as IModuleState;
};

/**
 * Переменная для хранения "геттеров", инициализируется при первом вызове `getGetters`
 */
let cachedGetters: null | IModuleGetters = null;

/**
 * Получение "геттеров" для модуля
 * @returns "геттеры" модуля
 */
const getGetters = (): IModuleGetters => {
    if (cachedGetters !== null) {
        return cachedGetters;
    }

    cachedGetters = {
        get itemMap(): Map<string, TMenuItem> {
            return storeService.getters[`${moduleName}/itemMap`];
        }
    };
    return cachedGetters;
};


/**
 * Запуск "мутации" модуля в vuex
 * @param mutation имя мутации модуля
 * @param payload данные, используемые в мутации
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const commit = (mutation: string, payload: any) => {
    storeService.commit(`${moduleName}/${mutation}`, payload);
};


/**
 * Метод вызывается, чтоб vuex понял, что элементы меню поменялись
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const forceUpdateItems = () => {
    const newItems: TMenuItem[] = [];

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    newItems.push(...accessor.items);

    commit('setItems', newItems);
};
// #endregion


// #region Доступ к данным модуля в vuex
export const accessor = {
    /**
     * Получение состояния модуля
     */
    getState,

    /**
     * Элементы меню
     *
     * В массиве только корневые, дочерние элементы - каждый в своем родителе
     */
    get items(): TMenuItem[] {
        return this.getState().items;
    },

    /**
     * Активные элементы меню (согласно состоянию vue-router)
     */
    get active(): TMenuItem[] {
        return this.getState().active;
    },

    get expanded(): IMenu[] {
        return this.getState().expanded;
    },

    set expanded(expanded: IMenu[]) {
        commit('setExpanded', expanded);
    },

    /**
     * Карта элементов меню по их `id`
     *
     * Хранятся только те элементы меню, у которых указан `id`
     */
    get itemMap(): Map<string, TMenuItem> {
        return getGetters().itemMap;
    },

    /**
     * Обновление активных элементов согласно состояния vue-router
     * @param router vue-router, по состоянию которого определяются активные элементы меню
     */
    updateActiveItems(router: VueRouter) {
        const active = getActive(router, this.items);
        commit('setActive', active);
    },

    /**
     * Инициализация; например, получение данных из сервера, на основе которых будут обновляться те или иные меню
     */
    initialize() {
        initialize(
            // () => console.log('Menu initialized completely 2'),
            null,
            () => {
                // forceUpdateItems();
            }
        );
    },

    /**
     * Применяет изменения источника данных для меню "Бюджет"
     * @param budgetMenu данные для меню "Бюджет"
     */
    budgetMenuSourceChanged(router: VueRouter, budgetMenu: IBudgetMenuData[]) {
        applyDataForBudgetMenu(budgetMenu);
        // forceUpdateItems();
        this.updateActiveItems(router);
    }
};
// #endregion

// регистрация модуля в vuex
storeService.registerModule(moduleName, module);