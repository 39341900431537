






















































































































































import Class from './v-widget';
export default Class;
