













import Class from './c-tab';
export default Class;
