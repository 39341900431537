import { Component, Prop, Vue } from 'vue-property-decorator';

import {} from '@/utils';

import '../../initialization';
import queue from '../queue';
import { accessor } from '../../store';
import { IWidgetChartBudget } from '../../store/types';
import CChartBudgetView from './c-chart-budget-view';
import { IItemGraph, IChartBarItem, ILastResult } from './types';
import { assignGrow } from '../common';

@Component({
    components: {
        'c-chart-budget-view': CChartBudgetView
    }
})
export default class CContentChartBudget extends Vue {
    private created() {
        this.$watch('result', this.resultChanged);
    }

    private mounted() {
        this.resultChanged();
    }

    private beforeDestroy() {
        queue.clear(this);
    }


    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;

    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: IWidgetChartBudget;

    @Prop()
    public readonly redrawTrigger!: any;

    private typeChart: Map<string, string> = new Map();

    public get indicatorConfigs() {
        return this.data.indicatorConfigs;
    }

    public get result(): ILastResult[] | null {
        const lastresult: ILastResult[] = [];
        if (this.indicatorConfigs.length === 0) {
            return null;
        }
        this.indicatorConfigs.forEach((config) => {
            const indicatorKey = config.indicator;
            const indicator = accessor.getIndicator(indicatorKey);
            if (indicator === undefined) {
                return null;
            }
            const plan = config.plan;
            const unit = indicator.unit
            const growDatas = assignGrow(indicatorKey);
            let data: null | IItemGraph = null;
            const items: IChartBarItem[] = [];
            const type = config.type;
            let percentage = null;
            const selectedItems = accessor.selectedMap.get(indicatorKey);
            if (selectedItems !== undefined) {
                selectedItems.items.forEach((selectedItem) => {
                    const item: IChartBarItem = {
                        date: selectedItem.date,
                        value: selectedItem.fact,
                        plan: selectedItem.plan
                    };
                    items.push(item);
                    percentage = Math.round((selectedItem.fact / selectedItem.plan) * 100)
                });
                const current = selectedItems.item;
                if (current !== undefined) {
                    data = {
                        name: indicator.shortIndicator,
                        plan,
                        items,
                        growDatas: (growDatas !== undefined ? growDatas : null),
                        type,
                        unit,
                        percentage
                    };
                }
            }
            lastresult.push({ title: indicator.shortIndicator, data, indicatorKey });
            return lastresult;
        });
        return lastresult;
    }

    private resultChanged(): void {
        setTimeout(() => {
            queue.schedule(this, () => {
                this.result2 = this.result;
            });
        }, 2);
    }

    private result2: ILastResult[] | null = null;
}