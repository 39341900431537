<template>
    <div>
        <div class="top-info-block justify-content-between">
            <div class="sub-header" v-if="subHeader">
                <span style="cursor: pointer" @click="changePath">ЦЕНЫ НА СОЦИАЛЬНО-ЗНАЧИМЫЕ ПРОДУКТЫ</span>
            </div>
            <p v-else class="title">ЦЕНЫ НА СОЦИАЛЬНО-ЗНАЧИМЫЕ ПРОДУКТЫ</p>
            <div class="subtext">тенге</div>
        </div>
        <div class="content-block full-height scrollable">
            <div class="general-table">
                <table>
                    <thead>
                        <tr>
                            <th>товар</th>
                            <th>цена</th>
                            <th>прирост за месяц</th>
                            <th>прирост за год</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in res" :key="'item_' + index">
                            <td>{{item.title}}</td>
                            <td class="light-blue">{{item.fact}}</td>
                            <td>{{item.monthGrow}}</td>
                            <td>{{item.yearGrow}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="years-block datepick">
            <date-picker
                v-model="value1.date"
                :disabled-date="disabledDays"
                format="DD-MM-YYYY"
                type="date"
                placeholder="Выбрать дату"
            ></date-picker>
            <div class="years-prev" @click="prev"><i class="icon icon-keyboard icon-rotate-180"></i></div>
            <div class="years-next" @click="next"><i class="icon icon-keyboard"></i></div>
        </div>
    </div>
</template>

<script>
import { accessor } from '../../store';
import queue from '../queue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
    name: 'chartSoc',
    props: ['data', 'subHeader'],
    components: { DatePicker },
    data() {
        return {
            res: [],
            value1: { date: new Date(), updated: false },
            availableDates: [],
            currentDate: new Date().getTime()
        };
    },
    methods: {
        getData(currentDate) {
            const result = [];
            this.data.indicatorConfigs.forEach((config) => {
                const indicator = accessor.getIndicator(config.indicator);
                if (indicator) {
                    indicator.items.sort((a, b) => b.date - a.date);
                    const title = config.title;
                    const selectedPeriod = indicator.items.reduce(function (prev, curr) {
                        return (Math.abs(curr.date.getTime() - currentDate) < Math.abs(prev.date.getTime() - currentDate) ? curr : prev);
                    });
                    const fact = Math.round(selectedPeriod.fact * config.measurement);
                    let monthGrow;
                    let yearGrow;
                    const curMonth = new Date(selectedPeriod.date.getFullYear(), selectedPeriod.date.getMonth(), 1);
                    const selectedMonth = indicator.items.filter(itemes => itemes.date.getMonth() === curMonth.getMonth()).reduce(function (prev, curr) {
                        return (Math.abs(curr.date.getTime() - curMonth.getTime()) < Math.abs(prev.date.getTime() - curMonth.getTime()) ? curr : prev);
                    });
                    if (selectedPeriod.date === selectedMonth.date) {
                        monthGrow = 0;
                    } else {
                        monthGrow = Math.round((selectedPeriod.fact * config.measurement) - (selectedMonth.fact * config.measurement));
                        if (monthGrow > 0) {
                            monthGrow = '+' + String(monthGrow);
                        }
                    }
                    const curYear = new Date(selectedPeriod.date.getFullYear(), 0, 1);
                    const selectedYear = indicator.items.filter(itemes => itemes.date.getFullYear() === curYear.getFullYear()).reduce(function (prev, curr) {
                        return (Math.abs(curr.date.getTime() - curYear.getTime()) < Math.abs(prev.date.getTime() - curYear.getTime()) ? curr : prev);
                    });
                    if (selectedPeriod.date === selectedYear.date) {
                        yearGrow = 0;
                    } else {
                        yearGrow = Math.round((selectedPeriod.fact * config.measurement) - (selectedYear.fact * config.measurement));

                        if (yearGrow > 0) {
                            yearGrow = '+' + String(yearGrow);
                        }
                    }
                    if (!this.value1.updated) {
                        this.value1 = { date: new Date(selectedPeriod.date), updated: true };
                    }
                    const item = { title, fact, monthGrow, yearGrow };
                    result.push(item);
                    this.availableDates = [...indicator.items.map(itemes => itemes.date)];
                }
            });
            this.res = result;
            this.res.sort((a, b) => b.yearGrow - a.yearGrow);
        },
        changePath() {
            const category = this.data.dictCategoryEntity;
            let cat = category;
            if (category.code.split('.').length > 2) {
                cat = accessor.categories.find(item => item.id === category.parent);
            }
            const url = {
                path: '/soc-eco',
                query: {
                    code: cat.code,
                    id: cat.id,
                    href: '/soc-eco'
                }
            };
            this.$router.push(url);
        },
        selectedMapChanged() {
            setTimeout(() => {
                queue.schedule(this, () => {
                    this.getData(this.currentDate);
                });
            }, 1);
        },
        prev() {
            const dateList = [...this.availableDates.sort((a, b) => b - a)];
            const date = new Date(`${this.value1.date.getFullYear()}-${this.value1.date.getMonth() + 1}-${this.value1.date.getDate()}`);
            for (const d of dateList) {
                const available = new Date(`${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`);
                if (available.getTime() < date.getTime()) {
                    this.value1 = { date: available, updated: true };
                    break;
                }
            }
        },
        next() {
            const dateList = [...this.availableDates.sort((a, b) => a - b)];
            const date = new Date(`${this.value1.date.getFullYear()}-${this.value1.date.getMonth() + 1}-${this.value1.date.getDate()}`);
            for (const d of dateList) {
                const available = new Date(`${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`);
                if (available.getTime() > date.getTime()) {
                    this.currentDate = available;
                    this.value1 = { date: available, updated: true };
                    break;
                }
            }
        },
        disabledDays(value) {
            const valueDate = `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`;
            const disabled = this.availableDates.find(a => `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}` === valueDate);
            if (!disabled) {
                return value;
            }
        }
    },
    created() {
        this.$watch('res', this.selectedMapChanged);
        this.$watch('value1.date', () => {
            const date = `${this.value1.date.getFullYear()}-${this.value1.date.getMonth() + 1}-${this.value1.date.getDate()}`;
            this.availableDates.forEach((d) => {
                const available = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
                if (available === date) {
                    this.currentDate = new Date(available);
                }
            });
        });
    },
    mounted() {
        this.selectedMapChanged(this.currentDate);
    },
    beforeDestroy() {
        queue.clear(this);
    }
};
</script>