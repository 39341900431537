


















































































import Class from './c-content-chart-linear-marker';
export default Class;
