import { Component, Prop, Vue } from 'vue-property-decorator';

import CChartStackColumnView from './c-view/';

@Component({
    components: {
        'c-chart-stack-column-view': CChartStackColumnView
    }
})
export default class CContentChartStackColumn extends Vue {
    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: any;

    private get result() {
        const res: any[] = [];
        this.data.data.forEach((item: any) => {
            const indicator = {
                name: item.name_ru,
                fact: item.fact,
                plan: item.plan,
                vertical: this.data.vertical
            };
            res.push(indicator);
        });
        return res;
    }
    private label = '01.09.2021';

    // private created() {
    //     const d = new Date();
    //     if ((d.getMonth() + 1) < 10) {
    //         this.label = `на 01.0${d.getMonth() + 1}.2021`;
    //     } else {
    //         this.label = `на 01.${d.getMonth() + 1}.2021`;
    //     }
    // }
}