






















































import Class from './c-content-chart-flat-pie';
export default Class;
