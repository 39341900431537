import { Vue, Component, Prop } from 'vue-property-decorator';

import Highcharts, { GradientColorObject } from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsModuleSolidGauge from 'highcharts/modules/solid-gauge';

import { IGrowIndex } from '../../common';

HighchartsMore(Highcharts);
HighchartsModuleSolidGauge(Highcharts);

Highcharts.setOptions({
    lang: {
        loading: 'Загрузка...',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
        thousandsSep: ' ',
        decimalPoint: ','
    }
});

@Component
export default class SolidGauge extends Vue {
    @Prop({
        type: Object,
        required: true
    })
    private data!: IGrowIndex;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    @Prop()
    public readonly redrawTrigger!: any;

    private redrawTriggerChanged() {
        if (this.chart !== undefined) {
            this.chart.reflow();
        }
    }

    private get options(): Highcharts.Options {
        const colors = ['#257bb0', '#953735', '#e46c0a', '#416084', '#5a80ad', '#449fd8', '#c0504d', '#f79646', '#2c4058', '#195275', '#632523', '#984807'];
        const data = this.data;


        const n = (value: number): string => {
            return this.$n(value, 'number');
        };

        const result: Highcharts.Options = {
            colors,
            chart: {
                type: 'solidgauge',
                backgroundColor: 'transparent',
                marginTop: 25,
                animation: false
            },
            title: {
                text: ''
            },
            tooltip: {
                enabled: false
            },
            exporting: {
                enabled: false,
                menuItemDefinitions: {
                    // Custom definition
                    printChart: {
                        text: 'Печать'
                    },
                    downloadPNG: {
                        text: 'Скачать PNG'
                    },
                    downloadJPEG: {
                        text: 'Скачать JPEG'
                    },
                    downloadPDF: {
                        text: 'Скачать PDF'
                    },
                    downloadSVG: {
                        text: 'Скачать SVG'
                    }
                }
            },
            pane: {
                size: '75%',
                startAngle: -90,
                endAngle: 90,
                background: [
                    {
                        backgroundColor: '#b4d9ef' as unknown as GradientColorObject,
                        borderWidth: 0,
                        innerRadius: '60%',
                        outerRadius: '100%',
                        shape: 'solid'
                    }
                ]
            },
            yAxis: {
                min: 0,
                max: data.max,
                lineWidth: 0,
                minorTickInterval: null,
                tickPositions: [0, data.max],

                labels: {
                    x: 0,
                    y: 20,
                    formatter(): string {
                        return n(this.value);
                    },
                    style: {
                        color: '#9cb3ce',
                        fontSize: '1rem'
                    }

                }
            },
            credits: {
                enabled: false
            },

            series: [{
                type: 'solidgauge',
                data: [{
                    radius: 100,
                    innerRadius: 60,
                    y: ((data.value / data.max) > 0.9 ? data.max * 0.9 : data.value),
                    name: n(data.value)
                }],
                dataLabels: {
                    formatter(): string {
                        return '';
                    }

                },
                tooltip: {
                    valueSuffix: ''
                }
            }],
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: -50,
                        borderWidth: 0,
                        useHTML: true
                    }
                },
                series: {
                    animation: false
                }
            }
        };
        return result;
    }

    private chart: Highcharts.Chart | undefined;

    private optionsChanged() {
        if (this.chart === undefined) {
            console.warn('Chart is not created yet');
        } else {
            this.chart.update(this.options);
        }
    }

    private downloadAction() {
        if (this.chart !== undefined) {
            if (this.download === 'print') {
                this.chart.print();
            }
            if (this.download === 'pdf') {
                this.chart.exportChart({
                    type: 'application/pdf'
                }, {});
            }
            if (this.download === 'jpeg') {
                this.chart.exportChart({
                    type: 'image/jpeg'
                }, {});
            }
            if (this.download === 'png') {
                this.chart.exportChart({
                    type: 'image/png'
                }, {});
            }
            if (this.download === 'svg') {
                this.chart.exportChart({
                    type: 'image/svg+xml'
                }, {});
            }
        }
    }

    private created() {
        this.$watch('options', this.optionsChanged);
        this.$watch('redrawTrigger', this.redrawTriggerChanged);
    }

    private mounted() {
        this.chart = Highcharts.chart(this.$refs.chart as HTMLElement, this.options);
        this.$watch('download', this.downloadAction);
    }

    private beforeDestroy() {
        if (this.chart !== undefined) {
            this.chart.destroy();
        }
    }
}