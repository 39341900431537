import { Component, Vue } from 'vue-property-decorator';


import ChartToneBar from './chart-tone-bar';

export interface IShownData {
    type: string;
    data: any | null;
}

@Component({
    components: {
        'chart-tone-bar': ChartToneBar
    }
})
export default class CContentChartToneBar extends Vue {
    public datas: any = null;


    private mounted() {
        this.loadData();
    }

    public get selectedMap(): any {
        const ajxParams = { params: {
            'type': 1,
            'period': 1,
            'selectedPeriod': null,
            'keywords': 'Жанаозен* | Жаңаөзен* | Жанаөзен | Мангистау* | Маңғыстау* | (Бейнеуск* + район*) | (Каракиянск* + район*) | (Мангистауск* + район*) | (Мунайлинск* + район*) | (Тупкараганск* + район*) | Ақтау*  | Актау* | (Мұнайлы* + аудан*) | (Бейнеу* + аудан*) | (Қарақия* + аудан*) | (Түпқараған* + аудан*) | (Трумов* + Серикба*) | (Трұмов* + Серікбай) | (Жұмашев* + Қаныбек*)  | (Қаныбек* +Бекболатұлы*)  | (Каныбек* + Бекболатович*) | Аққұлов* | (Нұрлыбай* + Қаржаубайұлы*) | Аккулов* | (Нурлыба* + Каржаубаевич*)  | Қраубаев* | (Алихан* + Сұлтанмұратұлы*) | Краубаев* | (Алихан* + Султанмуратович*) | (Бақытжан* +Аманжолұлы*) | (Качаков* + Бақытжан*) | (заместител* +Качаков*) | (Бакытжан* + Аманжолович*) | Ниязов* | (Галымжан* +  Мукырович*) | (Ғалымжан* + Мұқырұлы*) | (Шамғали* + Смитұлы*) | Хамиев* | (Шамгали* + Смитович*) | Күмісқалиев* |  (Ержан* + Көпжасарұлы*) | (Кумискалиев*) | (Ержан* + Копжасарович*) | Сағымбаев* | (Әнуар* + Әлімжанұлы*) | Сагимбаев* | (Ануар* + Алимжанович*) | (Жамал* + Жаманбаев*) | (Болат* + Ережепов*) | (Болат* + Қайруллаұлы*) | (Болат* + Кайруллаевич*) | (Жетібай* + ауыл*) | (сел* + Тенге) | (Теңге* + ауыл*) | Aktau',
            'attrSearch': true,
            'tone': null } };
        const settings = {
            method: 'POST',
            body: JSON.stringify(ajxParams),
            headers: {
                'Accept': 'application/json, text/plain, **/**',
                'Content-Type': 'application/json;charset=utf-8'
            }
        };
        const result = {
            postLine: 'http://mm.csi.kz:5000/report-form',
            settings: settings
        };

        return result;
    }

    private async loadData() {
        try {
            const response = await fetch(this.selectedMap.postLine, this.selectedMap.settings);
            if (!response.ok) {
                const message = `>>>An error has occured>>>: ${response.status}`;
                return message;
            }
            const data = await response.json();
            if (data !== null || data.data.length !== 0) {
                data.data.sort(this.sortByField('bDate'));
            }
            this.datas = { type: 'chart-tone-bar', data: data.data };
        } catch (e) {
            return e;
        }
        // console.log('result=', this.datas);
        return this.datas;
    }

    private sortByField(field: string): any {
        return (a: { [x: string]: number }, b: { [x: string]: number }) => (a[field] > b[field] ? 1 : -1);
    }

    public get indicatorKeys(): string[] {
        return [];
    }
}