import { Component, Prop, Vue } from 'vue-property-decorator';

import { I18nTemplate } from '@/services/i18n';

import '../../initialization';
import { accessor } from '../../store';
import { ISelectedIndicatorItems, IWidgetTextLines } from '../../store/types';
import { IGrowIndex, assignGrow } from '../common';

import queue from '../queue';


interface IShownData {
    actual: boolean;
    value: string;
    unit: string | null;
    warning: I18nTemplate;
    growIndicProc: null | number; // индикатор роста в процентах
    growIndic: null | number; // 0- равное значение, 1 положительный рост, -1 падение роста
}

interface IShownItem {
    title: string;
    data: IGrowIndex | null;
}


@Component
export default class CContentTextLines extends Vue {
    private created() {
        this.$watch('getShownData', this.selectedMapChanged);
    }

    private mounted() {
        this.selectedMapChanged();
    }

    private beforeDestroy() {
        queue.clear(this);
    }


    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;


    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: IWidgetTextLines;


    public get indicatorConfigs() {
        return this.data.indicatorConfigs;
    }


    public get selectedMap(): Map<string, ISelectedIndicatorItems> {
        const result = new Map<string, ISelectedIndicatorItems>();

        this.indicatorConfigs.forEach((key) => {
            const selectedIndicator = accessor.selectedMap.get(key.indicator);
            if (selectedIndicator !== undefined) {
                result.set(key.indicator, selectedIndicator);
            }
        });

        return result;
    }

    private selectedMapChanged() {
        setTimeout(() => {
            queue.schedule(this, () => {
                this.shownData = this.getShownData;
            });
        }, 1);
    }

    private previousDate() {
        this.year += 1;
    }

    private nextDate() {
        this.year -= 1;
    }

    private year = 0;

    private indic: any = [];

    private itemsLen = 0;


    private shownData: IGrowIndex[] = [];

    public get getShownData(): IGrowIndex[] {
        const result: IGrowIndex[] = [];
        const len: any = [];
        let indicLen = 0;

        this.indicatorConfigs.forEach((indicatorKey) => {
            const growDatas = assignGrow(indicatorKey.indicator);
            const data = ((growDatas !== undefined) ? growDatas : null);
            if (data !== null) {
                result.push(data);
                const selectedIndicator = accessor.selectedMap.get(indicatorKey.indicator);
                if (selectedIndicator !== undefined) {
                    if (selectedIndicator.items.length > len.length) {
                        const indicator = [];
                        for (let i = selectedIndicator.items.length - 1; i > -1; i--) {
                            const current = selectedIndicator.items[i].fact;
                            const date = selectedIndicator.items[i].date.getFullYear();
                            let growProc = null;
                            let measurement = 1;
                            if (indicatorKey.measurement !== undefined) {
                                measurement = indicatorKey.measurement;
                            }
                            const confUnit: any = indicatorKey.unit;
                            let unit: any;
                            if ((Object.keys(confUnit).length === 0)) {
                                unit = data.unit;
                            } else if ((confUnit.name_ru === '')) {
                                unit = data.unit;
                            } else {
                                if (confUnit.nationalSymbol === undefined || confUnit.nationalSymbol === null) {
                                    unit = confUnit.name_ru;
                                } else {
                                    unit = confUnit.nationalSymbol;
                                }
                            }
                            let unitLowerCased: string | null = null;
                            if (unit !== null || unit !== undefined) {
                                unitLowerCased = unit.toLowerCase();
                                if (unitLowerCased === 'процент') {
                                    unitLowerCased = '%';
                                }
                            }
                            const lcUnit = (Boolean((unit === 'процент') || (unit === '%')));
                            if (i !== 0) {
                                const j = i - 1;
                                const previous = selectedIndicator.items[j].fact;
                                growProc = (lcUnit
                                    ? Math.round((current - previous) * 100) / 100
                                    : Math.round(((current - previous) / previous) * 100 * 100) / 100);
                            }
                            const item = {
                                title: indicatorKey.title,
                                value: current * measurement,
                                unit: unitLowerCased,
                                growProc: growProc,
                                date,
                                growMark: data.growMark
                            };
                            indicator.push(item);
                            if (i === 0) {
                                len.push(indicator);
                            }
                            if (indicLen < selectedIndicator.items.length) {
                                indicLen = selectedIndicator.items.length;
                            }
                        }
                    }
                }
            }
        });
        this.indic = len;
        this.itemsLen = indicLen;
        return result;
    }

    private get years() {
        if (this.indic[0] !== undefined) {
            const items = this.indic[0];
            const years: any[] = [];
            items.forEach((item: any) => {
                years.push(item.date);
            });
            this.selected_year = items;
            const date = new Date();
            const currYear = date.getFullYear();
            const firstIndex = this.selected_year.findIndex(item => item.date == currYear);
            this.year = firstIndex;
            return years.reverse();
        }
    }

    private selected_year: any[] = [];
}