









import { Vue, Component, Prop } from 'vue-property-decorator';
import { IMenuItem, IMenuNavRoute, IMenuNavExternal } from './types';
import { getFixedRawLocation } from './utils';


@Component
export default class CBoardLeftItem extends Vue {
    @Prop({ required: true })
    public item!: IMenuItem;

    private onClick(ev: Event) {
        const nav = this.item.nav;
        switch (nav.type) {
            case 'route':
                this.onRouteClick(nav);
                break;
            case 'external':
                this.onExternalClick(nav);
                break;
            default:
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                console.warn(`Cannot process unknown nav type "${nav.type}"`);
                break;
        }
    }

    private onRouteClick(nav: IMenuNavRoute) {
        const rawLocation = getFixedRawLocation(nav);

        const resolvedLocation = this.$router.resolve(rawLocation);
        if (resolvedLocation.resolved.fullPath === this.$route.fullPath) {
            // eslint-disable-next-line no-console
            console.info(`Navigation to current path has been cancelled; path - ${this.$route.fullPath}`);
            return;
        }

        this.$router
            .push(rawLocation)
            .catch((e) => {
                if ((e instanceof Error) && (e.name === 'NavigationDuplicated')) {
                    // eslint-disable-next-line no-console
                    console.info(e.message);
                } else {
                    console.error(e);
                }
            });
    }

    private onExternalClick(nav: IMenuNavExternal) {
        window.open(nav.path);
    }
}
