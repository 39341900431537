












import { Vue, Component, Prop } from 'vue-property-decorator';
import { TMenuItem } from './types';
import CBoardLeftMenu from './CBoardLeftMenu.vue';


@Component
export default class CBoardLeftProxy extends Vue {
    @Prop({ required: true })
    private item!: TMenuItem;

    public getMenu(): CBoardLeftMenu | null {
        const menu = this.$refs.menu;
        if (menu instanceof Vue) {
            return (menu as CBoardLeftMenu);
        }
        return null;
    }
}
