import I18nDateTemplate from './date-template';
import service from './index';

/**
 * Тип аргумента перевода
 */
export type I18nTemplateArg = null | boolean | string | number | Date | I18nTemplate | I18nDateTemplate;

/**
 * Шаблон перевода
 *
 * Хранит ключ (`key`) и аргументы перевода (`args`), текущее значение возвращается методом `toString`
 * (используется компонент локализации из файла "src/services/i18n/vue.ts")
 *
 * Данные объекта не могут быть изменены
 */
export default class I18nTemplate {
    /**
     *
     */
    public readonly key: string;
    public readonly args: readonly I18nTemplateArg[];

    /**
     * Пытается распознать ответ сервера как шаблон перевода
     * @param source источник - должен быть объектом
     * @throws если `source` не может быть распознан как шаблон перевода
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static serverData(source: any): I18nTemplate {
        if (source instanceof Object) {
            // source.key должен быть строкой
            if (typeof source.key !== 'string') {
                throw new Error('source.key is not string: ' + typeof source.key);
            }
            const key = source.key;

            const sourceArgs = source.args;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const args: any[] = [];

            // распознование аргументов перевода
            if (Array.isArray(sourceArgs)) {
                sourceArgs.forEach((arg, i) => {
                    if (arg === null) {
                        args.push(null);
                    } else {
                        // аргумент должем быть объектов, иначе - выбрасывается ошибка
                        if (arg instanceof Object) {
                            // тип аргумента должен быть строкой
                            const type = arg.type;
                            if (typeof type !== 'string') {
                                throw new Error(`source.args[${i}].type is not string: ${typeof type}`);
                            }

                            const value = arg.value;

                            // чтение разных типов аргумента
                            switch (type) {
                                case 'boolean':
                                    if (typeof value !== 'boolean') {
                                        throw new Error(`source.args[${i}].value is not boolean: ${typeof value}`);
                                    }
                                    args.push(value);
                                    break;
                                case 'number':
                                    if (typeof value !== 'number') {
                                        throw new Error(`source.args[${i}].value is not number: ${typeof value}`);
                                    }
                                    args.push(value);
                                    break;
                                case 'string':
                                    if (typeof value !== 'string') {
                                        throw new Error(`source.args[${i}].value is not string: ${typeof value}`);
                                    }
                                    args.push(value);
                                    break;
                                case 'date':
                                    if (!((value instanceof Date) || (typeof value === 'string') || (typeof value === 'number'))) {
                                        throw new Error(`source.args[${i}].value is not Date, string nor number: ${typeof value}`);
                                    }
                                    args.push(new Date(value));
                                    break;
                                case 'i18n-template':
                                    // чтение вложенного шаблона перевода
                                    args.push(I18nTemplate.serverData(value));
                                    break;
                                case 'i18n-date-template':
                                    // чтение вложенного шаблона даты
                                    args.push(I18nDateTemplate.serverData(value));
                                    break;
                                default:
                                    // неизвестный тип аргумента
                                    throw new Error(`source.args[${i}].type has unknown value: ${type}`);
                            }
                        } else {
                            throw new Error(`source.args[${i}] is not object or null: ${typeof arg}`);
                        }
                    }
                });
            }

            return new I18nTemplate(key, ...args);
        }

        throw new Error('source is not object: ' + (source === null ? 'null' : typeof source));
    }

    /**
     * @param key ключ перевода
     * @param args аргумента перевода
     */
    public constructor(key: string, ...args: I18nTemplateArg[]) {
        this.key = key;
        this.args = Array.from(args);

        Object.freeze(this.args);
        Object.freeze(this);
    }

    /**
     * Получение текущего значения перевода
     */
    public toString(): string {
        return service.getTranslate(this.key, this.args);
    }
}