<template>
    <div class="chart">
        <div class="top-info-block" v-if="subHeader">
<!--            <p class="title">{{ data.title }}</p>-->
            <p class="title" @click="changePath">{{ data.title }}</p>
        </div>
        <div class="content-block">
            <div class="general-indicators">
                <div class="indicator green">
                    <div class="indicator-content">
                        <p class="text">Всего</p>
                        <p class="number">{{ data.data.count }}</p>
                        <i class="icon icon-analytics"></i>
                    </div>
                </div>
                <div class="indicator blue">
                    <div class="indicator-content">
                        <p class="text">Завершенные</p>
                        <p class="number">{{ data.data.done }}</p>
                        <i class="icon icon-analytics"></i>
                    </div>
                </div>
                <div class="indicator orange">
                    <div class="indicator-content">
                        <p class="text">Реализуются</p>
                        <p class="number">{{ data.data.working }}</p>
                        <i class="icon icon-analytics"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Chart-investing',
    props: ['subHeader', 'data'],
    methods: {
        changePath() {
            this.$router.push({
                path: '/monitoring',
                query: this.data.href
            });
        }
    }
};
</script>