import { Component, Model, Prop, Vue } from 'vue-property-decorator';

import { Dates } from '@/utils';

import { getDateZoom, EMonth, EDateZoom } from '@/utils/dates';


const events = {
    model: 'change',
    zoom: 'zoom'
};

@Component
export default class CDateNav extends Vue {
    // #region Lifecycle
    private created(): void {
        this.$watch('valueDate', this.valueDateChanged);
        this.$watch('minDate', this.minDateChanged);
        this.$watch('maxDate', this.maxDateChanged);
        this.$watch('actualZoom', this.actualZoomChanged);
        this.$watch('actualMinZoom', this.actualMinZoomChanged);
        this.$watch('actualMaxZoom', this.actualMaxZoomChanged);

        this.updateLocalValue();
        this.updateLocalZoom();
    }
    // #endregion


    // #region Value
    @Model(events.model, {
        type: Date,
        required: false,
        default: null
    })
    public readonly value!: Date | null;

    public get valueDate(): Date | null {
        if (this.value === null) {
            return null;
        }
        return new Date(this.value).clearTimePart();
    }

    private valueDateChanged(): void {
        this.updateLocalValueDelayed();
    }
    // #endregion


    // #region Min
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly min!: Date | null;

    public get minDate(): Date {
        if (this.min === null) {
            return Dates.minDate;
        }
        return new Date(this.min).clearTimePart();
    }

    public get minMonth(): number {
        return this.minDate.getMonth();
    }

    public get minYearMonth(): number {
        return this.minDate.getYearMonth();
    }

    public get minYear(): number {
        return this.minDate.getFullYear();
    }

    private minDateChanged(): void {
        this.updateLocalValueDelayed();
    }
    // #endregion


    // #region Max
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly max!: Date | null;

    public get maxDate(): Date {
        if (this.max === null) {
            return Dates.maxDate;
        }
        return new Date(this.max).clearTimePart();
    }

    private maxDateChanged(): void {
        this.updateLocalValueDelayed();
    }

    public get maxMonth(): number {
        return this.maxDate.getMonth();
    }

    public get maxYearMonth(): number {
        return this.maxDate.getYearMonth();
    }

    public get maxYear(): number {
        return this.maxDate.getFullYear();
    }
    // #endregion


    // #region Zoom
    @Prop({
        type: Number,
        required: false,
        default: null,
        validator(value: number | null): boolean {
            return (value === null) || ((value >= EDateZoom.MONTH) && (value <= EDateZoom.YEARS_1000000));
        }
    })
    public readonly zoom!: number | null;

    public get actualZoom(): EDateZoom | null {
        if (this.zoom === null) {
            return null;
        }

        return getDateZoom({
            alias: 'zoom',
            value: this.zoom,
            fallback: EDateZoom.MONTH,
            min: EDateZoom.MONTH,
            max: EDateZoom.YEARS_1000000
        });
    }

    private actualZoomChanged(): void {
        this.updateLocalZoom();
    }
    // #endregion


    // #region Min zoom
    @Prop({
        type: Number,
        required: false,
        default: null
    })
    public readonly minZoom!: number | null;

    public get actualMinZoom(): EDateZoom {
        if (this.minZoom === null) {
            return EDateZoom.MONTH;
        }

        return getDateZoom({
            alias: 'min zoom',
            value: this.minZoom,
            fallback: EDateZoom.MONTH,
            min: EDateZoom.MONTH,
            max: EDateZoom.YEARS_1000000
        });
    }

    private actualMinZoomChanged(): void {
        this.updateLocalZoom();
    }
    // #endregion


    // #region Max zoom
    @Prop({
        type: Number,
        required: false,
        default: null
    })
    public readonly maxZoom!: number | null;

    public get actualMaxZoom(): EDateZoom {
        if (this.maxZoom === null) {
            return EDateZoom.CENTURY;
        }

        return getDateZoom({
            alias: 'max zoom',
            value: this.maxZoom,
            fallback: EDateZoom.CENTURY,
            min: EDateZoom.MONTH,
            max: EDateZoom.YEARS_1000000
        });
    }

    private actualMaxZoomChanged(): void {
        this.updateLocalZoom();
    }
    // #endregion


    // #region Locale
    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly locale!: string | null;

    public get actualLocale(): string {
        if (this.locale === null) {
            return 'en';
        }
        return this.locale;
    }

    public get monthFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.actualLocale, { month: 'long' });
    }
    // #endregion


    // #region Local value
    private localValue = new Date().clearTimePart();
    private localValueUpdateHandle: number | undefined;

    private updateLocalValue(): void {
        if (this.minDate > this.maxDate) {
            console.error(`Min date ${this.minDate.toDateString()} is greater than max date ${this.maxDate.toDateString()}`);
            return;
        }

        if (this.valueDate === null) {
            if (this.localValue < this.minDate) {
                this.localValue = new Date(this.minDate);
            } else if (this.localValue > this.maxDate) {
                this.localValue = new Date(this.maxDate);
            }
        } else if (this.valueDate < this.minDate) {
            console.error(`Value date ${this.valueDate.toDateString()} is less than min date ${this.minDate.toDateString()}`);
        } else if (this.valueDate > this.maxDate) {
            console.error(`Value date ${this.valueDate.toDateString()} is greater than max date ${this.maxDate.toDateString()}`);
        } else if (this.localValue.notEquals(this.valueDate)) {
            this.localValue = new Date(this.valueDate);
        }
    }

    private updateLocalValueDelayed(): void {
        if (this.localValueUpdateHandle === undefined) {
            this.localValueUpdateHandle = setTimeout(() => {
                this.localValueUpdateHandle = undefined;
                this.updateLocalValue();
            });
        }
    }

    public get currentMonthName(): string {
        return this.monthFormat.format(this.localValue).capitalized();
    }

    public get currentMonth(): number {
        return this.localValue.getMonth();
    }

    public get currentYear(): number {
        return this.localValue.getFullYear();
    }

    public get currentYearMonth(): number {
        return this.localValue.getYearMonth();
    }
    // #endregion


    private setValue(value: Date): void {
        const date = new Date(value);
        if (this.value !== null) {
            date.copyTimePart(this.value);
        }
        if ((this.min !== null) && (date < this.min)) {
            date.setTime(this.min.getTime());
        }
        if ((this.max !== null) && (date > this.max)) {
            date.setTime(this.maxDate.getTime());
        }

        if (this.value === null) {
            if (this.localValue.datePartNotEquals(date)) {
                this.localValue = new Date(date).clearTimePart();
            }
            this.$emit(events.model, date);
        } else if (this.value.notEquals(date)) {
            this.$emit(events.model, date);
        }
    }


    // #region Local zoom
    private localZoom: EDateZoom = EDateZoom.MONTH;

    private updateLocalZoom(): void {
        if (this.actualMinZoom > this.actualMaxZoom) {
            console.error(`Min zoom ${this.actualMinZoom} is greater than max zoom ${this.actualMaxZoom}`);
            return;
        }

        if (this.actualZoom !== null) {
            if (this.actualZoom < this.actualMinZoom) {
                console.error(`Zoom ${this.actualZoom} is less than min zoom ${this.actualMinZoom}`);
                return;
            }
            if (this.actualZoom > this.actualMaxZoom) {
                console.error(`Zoom ${this.actualZoom} is greater than max zoom ${this.actualMaxZoom}`);
                return;
            }
        }

        if ((this.actualZoom !== null) && (this.localZoom !== this.actualZoom)) {
            this.localZoom = this.actualZoom;
        }
    }

    private setLocalZoom(value: EDateZoom): void {
        let newValue: number = value;
        if ((this.minZoom !== null) && (newValue < this.minZoom)) {
            newValue = this.minZoom;
        }
        if ((this.maxZoom !== null) && (newValue > this.maxZoom)) {
            newValue = this.maxZoom;
        }

        if (this.zoom === null) {
            if (this.localZoom !== newValue) {
                this.localZoom = newValue;
            }
            this.$emit(events.zoom, newValue);
        } else if (this.zoom !== newValue) {
            this.$emit(events.zoom, newValue);
        }
    }

    private setNextLocalZoom(): void {
        let nextZoom = this.localZoom + 1;
        if (nextZoom > this.actualMaxZoom) {
            nextZoom = this.actualMinZoom;
        }

        this.setLocalZoom(nextZoom);
    }

    public get zoomDivider(): number {
        if (this.localZoom === EDateZoom.MONTH) {
            return 1;
        }
        return Math.pow(10, (this.localZoom + 1));
    }

    public get minZoomYearMonth(): number {
        return this.currentYearMonth - (this.currentYearMonth % this.zoomDivider);
    }

    public get maxZoomYearMonth(): number {
        if (this.localZoom === EDateZoom.MONTH) {
            return this.minZoomYearMonth;
        }
        return this.minZoomYearMonth + this.zoomDivider - (100 - 11);
    }

    public get zoomText(): string {
        switch (this.localZoom) {
            case EDateZoom.MONTH:
                return `${this.currentMonthName} ${this.currentYear}`;
            case EDateZoom.YEAR:
                return String(this.currentYear);
            default:
                return `${this.minZoomYearMonth / 100} - ${(this.maxZoomYearMonth - 11) / 100}`;
        }
    }
    // #endregion


    // #region Tests
    public get hasPrevious(): boolean {
        return this.minZoomYearMonth > this.minYearMonth;
    }

    public get hasNext(): boolean {
        return (this.maxZoomYearMonth < this.maxYearMonth);
    }
    // #endregion


    // #region Changes
    private setPreviousMonth(): void {
        const month = (this.currentMonth === 0 ? EMonth.DECEMBER : this.currentMonth - 1);
        const date = new Date(this.localValue);
        date.changeMonth(-1);
        if (date.getMonth() !== month) {
            date.setDate(0);
        }
        this.setValue(date);
    }

    private setNextMonth(): void {
        const month = (this.currentMonth === EMonth.DECEMBER ? EMonth.JANUARY : this.currentMonth + 1) as EMonth;
        const date = new Date(this.localValue);
        date.changeMonth(1);
        if (date.getMonth() !== month) {
            date.setDate(0);
        }
        this.setValue(date);
    }

    private setPrevious(): void {
        const month = this.currentMonth;
        const year = this.currentYear - (this.zoomDivider / 100);
        const date = new Date(year, month, this.localValue.getDate());
        if (date.getMonth() !== month) {
            date.setDate(0);
        }
        this.setValue(date);
    }

    private setNext(): void {
        const month = this.currentMonth;
        const year = this.currentYear + (this.zoomDivider / 100);
        const date = new Date(year, month, this.localValue.getDate());
        if (date.getMonth() !== month) {
            date.setDate(0);
        }
        this.setValue(date);
    }

    private onBackClick(): void {
        if (this.hasPrevious) {
            if (this.localZoom === EDateZoom.MONTH) {
                this.setPreviousMonth();
            } else {
                this.setPrevious();
            }
        }
    }

    private onForwardClick(): void {
        if (this.hasNext) {
            if (this.localZoom === EDateZoom.MONTH) {
                this.setNextMonth();
            } else {
                this.setNext();
            }
        }
    }

    private onZoomClick(ev: Event): void {
        this.setNextLocalZoom();

        const target = ev.target;
        if (target instanceof HTMLElement) {
            target.blur();
        }
    }
    // #endregion
}