<template>
    <div class="chart">
        <div class="sub-header" v-if="data.href">
            <span style="cursor: pointer" @click="changePath">{{
                data.title
            }}</span>
        </div>
        <div class="content-block">
            <div class="centered-number">
                <div class="centered-number-block">
                    <div class="centered-number-content">
                        <p class="text">
                            Не соответствующие региональным стандартам
                        </p>
                        <div class="number">
                            <span class="big">{{ data.data.dependent }}</span
                            ><span class="small">/ {{ data.data.all }}</span>
                        </div>
                        <i class="icon icon-analytics"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Chart-investing',
    props: ['subHeader', 'data'],
    methods: {
        changePath() {
            this.$router.push(this.data.href);
        }
    }
};
</script>