var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"trigger",class:{
        'active': _vm.active,
        'menu': _vm.isMenu
    },attrs:{"href":_vm.href},on:{"click":_vm.onClick}},[_c('span',{staticClass:"icon"},[(_vm.item.icon)?[(typeof _vm.item.icon === 'string')?_c('i',{class:'icon icon-' + _vm.item.icon}):_vm._e(),((_vm.item.icon instanceof Object) && (_vm.item.icon.type === 'svg'))?_c('svg',{staticClass:"svg-icon",attrs:{"viewBox":_vm.item.icon.viewBox || '2 2 20 20'}},[_c('path',{attrs:{"d":_vm.item.icon.path,"fill":_vm.item.icon.fill}})]):_vm._e()]:_vm._e()],2),_c('span',{staticClass:"text"},[(_vm.item.title.i18n)?[_vm._v(_vm._s(_vm.$t(_vm.item.title.i18n)))]:[_vm._v(_vm._s(_vm.item.title.text))]],2),(_vm.endIconVisible)?_c('span',{staticClass:"submenu-icon"},[(_vm.isMenu)?_c('i',{staticClass:"icon icon-keyboard",class:{
                'icon-keyboard': _vm.isMenu,
                'icon-more': _vm.isExternalLink,
                'collapsed': _vm.collapsed
            }}):(_vm.isExternalLink)?_c('svg',{staticClass:"svg-icon",attrs:{"viewBox":"2 2 20 20"}},[_c('path',{attrs:{"d":"M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z"}})]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }