import { Component, Prop, Vue } from 'vue-property-decorator';
import '../../initialization';
import { accessor } from '../../store';
import { ISelectedIndicatorItems, IWidgetChartActual } from '../../store/types';
import queue from '../queue';
import { IGrowIndex } from '../common';
import { I18nDateTemplate, I18nTemplate } from '@/services/i18n';



@Component
export default class CContentChartActual extends Vue {
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;


    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: IWidgetChartActual;

    private shownData: IGrowIndex[] = [];

    // eslint-disable-next-line @typescript-eslint/camelcase
    private ru_months = [
        'январь',
        'февраль',
        'март',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь'
    ];

    private created() {
        this.$watch('getShownData', this.selectedMapChanged);
    }

    private mounted() {
        this.selectedMapChanged();
    }

    private beforeDestroy() {
        queue.clear(this);
    }

    public get indicatorConfigs() {
        return this.data.indicatorConfigs;
    }

    private getIndicator(key: string) {
        const list = accessor.indicators;
        const indicator = list.find(x => x.newKey === key);
        if (indicator === undefined) {
            return undefined;
        }
        return indicator.key;
    }

    public get selectedMap(): Map<string, ISelectedIndicatorItems> {
        const result = new Map<string, ISelectedIndicatorItems>();

        this.indicatorConfigs.forEach((key) => {
            let selectedIndicator = accessor.selectedMap.get(key.indicator);
            if (selectedIndicator === undefined) {
                const newKey: any = this.getIndicator(key.indicator);
                selectedIndicator = accessor.selectedMap.get(newKey);
            }
            if (selectedIndicator !== undefined) {
                result.set(key.indicator, selectedIndicator);
            }
        });

        return result;
    }

    private selectedMapChanged() {
        queue.schedule(this, () => {
            this.shownData = this.getShownData;
        });
    }

    private currentDate = '';

    public get getShownData(): IGrowIndex[] {
        const result: IGrowIndex[] = [];

        this.indicatorConfigs.forEach((indicatorKey) => {
            let selectedItems = accessor.getIndicator(indicatorKey.indicator);
            if (selectedItems === undefined) {
                const newKey: any = this.getIndicator(indicatorKey.indicator);
                selectedItems = accessor.getIndicator(newKey);
            }
            if (selectedItems !== undefined) {
                const items = selectedItems.items.sort((a: any, b: any) => a.date - b.date);
                const d = items.find(x => x.date.getFullYear() === new Date().getFullYear());
                const p = items.find(x => x.date.getFullYear() === new Date().getFullYear() - 1);
                let current = items[items.length - 1];
                if (d !== undefined) {
                    current = d;
                }
                if (current !== undefined) {
                    const confUnit: any = this.indicatorConfigs[0].unit;
                    let unit: any;
                    if ((Object.keys(confUnit).length === 0)) {
                        unit = selectedItems.unit;
                    } else if ((confUnit.name_ru === '')) {
                        unit = selectedItems.unit;
                    } else {
                        unit = confUnit.name_ru;
                    }
                    let unitLowerCased = '';
                    if (unit !== null || unit !== undefined) {
                        unitLowerCased = unit.toLowerCase();
                        if (unitLowerCased === 'процент') {
                            unitLowerCased = '%';
                        }
                    }
                    let measurement = 1;
                    if (this.indicatorConfigs[0].measurement !== undefined) {
                        measurement = this.indicatorConfigs[0].measurement;
                    }
                    const currentYear = String(current.date.getFullYear());
                    let currentMonth = String(current.date.getMonth() + 1);
                    let currentDay = String(current.date.getDate());
                    if (parseInt(currentDay) < 10) {
                        currentDay = '0' + currentDay;
                    }
                    if (parseInt(currentMonth) < 10) {
                        currentMonth = '0' + currentMonth;
                    }
                    if (selectedItems.periodicity === 'год') {
                        this.currentDate = currentYear + ' г.';
                    }
                    if (selectedItems.periodicity === 'месяц') {
                        this.currentDate = this.ru_months[parseInt(currentMonth) - 1] + ' ' + currentYear + 'г.';
                    }
                    if (selectedItems.periodicity === 'месяц с накоплением') {
                        if (currentMonth === '01') {
                            this.currentDate = this.ru_months[parseInt(currentMonth) - 1] + ' ' + currentYear + 'г.';
                        } else {
                            this.currentDate = this.ru_months[0] + '-' + this.ru_months[parseInt(currentMonth) - 1] + ' ' + currentYear + 'г.';
                        }
                    }
                    if (selectedItems.periodicity === 'квартал') {
                        if (parseInt(currentMonth) <= 3) {
                            currentMonth = '1 квартал';
                        } else if ((parseInt(currentMonth) > 3) && (parseInt(currentMonth) <= 6)) {
                            currentMonth = '2 квартал';
                        } else if ((parseInt(currentMonth) > 6) && (parseInt(currentMonth) <= 9)) {
                            currentMonth = '3 квартал';
                        } else if ((parseInt(currentMonth) > 9) && (parseInt(currentMonth) <= 12)) {
                            currentMonth = '4 квартал';
                        }
                        this.currentDate = currentMonth + ' ' + currentYear + 'г.';
                    }
                    if (selectedItems.periodicity === 'квартал с накоплением') {
                        if (parseInt(currentMonth) <= 3) {
                            currentMonth = '1 квартал';
                        } else if ((parseInt(currentMonth) > 3) && (parseInt(currentMonth) <= 6)) {
                            currentMonth = '2 квартал';
                        } else if ((parseInt(currentMonth) > 6) && (parseInt(currentMonth) <= 9)) {
                            currentMonth = '3 квартал';
                        } else if ((parseInt(currentMonth) > 9) && (parseInt(currentMonth) <= 12)) {
                            currentMonth = '4 квартал';
                        }
                        if (currentMonth === '1 квартал') {
                            this.currentDate = currentMonth + ' ' + currentYear + 'г.';
                        } else {
                            this.currentDate = '1-' + currentMonth + ' ' + currentYear + 'г.';
                        }
                    }
                    if (selectedItems.periodicity === 'день' || selectedItems.periodicity === 'неделя') {
                        this.currentDate = currentDay + ' ' + this.ru_months[parseInt(currentMonth) - 1] + ' ' + currentYear + 'г.';
                    }
                    // this.currentDate = currentDay + '/' + currentMonth + '/' + currentYear;
                    const value = Math.round((current.fact * measurement) * 10) / 10; // this.$n(current.fact, 'number');
                    const character = selectedItems.character;
                    let previous = items[items.length - 2];
                    if ((p !== undefined) && (d !== undefined)) {
                        previous = p;
                    }
                    const warning = new I18nTemplate('modules.widgetYard.widget.textBars.notActualWarning',
                        new I18nDateTemplate(current.periodStart, 'short'),
                        new I18nDateTemplate(current.periodEnd, 'short'));

                    let growProc = null;
                    let growMark = 1;
                    if (previous !== undefined) {
                        growProc = Math.round(((current.fact / previous.fact) * 100 - 100) * 100) / 100;
                        if (unitLowerCased === '%') {
                            growProc = Math.round((current.fact - previous.fact) * 100) / 100;
                        }
                        if (character === 0) {
                            growMark = character;
                        } else {
                            if (growProc === 0) {
                                growMark = character;
                            } else {
                                growMark = character * growProc;
                            }
                        }
                    }
                    if (growProc !== null) {
                        growProc = Math.abs(growProc);
                    }
                    const data = { key: selectedItems.key,
                        title: selectedItems.shortIndicator,
                        periodicity: selectedItems.periodicity,
                        digit: null,
                        warning,
                        character: character,
                        previous,
                        current,
                        value,
                        max: 0,
                        unit: unitLowerCased,
                        growProc,
                        growMark };

                    if (data !== null) {
                        result.push(data);
                    }
                }
            }
        });

        return result;
    }
}