




































import Class from './c-content-chart-gantt';
export default Class;
