import { Component, Prop, Vue } from 'vue-property-decorator';

import { Dates } from '@/utils';
import { TWeekDay } from '@/utils/dates';


interface IRangeConfig {
    date: Date;
    firstDayOfWeek: TWeekDay;
}

interface IRange {
    start: Date;
    end: Date;
}

interface IDayCell {
    key: number;
    value: Date;
    currentYear: boolean;
    currentMonth: boolean;
    current: boolean;
    lessThanMin: boolean;
    greaterThanMax: boolean;
    outOfRange: boolean;
    marked: boolean;
}


@Component
export default class CCalendar extends Vue {
    // #region Lifecycle
    private created(): void {
        this.$watch('valueDate', this.valueDateChanged);
        this.$watch('minDate', this.minDateChanged);
        this.$watch('maxDate', this.maxDateChanged);

        this.updateLocalValue();
    }
    // #endregion


    // #region Value
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly value!: Date | null;

    public get valueDate(): Date | null {
        if (this.value === null) {
            return null;
        }

        const result = new Date(this.value);
        result.clearTimePart();
        return result;
    }

    private valueDateChanged(): void {
        this.updateLocalValueDelayed();
    }
    // #endregion


    // #region Min
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly min!: Date | null;

    public get minDate(): Date {
        if (this.min === null) {
            return Dates.minDate;
        }

        const result = new Date(this.min);
        result.clearTimePart();
        return result;
    }

    private minDateChanged(): void {
        this.updateLocalValueDelayed();
    }
    // #endregion


    // #region Max
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly max!: Date | null;

    public get maxDate(): Date {
        if (this.max === null) {
            return Dates.maxDate;
        }

        const result = new Date(this.max);
        result.clearTimePart();
        return result;
    }

    private maxDateChanged(): void {
        this.updateLocalValueDelayed();
    }
    // #endregion


    // #region Marked
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly marked!: Date | null;

    public get markedDate(): Date | null {
        if (this.marked === null) {
            return null;
        }
        return new Date(this.marked).clearTimePart();
    }
    // #endregion


    // #region Locale
    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly locale!: string | null;

    public get actualLocale(): string {
        if (this.locale === null) {
            return 'en';
        }
        return this.locale;
    }

    public get dayOfWeekFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.actualLocale, { weekday: 'short' });
    }
    // #endregion


    // #region First day of week
    @Prop({
        type: Number,
        required: false,
        default: null
    })
    public readonly firstDayOfWeek!: number | null;

    public get actualFirstDayOfWeek(): TWeekDay {
        if (this.firstDayOfWeek === null) {
            return TWeekDay.MONDAY;
        }
        if ((this.firstDayOfWeek >= TWeekDay.SUNDAY) && (this.firstDayOfWeek <= TWeekDay.SATURDAY)) {
            return this.firstDayOfWeek;
        }
        return TWeekDay.MONDAY;
    }
    // #endregion


    // #region Days of week
    public get daysOfWeek(): string[] {
        const date = new Date();
        while (date.getDay() !== this.actualFirstDayOfWeek) {
            date.changeDate(-1);
        }

        const format = this.dayOfWeekFormat;

        const result: string[] = [];
        for (let i = 0; i < 7; i++) {
            const dayName = format.format(date);
            result.push(dayName);
            date.changeDate(1);
        }
        return result;
    }
    // #endregion


    // #region Local value
    private localValue = new Date().clearTimePart();
    private localValueUpdateHandle: number | undefined;

    private updateLocalValue(): void {
        if (this.minDate > this.maxDate) {
            console.error(`Min date ${this.minDate.toDateString()} is greater than max date ${this.maxDate.toDateString()}`);
            return;
        }
        if (this.valueDate === null) {
            if (this.localValue < this.minDate) {
                this.localValue = new Date(this.minDate);
            } else if (this.localValue > this.maxDate) {
                this.localValue = new Date(this.maxDate);
            }
        } else {
            if (this.valueDate < this.minDate) {
                console.error(`Value date ${this.valueDate.toDateString()} is less than min ${this.minDate.toDateString()}`);
                return;
            }
            if (this.valueDate > this.maxDate) {
                console.error(`Value date ${this.valueDate.toDateString()} is greater than max date ${this.maxDate.toDateString()}`);
            }
            if (this.localValue.getTime() === this.valueDate.getTime()) {
                return;
            }

            this.localValue = new Date(this.valueDate);
        }
    }

    private updateLocalValueDelayed(): void {
        if (this.localValueUpdateHandle === undefined) {
            this.localValueUpdateHandle = setTimeout(() => {
                this.localValueUpdateHandle = undefined;
                this.updateLocalValue();
            });
        }
    }
    // #endregion


    // #region Range
    public get rangeConfig(): IRangeConfig {
        return {
            date: this.localValue,
            firstDayOfWeek: this.actualFirstDayOfWeek
        };
    }

    public get range(): IRange {
        const firstDayOfWeek = this.rangeConfig.firstDayOfWeek;
        const start = new Date(this.rangeConfig.date).setDateChained(1);

        while (start.getDay() !== firstDayOfWeek) {
            start.changeDate(-1);
        }

        const end = new Date(start).changeDate(7 * 6);

        return { start, end };
    }
    // #endregion


    // #region Day cells
    public get dayCells(): IDayCell[] {
        const { start, end } = this.range;
        const date = new Date(start);

        const year = this.localValue.getFullYear();
        const month = this.localValue.getMonth();
        const currentMs = this.localValue.getTime();
        const minMs = this.minDate.getTime();
        const maxMs = this.maxDate.getTime();

        const result: IDayCell[] = [];
        // eslint-disable-next-line no-unmodified-loop-condition
        while (date < end) {
            const valueMs = date.getTime();
            const value = new Date(valueMs);

            const currentYear = (value.getFullYear() === year);
            const currentMonth = (currentYear && (value.getMonth() === month));
            const current = (valueMs === currentMs);

            const lessThanMin = (valueMs < minMs);
            const greaterThanMax = (valueMs > maxMs);
            const outOfRange = (lessThanMin || greaterThanMax);

            const marked = (this.markedDate !== null) && (date.datePartEquals(this.markedDate));

            const dayCell: IDayCell = { key: valueMs, value, currentYear, currentMonth, current, lessThanMin, greaterThanMax, outOfRange, marked };
            result.push(dayCell);

            date.changeDate(1);
        }

        return result;
    }
    // #endregion
}