export const getNodePath = (ev: Event): Node[] => {
    const result: Node[] = [];

    if (ev.target instanceof Node) {
        let node: Node | null = ev.target;
        while (node !== null) {
            result.push(node);
            node = node.parentNode;
        }
    }

    return result;
};

export const getElementPath = (ev: Event): HTMLElement[] => {
    const result: HTMLElement[] = [];

    if (ev.target instanceof Node) {
        let element: HTMLElement | null;
        if (ev.target instanceof HTMLElement) {
            element = ev.target;
        } else {
            element = ev.target.parentElement;
        }

        while (element !== null) {
            result.push(element);
            element = element.parentElement;
        }
    }

    return result;
};