








































import { Vue, Component } from 'vue-property-decorator';
import App from '@/App.vue';
import store from '@/services/store';
import { accessor as moduleAccessor } from './module';
import { TMenuItem, IBudgetMenuData } from './types';
import { Route } from 'vue-router';


@Component
export default class CBoardLeft extends Vue {
    private openBoardListener: (() => void) | null = null;
    private collapsed = false;

    private get items(): TMenuItem[] {
        return moduleAccessor.items;
    }

    private get route(): Route {
        return this.$router.currentRoute;
    }

    private get budgetMenuSource(): Array<Record<string, any>> {
        return store.state.menuBudget;
    }

    private get budgetMenuDataList(): IBudgetMenuData[] {
        const result: IBudgetMenuData[] = [];

        if (Array.isArray(this.budgetMenuSource)) {
            this.budgetMenuSource.forEach((item, itemIndex) => {
                if (!(item instanceof Object)) {
                    console.error(`Source of menu "Budget": source[${itemIndex}] is not instance of object; source[${itemIndex}]:`, item);
                    return;
                }

                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                const href: string = item.href;
                if (typeof href !== 'string') {
                    console.error(`Source of menu "Budget": source[${itemIndex}].href is not string; source[${itemIndex}]:`, item);
                    return;
                }

                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                const i18n: string = item.i18n;
                if (typeof i18n !== 'string') {
                    console.error(`Source of menu "Budget": source[${itemIndex}].i18n is not string; source[${itemIndex}]:`, item);
                    return;
                }

                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                const id: string = item.id;
                if (typeof id !== 'string') {
                    console.error(`Source of menu "Budget": source[${itemIndex}].id is not string; source[${itemIndex}]:`, item);
                    return;
                }

                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                result.push(item);
            });
        } else {
            console.error('Source of budget menu: source is not array', this.budgetMenuSource);
        }

        return result;
    }

    private created() {
        this.openBoardListener = () => {
            this.onOpenBoard();
        };
        this.$root.$on('openBoard', this.openBoardListener);

        this.$watch('$route', () => {
            moduleAccessor.updateActiveItems(this.$router);
        });
        this.$watch('route', () => {
            moduleAccessor.updateActiveItems(this.$router);
        });
        this.$watch('$router', () => {
            moduleAccessor.updateActiveItems(this.$router);
        });
        this.$watch('items', () => {
            moduleAccessor.updateActiveItems(this.$router);
        });
        this.$watch('budgetMenuDataList', () => {
            moduleAccessor.budgetMenuSourceChanged(this.$router, this.budgetMenuDataList);
        });
    }

    private mounted() {
        setTimeout(() => {
            moduleAccessor.updateActiveItems(this.$router);
            moduleAccessor.initialize();
            moduleAccessor.budgetMenuSourceChanged(this.$router, this.budgetMenuDataList);
        });
    }

    private destroyed() {
        if (this.openBoardListener !== null) {
            this.$root.$off('openBoard', this.openBoardListener);
        }
    }

    private openBoard() {
        this.$root.$emit('openBoard', true);
    }

    private onOpenBoard() {
        // eslint-disable-next-line no-shadow
        let parent: (Vue | undefined) = this.$parent;
        while (parent instanceof Vue) {
            if (parent instanceof App) {
                break;
            } else {
                parent = parent.$parent;
            }
        }

        if (parent instanceof App) {
            this.collapsed = parent.active;
        }
    }
}
