













































































































import Class from './c-modal';
export default Class;
