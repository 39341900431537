import { FunctionalComponentOptions, VNode } from 'vue';

import variants from './variants';


interface IProps {
    icon: string | null;
    svg: boolean;
}


const options: FunctionalComponentOptions<IProps> = {
    functional: true,

    props: {
        icon: {
            type: String,
            required: false,
            default: null
        },
        svg: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    render: (h, context): VNode => {
        let icon: string | null = null;
        if (typeof context.props.icon === 'string') {
            icon = context.props.icon;
        }

        if ((context.children !== undefined) && context.children.isNotEmpty) {
            let childrenText = '';
            context.children.forEach((child) => {
                if (typeof child.text === 'string') {
                    childrenText += child.text.trim();
                }
            });

            icon = childrenText;
        }

        if (icon === null) {
            console.warn('Cannot define icon', context);
            return h();
        }
        icon = icon.trim().toLowerCase();


        if (context.props.svg) {
            const variant = variants.get(icon);
            if (variant !== undefined) { return variant(h, context); }
        }

        if (typeof context.data.staticClass !== 'string') { context.data.staticClass = ''; }
        context.data.staticClass += ` mdi mdi-${icon}`;
        return h(
            'i',
            context.data
        );
    }
};
export default options;