import { Component, Model, Prop, Vue } from 'vue-property-decorator';

import theme from '@/services/theme';

import { TModalHideReason } from '../types';

import ModalHideEvent from './modal-hide-event';


// #region Portal
let portal: HTMLDivElement | undefined;

const addToPortal = (el: Element) => {
    if (portal === undefined) {
        portal = document.createElement('div');
        portal.classList.add('c-modal--portal');
        document.body.appendChild(portal);
    }

    portal.appendChild(el);
};

const rescanPortal = () => {
    if ((portal !== undefined) && (!portal.hasChildNodes())) {
        document.body.removeChild(portal);
        portal = undefined;
    }
};
// #endregion

const events = {
    model: 'change',
    hide: 'hide',
    overlayClick: 'overlay-click',
    closeClick: 'close-click',
    okClick: 'ok-click',
    cancelClick: 'cancel-click',
    hidden: 'hidden'
};


@Component
export default class CModal extends Vue {
    private windowWidth = 0;
    private windowHeight = 0;
    private windowResizeListener!: () => void;


    // #region Lifecycle
    private created() {
        this.windowResizeListener = () => {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        };

        this.$watch('visible', this.visibleChanged);
        this.$watch('localVisible', this.localVisibleChanged);
        this.$watch('overlayOpacityTimeout', this.overlayOpacityTimeoutChanged);
        this.$watch('windowContainerTranslateTimeout', this.windowContainerTranslateTimeoutChanged);

        window.addEventListener('resize', this.windowResizeListener);
        this.windowResizeListener();
    }

    private mounted() {
        addToPortal(this.$el);

        this.updateToolbar();

        if (this.$el instanceof HTMLElement) {
            this.$el.style.display = '';
            this.$el.getBoundingClientRect();

            const windowContainer = this.getWindowContainer();
            if (windowContainer !== undefined) {
                windowContainer.getBoundingClientRect();
            }

            const overlay = this.getOverlay();
            if (overlay !== undefined) {
                overlay.getBoundingClientRect();
            }

            setTimeout(() => {
                if (this.$el instanceof HTMLElement) {
                    this.$el.style.display = 'none';
                }
            });
        }

        if (this.visible === null) {
            this.localVisible = true;
        } else {
            this.localVisible = this.visible;
        }
    }

    private updated() {
        this.updateToolbar();
    }

    private beforeDestroy() {
        const el = this.$el;
        if ((el instanceof HTMLElement) && (el.parentElement === portal)) {
            portal.removeChild(el);
        }
        window.removeEventListener('resize', this.windowResizeListener);
    }

    private destroyed() {
        rescanPortal();
    }
    // #endregion


    // #region Elements
    private getOverlay(): HTMLElement | undefined {
        const result = this.$refs.overlay;
        if (result instanceof HTMLElement) {
            return result;
        }
        return undefined;
    }

    private getWindowContainer(): HTMLElement | undefined {
        const result = this.$refs.windowContainer;
        if (result instanceof HTMLElement) {
            return result;
        }
        return undefined;
    }

    private getWindow(): HTMLElement | undefined {
        const result = this.$refs.window;
        if (result instanceof HTMLElement) {
            return result;
        }
        return undefined;
    }
    // #endregion


    private get themeDark(): boolean {
        return theme.dark;
    }


    private hasToolbar = false;

    private updateToolbar() {
        const hasToolbar = (this.$slots.toolbar !== undefined);
        if (this.hasToolbar !== hasToolbar) {
            this.hasToolbar = hasToolbar;
        }
    }


    // #region "visible"
    @Model(events.model, {
        type: Boolean,
        required: false,
        default: null
    })
    public readonly visible!: boolean | null;

    private visibleChanged() {
        if (this.visible !== null) {
            if (this.localVisible !== this.visible) {
                this.localVisible = this.visible;
            }
        }
    }
    // #endregion

    // #region "localVisible"
    private localVisible = false;
    private animationStartTimeout: number | undefined;

    private localVisibleChanged() {
        if (this.visible !== this.localVisible) {
            this.$emit(events.model, this.localVisible);
        }

        if (!this.inTransition) {
            this.inTransition = true;
        }

        if (this.animationStartTimeout !== undefined) {
            clearTimeout(this.animationStartTimeout);
        }

        if (this.$el instanceof HTMLElement) {
            this.$el.style.display = '';
            this.$el.getBoundingClientRect();
        }

        let maxTranslate = `translateY(-${window.innerHeight + 25}px)`;
        (() => {
            const localWindow = this.getWindow();
            if (localWindow !== undefined) {
                const windowHeight = localWindow.offsetHeight;
                maxTranslate = `translateY(-${(windowHeight + window.innerHeight) / 2 + 25}px)`;
            }
        })();

        this.animationStartTimeout = setTimeout(() => {
            const overlay = this.getOverlay();
            const windowContainer = this.getWindowContainer();

            if (this.localVisible) {
                this.overlayOpacity = 0;
                if (overlay !== undefined) {
                    overlay.getBoundingClientRect();
                }
                this.overlayOpacityTimeout = setTimeout(() => {
                    this.overlayOpacity = 1;
                });

                if (windowContainer !== undefined) {
                    windowContainer.style.transform = maxTranslate;
                    windowContainer.getBoundingClientRect();
                }
                this.windowContainerTranslateTimeout = setTimeout(() => {
                    if (windowContainer !== undefined) {
                        windowContainer.style.transform = 'translateY(0)';
                    }
                });
            } else {
                this.overlayOpacity = 1;
                if (overlay !== undefined) {
                    overlay.getBoundingClientRect();
                }
                this.overlayOpacityTimeout = setTimeout(() => {
                    this.overlayOpacity = 0;
                });

                if (windowContainer !== undefined) {
                    windowContainer.style.transform = 'translateY(0)';
                    windowContainer.getBoundingClientRect();
                }
                this.windowContainerTranslateTimeout = setTimeout(() => {
                    if (windowContainer !== undefined) {
                        windowContainer.style.transform = maxTranslate;
                    }
                });
            }
        });
    }
    // #endregion


    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    private noHeader!: boolean;

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    private noFooter!: boolean;

    // #region transition data
    private overlayOpacity = 0;
    private overlayOpacityTimeout: number | null = null;
    private windowContainerTranslateTimeout: number | null = null;
    private inTransition = false;

    private overlayOpacityTimeoutChanged(n: number | null, o: number | null) {
        if (o !== null) {
            clearTimeout(o);
        }
    }

    private windowContainerTranslateTimeoutChanged(n: number | null, o: number | null) {
        if (o !== null) {
            clearTimeout(o);
        }
    }
    // #endregion


    // #region Visibility
    private get shown(): boolean {
        return this.localVisible || this.inTransition;
    }
    // #endregion


    // #region Events
    private onTransitionStart() {
        // may be useful
    }

    private onTransitionEnd() {
        if (this.inTransition) {
            this.inTransition = false;
        }

        if (!this.localVisible) {
            this.$emit(events.hidden);
        }
    }

    private onHide(reason: TModalHideReason) {
        const ev = new ModalHideEvent(reason);

        this.$emit(events.hide, ev);

        if ((!ev.defaultPrevented) && this.localVisible) {
            this.localVisible = false;
            this.$emit(reason);
        }
    }

    private onOverlayClick() {
        this.onHide('overlay-click');
    }

    private onWindowContainerClick(ev: Event) {
        if (ev.target === this.$refs.windowContainer) {
            this.onOverlayClick();
        }
    }

    private onCloseButtonClick() {
        this.onHide('close-click');
    }

    private onOkButtonClick() {
        this.onHide('ok-click');
    }

    private onCancelButtonClick() {
        this.onHide('cancel-click');
    }
    // #endregion
}