

















import Class from './c-content-chart-model-data';
export default Class;
