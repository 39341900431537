import { Component, Prop, Vue } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { ILastResult } from '../types';
import ChartGrouped from 'highcharts-grouped-categories';
import MultiColor from 'highcharts-multicolor-series';

interface ISeries {
    name: string;
    type: string;
    data: object;
    tooltip: object;
    yAxis: undefined | number;
}
interface ILabel {
    labels: object;
    title: object;
    opposite: boolean;
    maxPadding: number;
}

HighchartsMore(Highcharts);
ChartGrouped(Highcharts);
MultiColor(Highcharts);

Highcharts.setOptions({
    lang: {
        loading: 'Загрузка...',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
        thousandsSep: ' ',
        decimalPoint: ','
    }
});


@Component
export default class ChartModelLinear extends Vue {
    private options: Highcharts.Options = {};

    @Prop({
        type: Array,
        required: true
    })
    private data!: ILastResult[];
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly full!: boolean;
    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly sendToChart!: any;

    @Prop()
    public readonly redrawTrigger!: any;

    private redrawTriggerChanged() {
        if (this.chart !== undefined) {
            this.chart.reflow();
        }
    }

    private formatDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric'
        });

        return format.format(date);
    }
    private quarterDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric',
            month: 'long'
        });

        return format.format(date);
    }
    private setOptions() {
        if (this.data[0] === undefined) {
            return null;
        }
        const n = (value: number): string => {
            return this.$n(value, 'number');
        };
        const colors = ['#459ED8', '#953735', '#e46c0a', '#416084', '#449fd8', '#c0504d', '#f79646', '#5a80ad', '#195275', '#632523', '#984807', '#2c4058'];
        const newColors = ['#ccebff', '#ffc8c7', '#ffd3b0', '#c7e1ff'];
        const test: Highcharts.SeriesOptionsType[] = [];
        const label: ILabel[] = [];
        let xLine: any = {};
        const url: any = this.$router;
        const windowHeight = window.innerHeight;
        let hChart: any = 240;
        let fontSize = '12px';
        let fontSizeYear = '14px';
        let legendFontSize = '14px';
        if (url.history.current.path === '/') {
            hChart = 260;
        }
        if (this.full) {
            hChart = windowHeight - 265;
            fontSize = '20px';
            fontSizeYear = '20px';
            legendFontSize = '20px';
        }
        const showLegend = true;
        const periodicity = this.data[0].data?.periodicity;
        for (let i = 0; i < this.data.length; i++) {
            const elem = this.data[i];
            const datas = elem.data;
            if (datas === null) {
                return null;
            }
            // описание грaфика по yAxis
            const labels: object = {
                format: '{value}',
                style: {
                    color: colors[i],
                    fontSize: 0
                }
            };
            const title: object = {
                text: datas.unit,
                style: {
                    color: colors[i],
                    fontSize: 0
                }
            };
            let opposite = false;
            if ((i % 2 === 0)) {
                opposite = true;
            }
            const maxPadding = 0.1;
            label.push({ labels, title, opposite, maxPadding });
            // создание графика(series)
            const data: any = [];
            const type = 'coloredline';
            const tooltip: object = {
                valueSuffix: ' '
            };
            const color = colors[i];
            const dataLabels = {
                y: 0
            };
            const legendIndex = i;
            let names = elem.title + ', ' + datas.unit;
            if (datas.unit === null) {
                names = datas.name;
            }
            if (datas.periodicity === 'год') {
                const categories: any = [];
                const date = new Date();
                const year = date.getFullYear();
                datas.items.forEach((item) => {
                    const itemDate = item.date;
                    const itemDateText = this.formatDate(itemDate);
                    const count = Number(itemDateText);
                    categories.push(count);
                    let lineColor = colors[i];
                    if (year - 1 < count) {
                        lineColor = newColors[i];
                    }
                    data.push({
                        'x': count,
                        'y': item.value,
                        'segmentColor': lineColor,
                        'color': lineColor
                    });
                });
                xLine = {
                    type: 'category',
                    tickWidth: 0,
                    labels: {
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: fontSizeYear,
                            fontWeight: 400
                        }
                    },
                    tickInterval: 1
                };
            }
            if (datas.periodicity === 'неделя') {
                const categories: any = [];
                datas.items.forEach((item) => {
                    const year = item.date.getFullYear().toString().substr(-2);
                    let month = (item.date.getMonth() + 1).toString();
                    let day = item.date.getDate().toString();
                    if (month.length === 1) {
                        month = '0' + month;
                    }
                    if (day.length === 1) {
                        day = '0' + day;
                    }
                    const d = `${day}/${month}/${year}`;
                    categories.push(d);
                    data.push(item.value);
                });
                xLine = {
                    labels: {
                        useHTML: true,
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '10px',
                            fontWeight: 600
                        }
                    },
                    categories: categories,
                    tickInterval: 1
                };
            }
            if (datas.periodicity === 'квартал' || datas.periodicity === 'квартал с накоплением') {
                const categories: any[] = [];
                const milliseconds: number[] = [];
                const date = new Date();
                const currentM = date.getMonth();
                const currentY = date.getFullYear();
                datas.items.forEach((item, index) => {
                    const itemDate = item.date;
                    let lineColor = colors[i];
                    const year = itemDate.getFullYear();
                    let month = itemDate.getMonth() + 1;
                    const monthCur = itemDate.getMonth();
                    if (month <= 3) {
                        month = 'I';
                    } else if ((month > 3) && (month <= 6)) {
                        month = 'II';
                    } else if ((month > 6) && (month <= 9)) {
                        month = 'III';
                    } else if ((month > 9) && (month <= 12)) {
                        month = 'IV';
                    }
                    const d = month + '-' + (String(year)).substr(2);
                    if (currentY < year) {
                        lineColor = newColors[i];
                    } else if (currentY === year) {
                        if (currentM < monthCur + 1) {
                            lineColor = newColors[i];
                        }
                    }
                    categories.push(d);
                    const milli = Date.parse(itemDate);
                    milliseconds.push(milli);
                    data.push({
                        'y': item.value,
                        'segmentColor': lineColor,
                        'color': lineColor
                    });
                });
                categories.sort((a: any, b: any) => parseInt(a.name) - parseInt(b.name));
                xLine = {
                    type: 'category',
                    tickWidth: 0,
                    labels: {
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '14px',
                            fontWeight: 400
                        }
                    },
                    categories: categories
                };
            }
            if (datas.periodicity === 'месяц' || datas.periodicity === 'месяц с накоплением') {
                const categories: any = [];
                const milliseconds: number[] = [];
                const date = new Date();
                const currentM = date.getMonth();
                const currentY = date.getFullYear();
                datas.items.forEach((item) => {
                    const itemDate = item.date;
                    let lineColor = colors[i];
                    const shortMonthNames = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];
                    const year = itemDate.getFullYear();
                    const month = itemDate.getMonth();
                    const d = shortMonthNames[month] + '/' + (String(year)).substr(2);
                    if (currentY < year) {
                        lineColor = newColors[i];
                    } else if (currentY === year) {
                        if (currentM < month + 1) {
                            lineColor = newColors[i];
                        }
                    }
                    categories.push(d);
                    const milli = Date.parse(itemDate);
                    milliseconds.push(milli);
                    data.push({
                        'y': item.value,
                        'segmentColor': lineColor,
                        'color': lineColor
                    });
                });
                xLine = {
                    type: 'category',
                    tickWidth: 0,
                    labels: {
                        useHTML: true,
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '12px',
                            fontWeight: 600
                        },
                        // eslint-disable-next-line consistent-return
                        formatter() {
                            // eslint-disable-next-line @typescript-eslint/no-this-alias
                            const that: any = this;
                            if (that.pos % 2 !== 0) {
                                return that.value;
                            }
                        }
                    },
                    tickInterval: 1,
                    categories: categories
                };
            }
            test.push({ name: names, type, data, tooltip, legendIndex, color, dataLabels, yAxis: 0 } as any);
            test.forEach((item, index) => {
                if (datas.diffAxis === true) {
                    item.yAxis = index;
                }
            });
        }
        this.options = {
            colors,
            chart: {
                backgroundColor: 'transparent',
                spacing: [0, 0, 0, 0],
                height: hChart,
                style: {
                    fontFamily: 'Manrope, sans-serif'
                }
            },
            title: {
                text: ''
            },
            subtitle: {
                text: String(periodicity),
                style: {
                    display: 'none'
                }
            },
            exporting: {
                enabled: false
            },
            xAxis: xLine,
            yAxis: label,
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: fontSize,
                            color: '#459ED8'
                        }
                    }
                },
                column: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true
                    }
                },
                series: {
                    dataLabels: {
                        enabled: true,
                        allowOverlap: false,
                        style: {
                            fontSize: '1.8rem',
                            fontWeight: '500',
                            textOutline: '0px'
                        },
                        formatter() {
                            const ser: any = this.series;
                            const point: any = this.point;
                            if (periodicity === 'месяц') {
                                const y: any = this.y;
                                return '<span style="color: ' + ser.color + '">' + (point.index % 2 !== 0 ? n(y) : '') + '</span>';
                            }
                            return '<span style="color: ' + ser.color + '">' + (this.y !== null ? n(this.y) : '') + '</span>';
                        }
                    },
                    animation: false
                }
            },
            credits: {
                enabled: false
            },
            tooltip: {
                shared: true
            },
            legend: {
                enabled: showLegend,
                x: 20,
                align: 'left',
                verticalAlign: 'top',
                itemStyle: {
                    fontWeight: '500',
                    fontSize: legendFontSize,
                    textOverflow: 'null'
                } /** ,
                labelFormatter: function () {
                    return '<span style="color:' + this.color + '">' + this.name + '</span>';
                } */
            },
            series: test
        };
        return this.options;
    }

    private chart: Highcharts.Chart | undefined;

    private dataChanged() {
        this.setOptions();
        if (this.chart === undefined) {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
        } else {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
        }
    }

    private downloadAction() {
        if (this.chart !== undefined) {
            if (this.download === 'print') {
                this.chart.print();
            }
            if (this.download === 'pdf') {
                this.chart.exportChart({
                    type: 'application/pdf'
                }, {});
            }
            if (this.download === 'jpeg') {
                this.chart.exportChart({
                    type: 'image/jpeg'
                }, {});
            }
            if (this.download === 'png') {
                this.chart.exportChart({
                    type: 'image/png'
                }, {});
            }
            if (this.download === 'svg') {
                this.chart.exportChart({
                    type: 'image/svg+xml'
                }, {});
            }
        }
    }

    private created() {
        this.$watch('data', this.dataChanged);
        this.$watch('redrawTrigger', this.redrawTriggerChanged);
        if (this.chart !== undefined) {
            this.chart.destroy();
        }
    }

    private mounted() {
        this.dataChanged();
        this.$watch('download', this.downloadAction);
    }

    private beforeDestroy() {
        if (this.chart !== undefined) {
            this.chart.destroy();
        }
    }
}