export const maxRange = 8640000000000000;
export const maxMilliseconds = maxRange;
export const minMilliseconds = -maxRange;
export const maxDateTime = new Date(maxMilliseconds);
export const minDateTime = new Date(minMilliseconds);
export const maxDate = new Date(maxDateTime)
    .clearTimePart();
export const minDate = new Date(minDateTime)
    .changeDate(1)
    .clearTimePart();

export enum TWeekDay {
    SUNDAY = 0,
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6
}

export enum EMonth {
    JANUARY = 0,
    FEBRUARY = 1,
    MARCH = 2,
    APRIL = 3,
    MAY = 4,
    JUNE = 5,
    JULY = 6,
    AUGUST = 7,
    SEPTEMBER = 8,
    OCTOBER = 9,
    NOVEMBER = 10,
    DECEMBER = 11
}

export enum EDateZoom {
    MONTH = 0,
    YEAR = 1,
    DECADE = 2,
    CENTURY = 3,
    YEARS_1000 = 4,
    YEARS_10000 = 5,
    YEARS_100000 = 6,
    YEARS_1000000 = 7
}

export const getDateZoom = (opts: { alias?: string; value: number; fallback: EDateZoom; min?: EDateZoom; max?: EDateZoom }): EDateZoom => {
    const { value, fallback } = opts;
    const alias = (opts.alias === undefined ? 'zoom' : opts.alias);
    const min = (opts.min === undefined ? EDateZoom.MONTH : opts.min);
    const max = (opts.max === undefined ? EDateZoom.YEARS_1000000 : opts.max);

    if (value.isNotInteger) {
        console.error(`Invalid ${alias} ${value} (not integer)`);
        return fallback;
    }

    if (value < min) {
        console.error(`Invalid ${alias} ${value} (less than min ${min})`);
        return min;
    }

    if (value > max) {
        console.error(`Invalid ${alias} ${value} (greater than max ${max})`);
        return max;
    }

    return value as EDateZoom;
};