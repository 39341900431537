import { Component, Vue, Prop } from 'vue-property-decorator';

import store from '@/services/store';
import '../../initialization';
import { accessor } from '../../store';

import { EWidgetType, ISelectedIndicatorItems, TWidget, IWidgetChartMultipleAxes, IWidgetChartBars, IWidgetChartBudget } from '../../store/types';

import CContentTextBars from '../c-content-text-bars';
import CContentTextLines from '../c-content-text-lines';
import CContentMeters from '../c-content-meters';
import CContentLinearMarkers from '../c-content-chart-linear-marker';
import CContentChartBars from '../c-content-chart-bars';
import CContentChart3dPie from '../c-content-chart-3d-pie';
import CContentChartFlatPie from '../c-content-chart-flat-pie';
import CContentChartRadiusPie from '../c-content-chart-radius-pie';
import CContentChartMultipleAxes from '../c-content-chart-multiple-axes';
import CContentChartStackColumn from '../c-content-chart-stack-column';
import CContentChartTreeMap from '../c-content-chart-tree-map';
import CContentChartActual from '../c-content-chart-actual';
import CContentChartGantt from '../c-content-chart-gantt';
import CContentChartBudget from '../c-content-chart-budget';
import CContentChartModelData from '../c-content-chart-model-data';
import CContentChartToneLine from '../c-content-chart-tone-line';
import CContentChartToneBar from '../c-content-chart-tone-bar';
import CContentChartSphereTonesBar from '../c-content-chart-sphere-tones-bar';
import CContentChartModelLinear from '../c-content-chart-model-linear';
import CContentChartCiBp from '../c-content-chart-ci-bp/c-content-chart-ci-bp.vue';
import CContentChartSnp from '../c-content-chart-snp';
import CContentChartMonitoring from '../c-content-chart-monitoring';
import CContentChartInvesting from '../c-content-chart-investing/c-content-chart-investing.vue';
import CContentChartRadiusPieForBudget from '@/modules/widget-yard/c-widget/c-content-chart-radius-pie-for-budget';
import CContentChartVillage from '@/modules/widget-yard/c-widget/c-content-chart-village/c-content-chart-village.vue';
import CContentChartInvestBar from '@/modules/widget-yard/c-widget/c-content-chart-invest-bar/c-content-chart-invest-bar.vue';
import CContentChartInvestRequest from '../c-content-chart-invest-request/c-content-chart-invest-request.vue';
import CContentChartInvestRequestList from '../c-content-chart-invest-request-list/c-content-chart-invest-request-list.vue';
import CContentChartInvestPrograms from '../c-content-chart-invest-programs/c-content-chart-invest-programs.vue';
import CContentChartCovid from '../c-content-chart-covid/c-content-chart-covid.vue';
import CContentChartVaccination from '../c-content-chart-vaccination/c-content-chart-vaccination.vue';
import CContentChartSoc from '../c-content-chart-soc/c-content-chart-soc.vue';




@Component({
    components: {
        'c-content-text-bars': CContentTextBars,
        'c-content-text-lines': CContentTextLines,
        'c-content-meters': CContentMeters,
        'c-content-linear-marker': CContentLinearMarkers,
        'c-content-chart-bars': CContentChartBars,
        'c-content-chart-3d-pie': CContentChart3dPie,
        'c-content-chart-flat-pie': CContentChartFlatPie,
        'c-content-chart-radius-pie': CContentChartRadiusPie,
        'c-content-chart-multiple-axes': CContentChartMultipleAxes,
        'c-content-chart-stack-column': CContentChartStackColumn,
        'c-content-chart-tree-map': CContentChartTreeMap,
        'c-content-chart-actual': CContentChartActual,
        'c-content-chart-gantt': CContentChartGantt,
        'c-content-chart-budget': CContentChartBudget,
        'c-content-chart-model-data': CContentChartModelData,
        'c-content-chart-model-linear': CContentChartModelLinear,
        'c-content-chart-tone-line': CContentChartToneLine,
        'c-content-chart-tone-bar': CContentChartToneBar,
        'c-content-chart-sphere-tones-bar': CContentChartSphereTonesBar,
        'c-content-chart-ci-bp': CContentChartCiBp,
        'c-content-chart-snp': CContentChartSnp,
        'c-content-chart-monitoring': CContentChartMonitoring,
        'c-content-chart-investing': CContentChartInvesting,
        'c-content-chart-radius-pie-for-budget': CContentChartRadiusPieForBudget,
        'c-content-chart-village': CContentChartVillage,
        'c-content-chart-invest-bar': CContentChartInvestBar,
        'c-content-chart-invest-request': CContentChartInvestRequest,
        'c-content-chart-invest-request-list': CContentChartInvestRequestList,
        'c-content-chart-invest-programs': CContentChartInvestPrograms,
        'c-content-chart-covid': CContentChartCovid,
        'c-content-chart-vaccination': CContentChartVaccination,
        'c-content-chart-soc': CContentChartSoc
    }
})
export default class CContent extends Vue {
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly full!: boolean;

    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: TWidget;

    @Prop()
    public readonly redrawTrigger!: any;

    @Prop()
    public subHeader!: false;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    private redrawAddition = 0;

    private fullSize = false;

    created() {
        this.$root.$on('fullSizeActive', () => {
            const self = this;
            setTimeout(function () {
                self.fullSize = !self.fullSize;
            }, 200)
        });
    }

    /* public mounted() {
        setTimeout(() => {
            this.redrawAddition = Date.now();
        });

        if (this.$route.path === '/soc-eco') {
            this.subHeader = true;
        } else {
            this.subHeader = false;
        }
    }

    public updated() {
        if (this.$route.path === '/soc-eco') {
            this.subHeader = false;
        } else {
            this.subHeader = true;
        }
    } */

    public get actualRedrawTrigger(): any {
        return {
            redrawTrigger: this.redrawTrigger,
            redrawAddition: this.redrawAddition
        };
    }

    public get isAuthorized(): boolean {
        return store.state.user.authorized;
    }

    public get id(): number {
        return this.data.id;
    }

    public get title(): string {
        if ((this.data.title.trim().length === 0) && (this.singleSelectedItem !== null)) {
            return this.singleSelectedItem.shortIndicator;
        }

        return this.data.title;
    }

    public get type(): EWidgetType {
        return this.data.type;
    }

    public get indicatorKeys(): string[] {
        if (this.data.type === EWidgetType.CHART_MULTIPLE_AXES) {
            const data = this.data as IWidgetChartMultipleAxes;
            const resArr: string[] = [];
            for (const indicatorConfig of data.indicatorConfigs) {
                resArr.push(indicatorConfig.indicator);
            }
            return resArr;
        }
        if (this.data.type === EWidgetType.CHART_BARS) {
            const data = this.data as IWidgetChartBars;
            const resArr: string[] = [];
            for (const indicatorConfig of data.indicatorConfigs) {
                resArr.push(indicatorConfig.indicator);
            }
            return resArr;
        }
        if (this.data.type === EWidgetType.CHART_BUDGET) {
            const data = this.data as IWidgetChartBudget;
            const resArr: string[] = [];
            for (const indicatorConfig of data.indicatorConfigs) {
                resArr.push(indicatorConfig.indicator);
            }
            return resArr;
        }
        return this.data.indicatorKeys;
    }

    public get singleSelectedItem(): ISelectedIndicatorItems | null {
        if (this.data.indicatorKeys.length === 1) {
            const result = accessor.selectedMap.get(this.data.indicatorKeys[0]);
            if (result !== undefined) {
                return result;
            }
        }
        return null;
    }
}