











































import Class from './c-content-chart-radius-pie';
export default Class;
