import { Vue, Component, Prop } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import Highcharts3D from 'highcharts/highcharts-3d';
import { IItem } from '../types';

HighchartsMore(Highcharts);
Highcharts3D(Highcharts);

Highcharts.setOptions({
    lang: {
        loading: 'Загрузка...',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
        thousandsSep: ' ',
        decimalPoint: ','
    }
});


@Component
export default class CView extends Vue {
    private lineOptions: Highcharts.Options = {};

    @Prop({
        type: Array,
        required: true
    })
    private data!: IItem[];

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    @Prop()
    public readonly redrawTrigger!: any;

    private redrawTriggerChanged() {
        if (this.chart !== undefined) {
            this.chart.reflow();
        }
    }

    private formatDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: '2-digit',
            month: 'short',
            day: 'numeric'
        });

        return format.format(date);
    }

    private updateOptions() {
        const colors = ['#459ED8', '#953735', '#e46c0a', '#416084', '#449fd8', '#c0504d', '#f79646', '#5a80ad', '#195275', '#632523', '#984807', '#2c4058'];
        const categories: string[] = [];
        const shortNamesFacts: Array<[string, number]> = [];


        this.data.forEach((item) => {
            const itemDate = item.date;
            const itemDateText = this.formatDate(itemDate);
            const unit = item.unit;
            let title = item.shortName + ', ' + unit;
            if (unit === null) {
                title = item.shortName;
            }
            categories.push(itemDateText);
            shortNamesFacts.push([title, item.value]);
            return shortNamesFacts;
        });

        this.lineOptions = {
            colors,
            chart: {
                type: 'pie',
                backgroundColor: 'transparent',
                spacing: [0, 0, 0, 0],
                height: 185,
                style: {
                    'float': 'left'
                }
            },
            title: {
                text: ' '
            },
            exporting: {
                enabled: false,
                menuItemDefinitions: {
                    // Custom definition
                    printChart: {
                        text: 'Печать'
                    },
                    downloadPNG: {
                        text: 'Скачать PNG'
                    },
                    downloadJPEG: {
                        text: 'Скачать JPEG'
                    },
                    downloadPDF: {
                        text: 'Скачать PDF'
                    },
                    downloadSVG: {
                        text: 'Скачать SVG'
                    }
                }
            },
            tooltip: {
                pointFormat: '{series.name} <b>{point.y}</b>'
            },
            plotOptions: {
                pie: {
                    center: [100, '50%'],
                    allowPointSelect: true,
                    cursor: 'pointer',
                    borderWidth: 0,
                    dataLabels: {
                        alignTo: 'connectors',
                        connectorShape: 'crookedLine',
                        crookDistance: '100%',
                        enabled: false,
                        format: '<span style="color:{point.color}">{point.percentage:.1f}%</span>',
                        style: {
                            fontSize: '1.2rem',
                            fontWeight: '300',
                            textOutline: '0px'
                        }
                    },
                    showInLegend: true
                },
                series: {
                    animation: false
                }
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'top',
                x: 200,
                y: 25,
                floating: true,
                itemStyle: {
                    fontWeight: '100',
                    fontSize: '12px',
                    textOverflow: 'null'
                },
                navigation: {
                    enabled: false
                },
                symbolRadius: 0
            },
            series: [{
                type: 'pie',
                name: '',
                size: '60%',
                data: shortNamesFacts
            }],
            credits: {
                enabled: false
            }
        };
    }

    private chart: Highcharts.Chart | undefined;

    private dataChanged() {
        this.updateOptions();
        if (this.chart === undefined) {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.lineOptions);
        } else {
            this.chart.update(this.lineOptions, true, true);
        }
    }

    private downloadAction() {
        if (this.chart !== undefined) {
            if (this.download === 'print') {
                this.chart.print();
            }
            if (this.download === 'pdf') {
                this.chart.exportChart({
                    type: 'application/pdf'
                }, {});
            }
            if (this.download === 'jpeg') {
                this.chart.exportChart({
                    type: 'image/jpeg'
                }, {});
            }
            if (this.download === 'png') {
                this.chart.exportChart({
                    type: 'image/png'
                }, {});
            }
            if (this.download === 'svg') {
                this.chart.exportChart({
                    type: 'image/svg+xml'
                }, {});
            }
        }
    }

    private created() {
        this.$watch('data', this.dataChanged);
        this.$watch('redrawTrigger', this.redrawTriggerChanged);
    }

    private mounted() {
        this.dataChanged();
        this.$watch('download', this.downloadAction);
    }

    private beforeDestroy() {
        if (this.chart !== undefined) {
            this.chart.destroy();
        }
    }
}