import { Component, Model, Prop, Vue } from 'vue-property-decorator';


const events = {
    model: 'change',
    zoom: 'zoom',
    visibility: 'visibility'
};

@Component
export default class CDatePicker extends Vue {
    // #region Lifecycle
    private created(): void {
        this.$watch('visible', this.visibleChanged);

        this.visibleChanged();
    }

    private mounted(): void {
        this.anchor = this.$refs.anchor as HTMLDivElement;
    }

    private beforeDestroy(): void {
        this.anchor = null;
    }
    // #endregion


    // #region Elements
    private anchor: HTMLDivElement | null = null;
    // #endregion


    // #region Variant
    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly variant!: string;
    // #endregion


    // #region Visible
    @Prop({
        type: Boolean,
        required: false,
        default: null
    })
    public readonly visible!: boolean | null;

    private visibleChanged(): void {
        if ((this.visible !== null) && (this.localVisible !== this.visible)) {
            this.localVisible = this.visible;
        }
    }

    private localVisible = false;

    private setVisible(value: boolean): void {
        if (this.visible === null) {
            if (this.localVisible !== value) {
                this.localVisible = value;
            }
        }
        if (this.visible !== value) {
            this.$emit(events.visibility, value);
        }
    }
    // #endregion


    // #region Portal
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly portal!: boolean;
    // #endregion


    // #region Zoom
    @Prop({
        type: Number,
        required: false,
        default: null
    })
    public readonly zoom!: number | null;
    // #endregion


    // #region Min zoom
    @Prop({
        type: Number,
        required: false,
        default: null
    })
    public readonly minZoom!: number | null;
    // #endregion


    // #region Max zoom
    @Prop({
        type: Number,
        required: false,
        default: null
    })
    public readonly maxZoom!: number | null;
    // #endregion


    private onZoomChange(zoom: number): void {
        if (this.zoom !== zoom) {
            this.$emit(events.zoom, zoom);
        }
    }


    // #region Value
    @Model(events.model, {
        type: Date,
        required: false,
        default: null
    })
    public readonly value!: Date | null;
    // #endregion


    // #region Value text
    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly valueText!: string | null;
    // #endregion


    // #region Min
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly min!: Date | null;
    // #endregion


    // #region Max
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly max!: Date | null;
    // #endregion


    private onChange(value: Date): void {
        if ((this.value === null) || this.value.datePartNotEquals(value)) {
            this.$emit(events.model, value);
        }
    }


    // #region Locale
    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly locale!: string | null;
    // #endregion


    public getValueFormatter(): Intl.DateTimeFormat {
        let locale: string;
        if (this.locale === null) {
            locale = 'en';
        } else {
            locale = this.locale;
        }

        return new Intl.DateTimeFormat(locale, { year: 'numeric', month: '2-digit', day: '2-digit' });
    }

    public getValueText(): string {
        if (this.valueText !== null) {
            return this.valueText;
        }

        if (this.value === null) {
            return '...';
        }

        return this.getValueFormatter().format(this.value);
    }


    // #region First day of week
    @Prop({
        type: Number,
        required: false,
        default: null
    })
    public readonly firstDayOfWeek!: number | null;
    // #endregion


    // #region Tool buttons
    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly today!: string | null;

    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly reset!: string | null;

    @Prop({
        type: String,
        required: false,
        default: null
    })
    public readonly clear!: string | null;
    // #endregion


    private onClick(): void {
        const visible = !this.localVisible;
        document.body.click();
        this.setVisible(visible);
    }
}