

















































































import Class from './c-menu';
export default Class;
