import { Vue, Component, Prop } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { ILastResult } from '../types';

HighchartsMore(Highcharts);

Highcharts.setOptions({
    lang: {
        loading: 'Загрузка...',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
        thousandsSep: ' ',
        decimalPoint: ','
    }
});


interface ILabel {
    labels: object;
    title: object;
    visible: boolean;
    maxPadding: number;
    stackLabels: object;
}

@Component
export default class ChartStackColumnView extends Vue {
    private options: Highcharts.Options = {};

    @Prop({
        type: Array,
        required: true
    })
    private data!: ILastResult[];

    @Prop()
    public readonly redrawTrigger!: any;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    private redrawTriggerChanged() {
        if (this.chart !== undefined) {
            this.chart.reflow();
        }
    }

    private formatDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric'
        });

        return format.format(date);
    }

    private quarterDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric',
            month: 'long'
        });

        return format.format(date);
    }

    private setOptions() {
        const colors = ['#c0504d', '#F79647', '#56B475', '#5a80ad', '#195275', '#632523', '#984807', '#2c4058'];
        const test: Highcharts.SeriesOptionsType[] = [];
        const label: ILabel[] = [];
        let xLine: any = {};
        this.data.forEach((elem, i) => {
            const datas = elem.data;
            if (datas === null) {
                return null;
            }
            const data: any = [];
            const names: string | null = datas.name;

            const labels: object = {
                enabled: false
            };
            const title: object = {
                enabled: false
            };
            const stackLabels: object = {
                enabled: true
            };
            const maxPadding = 0.1;
            const visible = true;
            label.push({ stackLabels, labels, title, visible, maxPadding });
            if (datas.periodicity === 'год') {
                const categories: any = [];
                datas.items.forEach((item) => {
                    const itemDate = item.date;
                    const itemDateText = this.formatDate(itemDate);
                    const count = Number(itemDateText);
                    categories.push(count);
                    data.push([count, item.value]);
                });
                xLine = {
                    type: 'category',
                    labels: {
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '14px',
                            fontWeight: 400
                        }
                    }
                };
            }
            if (datas.periodicity === 'квартал' || datas.periodicity === 'квартал с накоплением') {
                const categories: any[] = [];
                const milliseconds: number[] = [];
                datas.items.forEach((item, index) => {
                    const itemDate = item.date;
                    const year = itemDate.getFullYear();
                    let month = itemDate.getMonth() + 1;
                    if (month <= 3) {
                        month = 'I';
                    } else if ((month > 3) && (month <= 6)) {
                        month = 'II';
                    } else if ((month > 6) && (month <= 9)) {
                        month = 'III';
                    } else if ((month > 9) && (month <= 12)) {
                        month = 'IV';
                    }
                    const d = month + '-' + (String(year)).substr(2);
                    categories.push(d);
                    const milli = Date.parse(itemDate);
                    milliseconds.push(milli);
                    data.push(item.value);
                });
                xLine = {
                    type: 'category',
                    tickWidth: 0,
                    labels: {
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '14px',
                            fontWeight: 400
                        }
                    },
                    categories: categories
                };
            }
            if (datas.periodicity === 'неделя') {
                const categories: any = [];
                datas.items.forEach((item) => {
                    const year = item.date.getFullYear().toString().substr(-2);
                    let month = (item.date.getMonth() + 1).toString();
                    let day = item.date.getDate().toString();
                    if (month.length === 1) {
                        month = '0' + month;
                    }
                    if (day.length === 1) {
                        day = '0' + day;
                    }
                    const d = `${day}/${month}/${year}`;
                    categories.push(d);
                    data.push(item.value);
                });
                xLine = {
                    labels: {
                        useHTML: true,
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '10px',
                            fontWeight: 600
                        }
                    },
                    categories: categories,
                    tickInterval: 1
                };
            }
            if (datas.periodicity === 'месяц' || datas.periodicity === 'месяц с накоплением') {
                const categories: any = [];
                const milliseconds: number[] = [];
                datas.items.forEach((item, index) => {
                    const itemDate = item.date;
                    const shortMonthNames = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];
                    const year = itemDate.getFullYear();
                    const month = itemDate.getMonth();
                    const d = shortMonthNames[month] + '/' + (String(year)).substr(2);
                    categories.push(d);
                    const milli = Date.parse(itemDate);
                    milliseconds.push(milli);
                    data.push(item.value);
                });
                xLine = {
                    labels: {
                        useHTML: true,
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '12px',
                            fontWeight: 600
                        },
                        formatter() {
                            const that: any = this;
                            if (that.pos % 2 !== 0) {
                                return that.value;
                            }
                        }
                    },
                    categories: categories,
                    tickInterval: 1
                };
            }
            test.push({ name: names, data } as Highcharts.SeriesOptionsType);
            return test;
        });
        this.options = {
            colors,
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
                spacing: [0, 0, 0, 0],
                height: 205,
                style: {
                    fontFamily: 'Manrope, sans-serif'
                }
            },
            title: {
                text: ' '
            },
            exporting: {
                enabled: false,
                menuItemDefinitions: {
                    // Custom definition
                    printChart: {
                        text: 'Печать'
                    },
                    downloadPNG: {
                        text: 'Скачать PNG'
                    },
                    downloadJPEG: {
                        text: 'Скачать JPEG'
                    },
                    downloadPDF: {
                        text: 'Скачать PDF'
                    },
                    downloadSVG: {
                        text: 'Скачать SVG'
                    }
                }
            },
            xAxis: xLine,
            yAxis: label,
            legend: {
                align: 'left',
                alignColumns: false,
                verticalAlign: 'top',
                itemStyle: {
                    fontWeight: '500',
                    fontSize: '13px',
                    textOverflow: 'null'
                }
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Всего: {point.stackTotal}'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    borderWidth: 0,
                    stacking: 'normal',
                    dataLabels: {
                        align: 'center',
                        enabled: true
                    }
                },
                series: {
                    dataLabels: {
                        style: {
                            fontSize: '0',
                            fontWeight: '500',
                            textOutline: '0px',
                            color: '#3F4D67'
                        },
                        // eslint-disable-next-line consistent-return
                        formatter() {
                            if (this.y !== null) {
                                return Highcharts.numberFormat(this.y, 0);
                            }
                        }
                    },
                    animation: false
                }
            },
            series: test
        };
    }

    private chart: Highcharts.Chart | undefined;

    private dataChanged() {
        this.setOptions();
        if (this.chart === undefined) {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
            this.$el.attributes[3].value = 'overflow: visible';
        } else {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
            this.$el.attributes[3].value = 'overflow: visible';
        }
    }

    private created() {
        this.$watch('data', this.dataChanged);
        this.$watch('redrawTrigger', this.redrawTriggerChanged);
    }

    private downloadAction() {
        if (this.chart !== undefined) {
            if (this.download === 'print') {
                this.chart.print();
            }
            if (this.download === 'pdf') {
                this.chart.exportChart({
                    type: 'application/pdf'
                }, {});
            }
            if (this.download === 'jpeg') {
                this.chart.exportChart({
                    type: 'image/jpeg'
                }, {});
            }
            if (this.download === 'png') {
                this.chart.exportChart({
                    type: 'image/png'
                }, {});
            }
            if (this.download === 'svg') {
                this.chart.exportChart({
                    type: 'image/svg+xml'
                }, {});
            }
        }
    }

    private mounted() {
        this.dataChanged();
        this.$watch('download', this.downloadAction);
    }

    private beforeDestroy() {
        if (this.chart !== undefined) {
            this.chart.destroy();
        }
    }
}