<template>
    <div class="chart">
        <div class="top-info-block">
            <span style="cursor: pointer" @click="changePath"
                >{{ data.title }} {{ date }}</span
            >
        </div>
        <div class="content-block full-height">
            <div class="plan-fact">
                <div class="plan">
                    <div class="top-percent"></div>
                    <div class="plan-fact-content">
                        <div class="fill-block">
                            <div class="fill" style="height: 100%"></div>
                            <div class="number">
                                {{
                                    Math.round(data.data.items[0].plan * 10) /
                                    10
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="bottom-text">план</div>
                </div>
                <div class="fact">
                    <div class="top-percent">
                        {{ data.data.items[0].procent }}%
                    </div>
                    <div class="plan-fact-content">
                        <div class="fill-block">
                            <div
                                class="fill"
                                :style="{
                                    height: data.data.items[0].procent + '%',
                                }"
                            ></div>
                            <div class="number">
                                {{
                                    Math.round(data.data.items[0].fact * 10) /
                                    10
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="bottom-text">факт</div>
                </div>
            </div>
        </div>
        <div class="years-block">
            <div class="years-prev">
                <i class="icon icon-keyboard icon-rotate-180"></i>
            </div>
            <div class="years">
                <span>2018</span>
                <span>2019</span>
                <span>2020</span>
                <span class="active">2021</span>
                <span>2022</span>
                <span>2023</span>
                <span>2024</span>
            </div>
            <div class="years-next"><i class="icon icon-keyboard"></i></div>
        </div>
    </div>
</template>

<script>
import store from '@/services/store';
export default {
    name: 'Chart-investing',
    props: ['subHeader', 'data'],
    data() {
        return {
            date: '01.10.2021'
        };
    },
    methods: {
        changePath() {
            this.$router.push({
                path: '/monitoring',
                query: this.data.href
            });
        }
    }
    // created() {
    //     const d = new Date();
    //     let mon = d.getMonth() + 1;
    //     if (mon < 10) {
    //         mon = '0' + String(d.getMonth() + 1);
    //     }
    //     this.date = ` 01.${mon}.${d.getFullYear()}`;
    // }
};
</script>
