import { Module } from 'vuex';

import vueStore from '@/services/store';

import { IModuleState, IStatRequests } from './types';


const moduleName = 'requests';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const module: Module<IModuleState, any> = {
    namespaced: true,

    state: {
        activeProblems: new Map(),
        loading: false
    },

    mutations: {
        setLoading: (state, loading: boolean) => {
            state.loading = loading;
        },

        setActiveProblems: (state, newActiveProblems: Map<string, IStatRequests>) => {
            state.activeProblems = newActiveProblems;
        }
    }
};
vueStore.registerModule(moduleName, module);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const commit = (mutation: string, payload?: any) => {
    vueStore.commit(`${moduleName}/${mutation}`, payload);
};


export default {
    get state(): IModuleState {
        const root = vueStore.state as unknown as { [moduleName]: IModuleState };
        return root[moduleName];
    },

    get loading(): boolean {
        return this.state.loading;
    },
    set loading(value: boolean) {
        commit('setLoading', value);
    },

    get activeProblems(): Map<string, IStatRequests> {
        return this.state.activeProblems;
    },
    set activeProblems(value: Map<string, IStatRequests>) {
        commit('setActiveProblems', value);
    },

    get wipCount(): number {
        let result = 0;

        this.activeProblems.forEach((statRequests) => {
            result += statRequests.inprocess.length;
        });

        return result;
    }
};