import { CreateElement, RenderContext, VNode } from 'vue';


export type TRenderFunc = (h: CreateElement, c: RenderContext) => VNode;


export const viewBox = '0 0 24 24';
export const cssClass = 'c-icon c-icon-svg';
const cssClassAddition = ` ${cssClass}`;

export const createSvg = (h: CreateElement, context: RenderContext, ...children: VNode[]): VNode => {
    if (context.data.attrs === undefined) {
        context.data.attrs = {};
    }
    context.data.attrs.viewBox = viewBox;

    if (context.data.staticClass === undefined) {
        context.data.staticClass = cssClass;
    } else {
        context.data.staticClass += cssClassAddition;
    }

    const result = h('svg', context.data, children);
    return result;
};

export const createWithPath = (h: CreateElement, context: RenderContext, path: string): VNode => {
    const pathNode = h(
        'path',
        {
            attrs: {
                d: path
            }
        }
    );

    const result = createSvg(h, context, pathNode);
    return result;
};

const addToMap = (map: Map<string, TRenderFunc>, id: string, path: string): void => {
    map.set(id, (h, c): VNode => {
        return createWithPath(h, c, path);
    });
};
export default addToMap;