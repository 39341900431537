// Запускается после загрузки данных пользователя, здесь начинается выполнение основного кода проекта

import '@/extend';

// region Импорты из библиотек
import { Datepicker, Timepicker, DatetimePicker } from '@livelybone/vue-datepicker';
import BootstrapVue from 'bootstrap-vue';
import Highcharts from 'highcharts';
import Gantt from 'highcharts/modules/gantt';
import HighchartsModuleSolidGauge from 'highcharts/modules/solid-gauge';
import HighchartsModuleVariablePie from 'highcharts/modules/variable-pie';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsVue from 'highcharts-vue';
import Vue, { CreateElement } from 'vue';
import VueHighlightJS from 'vue-highlight.js';
import 'vue-highlight.js/lib/allLanguages.js';
import Multiselect from 'vue-multiselect';
import 'vue2-datepicker/locale/ru';
// endregion

// region Импорты из проекта
import Components from '@/components';
import { vue as i18n } from '@/services/i18n';
import router from '@/services/router';
import store from '@/services/store';
// endregion

// region Импорты из текущей папки
import App from './App.vue';
// endregion

/*
Импорты стилей - в файле "src/main.ts"
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'highlight.js/styles/androidstudio.css';
import 'vue2-datepicker/index.css';
import '@/assets/scss/style.scss';
*/


// region Настройка highcharts
Gantt(Highcharts);
HighchartsMore(Highcharts);
HighchartsModuleSolidGauge(Highcharts);
HighchartsModuleVariablePie(Highcharts);
// endregion

// region Настройка Vue
Vue.use(BootstrapVue);
Vue.use(HighchartsVue);
Vue.use(VueHighlightJS);
Vue.use(Components);

Vue.component('multiselect', Multiselect);
Vue.component('datepicker', Datepicker);
Vue.component('timepicker', Timepicker);
Vue.component('datetime-picker', DatetimePicker);

Vue.directive('demo', {
    bind: function (el, binding, vnode) {
        const s = JSON.stringify;
        el.innerHTML
            = 'name: ' + s(binding.name) + '<br>'
            + 'value: ' + s(binding.value) + '<br>'
            + 'expression: ' + s(binding.expression) + '<br>'
            + 'argument: ' + s(binding.arg) + '<br>'
            + 'modifiers: ' + s(binding.modifiers) + '<br>'
            + 'vnode keys: ' + Object.keys(vnode).join(', ');
    }
});

Vue.directive('hidden-roles', {
    bind: function (el, binding, vnode) {
        // if ((binding.value) && (binding.value.length>0)) {
        //     if (binding.value[0]=='roleTest') {
        //         console.log('hidden bind',  binding.value, el.innerText);
        //     }
        // }
        el.hidden = true; // скрыть предварительно все элементы с директивой
    },
    update: function (el, binding, vnode) {
        let roles: string[] = [];
        let found = true;
        let totalFound = true;
        // if ((binding.value) && (binding.value.length>0)) {
        //     if (binding.value[0]=='roleTest') {
        //         // console.log('Open to ',  binding.value, el.innerText);
        //     }
        // }
        if (store.state.user.roles !== undefined) {
            roles = store.state.user.roles.map(function (x) {
                return x.toUpperCase();
            });
            if (roles.length > 0) {
                totalFound = false;
                if (binding.value) {
                    found = binding.value.some((r: string) => roles.includes(r.toUpperCase()));
                }
                if (found) {
                    el.childNodes.forEach(value => el.removeChild(value));
                    if (el.parentNode) {
                        el.parentNode.removeChild(el);
                    }
                    // el.innerText = 'test' + el.innerText;
                } else {
                    if (!totalFound) {
                        totalFound = false;
                    }
                }
                el.hidden = totalFound;
            }
        }
    }
});
// endregion

// Запуск приложения Vue
new Vue({
    // подключение к приложению:
    router, // vue-router - плагин навигации
    store, // vuex - плагин общего для приложения хранилища
    i18n, // vue-i18n - плагин локализации
    render: (h: CreateElement) => h(App)
}).$mount('#app');
