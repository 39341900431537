export type TFlyingClickLocation = 'unknown' | 'anchor' | 'body';
export type TFlyingTrigger =
    'anchor-hover' | 'anchor-hover-show' | 'anchor-hover-hide' |
    'anchor-focus' | 'anchor-focus-show' | 'anchor-focus-hide' |
    'anchor-click' | 'anchor-click-show' | 'anchor-click-hide' |
    'body-click-hide' |
    'window-click-hide';
export const flyingTriggers: TFlyingTrigger[] = [
    'anchor-hover',
    'anchor-hover-show',
    'anchor-hover-hide',
    'anchor-focus',
    'anchor-focus-show',
    'anchor-focus-hide',
    'anchor-click',
    'anchor-click-show',
    'anchor-click-hide',
    'body-click-hide',
    'window-click-hide'
];

export type TModalHideReason = 'overlay-click' | 'close-click' | 'ok-click' | 'cancel-click';

export type TTabPlacement = 'top' | 'left' | 'right' | 'bottom';