import {Vue, Prop, Component} from 'vue-property-decorator';
import store from '@/services/store';

type FullAccesStates = true | false | undefined;

@Component
export default class CPanel extends Vue {
    @Prop({
        type: Array,
        required: true
    })
    public readonly sections!: any[];

    public curSection = {};


    private overAllAccesState: FullAccesStates = undefined; 

    /* public sectionList = [
        { id: 0, name: 'Основные показатели', src: '/img/panel/vector-0.png' },
        // { id: 15, name: 'Социально-экономическая модель ВРП', src: '/img/panel/vector-2.png' },
        // { id: 222, name: 'Все виджеты', src: '/img/panel/vector-1.png' },
        { id: 1, name: 'Демография и миграция', src: '/img/panel/vector-2.png' },
        { id: 2, name: 'ЖКХ', src: '/img/panel/vector-3.png' },
        { id: 3, name: 'Образование', src: '/img/panel/vector-4.png' },
        { id: 4, name: 'Здравоохранение', src: '/img/panel/vector-5.png' },
        { id: 5, name: 'Труд и социальная защита', src: '/img/panel/vector-6.png' },
        { id: 7, name: 'Строительство', src: '/img/panel/vector-7.png' },
        { id: 8, name: 'Сельское хозяйство', src: '/img/panel/vector-8.png' },
        { id: 9, name: 'Транспорт', src: '/img/panel/vector-9.png' },
        { id: 10, name: 'Телекоммуникации и связь', src: '/img/panel/vector-10.png' },
        { id: 11, name: 'Промышленность', src: '/img/panel/vector-11.png' },
        { id: 12, name: 'Торговля', src: '/img/panel/vector-12.png' },
        { id: 13, name: 'Малый и средний бизнес', src: '/img/panel/vector-13.png' }
    ]; */

    private getItemHref(item: any): string {
        if ((!(item instanceof Object)) || (typeof item.href !== 'string')) {
            return '#';
        }

        if (item.href.includes('://')) {
            return item.href;
        }

        return this.$router.resolve(
            {
                path: item.href,
                query: item.query,
                params: item.params
            },
            undefined,
            false
        )
            .href;
    }

    public selectSection(section: any) {
        this.curSection = section;

        this.$emit('selectSection', section);
    }

    private isMenuAviable(section: any): boolean {
        let res = false;
        let roles: any[] = []
        if (this.usrRoles != null) {
            roles = this.usrRoles.map(function (x) {
                return x.toUpperCase();
            });
        }
        if (section.hiddenRoles) {
            res = section.hiddenRoles.some((r: string) => roles.includes(r.toUpperCase()));
        }
        return !res;
    }

    private get usrRoles() {
        if (store.state.user.roles === undefined) {
            return null;
        }
        return store.state.user.roles;
    }

    public hasModuleAccess(moduleFullCode:string): boolean {
        const splitCode: string[] = moduleFullCode.split('.');
        const topLevelCode: string = splitCode[0]+'.000';
       
        const secondLevelCode: string = splitCode[0]+'.'+splitCode[1];
        if (this.overAllAccesState == undefined){
            this.overAllAccesState = store.state.user.userModules.find(user => user.modules !== topLevelCode 
                && user.modules.startsWith(splitCode[0])) == undefined;
        }
        return this.overAllAccesState || store.state.user.userModules.find(i => {
            const userModules: string[] = i.modules.split('.');
            return  secondLevelCode === userModules[0]+'.'+userModules[1];
        }) !== undefined;
    }
}