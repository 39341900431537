




































import Class from './c-content-chart-actual';
export default Class;
