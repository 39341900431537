import { Component, Prop, Vue } from 'vue-property-decorator';

import CSelect from '../c-select';


@Component
export default class CSelectItem extends Vue {
    // #region Lifecycle
    private mounted(): void {
        this.updateParent();
    }

    private updated(): void {
        this.updateParent();
    }
    // #endregion


    // #region Parent
    private parent: CSelect | null = null;

    private updateParent(): void {
        let parentComponent: Vue | null | undefined = this.$parent;
        while ((parentComponent !== undefined) && (parentComponent !== null) && (!(parentComponent instanceof CSelect))) {
            parentComponent = parentComponent.$parent;
        }

        if (parentComponent instanceof CSelect) {
            if (this.parent !== parentComponent) {
                this.parent = parentComponent;
            }
        } else if (this.parent !== null) {
            this.parent = null;
        }
    }
    // #endregion


    @Prop({
        required: false,
        default: null
    })
    private value!: any;


    private onClick(): void {
        if (this.parent === null) {
            console.warn('Parent is not defined');
        } else {
            this.parent.itemClicked(this.value);
        }
    }
}