// данные локализации при старте приложения (до начальной загрузки таких же данных)

import { LocaleMessageObject, DateTimeFormat, NumberFormat } from 'vue-i18n';
import enMessages from '@/../public/i18n/messages/en.json';

/**
 * Загружаемые данные локализации
 */
export interface ITranslates {
    /**
     * Форматы чисел
     */
    numberFormats: NumberFormat;

    /**
     * Форматы даты-времени
     */
    dateTimeFormats: DateTimeFormat;

    /**
     * Переводы
     */
    messages: LocaleMessageObject;
}

/**
 * Данные локализации при старте приложения (до начальной загрузки таких же данных)
 */
const messages: ITranslates = {
    numberFormats: enMessages.numberFormats,
    dateTimeFormats: enMessages.dateTimeFormats,
    messages: enMessages.messages
};
export default messages;