

































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { TMenuItem } from './types';
import { getFixedQuery } from './utils';
import { accessor as moduleAccessor } from './module';


@Component({
    inheritAttrs: true
})
export default class CBoardLeftTrigger extends Vue {
    @Prop({ required: true })
    private item!: TMenuItem;

    @Prop({ required: false, default: true })
    private collapsed!: boolean;

    private get href(): string | null {
        if (this.item.type === 'item') {
            switch (this.item.nav.type) {
                case 'route':
                    return ((): string => {
                        let path = this.item.nav.path;
                        if (!path.startsWith('/')) {
                            path = `/${path}`;
                        }

                        const rawLocation: RawLocation = { path };

                        if (this.item.nav.query instanceof Object) {
                            rawLocation.query = getFixedQuery(this.item.nav.query);
                        }

                        const resolvedLocation = this.$router.resolve(rawLocation);
                        return resolvedLocation.href;
                    })();
                case 'external':
                    return this.item.nav.path;
                default:
                    return null;
            }
        }

        return null;
    }

    private get activeItems(): TMenuItem[] {
        return moduleAccessor.active;
    }

    private get active(): boolean {
        return this.activeItems.includes(this.item);
    }

    private get isMenu(): boolean {
        return (this.item.type === 'menu');
    }

    private get isExternalLink(): boolean {
        return (
            (this.item.type === 'item')
            && (this.item.nav.type === 'external')
        );
    }

    private get endIconVisible(): boolean {
        return (this.isMenu || this.isExternalLink);
    }

    private onClick(ev: Event) {
        ev.preventDefault();
        this.$emit('click', ev);
    }
}
