import { Vue, Component, Prop } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { IItemPack } from '../types';
import groupedCategories from 'highcharts-grouped-categories';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import menuAccess from '@/utils/menu-access';

HighchartsMore(Highcharts);
groupedCategories(Highcharts);

Highcharts.setOptions({
    lang: {
        loading: 'Загрузка...',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
        thousandsSep: ' ',
        decimalPoint: ','
    }
});

interface ILabel {
    labels: object;
    title: object;
    visible: boolean;
    maxPadding: number;
}


@Component
export default class CView extends Vue {
    private lineOptions: Highcharts.Options = {};

    @Prop({
        type: Object,
        required: true
    })
    private data!: IItemPack;

    @Prop()
    public readonly redrawTrigger!: any;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    private redrawTriggerChanged() {
        if (this.chart !== undefined) {
            this.chart.reflow();
        }
    }

    private formatDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric'
        });

        return format.format(date);
    }

    private quarterDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric',
            month: 'long'
        });

        return format.format(date);
    }

    private updateOptions() {
        const colors = ['#459ED8', '#953735', '#e46c0a', '#416084', '#449fd8', '#c0504d', '#f79646', '#5a80ad', '#195275', '#632523', '#984807', '#2c4058'];
        const dataElements: any = [];
        let xLine: any = {};
        const label: ILabel[] = [];
        const n = (value: number): string => {
            return this.$n(value, 'number');
        };

        // описание грaфика по yAxis
        const labels: object = {
            style: {
                fontSize: 0
            }
        };
        const title: object = {
            style: {
                fontSize: 0
            }
        };

        const visible = true;
        const maxPadding = 0.1;
        label.push({ labels, title, visible, maxPadding });

        if (this.data.growDatas !== null) {
            if (this.data.growDatas.periodicity === 'год') {
                this.data.items.forEach((item) => {
                    const itemDate = item.date;
                    const itemDateText = this.formatDate(itemDate);
                    const count = Number(itemDateText);
                    dataElements.push([count, item.value]);
                });
                xLine = {
                    type: 'category',
                    tickWidth: 0,
                    labels: {
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '14px',
                            fontWeight: 400
                        }
                    }
                };
            }
            if (this.data.growDatas.periodicity === 'неделя') {
                const categories: any = [];
                this.data.items.forEach((item) => {
                    const year = item.date.getFullYear().toString().substr(-2);
                    let month = (item.date.getMonth() + 1).toString();
                    let day = item.date.getDate().toString();
                    if (month.length === 1) {
                        month = '0' + month;
                    }
                    if (day.length === 1) {
                        day = '0' + day;
                    }
                    const d = `${day}/${month}/${year}`;
                    categories.push(d);
                    dataElements.push(item.value);
                });
                xLine = {
                    labels: {
                        useHTML: true,
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '10px',
                            fontWeight: 600
                        }
                    },
                    categories: categories,
                    tickInterval: 1
                };
            }
            if (this.data.growDatas.periodicity === 'квартал' || this.data.growDatas.periodicity === 'квартал с накоплением') {
                const categories: any[] = [];
                const milliseconds: number[] = [];
                this.data.items.forEach((item, index) => {
                    const itemDate = item.date;
                    const year = String(itemDate.getFullYear());
                    let month = itemDate.getMonth() + 1;
                    if (month <= 3) {
                        month = 'I';
                    } else if ((month > 3) && (month <= 6)) {
                        month = 'II';
                    } else if ((month > 6) && (month <= 9)) {
                        month = 'III';
                    } else if ((month > 9) && (month <= 12)) {
                        month = 'IV';
                    }
                    const d = month + '-' + (String(year)).substr(2);
                    categories.push(d);
                    const milli = Date.parse(itemDate);
                    milliseconds.push(milli);
                    dataElements.push(item.value);
                });
                xLine = {
                    type: 'category',
                    tickWidth: 0,
                    labels: {
                        useHTML: true,
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '14px',
                            fontWeight: 400
                        }
                    },
                    categories: categories
                };
            }
            if (this.data.growDatas.periodicity === 'месяц' || this.data.growDatas.periodicity === 'месяц с накоплением') {
                const categories: any = [];
                const milliseconds: number[] = [];
                this.data.items.forEach((item) => {
                    const itemDate = item.date;
                    const shortMonthNames = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];
                    const year = itemDate.getFullYear();
                    const month = itemDate.getMonth();
                    const d = shortMonthNames[month] + '/' + (String(year)).substr(2);
                    categories.push(d);
                    const milli = Date.parse(itemDate);
                    milliseconds.push(milli);
                    dataElements.push(item.value);
                });
                xLine = {
                    type: 'category',
                    tickWidth: 0,
                    labels: {
                        useHTML: true,
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '12px',
                            fontWeight: 600
                        },
                        // eslint-disable-next-line consistent-return
                        formatter() {
                            // eslint-disable-next-line @typescript-eslint/no-this-alias
                            const that: any = this;
                            if (that.pos % 2 !== 0) {
                                return that.value;
                            }
                        }
                    },
                    categories: categories,
                    tickInterval: 1
                };
            }

            this.lineOptions = {
                colors,
                chart: {
                    type: 'line',
                    backgroundColor: 'transparent',
                    spacing: [0, 0, 0, 0],
                    height: 199,
                    style: {
                        fontFamily: 'Manrope, sans-serif'
                    }
                },
                title: {
                    text: ''
                },
                exporting: {
                    enabled: false,
                    menuItemDefinitions: {
                        // Custom definition
                        printChart: {
                            text: 'Печать'
                        },
                        downloadPNG: {
                            text: 'Скачать PNG'
                        },
                        downloadJPEG: {
                            text: 'Скачать JPEG'
                        },
                        downloadPDF: {
                            text: 'Скачать PDF'
                        },
                        downloadSVG: {
                            text: 'Скачать SVG'
                        }
                    }
                },
                xAxis: xLine,
                yAxis: label,
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true
                        },
                        enableMouseTracking: false,
                        showInLegend: false
                    },
                    series: {
                        dataLabels: {
                            formatter() {
                                return '<span style="color: ' + this.point.color + '">' + (this.y !== null ? n(this.y) : '') + '</span>';
                            },
                            style: {
                                fontSize: '.8rem',
                                fontWeight: '500',
                                textOutline: '0px'
                            }
                        },
                        animation: false
                    }
                },
                series: [{
                    type: 'line',
                    data: dataElements
                }],
                credits: {
                    enabled: false
                }
            };
        }
    }

    private chart: Highcharts.Chart | undefined;

    private dataChanged() {
        this.updateOptions();
        if (this.chart === undefined) {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.lineOptions);
            this.$el.attributes[2].value = 'overflow: visible';
        } else {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.lineOptions);
            this.$el.attributes[2].value = 'overflow: visible';
        }
    }

    private downloadAction() {
        if (this.chart !== undefined) {
            if (this.download === 'print') {
                this.chart.print();
            }
            if (this.download === 'pdf') {
                this.chart.exportChart({
                    type: 'application/pdf'
                }, {});
            }
            if (this.download === 'jpeg') {
                this.chart.exportChart({
                    type: 'image/jpeg'
                }, {});
            }
            if (this.download === 'png') {
                this.chart.exportChart({
                    type: 'image/png'
                }, {});
            }
            if (this.download === 'svg') {
                this.chart.exportChart({
                    type: 'image/svg+xml'
                }, {});
            }
        }
    }

    private created() {
        this.$watch('data', this.dataChanged);
        this.$watch('redrawTrigger', this.redrawTriggerChanged);
    }

    private mounted() {
        this.dataChanged();
        this.$watch('download', this.downloadAction);
    }

    private beforeDestroy() {
        if (this.chart !== undefined) {
            this.chart.destroy();
        }
    }
}