<template>
    <div class="chart">
        <div class="top-info-block">
            <div class="sub-header" v-if="subHeader">
                <span style="cursor: pointer" @click="changePath">{{
                    data.title
                }}</span>
            </div>
            <p v-else class="title">ВАКЦИНАЦИЯ</p>
        </div>
        <div class="content-block full-height">
            <div class="general-indicators-table default">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Всего</th>
                            <th>За сутки</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>План</td>
                            <td v-if="totalPlan > 0" class="number">{{totalPlan}}</td>
                            <td v-if="res[0]" class="number">{{res[0].plan}}</td>
                        </tr>
                        <tr>
                            <td class="light-blue bold">Факт</td>
                            <td v-if="total > 0" class="number light-blue">{{total}}</td>
                            <td v-if="res[0]" class="number light-blue">{{res[0].fact}}</td>
                        </tr>
                        <tr>
                            <td>Доля</td>
                            <td v-if="totalPlan > 0 && total > 0" class="number grey">{{ Math.round((total / totalPlan) * 100) }}%</td>
                            <td v-if="res[0]" class="number grey">{{ Math.round((res[0].fact / res[0].plan) * 100) }}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="years-block datepick">
            <date-picker
                v-model="value1.date"
                :disabled-date="disabledDays"
                format="DD-MM-YYYY"
                type="date"
                placeholder="Выбрать дату"
            ></date-picker>
            <div class="years-prev" @click="prev"><i class="icon icon-keyboard icon-rotate-180"></i></div>
            <div class="years-next" @click="next"><i class="icon icon-keyboard"></i></div>
        </div>
    </div>
</template>

<script>
import { accessor } from '../../store';
import queue from '../queue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
    name: 'Covid',
    props: ['data', 'subHeader'],
    components: { DatePicker },
    data() {
        return {
            res: [],
            total: 0,
            totalPlan: 0,
            value1: { date: new Date(), updated: false },
            availableDates: [],
            currentDate: new Date().getTime()
        };
    },
    methods: {
        changePath() {
            const category = this.data.dictCategoryEntity;
            let cat = category;
            if (category.code.split('.').length > 2) {
                cat = accessor.categories.find(item => item.id === category.parent);
            }
            const url = {
                path: '/soc-eco',
                query: {
                    code: cat.code,
                    id: cat.id,
                    href: '/soc-eco'
                }
            };
            this.$router.push(url);
        },
        getData(currentDate) {
            const result = [];
            this.total = 0;
            this.totalPlan = 0;
            this.data.indicatorConfigs.forEach((config) => {
                const indicator = accessor.getIndicator(config.indicator);
                if (indicator) {
                    indicator.items.sort((a, b) => b.date - a.date);
                    const selectedPeriod = indicator.items.reduce(function (prev, curr) {
                        return (Math.abs(curr.date.getTime() - currentDate) < Math.abs(prev.date.getTime() - currentDate) ? curr : prev);
                    });
                    if (!this.value1.updated) {
                        this.value1 = { date: new Date(selectedPeriod.date), updated: true };
                    }
                    if (indicator.periodicity === 'ежедневная') {
                        const fact = selectedPeriod.fact;
                        const plan = selectedPeriod.plan;
                        const item = { fact, plan };
                        result.push(item);
                    }
                    if (indicator.periodicity === 'единовременная') {
                        this.totalPlan = selectedPeriod.plan;
                    }
                    this.availableDates = [...indicator.items.map(itemes => itemes.date)];
                    if (indicator.periodicity === 'день с накоплением') {
                        this.total = selectedPeriod.fact;
                    }
                }
            });
            this.res = result;
        },
        selectedMapChanged() {
            setTimeout(() => {
                queue.schedule(this, () => {
                    this.getData(this.currentDate);
                });
            }, 500);
        },
        prev() {
            const date = `${this.value1.date.getFullYear()}-${this.value1.date.getMonth() + 1}-${this.value1.date.getDate()}`;
            for (const d of this.availableDates) {
                const available = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
                if (available < date) {
                    this.currentDate = new Date(available);
                    this.value1 = { date: new Date(available), updated: true };
                    break;
                }
            }
        },
        next() {
            const dateList = [...this.availableDates.sort((a, b) => a - b)];
            const date = `${this.value1.date.getFullYear()}-${this.value1.date.getMonth() + 1}-${this.value1.date.getDate()}`;
            for (const d of dateList) {
                const available = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
                if (available > date) {
                    this.currentDate = new Date(available);
                    this.value1 = { date: new Date(available), updated: true };
                    break;
                }
            }
        },
        disabledDays(value) {
            const valueDate = `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`;
            const disabled = this.availableDates.find(a => `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}` === valueDate);
            if (!disabled) {
                return value;
            }
        }
    },
    created() {
        this.$watch('res', this.selectedMapChanged);
        this.$watch('value1.date', () => {
            const date = `${this.value1.date.getFullYear()}-${this.value1.date.getMonth() + 1}-${this.value1.date.getDate()}`;
            this.availableDates.forEach((d) => {
                const available = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
                if (available === date) {
                    this.currentDate = new Date(available);
                }
            });
        });
    },
    mounted() {
        this.selectedMapChanged(this.currentDate);
    },
    beforeDestroy() {
        queue.clear(this);
    }
};
</script>