













import Class from './v-tab';
export default Class;
