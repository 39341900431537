


























































































import Class from './c-board';
export default Class;
