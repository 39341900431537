import { Component, Prop, Vue } from 'vue-property-decorator';

import { I18nTemplate } from '@/services/i18n';

import '../../initialization';
import { accessor } from '../../store';
import { ISelectedIndicatorItems, IWidgetMeters } from '../../store/types';
import { IGrowIndex, assignGrow } from '../common';

import queue from '../queue';

import CMeterView from './с-meter-view';

export interface IShownData {
    actual: boolean;
    value: number;
    max: number;
    warning: I18nTemplate;
    unit: string | null;
    growIndicProc: null | number; // индикатор роста в процентах
    growIndic: null | number; // 0- равное значение, 1 положительный рост, -1 падение роста
}

export interface IShownItem {
    title: string;
    data: IGrowIndex | null;
}


@Component({
    components: {
        'c-meter-view': CMeterView
    }
})
export default class CContentMeters extends Vue {
    private created() {
        this.$watch('getShownData', this.selectedMapChanged);
    }

    private mounted() {
        this.selectedMapChanged();
    }

    private beforeDestroy() {
        queue.clear(this);
    }


    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;

    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: IWidgetMeters;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    @Prop()
    public readonly redrawTrigger!: any;


    public get indicatorConfigs() {
        return this.data.indicatorConfigs;
    }


    public get selectedMap(): Map<string, ISelectedIndicatorItems> {
        const result = new Map<string, ISelectedIndicatorItems>();

        this.indicatorConfigs.forEach((key) => {
            const selectedIndicator = accessor.selectedMap.get(key.indicator);
            if (selectedIndicator !== undefined) {
                result.set(key.indicator, selectedIndicator);
            }
        });
        return result;
    }

    private selectedMapChanged() {
        setTimeout(() => {
            queue.schedule(this, () => {
                this.shownData = this.getShownData;
            });
        }, 1);
    }


    private shownData: IGrowIndex[] = [];

    public get getShownData(): IGrowIndex[] {
        const result: IGrowIndex[] = [];

        this.indicatorConfigs.forEach((indicatorKey) => {
            const data = assignGrow(indicatorKey.indicator);
            if (data !== undefined) {
                data.title = indicatorKey.title;
                let measurement = 1;
                if (indicatorKey.measurement !== undefined) {
                    measurement = indicatorKey.measurement;
                }
                const confUnit: any = indicatorKey.unit;
                let unit: any;
                if ((Object.keys(confUnit).length === 0)) {
                    unit = data.unit;
                } else if ((confUnit.name_ru === '')) {
                    unit = data.unit;
                } else {
                    if (confUnit.nationalSymbol === undefined || confUnit.nationalSymbol === null) {
                        unit = confUnit.name_ru;
                    } else {
                        unit = confUnit.nationalSymbol;
                    }
                }
                let unitLowerCased: string | null = null;
                if (unit !== null || unit !== undefined) {
                    unitLowerCased = unit.toLowerCase();
                    if (unitLowerCased === 'процент') {
                        unitLowerCased = '%';
                    }
                }
                data.unit = unit;
                data.value = data.value * measurement;
                result.push(data);
            }
        });

        return result;
    }
}