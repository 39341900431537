// экспорт компонентов приложения и плагина, который регистрирует их в Vue

import Vue, { PluginFunction } from 'vue';
import FocusLock from 'vue-focus-lock';
import VueDraggable from 'vuedraggable';

import сBadge from './c-badge';
import CCalendar from './c-calendar';
import CCard from './c-card';
import CDateNav from './c-date-nav';
import CDatePicker from './c-date-picker';
import CDatePickerBody from './c-date-picker-body';
import CDatePopover from './c-date-popover';
import CDropdown from './c-dropdown';
import CFlying from './c-flying';
import CFlyingFrame from './c-flying-frame';
import cIcon from './c-icon';
import CLoading from './c-loading';
import CMenu from './c-menu';
import CModal, { ModalHideEvent } from './c-modal';
import CMonthList from './c-month-list';
import CPagination from './c-pagination';
import CSelect from './c-select';
import CSelectItem from './c-select-item';
import CTab from './c-tab';
import CTabs from './c-tabs';
import CText from './Text.vue';
import CYearList from './c-year-list';
import CBoard from './c-board';
import CBoardLeft from './c-board-left/CBoardLeft.vue';
import CBoardLeftItem from './c-board-left/CBoardLeftItem.vue';
import CBoardLeftMenu from './c-board-left/CBoardLeftMenu.vue';
import CBoardLeftProxy from './c-board-left/CBoardLeftProxy.vue';
import CBoardLeftTrigger from './c-board-left/CBoardLeftTrigger.vue';
import CBoardRight from './c-board-right';
import CPanel from './c-panel';
import VTab from './v-tab';
import VTabs from './v-tabs';

// плагин для регистрации компонентов в Vue
const plugin: PluginFunction<unknown> = (vue: typeof Vue) => {
    vue.component('focus-lock', FocusLock);
    vue.component('vue-draggable', VueDraggable);

    vue.component<unknown>('c-badge', сBadge);
    vue.component('c-calendar', CCalendar);
    vue.component('c-card', CCard);
    vue.component('c-date-nav', CDateNav);
    vue.component('c-date-picker', CDatePicker);
    vue.component('c-date-picker-body', CDatePickerBody);
    vue.component('c-date-popover', CDatePopover);
    vue.component('c-dropdown', CDropdown);
    vue.component('c-flying', CFlying);
    vue.component('c-flying-frame', CFlyingFrame);
    vue.component<unknown>('c-icon', cIcon);
    vue.component('c-loading', CLoading);
    vue.component('c-menu', CMenu);
    vue.component('c-modal', CModal);
    vue.component('c-month-list', CMonthList);
    vue.component('c-pagination', CPagination);
    vue.component('c-select', CSelect);
    vue.component('c-select-item', CSelectItem);
    vue.component('c-tab', CTab);
    vue.component('c-tabs', CTabs);
    vue.component('c-text', CText);
    vue.component('c-year-list', CYearList);
    vue.component('c-board', CBoard);
    vue.component('c-board-left', CBoardLeft);
    vue.component('c-board-left-item', CBoardLeftItem);
    vue.component('c-board-left-menu', CBoardLeftMenu);
    vue.component('c-board-left-proxy', CBoardLeftProxy);
    vue.component('c-board-left-trigger', CBoardLeftTrigger);
    vue.component('c-board-right', CBoardRight);
    vue.component('c-panel', CPanel);
    vue.component('v-tab', VTab);
    vue.component('v-tabs', VTabs);
};
export default plugin;
export { CText, ModalHideEvent };