import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { I18nTemplate } from '@/services/i18n';

/**
 * Метод для выполнения запроса AJAX.
 * Без ошибок вызывает `onSuccess` и передает туда ответ сервера (уже прочитанный как JSON).
 * При какой-либо ошибке вызывает `onError` и передает туда перевод ошибки (объект класса `I18nTemplate`).
 *
 * @template T ожидаемый тип загруженных данных
 * @param config объект настроек запроса, нужно хотя бы `url` в нем заполнить
 * @param onSuccess этот метод вызывается при успешном выполнении запроса, в него передаются загруженные данные
 * @param onError этот метод вызывается при какой-либо ошибке, в него передается готовый перевод
 *   (полученный из сервера или сгенерированный на основе произошедшей ошибки)
 */
export default <T = unknown>(
    config: AxiosRequestConfig,
    onSuccess: (data: T, response: AxiosResponse<T>) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError?: (error: I18nTemplate, reason: any) => void,
    final?: () => void
): void => {
    axios.request<T>(config)
        .then((response) => {
            onSuccess(response.data, response);
        })
        .catch((reason) => {
            let error: I18nTemplate;

            // если reason - ошибка, перевод ошибки надо создать на основе reason
            // иначе - создать что-то типа "Произошла ошибка: ..."
            if (reason instanceof Error) {
                error = new I18nTemplate('error.network.common', reason.message);

                // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-extra-parens
                const response = (reason as any).response;

                // если в ошибке есть данные об ответе сервера, надо попытаться прочитать ответ сервера как перевод ошибки
                // и сохранить в переменной "error"
                if ((response instanceof Object) && (typeof response.status === 'number')) {
                    const data = response.data;
                    if (data instanceof Object) {
                        try {
                            // чтение ответа сервера
                            error = I18nTemplate.serverData(data);
                        } catch (e) {
                            // ответ сервера непонятный
                            error = new I18nTemplate('error.network.server-data.invalid', String(e));
                        }
                    }
                }
            } else {
                error = new I18nTemplate('error.network.common', String(reason));
            }

            // если передан метод "onError", надо вызвать его
            // иначе - добавить ошибку в список уведомлений
            if (typeof onError === 'function') {
                onError(error, reason);
            } else {
                // Отображение ошибки с помощью сервиса уведомлений
                // new I18nTemplate('error.network.requestErrorTitle')
                // TODO use toast service
                console.error('Request error', error);
            }
        })
        .finally(final);
};