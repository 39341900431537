import { Vue, Component, Prop } from 'vue-property-decorator';
import * as Highcharts from 'highcharts/highcharts-gantt';


Highcharts.setOptions({
    lang: {
        loading: 'Загрузка...',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек']
    }
});

@Component
export default class CChartGanttView extends Vue {
    private options: any;

    @Prop({
        type: Object,
        required: true
    })
    private data!: any;

    private ru_months = ['январь',
        'февраль',
        'март.',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь'];

    private getChart() {
        const names = this.data.model;
        // console.log(this.data.model)
        // // Parse car data into series.
        const d = [this.data];
        const series = d.map(function (dat: any) {
            const data = dat.deals.map(function (deal: any, i: number) {
                return {
                    id: 'deal-' + i,
                    name: deal.naming,
                    description: deal.description,
                    completionForm: deal.completionForm,
                    responsible: deal.responsible,
                    sourceOfFinancing: deal.sourceOfFinancing,
                    supportMeasures: deal.supportMeasures,
                    start: deal.from,
                    end: deal.to,
                    completed: deal.completion,
                    y: i
                };
            });
            return {
                name: names,
                data: data,
                current: dat.deals[dat.current]
            };
        });
        this.options = {
            chart: {
                type: 'gantt',
                backgroundColor: 'transparent'
            },
            series: series as any,
            title: {
                text: name
            },
            tooltip: {
                pointFormat: '<span>Наименование мероприятия: {point.name}</span><br/><span>С: {point.start:%e. %b}</span><br/><span>По: {point.end:%e. %b}</span>'
            },
            xAxis: {
                min: Date.UTC(2020, 0, 1),
                max: Date.UTC(2021, 0, 0),
                labels: {
                    format: '{value:%b %e }'
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            yAxis: {
                type: 'category',
                grid: {
                    enabled: true,
                    columns: [{
                        title: {
                            text: 'Наименование мероприятия'
                        },
                        categories: series[0].data.map(function (s: any) {
                            return s.name;
                        })
                    }
                    // {
                    //     title: {
                    //         text: 'Описание мероприятия'
                    //     },
                    //     categories: series[0].data.map(function (s: any) {
                    //         return s.description;
                    //     })
                    // },
                    // {
                    //     title: {
                    //         text: 'Форма завершения'
                    //     },
                    //     categories: series[0].data.map(function (s: any) {
                    //         return s.completionForm;
                    //     })
                    // },
                    // {
                    //     title: {
                    //         text: 'Ответственные за реализацию'
                    //     },
                    //     categories: series[0].data.map(function (s: any) {
                    //         return s.responsible;
                    //     })
                    // }
                    // {
                    //     title: {
                    //         text: 'Источник финансирования'
                    //     },
                    //     categories: series[0].data.map(function (s: any) {
                    //         return s.sourceOfFinancing;
                    //     })
                    // },
                    // {
                    //     title: {
                    //         text: 'Меры поддержки'
                    //     },
                    //     categories: series[0].data.map(function (s: any) {
                    //         return s.supportMeasures;
                    //     })
                    // },
                    // {
                    //     title: {
                    //         text: 'Responsible'
                    //     },
                    //     categories: series[0].data.map(function (s: any) {
                    //         return s.responsible;
                    //     })
                    // }
                    ]
                }
            }
        };
        return this.options;
    }

    private chart: Highcharts.Chart | undefined;

    private dataChanged() {
        this.getChart();
        if (this.chart === undefined) {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
            this.$el.attributes[2].value = 'overflow: visible';
        } else {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
            this.$el.attributes[2].value = 'overflow: visible';
        }
    }

    private created() {
        this.$watch('data', this.dataChanged);
    }

    private mounted() {
        this.dataChanged();
    }

    private beforeDestroy() {
        if (this.chart !== undefined) {
            this.chart.destroy();
        }
    }
}