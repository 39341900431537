import { Vue, Component, Prop } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { ILastResult } from '../types';

interface ILabel {
    title: object;
    labels: object;
    maxPadding: number;
    visible: boolean;
}

HighchartsMore(Highcharts);

Highcharts.setOptions({
    lang: {
        loading: 'Загрузка...',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек']
    }
});

@Component
export default class CChartBudgetView extends Vue {
    private options: Highcharts.Options = {};

    @Prop({
        type: Object,
        required: false
    })
    private data!: ILastResult;

    @Prop()
    public readonly redrawTrigger!: any;

    private redrawTriggerChanged() {
        if (this.chart !== undefined) {
            this.chart.reflow();
        }
    }

    private formatDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric'
        });

        return format.format(date);
    }

    private quarterDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric',
            month: 'long'
        });

        return format.format(date);
    }

    private setOptions() {
        const colors = ['#4472c4', '#ed7d31'];
        /** , '#e46c0a', '#416084', '#5a80ad', '#449fd8',
            '#c0504d', '#f79646', '#2c4058', '#195275', '#632523', '#984807' */
        let j = 0;
        const test: Highcharts.SeriesOptionsType[] = [];
        const label: ILabel[] = [];
        let types = false;
        let xAxis = {};
        const elem = this.data;
        const datas = elem.data;
        if (datas === null) {
            return null;
        }
        j += 1;
        const labels: object = {
            format: '{value}',
            style: {
                color: colors[j]
            }
        };
        const title: object = {
            style: {
                color: colors[j]
            }
        };

        const visible = false;
        const maxPadding = 0.1;
        label.push({ title, labels, maxPadding, visible });
        const data: any = [];
        const color = colors[j];
        const dataLabels = {
            y: 0
        };
        const legendIndex = 0;
        const names = datas.name + ', ' + datas.unit + ' (факт)';
        const tooltip: object = {
            valueSuffix: ' '
        };
        const yAxis = 0;
        let height = 50;
        const planEnabled = datas.plan;
        const type = 'column';
        const nameFact = 'Факт';
        const namePlan = 'План';
        if (datas.type === 'bar') {
            types = true;
            height = 30;
        }
        if (datas.growDatas !== null) {
            if (datas.growDatas.periodicity === 'год') {
                if (datas.plan === undefined) {
                    datas.items.forEach((item) => {
                        if (item.value !== 0) {
                            const itemDate = item.date;
                            const itemDateText = this.formatDate(itemDate);
                            const count = Number(itemDateText);
                            data.push([count, item.value]);
                        }
                    });
                    test.push({ name: names, type, data, tooltip, yAxis, legendIndex, color, dataLabels } as Highcharts.SeriesOptionsType);
                    if (planEnabled) {
                        const dataPlan: any = [];
                        const namesPlan = datas.name + ', ' + datas.unit + ' (план)';
                        j += 1;
                        datas.items.forEach((item) => {
                            if (item.plan !== 0) {
                                const itemDate = item.date;
                                const itemDateText = this.formatDate(itemDate);
                                const count = Number(itemDateText);
                                dataPlan.push([count, item.plan]);
                            }
                        });
                        test.push({ data: dataPlan, type, name: namesPlan, color: colors[j], tooltip, legendIndex, dataLabels, yAxis });
                    }
                    xAxis = {
                        gridLineWidth: 0,
                        labels: {
                            autoRotation: [90],
                            style: {
                                color: '#9cb3ce',
                                fontSize: '.9rem'
                            }
                        },
                        tickInterval: 1,
                        categories: [datas.name]
                    };
                } else {
                    datas.items.forEach((item) => {
                        if (item.value !== 0) {
                            const itemDate = item.date;
                            const itemDateText = this.formatDate(itemDate);
                            const count = Number(itemDateText);
                            data.push([item.value]);
                        }
                    });
                    test.push({ name: names, type, data, tooltip, yAxis, legendIndex, color, dataLabels } as Highcharts.SeriesOptionsType);
                    if (planEnabled) {
                        const dataPlan: any = [];
                        const namesPlan = datas.name + ', ' + datas.unit + ' (план)';
                        j += 1;
                        datas.items.forEach((item) => {
                            if (item.plan !== 0) {
                                const itemDate = item.date;
                                const itemDateText = this.formatDate(itemDate);
                                const count = Number(itemDateText);
                                dataPlan.push([item.plan]);
                            }
                        });
                        test.push({ data: dataPlan, type, name: namesPlan, color: colors[j], tooltip, legendIndex, dataLabels, yAxis });
                    }
                    xAxis = {
                        gridLineWidth: 0,
                        labels: {
                            enabled: false,
                            autoRotation: [90],
                            style: {
                                color: '#9cb3ce',
                                fontSize: '.9rem'
                            }
                        },
                        tickInterval: 1,
                        categories: [datas.name]
                    };
                }
            }
            if (datas.growDatas.periodicity === 'квартал' || datas.growDatas.periodicity === 'квартал с накоплением') {
                const categories: any[] = [];
                for (let i = 0; i < 2; i++) {
                    if (i === 0) {
                        datas.items.forEach((item, index) => {
                            if (item.value !== 0) {
                                const itemDate = item.date;
                                const year = itemDate.getFullYear();
                                let month = itemDate.getMonth() + 1;
                                if (month <= 3) {
                                    month = 'I кв';
                                } else if ((month > 3) && (month <= 6)) {
                                    month = 'II кв';
                                } else if ((month > 6) && (month <= 9)) {
                                    month = 'III кв';
                                } else if ((month > 9)) {
                                    month = 'IV кв';
                                }
                                if (index < 1) {
                                    const categor = {
                                        name: year,
                                        categories: [month]
                                    };
                                    categories.push(categor);
                                } else {
                                    const ind = categories.length - 1;
                                    if (categories[ind].name === year) {
                                        categories[ind].categories.push(month);
                                    } else {
                                        const categor = {
                                            name: year,
                                            categories: [month]
                                        };
                                        categories.push(categor);
                                    }
                                }
                                data.push(item.value);
                            }
                        });
                        xAxis = {
                            labels: {
                                style: {
                                    color: '#9cb3ce',
                                    fontSize: '.9rem'
                                }
                            },
                            tickWidth: 0,
                            categories: categories
                        };
                        test.push({ data, type, name: nameFact });
                    }
                    if ((i === 1) && (planEnabled)) {
                        datas.items.forEach((item) => {
                            if (item.plan !== 0) {
                                data.push(item.plan);
                            }
                        });
                    }
                }
            }
            if (datas.growDatas.periodicity === 'месяц' || datas.growDatas.periodicity === 'месяц с накоплением') {
                let l = false;
                let cat = [names];
                const categories: any = [];
                datas.items.forEach((item, index) => {
                    if (item.value !== 0) {
                        const itemDate = item.date;
                        const year = itemDate.getFullYear();
                        let month = itemDate.getMonth() + 1;
                        if (month < 10) {
                            month = '0' + month;
                        }
                        if (index < 1) {
                            const categor = {
                                name: year,
                                categories: [month]
                            };
                            categories.push(categor);
                        } else {
                            const ind = categories.length - 1;
                            if (categories[ind].name === year) {
                                categories[ind].categories.push(month);
                            } else {
                                const categor = {
                                    name: year,
                                    categories: [month]
                                };
                                categories.push(categor);
                            }
                        }
                        data.push(item.value);
                    }
                });
                if (datas.plan === undefined) {
                    l = true;
                    cat = categories;
                    // console.log(datas.plan);
                }
                xAxis = {
                    labels: {
                        enabled: l,
                        style: {
                            color: '#9cb3ce',
                            fontSize: '.9rem'
                        }
                    },
                    tickWidth: 0,
                    categories: cat
                };
                test.push({ name: names, type, data, tooltip, yAxis, legendIndex, color, dataLabels } as Highcharts.SeriesOptionsType);
                if (planEnabled) {
                    const dataPlan: any = [];
                    const namesPlan = datas.name + ', ' + datas.unit + ' (план)';
                    j += 1;
                    datas.items.forEach((item) => {
                        if (item.plan !== 0) {
                            dataPlan.push(item.plan);
                        }
                    });
                    test.push({ data: dataPlan, type, name: namesPlan, color: colors[j], tooltip, legendIndex, dataLabels, yAxis });
                }
            }
        }

        const n = (value: number): string => {
            return this.$n(value, 'number');
        };

        this.options = {
            colors,
            chart: {
                inverted: types,
                backgroundColor: 'transparent',
                // width: 320,
                height: height + '%'
            },
            title: {
                text: ' '
            },
            xAxis: xAxis,
            yAxis: label,
            plotOptions: {
                bar: {
                    grouping: true,
                    pointPadding: 0,
                    pointInterval: 0,
                    borderWidth: 0
                },
                column: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: true,
                    showInLegend: true
                },
                series: {
                    dataLabels: {
                        inside: (datas.type === 'bar'),
                        align: 'right',
                        style: {
                            fontSize: '.8rem',
                            fontWeight: '500',
                            textOutline: '0px'
                        },
                        formatter() {
                            if (datas.type === 'column') {
                                const anyThis = this as any;
                                if (anyThis.point.colorIndex === 0) {
                                    anyThis.point.shapeArgs.y = anyThis.point.shapeArgs.y - 6;
                                    anyThis.point.shapeArgs.x = anyThis.point.shapeArgs.x - 2;
                                }
                                if (anyThis.point.colorIndex === 1) {
                                    anyThis.point.shapeArgs.y = anyThis.point.shapeArgs.y + 5;
                                    anyThis.point.shapeArgs.x = anyThis.point.shapeArgs.x - 2;
                                }
                                return '<span style="color: ' + this.point.color + '; text-shadow: 2px 2px 10px #FFFFFF;">' + this.y + '</span>';
                            }
                            return '<span style="color: white;">' + (this.y !== null ? n(this.y) : '') + '</span>';
                        }
                    }
                }
            },
            tooltip: {
                shared: true
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            series: test
        };
        return this.options;
    }

    private chart: Highcharts.Chart | undefined;

    private dataChanged() {
        this.setOptions();
        if (this.chart === undefined) {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
            // this.$el.attributes[2].value = 'overflow: visible';
        } else {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
            // this.$el.attributes[2].value = 'overflow: visible';
            this.chart.reflow();
        }
    }

    private created() {
        this.$watch('data', this.dataChanged);
        this.$watch('redrawTrigger', this.redrawTriggerChanged);
    }

    private mounted() {
        this.dataChanged();
    }

    private beforeDestroy() {
        if (this.chart !== undefined) {
            this.chart.destroy();
        }
    }
}