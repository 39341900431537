export default class ElementPortal {
    private portal: HTMLElement | undefined;

    public portalConstructor: () => HTMLElement;

    public constructor(portalConstructor: () => HTMLElement) {
        this.portalConstructor = portalConstructor;
    }

    public add(...nodes: Array<string | Node>): void {
        if (this.portal === undefined) {
            this.portal = this.portalConstructor();
        }
        this.portal.append(...nodes);
    }

    public check(): void {
        if (this.portal !== undefined) {
            if (!this.portal.hasChildNodes()) {
                this.portal.remove();
                this.portal = undefined;
            }
        }
    }

    public remove(...nodes: Array<string | Node>): void {
        const { portal } = this;

        if (portal !== undefined) {
            const removed: Node[] = [];

            portal.childNodes.forEach((node) => {
                let removeNode = false;
                if (node instanceof Text) {
                    if ((node.textContent !== null) && nodes.includes(node.textContent)) {
                        removeNode = true;
                    }
                }
                if (nodes.includes(node)) {
                    removeNode = true;
                }

                if (removeNode) {
                    removed.push(node);
                }
            });

            removed.forEach((node) => {
                portal.removeChild(node);
            });
        }

        this.check();
    }
}