import Flying from './flying';
import Rect from './rect';


export type TPlacement = 'auto' | 'top' | 'right' | 'bottom' | 'left';
export const placements: TPlacement[] = [ 'auto', 'top', 'right', 'bottom', 'left' ];
Object.freeze(placements);

export type TStrictPlacement = 'top' | 'right' | 'bottom' | 'left';
export const strictPlacements: TStrictPlacement[] = [ 'top', 'right', 'bottom', 'left' ];
Object.freeze(strictPlacements);

export type TPlacementVariant = 'start' | 'end';
export const placementVariants: TPlacementVariant[] = [ 'start', 'end' ];
Object.freeze(placementVariants);

export type TStrategy = 'fixed' | 'absolute';
export const strategies: TStrategy[] = [ 'fixed', 'absolute' ];
Object.freeze(strategies);


export interface IPlacement {
    placement: TPlacement;
    variant?: TPlacementVariant;
}

export interface IStrictPlacement {
    placement: TStrictPlacement;
    variant?: TPlacementVariant;
}

export interface IPlacementRect {
    placement: IPlacement;
    strict: IStrictPlacement;
    rect: Rect;
}


// #region Anchor hide callback
export interface IAnchorHideCallbackData {
    root: Flying;
    anchor: Element;
    anchorRect: Rect;
    body: HTMLElement;
    anchorHidden: boolean;
}

export type TAnchorHideCallback = (data: IAnchorHideCallbackData) => void;
// #endregion



// #region Rects callback
export interface IRectsCallbackData extends IAnchorHideCallbackData {
    boundary: Rect;
    left: Rect;
    right: Rect;
    top: Rect;
    bottom: Rect;
    sides: [ Rect, Rect, Rect, Rect ];
    max: Rect;
    maxPlacement: TStrictPlacement;
}

export type TRectsCallback = (data: IRectsCallbackData) => void;
// #endregion



// #region Body prepare callback
export interface IBodyPrepareCallbackData extends IRectsCallbackData {
    rects: IPlacementRect[];
}

export type TBodyPrepareCallback = (data: IBodyPrepareCallbackData) => void;
// #endregion



// #region Choosen rect callback
export interface IChoosenRectCallbackData extends IBodyPrepareCallbackData {
    choosenRect: IPlacementRect;
}

export type TChoosenRectCallback = (data: IChoosenRectCallbackData) => void;
// #endregion



// #region Body placed callback
export interface IBodyPlacedCallbackData extends IChoosenRectCallbackData {
    overlapping: boolean;
}

export type TBodyPlacedCallback = (data: IBodyPlacedCallbackData) => void;
// #endregion



// #region Before arrow place callback
export interface IBeforeArrowPlaceCallbackData {
    root: Flying;
    arrow: HTMLElement;
    anchor: Element;
    anchorRect: Rect;
    body: HTMLElement;
    choosenRect: IPlacementRect;
}

export type TBeforeArrowPlaceCallback = (data: IBeforeArrowPlaceCallbackData) => void;
// #endregion


// #region After arrow place callback
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAfterArrowPlaceCallbackData extends IBeforeArrowPlaceCallbackData {}

export type TAfterArrowPlaceCallback = (data: IAfterArrowPlaceCallbackData) => void;
// #endregion