import { Component, Model, Prop, Vue } from 'vue-property-decorator';


const events = {
    model: 'change',
    input: 'input'
};

@Component
export default class CPagination extends Vue {
    @Model(events.model, {
        type: Number,
        required: true,
        default: 1
    })
    public readonly value!: number;

    @Prop({
        type: Number,
        required: false,
        default: 20
    })
    public readonly perPage!: number;

    @Prop({
        type: Number,
        required: false,
        default: 0
    })
    public readonly totalRows!: number;

    @Prop({
        type: Number,
        required: false,
        default: 5
    })
    public readonly limit!: number;


    public get max(): number {
        if (!(Number.isFinite(this.perPage) && (this.perPage > 0))) {
            return 0;
        }

        return Math.ceil(this.totalRows / this.perPage);
    }

    public get numbers(): number[] {
        const result: number[] = [this.value];

        let minPicked = this.value;
        let maxPicked = this.value;
        let pickLeft = this.limit - 1;

        while ((pickLeft > 0) && ((minPicked > 1) || (maxPicked < this.max))) {
            if (minPicked > 1) {
                minPicked--;
                result.unshift(minPicked);
                pickLeft--;
            }

            if ((pickLeft > 0) && (maxPicked < this.max)) {
                maxPicked++;
                result.push(maxPicked);
                pickLeft--;
            }
        }

        return result;
    }

    public get atStart(): boolean {
        return this.numbers.includes(1);
    }

    public get atEnd(): boolean {
        return this.numbers.includes(this.max);
    }


    private emitNewValue(value: number) {
        this.$emit(events.model, value);
        this.$emit(events.input, value);
    }

    private onToStartClick() {
        if (this.value > 1) {
            this.emitNewValue(1);
        }
    }

    private onToPreviousClick() {
        if (this.value > 1) {
            this.emitNewValue(this.value - 1);
        }
    }

    private onNumberButtonClick(value: number) {
        this.emitNewValue(value);
    }

    private onToNextClick() {
        if (this.value < this.max) {
            this.emitNewValue(this.value + 1);
        }
    }

    private onToEndClick() {
        if (this.value < this.max) {
            this.emitNewValue(this.max);
        }
    }
}