

































import Class from './c-select';
export default Class;
