import { Module } from 'vuex';

import vueStore from '@/services/store';

import { IModuleState } from './types';


const cssClass = 'dark';
const localStorageKey = 'service/theme/dark';

const getClassList = (): DOMTokenList => {
    return document.body.classList;
};


const moduleName = 'theme';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const module: Module<IModuleState, any> = {
    namespaced: true,

    state: {
        dark: false
    },

    mutations: {
        setDark: (state, dark: boolean) => {
            state.dark = dark;

            const classList = getClassList();
            if (dark) {
                if (!classList.contains(cssClass)) {
                    classList.add(cssClass);
                }
            } else if (classList.contains(cssClass)) {
                classList.remove(cssClass);
            }

            localStorage.setItem(localStorageKey, String(dark));
        }
    }
};
vueStore.registerModule(moduleName, module);


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const commit = (mutation: string, payload?: any) => {
    const fullMutation = `${moduleName}/${mutation}`;
    vueStore.commit(fullMutation, payload);
};

export default {
    get state(): IModuleState {
        const root = vueStore.state as unknown as { [moduleName]: IModuleState };
        return root[moduleName];
    },

    get dark(): boolean {
        return this.state.dark;
    },
    set dark(value: boolean) {
        commit('setDark', value);
    }
};

if (localStorage.getItem(localStorageKey) === 'true') {
    commit('setDark', true);
}