import { Component, Model, Prop, Vue } from 'vue-property-decorator';

import { Dates } from '@/utils';
import { getDateZoom, EDateZoom } from '@/utils/dates';


interface IItem {
    value: number;
    text: string;
    disabled: boolean;
    marked: boolean;
}


const events = {
    model: 'change',
    click: 'click'
};

@Component
export default class CYearList extends Vue {
    // #region Value
    @Model(events.model, {
        type: Date,
        required: false,
        default: null
    })
    public readonly value!: Date | null;

    public get valueDate(): Date | null {
        if (this.value === null) {
            return null;
        }
        return new Date(this.value).clearTimePart();
    }

    public get valueYear(): number | null {
        if (this.valueDate === null) {
            return null;
        }
        return this.valueDate.getFullYear();
    }
    // #endregion


    // #region Min
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly min!: Date | null;

    public get minDate(): Date {
        if (this.min === null) {
            return Dates.minDate;
        }
        return new Date(this.min).clearTimePart();
    }

    public get minYear(): number {
        return this.minDate.getFullYear();
    }
    // #endregion


    // #region Max
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly max!: Date | null;

    public get maxDate(): Date {
        if (this.max === null) {
            return Dates.maxDate;
        }
        return new Date(this.max).clearTimePart();
    }

    public get maxYear(): number {
        return this.maxDate.getFullYear();
    }
    // #endregion


    // #region Marked
    @Prop({
        type: Date,
        required: false,
        default: null
    })
    public readonly marked!: Date | null;

    public get markedYear(): number | null {
        return (this.marked === null ? null : this.marked.getFullYear());
    }
    // #endregion


    // #region Zoom
    @Prop({
        type: Number,
        required: false,
        default: null
    })
    public readonly zoom!: number | null;

    public get actualZoom(): EDateZoom {
        if (this.zoom === null) {
            return EDateZoom.DECADE;
        }

        return getDateZoom({
            alias: 'zoom',
            value: this.zoom,
            fallback: EDateZoom.DECADE,
            min: EDateZoom.DECADE,
            max: EDateZoom.YEARS_1000000
        });
    }

    public get zoomDivider(): number {
        return Math.pow(10, this.actualZoom - 2);
    }
    // #endregion


    // #region Items
    public get items(): IItem[] {
        const result: IItem[] = [];
        const addItem = (year: number, baseDelta: number): void => {
            const minYear = (year - (year % this.zoomDivider));
            const yearDelta = (
                this.zoomDivider === 1
                    ? 0
                    : (this.zoomDivider / 10) * 9
            );
            const maxYear = minYear + yearDelta;

            const disabled = (maxYear < this.minYear) || (minYear > this.maxYear);
            const marked = (this.markedYear !== null) && (this.markedYear >= minYear) && (this.markedYear <= maxYear);

            const text = (
                yearDelta === 0
                    ? String(minYear)
                    : `${minYear} - ${maxYear}`
            );

            const value = year + baseDelta;

            const item: IItem = { value, text, disabled, marked };
            result.push(item);
        };

        const baseYear = (
            this.value === null
                ? new Date().getFullYear()
                : this.value.getFullYear()
        );
        const baseRemain = (baseYear % (this.zoomDivider * 10));
        const start = baseYear - baseRemain;

        for (let i = 0; i < 10; i++) {
            addItem(start + (this.zoomDivider * i), (baseRemain % this.zoomDivider));
        }

        return result;
    }
    // #endregion


    private onClick(year: number): void {
        if ((this.min !== null) && (this.max !== null) && (this.min > this.max)) {
            console.error(`Min ${this.min} is greater than max ${this.max}`);
            return;
        }

        const date = new Date().clearTimePart();
        if (this.value !== null) {
            date.setTime(this.value.getTime());
        }

        const month = date.getMonth();
        date.setFullYear(year);
        if (date.getMonth() !== month) {
            date.changeDate(0);
        }

        if ((this.min !== null) && (date < this.min)) {
            date.setTime(this.min.getTime());
        } else if ((this.max !== null) && (date > this.max)) {
            date.setTime(this.max.getTime());
        }

        if ((this.value === null) || this.value.datePartNotEquals(date)) {
            if (this.value !== null) {
                date.copyTimePart(this.value);
            }
            this.$emit(events.model, date);
        }

        this.$emit(events.click);
    }
}
