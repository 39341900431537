import { Vue, Component, Prop } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';
// import OfflineExporting from 'highcharts/modules/exporting';
// import ExportData from 'highcharts/modules/exporting';
import { ILastResult } from '../types';

interface ISeries {
    name: string;
    type: string;
    data: object;
    tooltip: object;
    yAxis: undefined | number;
}
interface ILabel {
    labels: object;
    title: object;
    opposite: boolean;
    visible: boolean;
    maxPadding: number;
}

HighchartsMore(Highcharts);
Exporting(Highcharts);

Highcharts.setOptions({
    lang: {
        loading: 'Загрузка...',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
        thousandsSep: ' ',
        decimalPoint: ','
    }
});

@Component
export default class ChartModelData extends Vue {
    private options: Highcharts.Options = {};

    @Prop({
        type: Array,
        required: true
    })
    private data!: ILastResult[];

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    @Prop()
    public readonly redrawTrigger!: any;

    private redrawTriggerChanged() {
        if (this.chart !== undefined) {
            this.chart.reflow();
        }
    }

    private formatDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric'
        });

        return format.format(date);
    }
    private quarterDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric',
            month: 'long'
        });

        return format.format(date);
    }
    private setOptions() {
        const colors = ['#459ED8', '#953735', '#e46c0a', '#416084', '#449fd8', '#c0504d', '#f79646', '#5a80ad', '#195275', '#632523', '#984807', '#2c4058'];
        const test: Highcharts.SeriesOptionsType[] = [];
        const label: ILabel[] = [];
        let xLine: any = {};

        for (let i = 0; i < this.data.length; i++) {
            const elem = this.data[i];
            const datas = elem.data;
            if (datas === null) {
                return null;
            }
            // описание грaфика по yAxis
            const labels: object = {
                format: '{value}',
                style: {
                    color: colors[i],
                    fontSize: 0
                }
            };
            const title: object = {
                text: datas.unit,
                style: {
                    color: colors[i],
                    fontSize: 0
                }
            };
            let opposite = true;
            if ((i % 2 === 0)) {
                opposite = true;
            }
            const visible = false;
            const maxPadding = 0.1;
            label.push({ labels, title, opposite, visible, maxPadding });
            // создание графика(series)
            const data: any = [];
            const type = datas.type;
            const tooltip: object = {
                valueSuffix: ' '
            };
            const color = colors[i];
            const dataLabels = {
                y: 0
            };
            const legendIndex = i;
            let names = elem.title + ', ' + datas.unit;
            if (datas.unit === null) {
                names = datas.name;
            }
            if (datas.periodicity === 'год') {
                const categories: any = [];
                datas.items.forEach((item) => {
                    const itemDate = item.date;
                    const itemDateText = this.formatDate(itemDate);
                    const count = Number(itemDateText);
                    categories.push(count);
                    data.push([count, item.value]);
                });
                xLine = {
                    type: 'category',
                    tickWidth: 0,
                    labels: {
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '14px',
                            fontWeight: 400
                        }
                    },
                    tickInterval: 1
                };
            }
            if (datas.periodicity === 'неделя') {
                const categories: any = [];
                datas.items.forEach((item) => {
                    const year = item.date.getFullYear().toString().substr(-2);
                    let month = (item.date.getMonth() + 1).toString();
                    let day = item.date.getDate().toString();
                    if (month.length === 1) {
                        month = '0' + month;
                    }
                    if (day.length === 1) {
                        day = '0' + day;
                    }
                    const d = `${day}/${month}/${year}`;
                    categories.push(d);
                    data.push(item.value);
                });
                xLine = {
                    labels: {
                        useHTML: true,
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '10px',
                            fontWeight: 600
                        }
                    },
                    categories: categories,
                    tickInterval: 1
                };
            }
            if (datas.periodicity === 'квартал' || datas.periodicity === 'квартал с накоплением') {
                const categories: any[] = [];
                const milliseconds: number[] = [];
                datas.items.forEach((item, index) => {
                    const itemDate = item.date;
                    const year = itemDate.getFullYear();
                    let month = itemDate.getMonth() + 1;
                    if (month <= 3) {
                        month = 'I';
                    } else if ((month > 3) && (month <= 6)) {
                        month = 'II';
                    } else if ((month > 6) && (month <= 9)) {
                        month = 'III';
                    } else if ((month > 9) && (month <= 12)) {
                        month = 'IV';
                    }
                    const d = month + '-' + (String(year)).substr(2);
                    categories.push(d);
                    // if (index < 1) {
                    //     const categor = {
                    //         name: year,
                    //         categories: [month]
                    //     };
                    //     categories.push(categor);
                    // } else {
                    //     const ind = categories.length - 1;
                    //     if (categories[ind].name === year) {
                    //         categories[ind].categories.push(month);
                    //     } else {
                    //         const categor = {
                    //             name: year,
                    //             categories: [month]
                    //         };
                    //         categories.push(categor);
                    //     }
                    // }
                    const milli = Date.parse(itemDate);
                    milliseconds.push(milli);
                    data.push(item.value);
                });
                xLine = {
                    type: 'category',
                    tickWidth: 0,
                    labels: {
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '14px',
                            fontWeight: 400
                        }
                    },
                    categories: categories
                };
            }
            if (datas.periodicity === 'месяц' || datas.periodicity === 'месяц с накоплением') {
                const categories: any = [];
                const milliseconds: number[] = [];
                datas.items.forEach((item, index) => {
                    const itemDate = item.date;
                    const shortMonthNames = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];
                    const year = itemDate.getFullYear();
                    const month = itemDate.getMonth();
                    const d = shortMonthNames[month] + '/' + (String(year)).substr(2);
                    categories.push(d);
                    const milli = Date.parse(itemDate);
                    milliseconds.push(milli);
                    data.push(item.value);
                });
                xLine = {
                    labels: {
                        useHTML: true,
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '12px',
                            fontWeight: 600
                        },
                        formatter() {
                            const that: any = this;
                            if (that.pos % 2 !== 0) {
                                return that.value;
                            }
                        }
                    },
                    categories: categories,
                    tickInterval: 1
                };
            }
            const zoneAxis = 'x';
            let show = true;
            if (i > 0) {
                show = false;
            }
            const zones = [{
                value: 2019,
                dashStyle: 'solid'
            },
            {
                value: 2020,
                dashStyle: 'shortdashdot'
            },
            {
                dashStyle: 'dot'
            }];
            test.push({ name: names, type, data, tooltip, legendIndex, color, dataLabels, zoneAxis, zones, visible: show, yAxis: 0 } as Highcharts.SeriesOptionsType);
            test.forEach((item, index) => {
                if (datas.diffAxis === true) {
                    item.yAxis = index;
                }
            });
        }
        this.options = {
            colors,
            chart: {
                backgroundColor: 'transparent',
                spacing: [0, 0, 0, 0],
                height: 210,
                style: {
                    fontFamily: 'Manrope, sans-serif'
                }
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false,
                menuItemDefinitions: {
                    // Custom definition
                    printChart: {
                        text: 'Печать'
                    },
                    downloadPNG: {
                        text: 'Скачать PNG'
                    },
                    downloadJPEG: {
                        text: 'Скачать JPEG'
                    },
                    downloadPDF: {
                        text: 'Скачать PDF'
                    },
                    downloadSVG: {
                        text: 'Скачать SVG'
                    }
                }
            },
            xAxis: xLine,
            yAxis: label,
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: false,
                        formatter() {
                            if (this.y === null) {
                                return null;
                            }
                            return '<span style="color: ' + this.point.color + '">' + Highcharts.numberFormat(this.y, 0) + '</span>';
                        }
                    }
                },
                column: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true
                    }
                },
                series: {
                    dataLabels: {
                        enabled: false,
                        style: {
                            fontSize: '.8rem',
                            fontWeight: '500',
                            textOutline: '0px'
                        },
                        formatter() {
                            return '<span style="color: ' + this.point.color + '">' + (this.y !== null ? Highcharts.numberFormat(this.y, 0) : '') + '</span>';
                        }
                    },
                    animation: false
                }
            },
            credits: {
                enabled: false
            },
            tooltip: {
                shared: true
            },
            legend: {
                x: 20,
                align: 'left',
                verticalAlign: 'top',
                itemStyle: {
                    fontWeight: '500',
                    fontSize: '14px',
                    textOverflow: 'null'
                }/** ,
                labelFormatter: function () {
                    return '<span style="color:' + this.color + '">' + this.name + '</span>';
                } */
            },
            series: test
        };
        return this.options;
    }

    private chart: Highcharts.Chart | undefined;

    private dataChanged() {
        this.setOptions();
        if (this.chart === undefined) {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
            this.$el.attributes[2].value = 'overflow: visible';
        } else {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
            this.$el.attributes[2].value = 'overflow: visible';
        }
    }

    private downloadAction() {
        if (this.chart !== undefined) {
            if (this.download === 'print') {
                this.chart.print();
            }
            if (this.download === 'pdf') {
                this.chart.exportChart({
                    type: 'application/pdf'
                }, {});
            }
            if (this.download === 'jpeg') {
                this.chart.exportChart({
                    type: 'image/jpeg'
                }, {});
            }
            if (this.download === 'png') {
                this.chart.exportChart({
                    type: 'image/png'
                }, {});
            }
            if (this.download === 'svg') {
                this.chart.exportChart({
                    type: 'image/svg+xml'
                }, {});
            }
        }
    }

    private created() {
        this.$watch('data', this.dataChanged);
        this.$watch('redrawTrigger', this.redrawTriggerChanged);
    }

    private mounted() {
        this.dataChanged();
        this.$watch('download', this.downloadAction);
    }

    private beforeDestroy() {
        if (this.chart !== undefined) {
            this.chart.destroy();
        }
    }
}