import { Component, Model, Vue } from 'vue-property-decorator';


const events = {
    model: 'change'
};

@Component
export default class CSelect extends Vue {
    private visible = false;


    @Model(events.model)
    public readonly value!: any;


    public itemClicked(value: any): void {
        if (this.visible) {
            this.visible = false;
        }

        if (this.value !== value) {
            this.$emit(events.model, value);
        }
    }
}