<template>
    <div class="chart">
        <div class="top-info-block justify-content-between">
            <div class="sub-header" v-if="subHeader">
                <span style="cursor: pointer" @click="changePath">{{
                    data.title
                }}</span>
            </div>
            <p v-else class="title">COVID-2019</p>
            <p class="subtext">
                статус региона:
                <span
                    v-if="
                        res[0] && res[0].fact === 1
                    "
                    class="status green"
                    >Зеленый</span
                >
                <span
                    v-if="
                        res[0] && res[0].fact === 2
                    "
                    class="status yellow"
                    >Желтый</span
                >
                <span
                    v-if="
                        res[0] && res[0].fact === 3
                    "
                    class="status red"
                    >Красный</span
                >
            </p>
            <!--                            <p class="subtext">статус региона: <span class="status green">Зеленый</span></p>-->
            <!--                            <p class="subtext">статус региона: <span class="status blue">Синий</span></p>-->
            <!--                            <p class="subtext">статус региона: <span class="status red">Красный</span></p>-->
        </div>
        <div class="content-block full-height">
            <div class="double-general-indicators">
                <div class="indicators-block">
                    <div class="indicator">
                        <p class="text">Новые случаи</p>
                        <p v-if="res[1]" class="number red">
                            {{ res[1].fact }}
                        </p>
                    </div>
                    <div class="indicator">
                        <p class="text">Выздоровело</p>
                        <p v-if="res[2]" class="number green">
                            {{ res[2].fact }}
                        </p>
                    </div>
                </div>
                <div class="indicators-block">
                    <div class="indicator">
                        <p class="text">На лечении</p>
                        <p v-if="res[3]" class="number">
                            {{ res[3].fact }}
                        </p>
                    </div>
                    <div class="indicator">
                        <p class="text">Летальный исход</p>
                        <p v-if="res[4]" class="number">
                            {{ res[4].fact }}
                        </p>
                    </div>
                </div>
                <div class="indicators-block">
                    <div class="indicator">
                        <p class="text">Занятость коек</p>
                        <p
                            v-if="res[5]"
                            class="number light-blue"
                        >
                            {{ res[5].fact }}%
                        </p>
                    </div>
                    <div class="indicator">
                        <p class="text">Занятость реанимации</p>
                        <p
                            v-if="res[6]"
                            class="number light-blue"
                        >
                            {{ res[6].fact }}%
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="years-block datepick">
            <date-picker
                v-model="value1.date"
                :disabled-date="disabledDays"
                format="DD-MM-YYYY"
                type="date"
                placeholder="Выбрать дату"
            ></date-picker>
            <div class="years-prev" @click="prev"><i class="icon icon-keyboard icon-rotate-180"></i></div>
            <div class="years-next" @click="next"><i class="icon icon-keyboard"></i></div>
        </div>
    </div>
</template>

<script>
import { accessor } from '../../store';
import queue from '../queue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
    name: 'Covid',
    props: ['data', 'subHeader'],
    components: { DatePicker },
    data() {
        return {
            res: [],
            value1: { date: new Date(), updated: false },
            availableDates: [],
            currentDate: new Date().getTime()
        };
    },
    methods: {
        changePath() {
            const category = this.data.dictCategoryEntity;
            let cat = category;
            if (category.code.split('.').length > 2) {
                cat = accessor.categories.find(item => item.id === category.parent);
            }
            const url = {
                path: '/soc-eco',
                query: {
                    code: cat.code,
                    id: cat.id,
                    href: '/soc-eco'
                }
            };
            this.$router.push(url);
        },
        getData(currentDate) {
            const result = [];
            this.data.indicatorConfigs.forEach((config) => {
                const indicator = accessor.getIndicator(config.indicator);
                if (indicator) {
                    indicator.items.sort((a, b) => b.date - a.date);
                    const title = indicator.shortIndicator;
                    const selectedPeriod = indicator.items.reduce(function (prev, curr) {
                        return (Math.abs(curr.date.getTime() - currentDate) < Math.abs(prev.date.getTime() - currentDate) ? curr : prev);
                    });
                    const fact = selectedPeriod.fact;
                    if (!this.value1.updated) {
                        this.value1 = { date: new Date(selectedPeriod.date), updated: true };
                    }
                    const item = { title, fact, date: selectedPeriod.date };
                    result.push(item);
                    this.availableDates = [...indicator.items.map(itemes => itemes.date)];
                }
            });
            this.res = result;
        },
        selectedMapChanged() {
            setTimeout(() => {
                queue.schedule(this, () => {
                    this.getData(this.currentDate);
                });
            }, 500);
        },
        prev() {
            const date = `${this.value1.date.getFullYear()}-${this.value1.date.getMonth() + 1}-${this.value1.date.getDate()}`;
            for (const d of this.availableDates) {
                const available = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
                if (available < date) {
                    this.currentDate = new Date(available);
                    this.value1 = { date: new Date(available), updated: true };
                    break;
                }
            }
        },
        next() {
            const dateList = [...this.availableDates.sort((a, b) => a - b)];
            const date = `${this.value1.date.getFullYear()}-${this.value1.date.getMonth() + 1}-${this.value1.date.getDate()}`;
            for (const d of dateList) {
                const available = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
                if (available > date) {
                    this.currentDate = new Date(available);
                    this.value1 = { date: new Date(available), updated: true };
                    break;
                }
            }
        },
        disabledDays(value) {
            const valueDate = `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`;
            const disabled = this.availableDates.find(a => `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}` === valueDate);
            if (!disabled) {
                return value;
            }
        }
    },
    created() {
        this.$watch('res', this.selectedMapChanged);
        this.$watch('value1.date', () => {
            const date = `${this.value1.date.getFullYear()}-${this.value1.date.getMonth() + 1}-${this.value1.date.getDate()}`;
            this.availableDates.forEach((d) => {
                const available = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
                if (available === date) {
                    this.currentDate = new Date(available);
                }
            });
        });
    },
    mounted() {
        this.selectedMapChanged(this.currentDate);
    },
    beforeDestroy() {
        queue.clear(this);
    }
};
</script>