import { Vue, Component, Prop } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts);


@Component
export default class ChartStackColumnView extends Vue {
    private options: Highcharts.Options = {};

    @Prop({
        type: Array,
        required: true
    })
    private data!: any[];

    @Prop()
    public readonly redrawTrigger!: any;

    @Prop({
        type: String,
        required: false,
        default: ''
    })
    public readonly download!: string;

    private redrawTriggerChanged() {
        if (this.chart !== undefined) {
            this.chart.reflow();
        }
    }

    private formatDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric'
        });

        return format.format(date);
    }

    private quarterDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric',
            month: 'long'
        });

        return format.format(date);
    }

    private monthes = [
        { full: 'Январь', short: 'Янв.' },
        { full: 'Февраль', short: 'Фев.' },
        { full: 'Март', short: 'Мар.' },
        { full: 'Апрель', short: 'Апр.' },
        { full: 'Май', short: 'Май' },
        { full: 'Июнь', short: 'Июн.' },
        { full: 'Июль', short: 'Июл.' },
        { full: 'Август', short: 'Авг.' },
        { full: 'Сентябрь', short: 'Сен.' },
        { full: 'Октябрь', short: 'Окт.' },
        { full: 'Ноябрь', short: 'Ноя.' },
        { full: 'Декабрь', short: 'Дек.' }
    ]

    private setOptions() {
        const vertical = !this.data[0].vertical;
        let rotate = 90;
        let cls = '';
        if (vertical) {
            this.data.sort(function (a, b) {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            });
            rotate = 0;
            cls = 'labels-monitoring';
        }
        let colors = ['#5B8FF9', '#92D050', '#56B475', '#5a80ad', '#195275', '#632523', '#984807', '#2c4058'];
        const test: any[] = [];
        const label: any[] = [];
        const labels: object = {
            enabled: false
        };
        const title: object = {
            enabled: false
        };
        const stackLabels: object = {
            enabled: false
        };
        const maxPadding = 0.1;
        const visible = true;
        label.push({ stackLabels, labels, title, visible, maxPadding });
        const categories: any[] = [];
        const plan: any[] = [];
        const fact: any[] = [];
        let height = 400 + (10 * this.data.length);
        this.data.forEach((item) => {
            if (!vertical) {
                const mon = this.monthes.find(itemes => itemes.full === item.name);
                if (mon !== undefined) {
                    categories.push(mon.short);
                }
                fact.push(Math.round(item.fact * 100) / 100);
            } else if ((Math.round(item.plan * 10) / 10) > 0 && (Math.round(item.fact * 10) / 10) > 0) {
                categories.push(item.name);
                plan.push(Math.round(item.plan * 100) / 100);
                fact.push(Math.round(item.fact * 100) / 100);
            }
        });
        if (!vertical) {
            colors = ['#92D050'];
            height = 185;
            test.push({ name: 'Факт', data: fact });
        } else {
            test.push({ name: 'План на период', data: plan });
            test.push({ name: 'Факт', data: fact });
        }
        this.options = {
            colors,
            chart: {
                type: 'column',
                inverted: vertical,
                backgroundColor: 'transparent',
                spacing: [0, 0, 0, 0],
                height: height,
                style: {
                    fontFamily: 'Manrope, sans-serif'
                }
            },
            title: {
                text: ' '
            },
            exporting: {
                enabled: false
            },
            xAxis: {
                type: 'category',
                categories: categories,
                className: cls,
                labels: {
                    autoRotation: [rotate],
                    useHTML: true,
                    style: {
                        color: '#3F4D67',
                        fontSize: '14px'
                    }
                }
            },
            yAxis: label,
            legend: {
                enabled: vertical,
                align: 'left',
                alignColumns: false,
                verticalAlign: 'top',
                itemStyle: {
                    fontWeight: '500',
                    fontSize: '13px',
                    textOverflow: 'null'
                }
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Всего: {point.stackTotal}'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        // allowOverlap: true,
                        useHTML: true,
                        inside: false
                    },
                    animation: false
                }
            },
            series: test
        };
    }

    private chart: Highcharts.Chart | undefined;

    private dataChanged() {
        this.setOptions();
        if (this.chart === undefined) {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
            this.$el.attributes[3].value = 'overflow: visible';
        } else {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
            this.$el.attributes[3].value = 'overflow: visible';
        }
    }

    private created() {
        this.$watch('data', this.dataChanged);
        this.$watch('redrawTrigger', this.redrawTriggerChanged);
    }


    private mounted() {
        this.dataChanged();
    }

    private beforeDestroy() {
        if (this.chart !== undefined) {
            this.chart.destroy();
        }
    }
}